import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon } from 'antd';
import { get, put, post } from '../../../utils';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;

class Tag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        }
    }


    getTagDetail() {
        const { editId } = this.props
        let url = '/api/administrator/crm/ticket/tag/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ name: response.name })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getTagDetail()
        }
    }


    updateTag() {
        const { name } = this.state
        let data = {
            name: name,
            user: this.props.administratorId
        }
        if (this.props.editId) {
            let url = '/api/administrator/crm/ticket/tag/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/administrator/crm/ticket/tag/';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    onClickOk() {
        this.updateTag()
    }
    onClickCancel() {
        this.setState({ name: '' })
        this.props.backpage()
    }
    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            // wrapperCol: {
            //     xs: { span: 16 },
            //     sm: { span: 20 },
            // },
        };

        return (
            <div className='tagchat-page-wrapper' style={{ backgroundColor: '#F9F9F9', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >Tag</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Name'}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
})

export default connect(mapStateToProps, null)(Tag);

