const initialState = {
    packageDatail: null,
};


const packageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INITIALIZE_PACKAGE': { 
            return Object.assign({}, state, { packageDatail: action.payload })
        } 
        default:
            return state;
    }
}

export default packageReducer;