import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, notification } from 'antd';
import { get, put, post } from '../../../utils';
import defaultSquareSrc from '../../../resource/default/300x300-square.png'

import OrderAddress from './OrderAddress';
import OrderProduct from './OrderProduct';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;
const { TextArea } = Input;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

const actionPointRedeem = [
    {
        id: 1,
        label: "Point - Redeem",
        value: "Point - Redeem"
    },
    {
        id: 2,
        label: "Add - Point",
        value: "Add - Point"
    }

]
class PointRedeem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
     
            customerList: [],
            userCustomerId: null,
            description: null,
            point: 0,
            action: "Add - Point"
          
        }
    }


    getCustomerNonGuestList() {
        let url = '/api/crm/administrator/user/customer/non-guest';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log("responses: ", responses)
                this.setState({ customerList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getCustomerNonGuestList()
    }


    updatePointTransaction() {
        const {
            customerList,
            userCustomerId,
            point,
            action,
            description,
        } = this.state

        const customer = customerList.find(customer => customer.id === userCustomerId);

        if (!userCustomerId || userCustomerId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Customer".')
            return
        }
        if (!point || point == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Point".')
            return
        }
        if (!action || action == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Action".')
            return
        }
        if (action === "Point - Redeem" && point > customer.point ) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Point less than customer point".')
            return
        }

        let url = '/api/crm/administrator/sales/point-redeem-transaction';
        let update = post
        // if (this.props.editId) {
        //     url = '/api/crm/administrator/sales/order/' + this.props.editId
        //     update = put
        // }
        let data = {
            user_customer_id: userCustomerId,
            point: point,
            action: action,
            description: description,
            user: this.props.administratorId
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updatePointTransaction()
    }
    onClickCancel() {
        this.setState({
            name: '',
        })
        this.props.backpage()
    }
    render() {
        const formItemLayout = {
       
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutSummary = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
        };
        const { customerList, userCustomerId, point, action, description } = this.state
        const { folderName } = this.props
        const customer = customerList.find(customer => customer.id === userCustomerId);
        const imageSrc = customer ? `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${customer.avatar}` : defaultSquareSrc
        return (
            <div className='categoryarticle-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Sales Order' : 'Create Sales Order'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Form>
                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Customer Image'} required={false}>
                                    <img
                                        id="target"
                                        style={{ width: '100%', height: '100%' }}
                                        src={imageSrc}
                                        onError={(image) => image.target.src = defaultSquareSrc}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Customer Point'} required={false}>
                                    {/* <Input
                                        value={customer ? customer.point : 0}
                                        disabled={true}
                                    /> */}
                                    {customer ? customer.point : 0} Point

                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Customer'} required={true}>
                                    <Select
                                        showSearch={true}
                                        optionFilterProp={'title'}
                                        value={userCustomerId}
                                        onChange={(value) => this.setState({ userCustomerId: value })}
                                    >
                                        {customerList.map((customer, index) => {
                                            return (
                                                <Option key={customer.id} value={customer.id} title={customer.name}>{customer.name ? customer.name : customer.email}</Option>
                                            )
                                        })}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Action'} required={true}>
                                    <Select
                                        value={action}
                                        onChange={(value) => this.setState({ action: value })}
                                    >
                                        {actionPointRedeem.map((action, index) => {
                                            return (
                                                <Option key={action.id} value={action.value} title={action.label}>{action.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Point'} required={true}>
                                    <Input
                                        value={point}
                                        type='number'
                                        onChange={(event) => this.setState({ point: event.target.value > 0 ? event.target.value : 0 })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Description'} required={false}>
                                    <TextArea
                                        rows={4}
                                        value={description}
                                        onChange={(event) => this.setState({ description: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps, null)(PointRedeem);

