import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon, notification } from 'antd';
import { lget } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import LoadingModal from '../../../containers/CustomModal/LoadingModal';
import NotificationManualModal from '../../Notification/NotificationManualSend/NotificationManualModal'
import config from '../../../config.json'
import './style.scss'
notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;

class Course extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            name: '',
            courseType: '',
            categoryName: '',
            price: '',
        }
    }

    setStatusLoading(status) {
        this.setState({ loading: status })
    }

    getProductDetail(editId) {
        const { codexlearnApiProtocol, codexlearnApiHostname, codexlearnApiPort } = this.props
        let url = '/learn/connect/administrator/course/' + editId;
        let serverUrl = `${codexlearnApiProtocol}://${codexlearnApiHostname}:${codexlearnApiPort}` + url
        lget(serverUrl)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                if (responses.length) {
                    let response = responses[0]
                    this.setState({
                        name: response.name,
                        courseType: response.course_type,
                        categoryName: response.category_name,
                        price: response.price,
                    })
                }

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        const { editId } = this.props
        if (editId) {
            this.getProductDetail(editId)
        }
    }

    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div style={{ backgroundColor: '#FFFFFF', height: '100vh' }}>
                <div className="header-card" >
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'left', marginBottom: 10, cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{'View Course'}</span>
                    </Col>
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'right', marginBottom: 10 }}>
                    </Col>
                </div>
                <Card className="cardstyle">
                    {/* <Meta title="Course" /> */}
                    <Form>
                        <Row gutter={24}>
                            <Col sm={24} md={12} lg={8}  >
                                <FormItem {...formItemLayout} label={'Name'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={8}  >
                                <FormItem {...formItemLayout} label={'courseType'} required={true}>
                                    <Input
                                        value={this.state.courseType}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={8}  >
                                <FormItem {...formItemLayout} label={'categoryName'} required={true}>
                                    <Input
                                        value={this.state.categoryName}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={8}  >
                                <FormItem {...formItemLayout} label={'price'} required={true}>
                                    <Input
                                        value={this.state.price}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div >
        );
    }
}

Course.propTypes = {
    editId: PropTypes.number,
}

Course.defaultProps = {
    editId: null,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,

    codexlearnApiProtocol: state.login.codexlearn_api_protocol,
    codexlearnApiHostname: state.login.codexlearn_api_hostname,
    codexlearnApiPort: state.login.codexlearn_api_port,
})

export default connect(mapStateToProps)(Course);

