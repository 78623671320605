const initialState = {
    id: localStorage.getItem('id') ? localStorage.getItem('id') : null,
    name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
    username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
    avatar: localStorage.getItem('avatar') ? localStorage.getItem('avatar') : '',
    roleId: localStorage.getItem('roleId') ? localStorage.getItem('roleId') : null,
    roleName: localStorage.getItem('roleName') ? localStorage.getItem('roleName') : '',
    isAuthenticated: localStorage.getItem('isAuthenticated') ? localStorage.getItem('isAuthenticated') : false,
    folderName: localStorage.getItem('folderName') ? localStorage.getItem('folderName') : '0000000000',
    mainCustomerId: localStorage.getItem('mainCustomerId') ? localStorage.getItem('mainCustomerId') : null,
    emailConfirmed: localStorage.getItem('emailConfirmed') ? localStorage.getItem('emailConfirmed') : false,

    is_connect_codexlearn: localStorage.getItem('is_connect_codexlearn') ? localStorage.getItem('is_connect_codexlearn') : false,
    codexlearn_api_protocol: localStorage.getItem('codexlearn_api_protocol') ? localStorage.getItem('codexlearn_api_protocol') : 'http',
    codexlearn_api_hostname: localStorage.getItem('codexlearn_api_hostname') ? localStorage.getItem('codexlearn_api_hostname') : '',
    codexlearn_api_port: localStorage.getItem('codexlearn_api_port') ? localStorage.getItem('codexlearn_api_port') : '',
};


const login = (state = initialState, action) => {
    switch (action.type) {
        case 'INITIALIZE_LOGIN': {
            localStorage.setItem('isAuthenticated', true)
            localStorage.setItem('id', action.payload.id)
            localStorage.setItem('name', `${action.payload.firstname} ${action.payload.lastname}`)
            localStorage.setItem('username', action.payload.username)
            localStorage.setItem('avatar', action.payload.avatar)
            localStorage.setItem('roleId', action.payload.role_id)
            localStorage.setItem('roleName', action.payload.role_name)
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('emailConfirmed', action.payload.email_confirmed == 1 || action.payload.email_confirmed == '1')
            localStorage.setItem('folderName', action.payload.main_customer_id.toString().padStart(10, '0'))
            localStorage.setItem('mainCustomerId', action.payload.main_customer_id)

            localStorage.setItem('is_connect_codexlearn', action.payload.is_connect_codexlearn)
            localStorage.setItem('codexlearn_api_protocol', action.payload.codexlearn_api_protocol)
            localStorage.setItem('codexlearn_api_hostname', action.payload.codexlearn_api_hostname)
            localStorage.setItem('codexlearn_api_port', action.payload.codexlearn_api_port)
            return Object.assign({}, state, {
                isAuthenticated: true,
                id: action.payload.id,
                name: `${action.payload.firstname} ${action.payload.lastname}`,
                username: action.payload.username,
                avatar: action.payload.avatar,
                roleId: action.payload.role_id,
                roleName: action.payload.role_name,
                emailConfirmed: action.payload.email_confirmed == 1 || action.payload.email_confirmed == '1',
                folderName: action.payload.main_customer_id.toString().padStart(10, '0'),
                mainCustomerId: action.payload.main_customer_id,

                is_connect_codexlearn: action.payload.is_connect_codexlearn,
                codexlearn_api_protocol: action.payload.codexlearn_api_protocol,
                codexlearn_api_hostname: action.payload.codexlearn_api_hostname,
                codexlearn_api_port: action.payload.codexlearn_api_port,
            })
        }
        case 'LOGIN_SUCCESS': {
            localStorage.setItem('isAuthenticated', true)
            localStorage.setItem('id', action.payload.id)
            localStorage.setItem('name', `${action.payload.firstname} ${action.payload.lastname}`)
            localStorage.setItem('username', action.payload.username)
            localStorage.setItem('avatar', action.payload.avatar)
            // localStorage.setItem('roleId', action.payload.role_id)
            // localStorage.setItem('roleName', action.payload.role_name)
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('emailConfirmed', action.payload.email_confirmed == 1 || action.payload.email_confirmed == '1')
            return Object.assign({}, state, {
                isAuthenticated: true,
                id: action.payload.id,
                name: `${action.payload.firstname} ${action.payload.lastname}`,
                username: action.payload.username,
                avatar: action.payload.avatar,
                // roleId: action.payload.role_id,
                // roleName: action.payload.role_name,
                emailConfirmed: action.payload.email_confirmed == 1 || action.payload.email_confirmed == '1'
            })
        }
        case 'SIGNIN_MAIN_CUSTOMER_SUCCESS': {
            console.log('action.payloadaction.payloadaction.payload',action.payload)
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('folderName', action.payload.main_customer_id.toString().padStart(10, '0'))
            localStorage.setItem('mainCustomerId', action.payload.main_customer_id)
            localStorage.setItem('roleId', action.payload.role_id)
            localStorage.setItem('roleName', action.payload.role_name)

            localStorage.setItem('is_connect_codexlearn', action.payload.is_connect_codexlearn)
            localStorage.setItem('codexlearn_api_protocol', action.payload.codexlearn_api_protocol)
            localStorage.setItem('codexlearn_api_hostname', action.payload.codexlearn_api_hostname)
            localStorage.setItem('codexlearn_api_port', action.payload.codexlearn_api_port)
            return Object.assign({}, state, {
                folderName: action.payload.main_customer_id.toString().padStart(10, '0'),
                mainCustomerId: action.payload.main_customer_id,
                roleId: action.payload.role_id,
                roleName: action.payload.role_name,

                is_connect_codexlearn: action.payload.is_connect_codexlearn,
                codexlearn_api_protocol: action.payload.codexlearn_api_protocol,
                codexlearn_api_hostname: action.payload.codexlearn_api_hostname,
                codexlearn_api_port: action.payload.codexlearn_api_port,

            })
        }
        case 'SIGNOUT_MAIN_CUSTOMER_SUCCESS': {
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('folderName', null)
            localStorage.setItem('mainCustomerId', null)

            localStorage.setItem('is_connect_codexlearn', false)
            localStorage.setItem('codexlearn_api_protocol', 'http')
            localStorage.setItem('codexlearn_api_hostname', '')
            localStorage.setItem('codexlearn_api_port', '')
            return Object.assign({}, state, {
                folderName: '0000000000',
                mainCustomerId: null,

                is_connect_codexlearn: false,
                codexlearn_api_protocol: 'http',
                codexlearn_api_hostname: '',
                codexlearn_api_port: '',

            })
        }

        case 'LOGOUT': {
            localStorage.setItem('isAuthenticated', false)
            localStorage.setItem('id', null)
            localStorage.setItem('name', '')
            localStorage.setItem('username', '')
            localStorage.setItem('avatar', '')
            localStorage.setItem('role_id', null)
            localStorage.setItem('role_name', '')
            localStorage.setItem('token', null)
            localStorage.setItem('folderName', null)
            localStorage.setItem('mainCustomerId', null)
            localStorage.setItem('emailConfirmed', null)
            return Object.assign({}, state, {
                isAuthenticated: false,
                id: null,
                name: '',
                username: '',
                avatar: '',
                roleId: null,
                roleName: '',
                folderName: '0000000000',
                mainCustomerId: null,
                emailConfirmed: false
            })
        }
        default:
            return state;
    }
}

export default login;