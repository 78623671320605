import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import './style.scss'

const FormItem = Form.Item;

class Omise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { application_id_one, application_id_two, application_id_three } = this.props
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='omise-container-wrapper'>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <FormItem {...formItemLayout} label={'Omise Public Key'}>
                            <Input
                                value={application_id_one}
                                onChange={(event) => this.props.updateState({ application_id_one: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <FormItem {...formItemLayout} label={'Omise Secret Key'}>
                            <Input
                                value={application_id_two}
                                onChange={(event) => this.props.updateState({ application_id_two: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <FormItem {...formItemLayout} label={'Omise Public Key'}>
                            <Input
                                value={application_id_three}
                                onChange={(event) => this.props.updateState({ application_id_three: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default Omise;

