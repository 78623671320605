import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { initializeRole } from "../../../actions/role";
import { Form, Input, Card, Row, Col, Button, Select, Icon, Table, Checkbox, Modal, notification, Switch, DatePicker } from 'antd';
import { get, post, put, del } from '../../../utils';
import './style.scss'
import moment from 'moment';

notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;

class CustomerNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,

            userCustomerNotification: null,
            title: '',
            datetime: null,
            description: '',
            notification: true,
            notificationUserCustomer: true,
            notificationUserAdministrator: true,

            dataSource: [],
            columns: [
                {
                    title: 'Title',
                    dataIndex: 'title',
                    key: 'title',
                    align: 'left ',
                    width: '20%'
                },
                {
                    title: 'Detail',
                    dataIndex: 'description',
                    key: 'description',
                    align: 'center',
                    width: '30%',
                },
                {
                    title: 'Datetime',
                    dataIndex: 'datetime',
                    key: 'datetime',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => text ? moment(text).format('YYYY-MM-DD HH:ss:mm') : ''
                },
                {
                    title: 'Notification',
                    dataIndex: 'notification',
                    key: 'notification',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => `${item.notification ? 'Yes' : 'No'}`
                },
                {
                    title: 'Progress',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center',
                    width: '10%',
                    render: (text, item, index) => this.renderStatusColumn(text, item, index)
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    align: 'center',
                    width: '10%',
                    render: (text, item, index) => this.renderActionColumn(text, item, index)
                },
            ]
        }
    }
    renderStatusColumn(text, item, index) {
        switch (item.status) {
            case 'already':
                return <span style={{ color: 'green' }}>{'Already'}</span>
            case 'waitting':
                return <span style={{ color: 'orange' }}>{'Waitting'}</span>
            default:
                return <span>{item.status}</span>
        }
    }

    renderActionColumn(text, item, index) {
        let disabled = item.status === 'already'
        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                <div style={{
                    margin: 5,
                    width: 30, height: 30,
                    borderRadius: 15,
                    backgroundColor: '#EAEAEA',
                    cursor: !disabled ? 'pointer' : 'none'
                }}
                    onClick={() => !disabled ? this.onClickActionEdit(item) : null}
                >
                    <Icon type="edit" style={{ fontSize: 16, padding: 5, color: !disabled ? '#000' : '#fff' }} />
                </div>
                <div style={{
                    margin: 5,
                    width: 30, height: 30, borderRadius: 15,
                    backgroundColor: '#EAEAEA',
                    cursor: !disabled ? 'pointer' : 'none'
                }}
                    onClick={() => !disabled ? this.onClickActionDelete(item) : null}
                >
                    <Icon type="close" style={{ fontSize: 16, padding: 5, color: !disabled ? 'red' : '#fff' }} />
                </div>
            </div>
        )
    }
    onClickActionEdit(item) {
        this.setState({
            visible: true,
            userCustomerNotification: item.id,
            title: item.title,
            datetime: item.datetime,
            description: item.description,
            notification: item.notification,
            notificationUserCustomer: item.notification_user_customer === 1,
            notificationUserAdministrator: item.notification_user_administrator === 1,
        })
    }
    onClickActionDelete(item) {
        const { editId } = this.props
        let url = '/api/user/customer/' + editId + '/notification/' + item.id;
        del(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getCustomerNotificationDetail()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onChangeCheckbox(checked, index, name) {
        let dataSource = Object.assign([], this.state.dataSource)
        dataSource[index][name] = checked
        this.setState({ dataSource: dataSource })
    }

    getCustomerNotificationDetail() {
        const { editId } = this.props
        let url = '/api/user/customer/' + editId + '/notification';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ dataSource: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getCustomerNotificationDetail()
        }
    }

    updateCustomerNotification() {
        const { editId } = this.props
        const { title, datetime, description, notification, notificationUserCustomer, notificationUserAdministrator } = this.state
        if (!title || title == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Title".')
            return
        }
        let data = {
            title: title,
            datetime: datetime ? moment(datetime).format('YYYY-MM-DD HH:mm:ss') : null,
            description: description,
            notification: notification,
            notification_user_customer: notificationUserCustomer ? 1 : 0,
            notification_user_administrator: notificationUserAdministrator ? 1 : 0,
        }
        let url = '/api/user/customer/' + editId + '/notification';
        let update = post
        if (this.state.userCustomerNotification) {
            url = '/api/user/customer/' + editId + '/notification/' + this.state.userCustomerNotification
            update = put
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getCustomerNotificationDetail()
                this.closeModal()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickCreate() {
        this.setState({ visible: true })
    }

    onClickOk() {
        this.updateCustomerNotification()
    }

    onClickCancel() {
        this.closeModal()
    }

    closeModal() {
        this.setState({
            visible: false,
            userCustomerNotification: null,
            title: '',
            datetime: null,
            description: '',
            notificationUserCustomer: false,
            notificationUserAdministrator: false
        })
    }

    onChangeNotification(checked) {
        if (!checked) {
            this.setState({
                notification: checked,
                notificationUserCustomer: false,
                notificationUserAdministrator: false
            })
        } else {
            this.setState({ notification: checked })
        }
    }

    render() {
        const { visible, dataSource, columns } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutSwitch = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
        };
        return (
            <div className='notification-customer-container-wrapper'>
                <Row className='action-button-wrapper' gutter={24} type="flex" justify='space-between' style={{ padding: 10 }}>
                    <p className='title-header'>{'Notification'}</p>
                    <Button className='create-button' onClick={() => this.onClickCreate()}><Icon type="plus" />{'Create'}</Button>
                </Row>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
                <Modal
                    title={'Notification'}
                    visible={visible}
                    onOk={() => this.onClickOk()}
                    onCancel={() => this.onClickCancel()}
                >
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Titile'} required={true}>
                                    <Input
                                        value={this.state.title}
                                        onChange={(event) => this.setState({ title: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Date and Time'} required={true}> 
                                    <DatePicker
                                        showTime
                                        value={this.state.datetime ? moment(this.state.datetime) : null}
                                        onChange={(date, dateString) => this.setState({ datetime: dateString })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Detail'} required={true}>
                                    <TextArea
                                        rows={4}
                                        value={this.state.description}
                                        onChange={(event) => this.setState({ description: event.target.value })}
                                    />
                                </FormItem>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayoutSwitch} label={'Notification'} required={true}>
                                    <Switch
                                        style={{ paddingTop: 0 }}
                                        checked={this.state.notification}
                                        onChange={(checked) => this.onChangeNotification(checked)}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                <Checkbox
                                    style={{ paddingTop: 0 }}
                                    checked={this.state.notificationUserCustomer}
                                    onChange={(event) => this.setState({ notificationUserCustomer: event.target.checked })}
                                    disabled={!this.state.notification}
                                >{'Customer'}</Checkbox>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                <Checkbox
                                    style={{ paddingTop: 0 }}
                                    checked={this.state.notificationUserAdministrator}
                                    onChange={(event) => this.setState({ notificationUserAdministrator: event.target.checked })}
                                    disabled={!this.state.notification}
                                >{'Staff'}</Checkbox>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    roleId: state.login.roleId,
})

const mapDispatchToProps = (dispatch) => ({
    initializeRole: (payload) => dispatch(initializeRole(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomerNotification);

