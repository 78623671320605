

import { del } from './index'


export const deleteFileInServer = (fileName) => {
    return del('/api/crm/customer/upload/file', { fileName })
        .then((response) => {
            return response
        })
        .catch(err => {
            console.log(err.message)
            return err
        })
}