const initialState = {
    id: null,
    name: '',
    description: '',
    roleDatail: null,
};


const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INITIALIZE_ROLE': {
            return Object.assign({}, state, {
                id: action.payload.id,
                name: action.payload.name,
                description: action.payload.description,
                roleDetail: JSON.parse(action.payload.role_detail)
            })
        }
        default:
            return state;
    }
}

export default roleReducer;