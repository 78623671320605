import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


class CustomCircularProgressbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { title, positionDetail, borderWrapper } = this.props

        const ProgressDetail = (props) => {
            const { progressType, percentage, allValue, changeValue, allLabel, changeLabel, primaryColor, positionDetail } = props
            return (
                <div style={{ display: 'flex', textAlign: 'center', minWidth: 150, marginTop: ['right', 'left'].includes(positionDetail) ? 20 : 10 }}>
                    <div style={{ width: '33%' }}>
                        <div  >
                            <p style={{ margin: 0, color: primaryColor }}>{allValue}</p>
                            <p style={{ margin: 0, fontSize: 10 }}>{allLabel}</p>
                        </div>
                    </div>
                    <div style={{ width: '33%' }}>
                        <div  >
                            <p style={{ margin: 0, color: progressType == 'increase' ? 'green' : 'red' }}>{changeValue}</p>
                            <p style={{ margin: 0, fontSize: 10 }}>{changeLabel}</p>
                        </div>
                    </div>
                    <div style={{ width: '33%' }}>
                        <div >
                            <p style={{ margin: 0 }}>{`${percentage}%`}</p>
                            <p style={{ margin: 0, fontSize: 10}}>{progressType == 'increase' ? 'Increase' : 'Decrease'}</p>
                        </div>
                    </div>
                </div>
            )
        }

        const Progressbar = (props) => {
            const { progressType, percentage, primaryColor, secondaryColor } = props
            return (
                <div style = {{maxWidth : ['right', 'left'].includes(positionDetail) ? 80 : 80, marginLeft: 'auto', marginRight: 'auto'}}>
                    <CircularProgressbarWithChildren
                        value={percentage}
                        styles={buildStyles({
                            rotation: 0,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: primaryColor ? primaryColor : `rgba(62, 152, 199, ${percentage / 100})`,
                            textColor: '#f88',
                            trailColor: secondaryColor ? secondaryColor : '#d6d6d6',
                            backgroundColor: '#3e98c7',
                        })}
                    >

                        <div style={{ fontSize: 12, marginTop: -5, textAlign: 'center' }}>
                            <p style={{ margin: 0 }}>{progressType == 'increase' ? 'Increase' : 'Decrease'}</p>
                            <p style={{ margin: 0, fontSize: 20, color: primaryColor ? primaryColor : '#000000' }}>{`${percentage}%`}</p>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
            )
        }

        switch (positionDetail) {
            case 'bottom':
                return (
                    <div style={{ borderRadius: 3, border: borderWrapper, padding: 10 }}>
                        <p style={{ textAlign: 'center' }}>{title}</p>
                        <Progressbar {...this.props} />
                        <ProgressDetail {...this.props} />
                    </div>
                )
            case 'right':
                return (
                    <div style={{ borderRadius: 3, border: borderWrapper, padding: 10 }}>
                        <p style={{ textAlign: 'left' }}>{title}</p>
                        <div style={{ display: 'flex' }}>
                            <Progressbar {...this.props} />
                            <ProgressDetail {...this.props} />
                        </div>
                    </div>
                )
            default:
                return (
                    <div></div>
                );
        }

    }
}

CustomCircularProgressbar.propTypes = {
    title: PropTypes.string,
    progressType: PropTypes.string,
    percentage: PropTypes.number,

    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,

    allValue: PropTypes.number,
    changeValue: PropTypes.number,

    allLabel: PropTypes.string,
    changeLabel: PropTypes.string,

    positionDetail: PropTypes.string,
    borderWrapper: PropTypes.string,
}

CustomCircularProgressbar.defaultProps = {
    title: '',
    progressType: 'increase',
    percentage: 0,

    primaryColor: '#47C5EB',
    secondaryColor: '#B8E8F7',

    allValue: 0,
    changeValue: 0,

    allLabel: 'All',
    changeLabel: 'Up',

    positionDetail: 'bottom',
    borderWrapper: '1px solid #f5f5f5'
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated
})

export default connect(mapStateToProps)(CustomCircularProgressbar);

