import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { get, post,ppost } from '../../utils';
import { GiftedChat, Bubble } from 'react-web-gifted-chat'
import SocketIOClient from 'socket.io-client';
import uniqid from 'uniqid';
import moment from 'moment';
import { Icon, Popover, Col, Row, Input } from 'antd';
import UploadImage from './UploadImage';
// import UploadVideo from './UploadVideo';
import StorageOverLimitModal from '../../containers/CustomModal/CustomerOverLimitModal';
import ChatProductCard from './ChatProductCard';
import ChatCategoryProductCard from './ChatCategoryProductCard';
import ChatVideoCard from './ChatVideoCard';
import ChatRegisterCard from './ChatRegisterCard'
import config from '../../config.json'
import defaultSquareSrc from '../../resource/default/300x300-square.png'
import './style.scss'


const { TextArea } = Input;

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tempTyping: false,
            showTyping: false,
            showTypingUserList: [],
            typeFile: ['image/png', 'image/jpg'],
            visibleModalOversStorage:false,
            hoveredImage: false,
            hoveredVideo: false,
            messages: [],
        }
        this.GiftedChatRef = React.createRef()
        this.socket = SocketIOClient(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}`);
        this.socketTicketList = []
    }

    addStoreDataMessages(massages) {
        const { ticketId } = this.props
        if (massages.length > 0) {
            let storeDataString = localStorage.getItem(`massages-${ticketId}`);
            let storeData = []
            if (storeDataString) {
                storeData = JSON.parse(storeDataString)
            }
            storeData.push(massages[0])
            localStorage.setItem(`massages-${ticketId}`, JSON.stringify(storeData))
        }
    }

    deleteStoreDataMessages(massages) {
        const { ticketId } = this.props
        if (massages.length > 0) {
            let storeDataString = localStorage.getItem(`massages-${ticketId}`);
            let storeData = []
            if (storeDataString) {
                storeData = JSON.parse(storeDataString)
            }
            let index = storeData.findIndex(item => item.id == massages[0].id || item._id == massages[0]._id)
            if (index != -1) {
                storeData.splice(index, 1);
            }
            let mgsTemp = Object.assign([], this.state.messages)
            let mgsIndex = mgsTemp.findIndex(item => item.id == massages[0].id || item._id == massages[0]._id)
            if (mgsIndex != -1) {
                mgsTemp.splice(mgsIndex, 1);
            }

            localStorage.setItem(`massages-${ticketId}`, JSON.stringify(storeData))
            this.setState({ messages: mgsTemp })
        }
    }

    updateTicketList() {
        const { messages } = this.state
        let receivedList = []
        messages.map(message => {
            if (!message.received && message.user.permission == 'client') {
                receivedList.push(message.id)
            }
        })
        if (receivedList.length > 0) {
            this.updateReceived(receivedList)
        }
    }

    updateReceived(messageIdList, done) {
        const { ticketId } = this.props
        const url = '/api/customer/message/received/' + ticketId
        post(url, { messageIdList: JSON.stringify(messageIdList) })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                done(response)
            })
            .catch(err => {
                console.log(err)
            })
    }

    updateReceivedMessages() {
        let messages = Object.assign([], this.state.messages)
        messages.map(message => {
            if (message.user.permission == 'administrator') {
                message.received = true
            }
        })
        this.setState({ messages: [] }, () => this.setState({ messages: messages }))
    }

    getMessage(ticketId) {
        const { folderName } = this.props
        const url = '/api/administrator/crm/message/ticket/' + ticketId
        let storeDataString = localStorage.getItem(`massages-${ticketId}`);
        let storeData = []
        if (storeDataString) {
            storeData = JSON.parse(storeDataString)
        }

        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.length > 0) {
                    let receivedList = []
                    let messages = response.map(message => {
                        if (!message.received && message.user.permission == 'client') {
                            receivedList.push(message.id)
                            message.received = 1
                        }

                        let passIndex = storeData.findIndex(item => item.id == message.id)
                        if (passIndex != -1) {
                            storeData.splice(passIndex, 1);
                        }

                        if (message.imageName != null) {
                            message['image'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${message.imageName}`
                        }
                        if (message.fileName != null) {
                            message['file'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${message.fileName}`
                        }
                        message['error'] = false

                        // message['createdAt'] = moment.utc(message['createdAt']).local().format('YYYY-MM-DD HH:ss:mm')
                        return message
                    })
                    localStorage.setItem(`massages-${ticketId}`, JSON.stringify(storeData));
                    storeData.map(item => {
                        let temp = Object.assign({}, item)
                        temp['error'] = true
                        messages.unshift(temp)
                    })
                    this.setState({ messages: messages })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    updateMessages(ticketId, messages) {
        if (this.props.ticketId == ticketId) {
            this.appendMessages(messages)
        }
        this.props.getChatList()
    }

    componentWillReceiveProps(nextProps) {
        const { administratorId } =this.props

        const dataInitialSocket = {
            ticketId : nextProps.ticketId,
            customerId : administratorId
        }

        // console.log('get Message next Prop', nextProps.ticketId)
        this.socket.emit(`received-${nextProps.ticketId}`, 'administrator')
        if (nextProps.closepage !== this.props.closepage && nextProps.closepage) {
            this.updateTicketList()
        }

        if (nextProps.ticketId !== this.props.ticketId) {
            if (!this.socketTicketList.includes(nextProps.ticketId)) {
                this.socketTicketList.push(nextProps.ticketId)
                // this.socket.emit('ticket', nextProps.ticketId);

                this.socket.emit('ticket', dataInitialSocket);
                
                this.socket.on(`${nextProps.ticketId}`, (message) => this.updateMessages(nextProps.ticketId, message));
            }
            this.getMessage(nextProps.ticketId)
            this.props.getChatList()
        }
    }

    componentDidMount() {
        this.generateMessageId()
        const { ticketId, administratorId } = this.props
        const dataInitialSocket = {
            ticketId : ticketId,
            customerId : administratorId
        }

        // console.log("========================")
        // console.log("ticketId  :",ticketId)
        // console.log("========================")

        // this.socket.emit('ticket', ticketId);
        this.socket.emit('ticket', dataInitialSocket);
        this.socketTicketList.push(ticketId)
        this.socket.on(`${ticketId}`, (message) => this.updateMessages(ticketId, message));
        this.socket.on(`received-administrator-${ticketId}`, () => this.updateReceivedMessages());
        this.socket.on(`administrator-ticket-notification`, () => this.props.getChatList());

        this.getMessage(ticketId)
        this.socket.on('disconnect', () => {
            if (this.props.ticketId) {
                // this.socket.emit('ticket', this.props.ticketId);
                this.socket.emit('ticket', dataInitialSocket);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ticketId != this.props.ticketId) {
            // this.socket.emit(`received-${this.props.ticketId}`, 'administrator') 
            this.socket.emit(`hide-typing-administrator-${prevProps.ticketId}`, { id: prevProps.administratorId, name: prevProps.administratorName, avatar: prevProps.administratorAvatar, ticketId: prevProps.ticketId });

            this.socket.on(`hide-typing-administrator-${this.props.ticketId}`, (data) => this.hideTypingAdministrator(data));
            this.socket.on(`show-typing-administrator-${this.props.ticketId}`, (data) => this.showTypingAdministrator(data));
            this.socket.on(`hide-typing-client-${this.props.ticketId}`, (data) => this.hideTypingClient(data));
            this.socket.on(`show-typing-client-${this.props.ticketId}`, (data) => this.showTypingClient(data));

            this.setState({ messages: [], tempTyping: false })
        }
    }

    componentWillUnmount() {
        const { ticketId, administratorId, administratorName, administratorAvatar } = this.props
        this.socket.emit('disconnect');
        this.socket.disconnect()
        clearInterval(this.state.receivedInterval)
        this.socket.emit(`hide-typing-administrator-${ticketId}`, { id: administratorId, name: administratorName, avatar: administratorAvatar, ticketId: ticketId });
        this.setState({ tempTyping: false })

    }

    appendMessages(messages) {
        this.setState((previousState) => ({
            messages: GiftedChat.append(previousState.messages, messages),
        }))
    }

    onSend(messages = []) {
        // console.log('this.socket', messages)
        const { ticketId } = this.props
        if (messages.length) {
            messages[0]['_id'] = messages[0]['id']
            messages[0]['sent'] = true
        }
        this.addStoreDataMessages(messages)
        this.appendMessages(messages)
        this.socket.emit(`${ticketId}`, messages)
        this.props.getChatList()
        // window.scroll(0, 100); 
        this.GiftedChatRef.current.scrollTop = this.GiftedChatRef.current.scrollHeight;
    }

    generateMessageId() {
        let messageId = `${uniqid()}-${uniqid().slice(4, 8)}-${uniqid().slice(4, 8)}-${uniqid().slice(4, 8)}-${uniqid().slice(1, 4)}${uniqid().slice(2, 6)}${uniqid().slice(4, 8)}`
        return messageId
    }

    onSendFile(files, text) {
        const { ticketId, administratorId, administratorName, administratorAvatar, folderName } = this.props
        if (files.length > 0) {
            files.map(fileName => {
                const messageId = this.generateMessageId()
                let message = {
                    _id: messageId,
                    id: messageId,
                    sent: true,
                    text: text,
                    createdAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                    user: {
                        id: `administrator-${administratorId}`,
                        _id: `administrator-${administratorId}`,
                        name: administratorName,
                        _name: administratorName,
                        avatar: administratorAvatar,
                        permission: 'administrator'
                    },
                    imageName: null,
                    fileName: null,

                }
                if (fileName && ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG'].includes(fileName.split('.')[1])) {
                    if (!['#PRODUCT', '#CATEGORY_PRODUCT'].includes(text.split('-')[0])) {
                        message['image'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${fileName}`
                        message['imageName'] = fileName
                    }
                } else {
                    message['file'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${fileName}`
                    message['fileName'] = fileName
                }
                this.addStoreDataMessages([message])
                this.appendMessages([message])
                this.socket.emit(`${ticketId}`, [message])
            })

        }
    }

    onSendRegister() {
        const { ticketId, administratorId, administratorName, administratorAvatar } = this.props
        const messageId = this.generateMessageId()
        let message = {
            _id: messageId,
            id: messageId,
            sent: true,
            text: '#REGISTER-0',
            createdAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            user: {
                id: `administrator-${administratorId}`,
                _id: `administrator-${administratorId}`,
                name: administratorName,
                _name: administratorName,
                avatar: administratorAvatar,
                permission: 'administrator'
            },
            imageName: null,
            fileName: null,
        }

        this.addStoreDataMessages([message])
        this.appendMessages([message])
        this.socket.emit(`${ticketId}`, [message])
    }

    onPressResentMessage(message) {
        let temp = Object.assign({}, message)
        temp['_id'] = this.generateMessageId()
        temp['id'] = this.generateMessageId()
        temp['error'] = false
        temp['createdAt'] = moment.utc().format('YYYY-MM-DD HH:mm:ss')
        this.onSend([temp])
        this.deleteStoreDataMessages([message])
    }

    onPressDeleteMessage(message) {
        this.deleteStoreDataMessages([message])
    }

    handleHoverChange(event) {
        const { hoveredImage } = this.state
        this.setState({ hoveredImage: !hoveredImage })
    }
    handleHoverVideoChange(event) {
        const { hoveredVideo } = this.state
        this.setState({ hoveredVideo: !hoveredVideo })
    }

    onPressEnterInput(props) {
        if (props.text.length >= 1) {
            let messageId = this.generateMessageId()
            let message = {
                createdAt: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                id: messageId,
                sent: true,
                text: props.text,
                user: props.user,
                _id: messageId
            }
            props.onSend(message)
            props.onTextChanged('')
        }
    }

    hideTypingAdministrator(data) {
        if (this.props.ticketId == data.ticketId) {
            if (this.props.administratorId != data.id) {
                let showTypingUserList = this.state.showTypingUserList.filter(item => item.id != data.id && item.type == 'administrator')
                this.setState({ showTyping: false, showTypingUserList: showTypingUserList })
            }
        }
    }

    showTypingAdministrator(data) {
        if (this.props.ticketId == data.ticketId && this.props.administratorId != data.id) {
            let showTypingUserList = this.state.showTypingUserList.map(item => Object.assign({}, item))
            showTypingUserList.push(Object.assign({ type: 'administrator' }, data))
            this.setState({ showTyping: true, showTypingUserList: showTypingUserList })
        }
    }

    hideTypingClient(data) {
        if (this.props.ticketId == data.ticketId) {
            let showTypingUserList = this.state.showTypingUserList.filter(item => item.id != data.id && item.type == 'client')
            this.setState({ showTyping: false, showTypingUserList: showTypingUserList })
        }
    }

    showTypingClient(data) {
        if (this.props.ticketId == data.ticketId) {
            let showTypingUserList = this.state.showTypingUserList.map(item => Object.assign({}, item))
            showTypingUserList.push(Object.assign({ type: 'client' }, data))
            this.setState({ showTyping: true, showTypingUserList: showTypingUserList })
        }
    }

    onChangeInputMessage(event, props) {
        const { ticketId, administratorId, administratorName, administratorAvatar } = this.props
        if (event.keyCode != 13) {
            props.onTextChanged(event.target.value)
        }
        if (event.target.value.length > 0 && !this.state.tempTyping) {
            this.setState({ tempTyping: true })
            this.socket.emit(`show-typing-administrator-${ticketId}`, { id: administratorId, name: administratorName, avatar: administratorAvatar, ticketId: ticketId });
        }
        if (event.target.value.length == 0 && this.state.tempTyping) {
            this.setState({ tempTyping: false })
            this.socket.emit(`hide-typing-administrator-${ticketId}`, { id: administratorId, name: administratorName, avatar: administratorAvatar, ticketId: ticketId });
        }
    }

    onPressEnterInputMessage(event, props) {
        if (!event.shiftKey) {
            this.onPressEnterInput(props)
        }
    }
    onClickNewUpload() {
        // console.log('jjjjj',this)
        this.refs.imageFile.click()
    }
    showStorageOverLimitModal() {
        this.setState({ visibleModalOversStorage: true })
    }
    onChangeButton(event) {
        let fileSelect = Array.from(this.refs.imageFile.files)
        this.getStorageDetail((storageDetail) => {
            if (storageDetail.success) {
                // let dataSource = event.target.files.map(item => Object.assign({}, item))
                let fileLists = []
                if (fileSelect && fileSelect.length > 0) {
                    let fileSize = fileSelect[0].size / (1024 * 1024 * 1024)
                    if (storageDetail.storage.free < fileSize) {
                        this.showStorageOverLimitModal()
                    } else {
                        console.log('else')
                        let temp = { file: fileSelect[0] }
                        let reader = new FileReader();
                        reader.readAsDataURL(fileSelect[0]);
                        reader.onload = (e) => {
                            temp['view'] = e.target.result
                            fileLists.push(temp.file)
                            this.updateImageMessage(fileLists)
                        };
                    }
                    event.target.value = null;
                }
            }
        })
    }
    getStorageDetail(callback) {
        console.log('get storage')
        let url = '/api/crm/administrator/setting/storage';
        get(url)
            .then(responses => {
                callback({ success: true, storage: responses })
            })
            .catch(error => {
                console.log(error.message)
                callback({ success: false, error: error })
            })
    }
    async updateImageMessage(fileList) {
        // console.log('upload', fileList)
        // console.log('upload >', fileList.length)
        if (fileList.length > 0) {
            // console.log('upload >', fileList.length)
            let formData = new FormData();
            fileList.map((file, index) => formData.append("message" + index, file))
            // console.log('formData >', formData)
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                // console.log('fileNameList', fileNameList)
                this.onSendFile(fileNameList, '#IMAGE')
            }
        }
    }
    async uploadImageToServer(formData) {
        // console.log('formData', formData)
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log('res', response)
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    renderInputToolbar(props) {
        const { ticketId, administratorId, administratorName, administratorAvatar } = this.props
        if (props.text == '\n') {
            props.onTextChanged('')
            this.setState({ tempTyping: false })
            this.socket.emit(`hide-typing-administrator-${ticketId}`, { id: administratorId, name: administratorName, avatar: administratorAvatar, ticketId: ticketId });
        }

        return (
            <div className="menu-bar-bottom">
                <div style={{ width: '100%' }}>
                    <TextArea
                        rows={2}
                        value={props.text}
                        placeholder={props.placeholder}
                        onChange={(event) => this.onChangeInputMessage(event, props)}
                        onPressEnter={(event) => this.onPressEnterInputMessage(event, props)}
                        style={{ border: 'none', fontSize: '16px' }}
                    />
                </div>
                <div style={{ display: 'flex', padding: '8px 5px 4px 0px', margin: '0px 5px', backgroundColor: '#ffffff', borderRadius: '5px' }}>
                    <div style={{cursor:'pointer'}}>
                        <Popover
                            placement="topRight"
                            content={(
                                <UploadImage
                                    ticketId={this.props.ticketId}
                                    customerId={this.props.customerId}
                                    onSendFile={(files, text) => this.onSendFile(files, text)}
                                    closeHover={() => this.setState({ hoveredImage: false })}
                                />
                            )}
                            title={null}
                            trigger="click"
                            visible={this.state.hoveredImage}
                            onVisibleChange={(event) => this.handleHoverChange(event)}
                        >
                            <Icon type="plus" style={{ fontSize: 24, marginLeft: 5, color: '#48c5eb' }} />
                            <p className="upload-file-text" style={{ fontSize: 15, marginLeft: 5, color: '#48c5eb' }}>File</p>
                            {/* <Icon type="picture" style={{ fontSize: 24, marginLeft: 5, color: '#48c5eb' }} /> */}
                        </Popover>
                    </div>
                   <div style={{ textAlign: 'center',cursor:'pointer' }} onClick={() => this.onClickNewUpload()} >
                       {/* input ใต้ giftchat */}
                        {/* <input
                            multiple
                            id="imageFile"
                            type="file"
                            ref="imageFile"
                            accept={this.state.typeFile}
                            onChange={(event) => this.onChangeButton(event)}
                            style={{ display: 'none' }}
                        /> */}
                        <Icon type="picture" style={{ fontSize: 24, marginLeft: 5, color: '#48c5eb',textAlign:'center' }}/>
                        <p className="upload-file-text" style={{ fontSize: 15, marginLeft: 5, color: '#48c5eb' }}>Image</p>
                    </div>
                    <div style={{ cursor: 'pointer',textAlign: 'center' }} onClick={() => this.onSendRegister()}>
                        <Icon type="trademark" style={{ fontSize: 24, marginLeft: 5, color: '#48c5eb' }} />
                        <p className="upload-file-text" style={{ fontSize: 15, marginLeft: 5, color: '#48c5eb' }}>Register</p>
                    </div>
                </div>
            </div>
        )
    }

    renderTime = (props) => {
        const { administratorId } = this.props
        const Color = {
            white: '#fff',
            timeTextColor: '#aaa',
            black: '#000'
        }
        const containerStyle = {
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 5,
        }

        const textStyle = {
            margin: 0,
            fontSize: 12,
            backgroundColor: 'transparent',
            textAlign: 'right',
        }

        const styles = {
            left: {
                container: {
                    ...containerStyle,
                },
                text: {
                    color: Color.black,
                    ...textStyle,
                },
            },
            right: {
                container: {
                    ...containerStyle,
                },
                text: {
                    color: Color.white,
                    ...textStyle,
                },
            },
        }
        const position = props.currentMessage.user._id == `administrator-${administratorId}` ? 'right' : 'left'
        return (
            <div
                style={styles[position].container}
            >
                {props.currentMessage.error ?
                    <p style={styles[position].text}  >
                        <Icon type="redo" style={{ paddingLeft: 5 }} onClick={() => this.onPressResentMessage(props.currentMessage)} />
                        <Icon type="delete" style={{ paddingLeft: 5 }} onClick={() => this.onPressDeleteMessage(props.currentMessage)} />
                    </p>
                    :
                    <p style={styles[position].text} >
                        {/* โชว์เวลาที่ส่ง message */}
                        {moment.utc(props.currentMessage.createdAt).local().format('HH:mm')}
                        {/* {moment(props.currentMessage.createdAt).format('HH:ss')} */}
                    </p>
                }

            </div>
        )
    }

    //โชว์ข้อความ
    renderMessageText = (props) => {
        const firstChar = props.currentMessage.text ? props.currentMessage.text[0] : ''
        if (firstChar == '#') {
            const splitTexts = props.currentMessage.text.split('-')
            const type = splitTexts[0]
            const id = splitTexts[1]
            switch (type) {
                case '#PRODUCT':
                    return (
                        <ChatProductCard productId={id} />
                    );
                case '#CATEGORY_PRODUCT':
                    return (
                        <ChatCategoryProductCard categoryProductId={id} />
                    );
                case '#REGISTER':
                    return (
                        <ChatRegisterCard id={id} />
                    );

                default:
                    return null
            }
        }

        return (
            <p
                style={{
                    width: '100%',
                    height: '100%',
                    margin: '0px',
                    padding: '5px',
                    fontSize: '16px',
                }}>
                {props.currentMessage.text}
            </p>
        )
    }


    renderCustomView = (props) => {
        const { administratorId } = this.props
        if (props.currentMessage.file != null) {
            switch (props.currentMessage.text) {
                case '#VIDEO':
                    return (
                        <ChatVideoCard video={props.currentMessage.file} />
                    );
                default:
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => window.open(props.currentMessage.file)}>
                            <p>{props.currentMessage.fileName.slice(14)}</p>
                        </div>
                    )
            }
        }
    }
    renderAvatar = (props) => {
        const { folderName } = this.props
        const { user } = props.currentMessage
        if (user) {
            if (user.avatar === 'auto') {
                return (
                    <img
                        style={{ width: '40px', height: '40px', borderRadius: '20px', alignItems: 'center', borderRadius: '20px', justifyContent: 'center' }}
                        src={defaultSquareSrc} />
                )
            } else {
                return (
                    <img
                        src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${user.avatar}`}
                        style={{ width: '40px', height: '40px', borderRadius: '20px', alignItems: 'center', borderRadius: '20px', justifyContent: 'center' }}
                        onError={(image) => image.target.src = defaultSquareSrc}
                    />
                )
            }
        }
        return null
    }


    renderFooter(props) {
        const { folderName } = this.props
        if (this.state.showTyping) {
            return (
                <div style={{ display: 'flex' }}>
                    <div style={{ margin: 8 }}>
                        <img
                            style={{ width: '40px', height: '40px', borderRadius: '20px', alignItems: 'center', borderRadius: '20px', justifyContent: 'center' }}
                            src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${this.state.showTypingUserList[0].avatar}`} />
                    </div>
                    <div
                        style={{
                            backgroundColor: 'rgb(71, 197, 235)',
                            borderTopLeftRadius: 3,
                            borderTopRightRadius: 14,
                            borderBottomLeftRadius: 14,
                            borderBottomRightRadius: 14,
                            maxWidth: '80%',
                            overflowWrap: 'anywhere',
                            padding: 10,
                            minHeight: '50px'
                        }}
                    >
                        <p
                            style={{
                                width: '100%',
                                height: '100%',
                                margin: '0px',
                                padding: '5px',
                                fontSize: 16
                            }}>
                            {'กำลังพิมพ์...'}
                        </p>
                        {/* <p>{this.state.showTypingUserList[0].name}</p> */}
                    </div>
                </div >
            )
        }
        return null
    }

    renderBubble = (props) => {
        return (
            <Bubble
                {...props}
                wrapperStyle={{
                    left: {
                        backgroundColor: '#f2f2f2',
                        padding: '10px',
                        maxWidth: '80%',
                        overflowWrap: 'anywhere'
                    },
                    right: {
                        backgroundColor: '#47C5EB',
                        padding: '10px',
                        maxWidth: '80%',
                        overflowWrap: 'anywhere'
                    }
                }}
                textStyle={{
                    left: {
                        color: '#000000',
                    },
                    right: {
                        color: '#FFFFFF',
                    }
                }}
            />
        )
    }
    renderMessageImage() {
        return null
    }
    render() {
        const { administratorId, administratorName, administratorAvatar, folderName } = this.props
        const { messages } = this.state
        return (
            <Row style={{ height: '100%' }} >
                
            <GiftedChat
                ref={this.GiftedChatRef}
                messages={messages}
                onSend={(messages) => this.onSend(messages)}
                showUserAvatar={true}
                showAvatarForEveryMessage={true}
                renderUsernameOnMessage={false}
                renderInputToolbar={(props) => this.renderInputToolbar(props)}
                renderFooter={(props) => this.renderFooter(props)}
                renderCustomView={this.renderCustomView}
                renderMessageText={this.renderMessageText}
                renderTime={this.renderTime}
                renderBubble={this.renderBubble}
                renderAvatar={this.renderAvatar}

                // renderMessageImage= {this.renderMessageImage}
                user={{
                    id: `administrator-${administratorId}`,
                    _id: `administrator-${administratorId}`,
                    name: administratorName,
                    _name: administratorName,
                    avatar: administratorAvatar,
                    permission: 'administrator'
                }}
            />
                <input
                    multiple
                    id="imageFile"
                    type="file"
                    ref="imageFile"
                    accept={this.state.typeFile}
                    onChange={(event) => this.onChangeButton(event)}
                    style={{ display: 'none' }}
                />
                <StorageOverLimitModal
                    visible={this.state.visibleModalOversStorage}
                    onClose={() => this.setState({ visibleModalOversStorage: false })}
                />
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    administratorName: state.login.name,
    administratorAvatar: state.login.avatar,
    folderName: state.login.folderName,
})

const styles = {
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        height: "100vh",
    },
    conversationList: {
        display: 'flex',
        flex: 1,
    },
    chat: {
        display: "flex",
        flex: 3,
        flexDirection: "column",
        borderWidth: "1px",
        borderColor: "#ccc",
        borderRightStyle: "solid",
        borderLeftStyle: "solid",
    },
    converationDetails: {
        display: 'flex',
        flex: 1,
    }
}

export default connect(mapStateToProps, null)(Chat);