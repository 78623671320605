import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, InputNumber, Icon, Select, Tabs, Table } from 'antd';
import { get, put, post } from '../../../utils';
import './style.scss'

const { TabPane } = Tabs;

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;

class OrderProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            productSkuList: [],

            dataSource: [],
            columns: [
                {
                    title: 'Line No.',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'left ',
                    width: '10%',
                    render: (text, item, index) => (index + 1).toString().padStart(5, 0)
                },
                {
                    title: 'Product',
                    dataIndex: 'product_id',
                    key: 'product_id',
                    align: 'center',
                    render: (text, item, index) => this.renderProductColumn(text, item, index)
                },
                {
                    title: 'Product Sku',
                    dataIndex: 'product_sku_id',
                    key: 'product_sku_id',
                    align: 'center',
                    render: (text, item, index) => this.renderProductSkuColumn(text, item, index)
                },
                {
                    title: 'Price',
                    dataIndex: 'price',
                    key: 'price',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.product_price)}</span> 
                },
                {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    key: 'quantity',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => this.renderQuantityColumn(text, item, index)
                },
                {
                    title: 'Total Price',
                    dataIndex: 'total_price',
                    key: 'total_price',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.product_price * item.quantity)}</span> 
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    align: 'center',
                    width: '10%',
                    render: (text, item, index) => this.renderActionColumn(text, item, index)
                },
            ]
        }
    }




    renderProductColumn(text, item, index) {
        return (
            <Select
                value={item.product_id}
                onChange={(value) => this.updateProductOrderListProduct(index, item, value)}
            >
                {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
            </Select>
        )
    }

    renderProductSkuColumn(text, item, index) {
        let productId = this.props.productOrderList[index].product_id
        const filteredSkuList = this.state.productSkuList.filter(
            (skuItem) => skuItem.product_id === productId
        );
        return (
            <Select
                value={item.product_sku_id}
                onChange={(value) => this.updateProductSkuOrderListProduct(index, item, value)}
            >
                {filteredSkuList.map((item, index) => 
                <Option key={index} value={item.product_sku_id}>{item.attr_1}</Option>)
                }
            </Select>
        )
    }



    renderQuantityColumn(text, item, index) {
        return (
            <InputNumber
                value={item.quantity}
                onChange={(value) => this.updateProductOrderListQuantity(index, value)}
            />
        )
    }

    renderActionColumn(text, item, index) {
        let disabled = false
        return (
            <div style={{
                margin: 5,
                width: 30, height: 30, borderRadius: 15,
                backgroundColor: '#EAEAEA',
                cursor: !disabled ? 'pointer' : 'none'
            }}
                onClick={() => !disabled ? this.deleteProductOrderList(index) : null}
            >
                <Icon type="close" style={{ fontSize: 16, padding: 5, color: !disabled ? 'red' : '#fff' }} />
            </div>
        )
    }

    updateProductOrderListProduct(index, record, value) {
        const { productList } = this.state
        let indexProduct = productList.findIndex(item => item.id === value)
        if (indexProduct !== -1) {
            let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
            productOrderList[index].product_id = productList[indexProduct].id
            productOrderList[index].product_sku_id = null
            productOrderList[index].product_price = 0
            // productOrderList[index].product_price = productList[indexProduct].price * record.quantity
            this.props.updateProductOrderList(productOrderList)
        }
    }

    updateProductSkuOrderListProduct(index, record, value) {
        const { productList } = this.state
        const indexSku = this.state.productSkuList.findIndex(item => item.product_sku_id === value);
        const productSku = this.state.productSkuList[indexSku]
        let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
        productOrderList[index].product_sku_id = productSku.product_sku_id
        productOrderList[index].product_price = productSku.price_for_sale 
        // * record.quantity
        this.props.updateProductOrderList(productOrderList)
    }

    updateProductOrderListQuantity(index, value) {
        let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
        productOrderList[index].quantity = value
        this.props.updateProductOrderList(productOrderList)
    }

    deleteProductOrderList(index) {
        let productOrderList = this.props.productOrderList.filter((item, i) => i !== index)
        this.props.updateProductOrderList(productOrderList)
    }

    addProductOrderList() {
        let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
        productOrderList.push({
            id: null,
            product_id: null,
            product_sku_id: null,
            product_price: 0,
            quantity: 1,
        })
        this.props.updateProductOrderList(productOrderList)
    }

    getProductList() {
        let url = '/api/product';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ productList: responses.dataList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    getProductSkuList() {
        let url = '/api/product-sku';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ productSkuList: responses.dataList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getProductList()
        this.getProductSkuList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userCustomerId !== prevProps.userCustomerId && this.props.userCustomerId) {
            this.getUserCustomerAddressList()
        }
    }

    onChangeShippingAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id === value)
        if (index !== -1) {
            this.setState({
                shippingAddressId: userCustomerAddressList[index].id,
                shippingAddressDetail: userCustomerAddressList[index]
            })
        }
    }
    onChangeInvoiceAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id === value)
        if (index !== -1) {
            this.setState({
                invoiceAddressId: userCustomerAddressList[index].id,
                invoiceAddressDetail: userCustomerAddressList[index]
            })
        }
    }


    render() {
        const { columns } = this.state
        const { productOrderList } = this.props
        
        return (
            <div className='order-product-container-wrapper'> 
                <Row className='action-button-wrapper' gutter={24} type="flex" justify='space-between' style={{ padding: 10 }}>
                    <p className='title-header'>{'Product'}</p>
                    <Button className='create-button' onClick={() => this.addProductOrderList()}><Icon type="plus" />{'Add'}</Button>
                </Row>
                <Table
                    dataSource={productOrderList}
                    columns={columns}
                    pagination={false}
                />
            </div>
        );
    }
}



export default OrderProduct;

