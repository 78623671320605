import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, Tabs } from 'antd';
import { get, put, post } from '../../../utils';
import './style.scss'

const { TabPane } = Tabs;

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;

class OrderAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userCustomerAddressList: [],

            tempDeliveryName: null,
            tempDeliveryTelephone: null,
            tempDeliveryAddress: null,
            tempDeliveryProvince: null,
            tempDeliveryDistrict: null,
            tempDeliveryPostCode: null,

            tempInvoiceName: null,
            tempInvoiceTelephone: null,
            tempInvoiceAddress: null,
            tempInvoiceProvince: null,
            tempInvoiceDistrict: null,
            tempInvoicePostCode: null,

            isEditDelivery: false,
            isCreateDelivery: false,
            isEditInvoice: false,
            isCreateInvoice: false,
        }
    }

    getUserCustomerAddressList(callback = () => null) {
        const { userCustomerId } = this.props
        let url = '/api/address?user_customer_id=' + userCustomerId;
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ userCustomerAddressList: responses }, () => callback())
            })
            .catch(err => {
                console.log(err.message)
                callback()
            })
    }

    componentDidMount() {
        if (this.props.userCustomerId) {
            this.getUserCustomerAddressList()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userCustomerId !== prevProps.userCustomerId && this.props.userCustomerId) {
            this.getUserCustomerAddressList()
        }
        if (this.props.deliveryAddressId !== prevProps.deliveryAddressId && this.props.deliveryAddressId) {
            this.setState({ deliveryAddressId: this.props.deliveryAddressId })
        }
        if (this.props.invoiceAddressId !== prevProps.invoiceAddressId && this.props.invoiceAddressId) {
            this.setState({ invoiceAddressId: this.props.invoiceAddressId })
        }
    }

    onChangeDeliveryAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id == value)
        if (index !== -1) {
            this.props.updateDeliveryAddressId(userCustomerAddressList[index].id)
            this.props.updateDeliveryAddressDetail(userCustomerAddressList[index])
        }else{
            this.props.updateDeliveryAddressId(null)
            this.props.updateDeliveryAddressDetail(null)
        }
    }
    onChangeInvoiceAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id == value)
        if (index !== -1) {
            this.props.updateInvoiceAddressId(userCustomerAddressList[index].id)
            this.props.updateInvoiceAddressDetail(userCustomerAddressList[index])
        }else{
            this.props.updateInvoiceAddressId(null)
            this.props.updateInvoiceAddressDetail(null)
        }
    }

    onClickEditDelivery() {
        const { deliveryAddressDetail } = this.props
        if (deliveryAddressDetail) {
            this.setState({
                isEditDelivery: true,

                tempDeliveryName: deliveryAddressDetail.name,
                tempDeliveryTelephone: deliveryAddressDetail.telephone,
                tempDeliveryAddress: deliveryAddressDetail.address,
                tempDeliveryProvince: deliveryAddressDetail.province,
                tempDeliveryDistrict: deliveryAddressDetail.district,
                tempDeliveryPostCode: deliveryAddressDetail.post_code,
            })
        }

    }

    onClickCreateDelivery() {
        this.setState({ isCreateDelivery: true })
    }

    onClickDoneDelivery() {
        const { userCustomerId } = this.props
        const { deliveryAddressId, tempDeliveryName, tempDeliveryTelephone, tempDeliveryAddress, tempDeliveryProvince, tempDeliveryDistrict, tempDeliveryPostCode } = this.state
        let data = {
            name: tempDeliveryName,
            telephone: tempDeliveryTelephone,
            address: tempDeliveryAddress,
            province: tempDeliveryProvince,
            district: tempDeliveryDistrict,
            post_code: tempDeliveryPostCode
        }
        let url = '/api/crm/administrator/address';
        let update = post
        if (deliveryAddressId) {
            url = '/api/crm/administrator/address/' + deliveryAddressId;
            update = put
        } else {
            data['user_customer_id'] = userCustomerId
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getUserCustomerAddressList(() => {
                    this.onChangeDeliveryAddressDetail(response.id)
                    this.clearStateDelivery()
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickCancelDelivery() {
        this.clearStateDelivery()
    }

    clearStateDelivery() {
        this.setState({
            isEditDelivery: false,
            isCreateDelivery: false,

            tempDeliveryName: null,
            tempDeliveryTelephone: null,
            tempDeliveryAddress: null,
            tempDeliveryProvince: null,
            tempDeliveryDistrict: null,
            tempDeliveryPostCode: null,
        })
    }


    // Invoice
    onClickEditInvoice() {
        const { invoiceAddressDetail } = this.props
        if (invoiceAddressDetail) {
            this.setState({
                isEditInvoice: true,

                tempInvoiceName: invoiceAddressDetail.name,
                tempInvoiceTelephone: invoiceAddressDetail.telephone,
                tempInvoiceAddress: invoiceAddressDetail.address,
                tempInvoiceProvince: invoiceAddressDetail.province,
                tempInvoiceDistrict: invoiceAddressDetail.district,
                tempInvoicePostCode: invoiceAddressDetail.post_code,
            })
        }

    }

    onClickCreateInvoice() {
        this.setState({ isCreateInvoice: true })
    }

    onClickDoneInvoice() {
        const { userCustomerId } = this.props
        const { invoiceAddressId, tempInvoiceName, tempInvoiceTelephone, tempInvoiceAddress, tempInvoiceProvince, tempInvoiceDistrict, tempInvoicePostCode } = this.state
        let data = {
            name: tempInvoiceName,
            telephone: tempInvoiceTelephone,
            address: tempInvoiceAddress,
            province: tempInvoiceProvince,
            district: tempInvoiceDistrict,
            post_code: tempInvoicePostCode
        }
        let url = '/api/crm/administrator/address';
        let update = post
        if (invoiceAddressId) {
            url = '/api/crm/administrator/address/' + invoiceAddressId;
            update = put
        } else {
            data['user_customer_id'] = userCustomerId
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getUserCustomerAddressList(() => {
                    this.onChangeInvoiceAddressDetail(response.id)
                    this.clearStateInvoice()
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickCancelInvoice() {
        this.clearStateInvoice()
    }

    clearStateInvoice() {
        this.setState({
            isEditInvoice: false,
            isCreateInvoice: false,

            tempInvoiceName: null,
            tempInvoiceTelephone: null,
            tempInvoiceAddress: null,
            tempInvoiceProvince: null,
            tempInvoiceDistrict: null,
            tempInvoicePostCode: null,
        })
    }


    userCustomerAddressListToEnum(){
        const {userCustomerAddressList} = this.state
        let result = userCustomerAddressList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
        result.unshift(<Option key={99999} value={null}>{'--- ไม่ระบุ ---'}</Option>)
        return result
    }
    render() {
        const { userCustomerId, deliveryAddressId, invoiceAddressId, deliveryAddressDetail, invoiceAddressDetail } = this.props
        const {
            isEditDelivery, isCreateDelivery, isEditInvoice, isCreateInvoice,
            tempDeliveryName, tempDeliveryTelephone, tempDeliveryAddress, tempDeliveryProvince, tempDeliveryDistrict, tempDeliveryPostCode,
            tempInvoiceName, tempInvoiceTelephone, tempInvoiceAddress, tempInvoiceProvince, tempInvoiceDistrict, tempInvoicePostCode
        } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='order-address-container-wrapper'>
                <Tabs onChange={() => null} type="card">
                    <TabPane tab={"Shipping Address"} key={"1"}>
                        {userCustomerId ?
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={10} xl={10} >
                                        <Meta title={'Shipping Address'} />
                                    </Col>
                                    <Col sm={24} md={24} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {isCreateDelivery || isEditDelivery ?
                                            <Fragment>
                                                <Button type={'primary'} style={{ width: '48%' }} onClick={() => this.onClickDoneDelivery()}>{'Done'}</Button>
                                                <Button type={'danger'} style={{ width: '48%' }} onClick={() => this.onClickCancelDelivery()}>{'Cancel'}</Button>
                                            </Fragment>
                                            :
                                            deliveryAddressId ?
                                                <Button type={isEditDelivery ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickEditDelivery()}>{'Edit'}</Button>
                                                :
                                                <Button type={isCreateDelivery ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickCreateDelivery()}>{'Create'}</Button>
                                        }
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <Select
                                            value={deliveryAddressId}
                                            onChange={(value) => this.onChangeDeliveryAddressDetail(value)}
                                            disabled={isCreateDelivery || isEditDelivery}
                                        >
                                            {this.userCustomerAddressListToEnum() }
                                        </Select>
                                    </Col>
                                </Row>
                                <Form>
                                    <Row gutter={24}>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Name'} required={false}>
                                                <Input
                                                    value={tempDeliveryName ? tempDeliveryName : deliveryAddressDetail ? deliveryAddressDetail.name : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryName: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'telephone'} required={false}>
                                                <Input
                                                    value={tempDeliveryTelephone ? tempDeliveryTelephone : deliveryAddressDetail ? deliveryAddressDetail.telephone : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryTelephone: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Address'} required={true}>
                                                <Input
                                                    value={tempDeliveryAddress ? tempDeliveryAddress : deliveryAddressDetail ? deliveryAddressDetail.address : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryAddress: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Province'}>
                                                <Input
                                                    value={tempDeliveryProvince ? tempDeliveryProvince : deliveryAddressDetail ? deliveryAddressDetail.province : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryProvince: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'District'} required={true}>
                                                <Input
                                                    value={tempDeliveryDistrict ? tempDeliveryDistrict : deliveryAddressDetail ? deliveryAddressDetail.district : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryDistrict: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Post Code'} required={true}>
                                                <Input
                                                    value={tempDeliveryPostCode ? tempDeliveryPostCode : deliveryAddressDetail ? deliveryAddressDetail.post_code : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryPostCode: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            :
                            <div style={{ height: 300, textAlign: 'center', padding: 125 }}>
                                <h2>{'Please select customer'}</h2>
                            </div>
                        }
                    </TabPane>
                    <TabPane tab={'Invoice Address'} key={"2"}>
                        {userCustomerId ?
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={10} xl={10} >
                                        <Meta title={'Invoice Address'} />
                                    </Col>
                                    <Col sm={24} md={24} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {isCreateInvoice || isEditInvoice ?
                                            <Fragment>
                                                <Button type={'primary'} style={{ width: '48%' }} onClick={() => this.onClickDoneInvoice()}>{'Done'}</Button>
                                                <Button type={'danger'} style={{ width: '48%' }} onClick={() => this.onClickCancelInvoice()}>{'Cancel'}</Button>
                                            </Fragment>
                                            :
                                            deliveryAddressId ?
                                                <Button type={isEditInvoice ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickEditInvoice()}>{'Edit'}</Button>
                                                :
                                                <Button type={isCreateInvoice ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickCreateInvoice()}>{'Create'}</Button>
                                        }
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <Select
                                            value={invoiceAddressId}
                                            onChange={(value) => this.onChangeInvoiceAddressDetail(value)}
                                            disabled={isCreateInvoice || isEditInvoice}
                                        >
                                            {this.userCustomerAddressListToEnum()}
                                        </Select>
                                    </Col>
                                </Row>
                                <Form>
                                    <Row gutter={24}>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Name'} required={false}>
                                                <Input
                                                    value={tempInvoiceName ? tempInvoiceName : invoiceAddressDetail ? invoiceAddressDetail.name : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceName: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'telephone'} required={false}>
                                                <Input
                                                    value={tempInvoiceTelephone ? tempInvoiceTelephone : invoiceAddressDetail ? invoiceAddressDetail.telephone : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceTelephone: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Address'} required={true}>
                                                <Input
                                                    value={tempInvoiceAddress ? tempInvoiceAddress : invoiceAddressDetail ? invoiceAddressDetail.address : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceAddress: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Province'}>
                                                <Input
                                                    value={tempInvoiceProvince ? tempInvoiceProvince : invoiceAddressDetail ? invoiceAddressDetail.province : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceProvince: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'District'} required={true}>
                                                <Input
                                                    value={tempInvoiceDistrict ? tempInvoiceDistrict : invoiceAddressDetail ? invoiceAddressDetail.district : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceDistrict: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Post Code'} required={true}>
                                                <Input
                                                    value={tempInvoicePostCode ? tempInvoicePostCode : invoiceAddressDetail ? invoiceAddressDetail.post_code : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoicePostCode: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            :
                            <div style={{ height: 300, textAlign: 'center', padding: 125 }}>
                                <h2>{'Please select customer'}</h2>
                            </div>
                        }
                    </TabPane>
                </Tabs>


            </div>
        );
    }
}



export default OrderAddress;

