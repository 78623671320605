import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Button, Icon, Input, Select, notification } from 'antd';
import { get, post } from '../../../utils';
import { Link } from 'react-router-dom'
import './style.scss'

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languageList: [],
            aboutUsLanguageList: []
        }
    }


    getLanguageList() {
        let url = '/api/crm/administrator/language/raw';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ languageList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getTermsAndConditions() {
        let url = '/api/crm/administrator/aboutus';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log("getTermsAndConditions", response)
                this.setState({ aboutUsLanguageList: response.about_us_language_list })

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getLanguageList()
        this.getTermsAndConditions()
    }

    validateAboutUsLanguageList() {
        let result = {
            success: true,
            message: 'System notification',
            description: ''
        }
        this.state.aboutUsLanguageList.map((item, index) => {
            if (result.success) {
                if (!item.language_id || item.language_id == '') {
                    result.success = false
                    result.description = `Please enter "Language" in line ID 0000${index + 1} .`
                    return
                }
                if (!item.header_title || item.header_title == '') {
                    result.success = false
                    result.description = `Please enter "Header Title" in line ID 0000${index + 1} .`
                    return
                }
            }
        })
        return result
    }

    updateAboutUs() {
        let validateAboutUsLanguageListResult = this.validateAboutUsLanguageList()
        if (!validateAboutUsLanguageListResult.success) {
            openNotificationWithIcon('warning', 'System notification', validateAboutUsLanguageListResult.description)
            return
        }

        let aboutUsLanguageList = this.state.aboutUsLanguageList.map(item => Object.assign({}, item, { detail_list: JSON.stringify(item.detail_list) }))
        let data = {
            about_us_language_list: JSON.stringify(aboutUsLanguageList),
        }
        console.log("Press to Save")
        let url = '/api/crm/administrator/aboutus';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save swiper success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        console.log("Press to Save----------onClickOk")
        this.updateAboutUs()
    }

    onClickCancel() {
        this.getTermsAndConditions()
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }


    updateAboutUsLanguage(index, name, value) {
        let aboutUsLanguageList = this.state.aboutUsLanguageList.map(item => Object.assign({}, item))
        aboutUsLanguageList[index][name] = value
        this.setState({ aboutUsLanguageList: aboutUsLanguageList })
    }

    addAboutUsLanguage() {
        let aboutUsLanguageList = this.state.aboutUsLanguageList.map(item => Object.assign({}, item))
        aboutUsLanguageList.push({
            language_id: null,
            header_title: '',
            image: '',
            title: '',
            description_primary: '',
            description_secondary: '',
            description_tertiary: '',
        })
        this.setState({ aboutUsLanguageList: aboutUsLanguageList })
    }

    deleteAboutUsLanguage(index) {
        let aboutUsLanguageList = this.state.aboutUsLanguageList.filter((item, i) => i !== index) 
        this.setState({ aboutUsLanguageList: aboutUsLanguageList })
    }

    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left' }} style={{ cursor: 'pointer' }}>
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{"About Us"}</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <Button style={{ width: '100%' }} onClick={() => this.addAboutUsLanguage()}>{'+ Add'}</Button>
                        </Col>
                    </Row>
                    {this.state.aboutUsLanguageList.map((item, index) => {
                        return (
                            <Card style={{ marginBottom: 10 }}>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                        <FormItem {...formItemLayout} label={'Line ID'}>
                                            <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                        <FormItem {...formItemLayout} label={'Language'} required={true}>
                                            <Select
                                                allowClear={true}
                                                value={item.language_id}
                                                onChange={(value) => this.updateAboutUsLanguage(index, 'language_id', value)}
                                                style={{ width: '100%' }}
                                            >
                                                {this.state.languageList.length ?
                                                    this.state.languageList.map((item, index) => <Option key={index} value={item.id} disabled={this.state.aboutUsLanguageList.map(r => r.language_id).includes(item.id)} >{item.name}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Header Title'} required={true}>
                                            <Input
                                                value={item.header_title}
                                                onChange={(event) => this.updateAboutUsLanguage(index, 'header_title', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Image'} required={true}>
                                            <Input
                                                value={item.image}
                                                onChange={(event) => this.updateAboutUsLanguage(index, 'image', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Title'} required={true}>
                                            <Input
                                                value={item.title}
                                                onChange={(event) => this.updateAboutUsLanguage(index, 'title', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Description Primary'}  >
                                            <TextArea
                                                rows={4}
                                                value={item.description_primary}
                                                onChange={(event) => this.updateAboutUsLanguage(index, 'description_primary', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Description Secondary'}  >
                                            <TextArea
                                                rows={4}
                                                value={item.description_secondary}
                                                onChange={(event) => this.updateAboutUsLanguage(index, 'description_secondary', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Description Tertiary'}  >
                                            <TextArea
                                                rows={4}
                                                value={item.description_tertiary}
                                                onChange={(event) => this.updateAboutUsLanguage(index, 'description_tertiary', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24} type="flex" style={{ justifyContent: "center", marginBottom: 10 }} >
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <Button style={{ width: '100%' }} type={'danger'} onClick={() => this.deleteAboutUsLanguage(index)}>{'Delete'}</Button>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(AboutUs);
