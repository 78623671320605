import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../../utils';
import config from '../../../config.json'
import defaultSquareSrc from '../../../resource/default/300x300-square.png'

class TicketProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketProductList: [],

        }
    }

    getTicketProductList() {
        let { ticketId } = this.props
        if (!ticketId) {
            return
        }
        let url = '/api/administrator/crm/ticket/chat/product/' + ticketId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ ticketProductList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }



    componentDidMount() {
        this.getTicketProductList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ticketId != this.props.ticketId) {
            this.getTicketProductList()
        }
    }

    render() {
        const { ticketProductList } = this.state
        const { folderName } = this.props
        return (
            <div>
                <div>
                    <div className='divider' />
                    {ticketProductList.map(item => {
                        return (
                            <div className="ticket-product">
                                <img
                                    width={100} height={100}
                                    src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.image}`}
                                    onError={(image) => image.target.src = defaultSquareSrc}
                                />
                                <p>{item.name}</p>
                                {item.detailList.map(detail => {
                                    return (
                                        <div className="list-box" >
                                            <div className="icon-list"></div>
                                            <li>{detail.name}</li>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    userName: state.login.username,
    folderName: state.login.folderName,
})


export default connect(mapStateToProps)(TicketProduct);
