import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, notification, Checkbox } from 'antd';
import { get, patch,post } from '../../../utils'; 

import articleLayoutOne from '../../../resource/screen-mobile/home-article/home-article-one.jpg'


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class HomeLayoutArticleLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            settingScreenMobileId: null,
            articleLayout: 1,
            homeSwipeList: [
                { value: 1, name: 'Product Layout One', image: articleLayoutOne, size: 0 },
            ],
        }
    }

    getSettingScreenMobile() {
        let url = '/api/crm/administrator/setting/screen/mobile';
        get(url)
            .then(response => {
                this.setState({
                    settingScreenMobileId: response.id,
                    articleLayout: response.home_article
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getSettingScreenMobile()
    }


    updateSettingScreenMobile() {
        const { settingScreenMobileId, articleLayout } = this.state
        let url = '/api/crm/administrator/setting/screen/mobile/' + settingScreenMobileId + '/home/article';
        let data = { home_article: articleLayout }
        patch(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }

                openNotificationWithIcon('success', 'System notification', 'Save setting screen mobile product list layout success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateSettingScreenMobile()
    }
    onClickCancel() {
        this.getSettingScreenMobile()
    }


    updateHomeSwiper(item) {
        this.setState({
            articleLayout: item.value
        })
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'mobile')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    render() {
        const { homeSwipeList } = this.state
        return (
            <div >
                <Row >
                    <Col span={12} style={{ textAlign: 'left' }}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <div className="mobile-screen-content-wrapper">
                    {homeSwipeList.map(item => {
                        return (
                            <div className="item-mobile-screen-wrapper">
                                <Checkbox
                                    className='item-mobile-screen-checkbox-wrapper'
                                    checked={item.value == this.state.articleLayout}
                                    onChange={() => this.updateHomeSwiper(item)}
                                />
                                <div
                                    className='item-mobile-screen-image-wrapper'
                                    onClick={() => this.updateHomeSwiper(item)}
                                >
                                    <img
                                        src={item.image}
                                        className='item-mobile-screen-image'
                                        style={{ border: item.value == this.state.articleLayout ? '4px solid #48c5eb' : 'none' }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(HomeLayoutArticleLayout);
