import { combineReducers } from 'redux';

import loginReducer from './login';
import notificationReducer from './notification'
import themeReducer from './theme'
import packageReducer from './package'
import roleReducer from './role'
import systemmodalReducer from './systemmodal'
import forgotpasswordReducer from './forgotpassword'
import leftmenuReducer from './leftmenu'

const rootReducers = combineReducers({
    login: loginReducer,
    notification: notificationReducer,
    theme: themeReducer,
    package: packageReducer,
    role: roleReducer,
    systemmodal: systemmodalReducer,
    forgotpassword: forgotpasswordReducer,
    leftmenu: leftmenuReducer,
});

export default rootReducers