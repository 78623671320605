import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, notification,Select,DatePicker,Spin } from 'antd';
import moment from 'moment';
// import ImageUpload from '../../../containers/ImageUpload';
// import CustomerOverLimitModal from '../../../containers/CustomModal/CustomerOverLimitModal';
import { get, put, post, ppost } from '../../../utils';
import config from '../../../config.json'

import './style.scss'

const { TextArea } = Input;
const FormItem = Form.Item;
const { Meta } = Card;

const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

const dateFormat = 'YYYY-MM-DD';

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingCustomer:false,

            customeList: [],
            categoryWorkList: [],
            provinceList: [],
            districtList: [],
            subdistrictList: [],

            customeId: null,
            categoryWorkId: null,
            provinceId: null,
            districtId: null,
            subdistrictId: null,
            isPublish: 0,
            jobDescription: '',
            dateStart:null,
            dateEnd:null
        }

        this.timeOut = null
    }

    calOffSet(page,pageSize){
        let offSet = (page-1) * pageSize
        return offSet
    }

    getPostDetail() {
        const { editId } = this.props
        let url = '/api/crm/administrator/user/postwork/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({
                        customeId: response.data[0].customer_id,
                        categoryWorkId: response.data[0].category_work_id,
                        provinceId: response.data[0].province_id,
                        districtId :response.data[0].district_id,
                        subdistrictId:response.data[0].subdistrict_id,
                        isPublish: response.data[0].is_public,
                        jobDescription: response.data[0].job_description,
                        dateStart:response.data[0].start_date,
                        dateEnd:response.data[0].end_date
                    })
                    this.getCustomerList('getuser',response.data[0].customer_id)
                    this.getDistrict(response.data[0].province_id)
                    this.getSubDistrict(response.data[0].district_id)
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getcategoryWorkList()
        this.getProvince()
        if (this.props.editId) {
            this.getPostDetail()
        }else{
            this.getCustomerList()
        }
    }

    getProvince() {
        let url = '/api/crm/administrator/provinces';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({provinceList: response.data})
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getDistrict(provinceId = null) {
        let url = '/api/crm/administrator/districts';
        if(provinceId){
            url += '?province=' + provinceId
        }
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({districtList: response.data})
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getSubDistrict(districtId = null) {
        let url = '/api/crm/administrator/subdistricts';
        if(districtId){
            url += '?districts='+districtId
        }
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({subdistrictList: response.data})
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updatePost() {
        const { 
            customeId,categoryWorkId,provinceId,districtId,subdistrictId,isPublish,
            dateStart,dateEnd,
            jobDescription
        } = this.state
        if (!customeId || customeId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Name Customer".')
            return
        }
        if (!categoryWorkId || categoryWorkId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Category".')
            return
        }
        if (!provinceId || provinceId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Province".')
            return
        }
        if (!districtId || districtId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "District".')
            return
        }
        if (!subdistrictId || subdistrictId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Subdistrict".')
            return
        }

        if (!dateStart || dateStart == '') {
            openNotificationWithIcon('warning', 'System notification', 'เวลาจบ น้อยกว่าเวลาเริ่มต้น')
            return
        }

        if (!dateEnd || dateEnd == '') {
            openNotificationWithIcon('warning', 'System notification', 'เวลาจบ น้อยกว่าเวลาเริ่มต้น')
            return
        }

        if (moment(dateEnd, dateFormat) < moment(dateStart, dateFormat)) {
            openNotificationWithIcon('warning', 'System notification', 'เวลาจบ น้อยกว่าเวลาเริ่มต้น')
            return
        }

        
        if (!jobDescription || jobDescription == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Job Description".')
            return
        }
        let data = {
            customeId: customeId,
            categoryWorkId: categoryWorkId,
            provinceId: provinceId,
            districtId: districtId,
            subdistrictId:subdistrictId,
            isPublish: isPublish,
            dateStart: dateStart,
            dateEnd: dateEnd,
            jobDescription: jobDescription,
            permission:'administrator',
            userId: this.props.administratorId
        }

        if (this.props.editId) {
            let url = '/api/crm/administrator/user/postwork/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    if (response.success) {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/crm/administrator/user/postwork';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    if (response.success) {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }
    showCustomerOverLimitModal() {
        this.setState({ visible: true })
    }

    onClickOk() {
        this.updatePost()
    }
    onClickCancel() {
        this.setState({
            name: '',
            telephone: '',
            email: '',
            username: '',
            password: '',
        })
        this.props.backpage()
    }

    
    onChangeDateStart = (value, dateString) => {
        this.setState({ dateStart: dateString })
    }

    onChangeDateEnd = (value, dateString) => {
        this.setState({ dateEnd: dateString })
    }

    onSearchCustomer(val) {
        if(this.timeOut){
            clearTimeout(this.timeOut)
        }
        this.timeOut = setTimeout(() => this.getCustomerList('search',val) , 300);
    }

    async getCustomerList(option = null,val = null){
        this.setState({loadingCustomer:true})
        let pageSize = 300
        let offSet = await this.calOffSet(1 , pageSize)

        let url = "/api/v2/crm/administrator/user/customer?pageSize=" + pageSize+"&offSet="+offSet
        if(option == 'search'){
            url += '&searchUser=' + val
        }

        if(option == 'getuser'){
            url += '&userId=' + val
        }

        get(url)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({ customeList: response.data,loadingCustomer:false})
                }
                
            })
            .catch(err => {
                console.log(err.message)
                this.setState({loadingCustomer:false})
            })
    }

    getcategoryWorkList(){
        let url = "/api/administrator/crm/category/work"
        get(url)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.length){
                    this.setState({ categoryWorkList: response})
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    
    onChangeCustomer(value){
        this.setState({ customeId: value })
        this.getCustomerList()
    }

    onChangeProvince(value){
        this.setState({
            provinceId: value,
            districtId :null,
            subdistrictId:null
        })
        this.getDistrict(value)
    }
    onChangeDistrict(value){
        this.setState({
            districtId: value,
            subdistrictId:null
         })
        
        this.getSubDistrict(value)
    }

    customerListToElem() {
        let result = this.state.customeList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.Username}-${i.Name}`}>{i.Name}</Option>
        })
        return result
    }

    categoryWorkListToElem() {
        let result = this.state.categoryWorkList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.Name}`}>{i.Name}</Option>
        })
        return result
    }

    provinceListToElem() {
        let result = this.state.provinceList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }

    districtListToElem() {
        let result = this.state.districtList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }
    
    subdistrictListToElem() {
        let result = this.state.subdistrictList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }
    

    render() {
        const {dateStart,dateEnd } = this.state

        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <div className='post-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => this.props.backpage()} >
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Work - Post find a technician' : 'Create Work - Post find a technician'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>

                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Name Customer'} required={true}>
                                    {/* <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    /> */}
                                    <Select
                                        id='customer'
                                        showSearch
                                        optionFilterProp={'title'}
                                        value={ this.state.loadingCustomer ? null : this.state.customeId}
                                        onChange={(value) => this.onChangeCustomer(value)}
                                        // disabled={this.props.type == 'create' ? this.state.isdisabled : this.props.editRole ? this.state.isdisabled : true}
                                        onSearch={(value)=>this.onSearchCustomer(value)}
                                        notFoundContent={this.state.loadingCustomer ? <Spin size="small" /> : null}
                                    >
                                        {this.customerListToElem()}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Category'} required={true}>
                                    <Select
                                        id='categoryWork'
                                        showSearch
                                        optionFilterProp={'title'}
                                        value={this.state.categoryWorkId}
                                        onChange={(value) => this.setState({categoryWorkId: value})}
                                        // disabled={this.props.type == 'create' ? this.state.isdisabled : this.props.editRole ? this.state.isdisabled : true}
                                        // onSearch={(value)=>this.onSearchCustomer(value)}
                                        // notFoundContent={this.state.loadingCustomer ? <Spin size="small" /> : null}
                                    >
                                        {this.categoryWorkListToElem()}
                                    </Select>
                                </FormItem>
                            </Col>
                            {/* <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Location'} required={true}>
                                    <Input
                                        value={this.state.location}
                                        onChange={(event) => this.setState({ location: event.target.value })}
                                    />
                                </FormItem>
                            </Col> */}
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Province'} required={true}>
                                    <Select
                                        id='Province'
                                        showSearch
                                        optionFilterProp={'title'}
                                        value={this.state.provinceId}
                                        onChange={(value) => this.onChangeProvince(value)}
                                    >
                                        {this.provinceListToElem()}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'District'} required={true}>
                                    <Select
                                        id='District'
                                        showSearch
                                        optionFilterProp={'title'}
                                        value={this.state.districtId}
                                        onChange={(value) => this.onChangeDistrict(value)}
                                    >
                                        {this.districtListToElem()}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Subdistrict'} required={true}>
                                    <Select
                                        id='Subdistrict'
                                        showSearch
                                        optionFilterProp={'title'}
                                        value={this.state.subdistrictId}
                                        onChange={(value) => this.setState({subdistrictId: value})}
                                        // disabled={this.props.type == 'create' ? this.state.isdisabled : this.props.editRole ? this.state.isdisabled : true}
                                        // onSearch={(value)=>this.onSearchCustomer(value)}
                                        // notFoundContent={this.state.loadingCustomer ? <Spin size="small" /> : null}
                                    >
                                        {this.subdistrictListToElem()}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Publish'} >
                                    <Select
                                        id='Publish'
                                        value={this.state.isPublish}
                                        onChange={(value) => this.setState({isPublish: value})}
                                        // disabled={this.props.type == 'create' ? this.state.isdisabled : this.props.editRole ? this.state.isdisabled : true}
                                        // onSearch={(value)=>this.onSearchCustomer(value)}
                                        // notFoundContent={this.state.loadingCustomer ? <Spin size="small" /> : null}
                                    >
                                        <Option key={0} value={0} >{'No'}</Option>
                                        <Option key={1} value={1} >{'Yes'}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Start Date'} required={true}>
                                    <DatePicker 
                                        value={dateStart && moment(dateStart, dateFormat)}
                                        format={dateFormat}
                                        onChange={this.onChangeDateStart} 
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'End Date'} required={true}>
                                    <DatePicker 
                                        value={dateEnd && moment(dateEnd, dateFormat)}
                                        format={dateFormat}
                                        onChange={this.onChangeDateEnd} 
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Job Description'} required={true}>
                                    <TextArea
                                        autosize={{ minRows: 6 }}
                                        value={this.state.jobDescription}
                                        onChange={(event) => this.setState({jobDescription: event.target.value})}
                                    />
                                </FormItem>
                            </Col>

                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
    mainCustomerId: state.login.mainCustomerId,
})

export default connect(mapStateToProps, null)(Post);

