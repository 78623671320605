import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/login';
import { hideExpiredModal } from '../../actions/systemmodal';
import { Modal, Button } from 'antd';
import './style.scss'
import { Link } from 'react-router-dom';

class ExpiredModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    handleOk() {
        this.props.onClose()
    }

    handleCancel() {
        this.props.onClose()
    }
    handleLogout() {
        this.props.logout()
        this.props.hideExpiredModal()
    }

    render() {
        const { visible } = this.props
        return (
            <div className="home-page-wrapper">
                <Modal
                    title={"Session expired"}
                    visible={visible}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    footer={null}
                    closable={false}
                >
                    <div style={{ textAlign: 'center' }}>
                        <h1>{'Session expired'}</h1>
                        <Link to={'/'} onClick={() => this.handleLogout()}>
                            <Button type={'danger'}>logout</Button>
                        </Link>
                    </div>
                </Modal>
            </div>
        )
    }
}

ExpiredModal.propTypes = {
    onClose: PropTypes.func,
}

ExpiredModal.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    roleName: state.login.roleName,
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    hideExpiredModal: () => dispatch(hideExpiredModal())
})
export default connect(mapStateToProps, mapDispatchToProps)(ExpiredModal);

