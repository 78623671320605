import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Modal, notification,Checkbox, Icon } from 'antd';
import { get, post, put } from '../../../utils';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;
const { TextArea } = Input;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class NotificationManualModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            description: '',
            status: 'save',
            originalStatus: null,
            notificationType: '',
            notificationTypeList: [
                { value: 'product', label: 'Product' },
                { value: 'article', label: 'Article' },
            ],
            sendToType: '',
            sendToList: [
                { value: 'all', label: 'All' },
                { value: 'follow_product', label: 'Favorite Product' },
                { value: 'tag', label: 'Tag' },
            ],
            productId: null,
            sendToFollowProduct: null,
            productList: [],
            articleId: null,
            articleList: [],
            sendToTicketTag: null,
            ticketTagList: [],
        }
    }

    isDisabled() {
        const { originalStatus } = this.state
        if (originalStatus == 'sent') {
            return true
        }
        return false
    }

    getProductList() {
        let url = '/api/product';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ productList: response.dataList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getArticleList(languageCode) {
        let url = '/api/article?language=' + languageCode;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ articleList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getTicketTagList() {
        let url = '/api/ticket/tag';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ ticketTagList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getLanguageList() {
        let url = '/api/crm/administrator/language/raw';
        return get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ languageList: responses })
                this.getArticleList(responses[0].code)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        const { notificationType, productId, articleId } = this.props
        this.getProductList()
        this.getLanguageList()
        this.getTicketTagList()
        if (notificationType == 'product') {
            this.setState({ productId: productId, notificationType: 'product' })
        } else {
            this.setState({ articleId: articleId, notificationType: 'article' })
        }

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible != this.props.visible) {
            this.getProductList()
        }
        if (prevProps.productId != this.props.productId) {
            this.setState({ productId: this.props.productId })
        }
        if (prevProps.articleId != this.props.articleId) {
            this.setState({ articleId: this.props.articleId })
        }
    }



    updateNotificationManualSend(status) {
        const { subject, description, notificationType, sendToType, sendToFollowProduct, sendToTicketTag, productId, articleId } = this.state
        if(!subject || subject == ''){
            openNotificationWithIcon('error', 'System notification', 'Please enter subject.')
            return 
        }
        if(!description || description == ''){
            openNotificationWithIcon('error', 'System notification', 'Please enter description.')
            return 
        }
        let data = {
            subject: subject,
            description: description,
            send_to_type: sendToType,
            notification_type: notificationType,
            send_to_follow_product: sendToFollowProduct,
            send_to_ticket_tag: sendToTicketTag,
            product_id: productId,
            article_id: articleId,
            status: status,
            user: this.props.administratorId
        }

        let url = '/api/administrator/crm/notification/manual'; 
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.onClose()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateNotificationManualSend('sent')
    }
    onClickCancel() {
        this.props.onClose()
    }

    onClickSelectProduct(value) {
        if(value == 'product') {
            return(
                <Select
                    value={this.state.productId}
                    onChange={(value) => this.setState({ productId: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.notificationType == 'product' ? 'block' : 'none' ,width:'300px'}}
                >
                    {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        } 
        if(value == 'article') {
            return(
                <Select
                    value={this.state.articleId}
                    onChange={(value) => this.setState({ articleId: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.notificationType == 'article' ? 'block' : 'none' ,width:'300px'}}
                >
                    {this.state.articleList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        }
        
    }

    onClickSelectGroup(value) {
        if(value == 'follow_product') {
            return(
                <Select
                    value={this.state.sendToFollowProduct}
                    onChange={(value) => this.setState({ sendToFollowProduct: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.sendToType == 'follow_product' ? 'block' : 'none' ,width:'300px' }}
                >
                    {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        }
        if(value == 'tag') {
            return(
                <Select
                    value={this.state.sendToTicketTag}
                    onChange={(value) => this.setState({ sendToTicketTag: value })}
                    disabled={this.isDisabled()}
                    style={{display: this.state.sendToType == 'tag' ? 'block' : 'none' ,width:'300px' }}
                >
                    {this.state.ticketTagList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            )
        }
    }
    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const { visible } = this.props
        return (
            <div>
                <Modal
                    title={null}
                    visible={visible}
                    closable={false}
                    onOk={() => this.onClickOk()}
                    onCancel={() => this.onClickCancel()}
                    width={850}
                    footer={[
                        <Button key="back" onClick={() => this.onClickCancel()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                            {'Cancel'}
                        </Button>,
                        <Button key="submit" onClick={() => this.onClickOk()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                            {'Send'}
                        </Button>,
                    ]}
                >
                    <Row className="row-box-modal">
                        <Col>
                            <div className="header-row">
                                <Checkbox checked={this.state.subject.length > 0 ? true : false} />
                                <h3 style={{marginLeft:10}}>Header</h3>
                                
                            </div>
                            <Input
                                value={this.state.subject}
                                onChange={(event) => this.setState({ subject: event.target.value })}
                                disabled={this.isDisabled()}
                                // style={{border:'none'}}
                            />
                        </Col>
                    </Row>
                    <Row className="row-box-modal">
                        <Col>
                            <div className="header-row">
                                <Checkbox checked={this.state.description.length > 0 ? true : false} />
                                <h3 style={{marginLeft:10}}>Description</h3>
                            </div>
                            <TextArea
                                value={this.state.description}
                                onChange={(event) => this.setState({ description: event.target.value })}
                                disabled={this.isDisabled()}
                                rows={4}
                                // style={{border:'none'}}
                            />
                        </Col>
                    </Row>
                    <Row className="row-box-modal">
                        <Col>
                            <div className="header-row">
                                <Checkbox checked={this.state.productId != null || this.state.articleId != null ? true : false} />
                                <h3 style={{marginLeft:10}}>Additional Information</h3>
                                <span style={{color:'#b4b4b4',marginLeft:10}}>: Please select additional information to be broadcast</span>
                            </div>
                            {this.state.notificationTypeList.map((item, index) => {
                                return (
                                    <div className="card-itembox">
                                        <Button 
                                            onClick={() => this.setState({ notificationType: item.value })} 
                                            style={{
                                                backgroundColor:this.state.notificationType == item.value ? '#48c5eb' : '#fff',
                                                color:this.state.notificationType == item.value ? '#fff' : '#000',
                                                width:'150px',
                                                textAlign:'left',
                                                paddingLeft:'20px'
                                            }} 
                                        >
                                            <Icon type={item.value == 'product' ? 'dropbox' : 'book'} />
                                            {item.label}
                                        </Button>
                                        {this.onClickSelectProduct(item.value)}
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                    <Row className="row-box-modal">
                            <Col>
                                <div className="header-row">
                                    <Checkbox checked={this.state.sendToFollowProduct != null || this.state.sendToTicketTag != null || this.state.sendToType == 'all' ? true : false} />
                                    <h3 style={{marginLeft:10}}>Audience Group</h3>
                                    <span style={{color:'#b4b4b4',marginLeft:10}}>: Please select audience group to be broadcast</span>
                                </div>
                                {this.state.sendToList.map((item, index) => {
                                    return (
                                        <div className="card-itembox">
                                            <Button 
                                                onClick={() => this.setState({ sendToType: item.value })}
                                                style={{
                                                    backgroundColor:this.state.sendToType == item.value ? '#48c5eb' : '#fff',
                                                    color:this.state.sendToType == item.value ? '#fff' : '#000',
                                                    width:'150px',
                                                    textAlign:'left',
                                                    paddingLeft:'20px'
                                                }}
                                            >
                                                <Icon type={item.value == 'follow_product' ? 'heart' : item.value == 'all' ? 'user' : 'tag'} />
                                                {item.label}
                                            </Button>
                                            {this.onClickSelectGroup(item.value)}
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>
                    {/* ======================================================== */}
                    {/* <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Subject'}>
                                <Input
                                    value={this.state.subject}
                                    onChange={(event) => this.setState({ subject: event.target.value })}
                                    disabled={this.isDisabled()}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Description'}>
                                <Input
                                    value={this.state.description}
                                    onChange={(event) => this.setState({ description: event.target.value })}
                                    disabled={this.isDisabled()}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Type'}>
                                <Select
                                    value={this.state.notificationType}
                                    style={{ width: '100%' }}
                                    onChange={(value) => this.setState({ notificationType: value })}
                                    disabled={this.isDisabled()}
                                >
                                    {this.state.notificationTypeList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                </Select>
                            </FormItem>
                        </Col>
                        {
                            this.state.notificationType == 'product' ?
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Product'}>
                                        <Select
                                            value={this.state.productId}
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.setState({ productId: value })}
                                            disabled={this.isDisabled()}
                                        >
                                            {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    </FormItem>
                                </Col>
                                :
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Article'}>
                                        <Select
                                            value={this.state.articleId}
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.setState({ articleId: value })}
                                            disabled={this.isDisabled()}
                                        >
                                            {this.state.articleList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    </FormItem>
                                </Col>
                        }

                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Sent to'}>
                                <Select
                                    value={this.state.sendToType}
                                    style={{ width: '100%' }}
                                    onChange={(value) => this.setState({ sendToType: value })}
                                    disabled={this.isDisabled()}
                                >
                                    {this.state.sendToList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                </Select>
                            </FormItem>
                        </Col>
                        {
                            this.state.sendToType == 'follow_product' ?
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Sent to favorite product'}>
                                        <Select
                                            value={this.state.sendToFollowProduct}
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.setState({ sendToFollowProduct: value })}
                                            disabled={this.isDisabled()}
                                        >
                                            {this.state.productList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    </FormItem>
                                </Col>
                                :
                                null
                        }
                        {
                            this.state.sendToType == 'tag' ?
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Sent to tag'}>
                                        <Select
                                            value={this.state.sendToTicketTag}
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.setState({ sendToTicketTag: value })}
                                            disabled={this.isDisabled()}
                                        >
                                            {this.state.ticketTagList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    </FormItem>
                                </Col>
                                :
                                null
                        }
                    </Row> */}
                </Modal>
            </div>
        );
    }
}

NotificationManualModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

NotificationManualModal.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
})

export default connect(mapStateToProps)(NotificationManualModal);

