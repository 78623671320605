import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select } from 'antd';
import { get, put, post } from '../../../utils';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;

class LinkChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            status: 'ยังไม่ส่ง',
            description: '',
            statusList: [
                { value: 'ส่งแล้ว', label: 'ส่งแล้ว' },
                { value: 'ยังไม่ส่ง', label: 'ยังไม่ส่ง' },
                { value: 'ยกเลิก', label: 'ยกเลิก' }
            ]
        }
    }


    getArticleDetail() {
        const { editId } = this.props
        let url = '/api/article/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    name: response.name,
                    status: response.status,
                    description: response.description,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getArticleDetail()
        }
    }


    updateArticle() {
        const { name, status, description, categoryArticleId } = this.state
        let data = {
            name: name,
            status: status,
            description: description,
            category_article_id: categoryArticleId,
            user: this.props.administratorId
        }
        if (this.props.editId) {
            let url = '/api/article/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/article';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    onClickOk() {
        this.updateArticle()
    }
    onClickCancel() {
        this.setState({
            name: '',
            status: 'ยังไม่ส่ง',
            description: ''
        })
        this.props.backpage()
    }
    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 20 },
            },
        };
        const styles = {
            cardStyle: {
                backgroundColor: '#FFFFFF',
                width: '95%',
                height: 'calc(95% - 10vh)',
                margin: 0,
                position: 'absolute',
                top: 'calc(50% + 5vh)',
                left: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                overflow: 'auto'
            }
        }
        return (
            <div className='linkchat-page-wrapper' style={{ backgroundColor: '#F9F9F9', height: '90vh' }}>
                <Card style={styles.cardStyle}>
                    <Meta title="Article" />
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Header'}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Status'}>
                                    <Select
                                        value={this.state.status}
                                        onChange={(value) => this.setState({ status: value })}
                                    >
                                        {this.state.statusList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Description'}>
                                    <TextArea
                                        autosize={{ minRows: 10 }}
                                        value={this.state.description}
                                        onChange={(event) => this.setState({ description: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center" align="top">
                            <Col xs={12} sm={10} md={8} lg={6} xl={4} >
                                <Button type="danger" style={{ width: '90%' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                            </Col>
                            <Col xs={12} sm={10} md={8} lg={6} xl={4} >
                                <Button type="primary" style={{ width: '90%' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
})

export default connect(mapStateToProps, null)(LinkChat);

