import React from 'react';
import { Row, Col, Button, Icon, notification, Checkbox } from 'antd';
import { get, post, patch, ppost } from '../../../utils'; 
import { connect } from 'react-redux';

import HomeLayoutMenuModal from './component/HomeLayoutMenuModal'

import homeLayoutMenuOne from '../../../resource/screen-mobile/home-layout-menu/home-layout-menu-one.png'
import homeLayoutMenuTwo from '../../../resource/screen-mobile/home-layout-menu/home-layout-menu-two.png'
import homeLayoutMenuThree from '../../../resource/screen-mobile/home-layout-menu/home-layout-menu-three.png'

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class HomeLayoutMenuScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            homeLayoutMenu: 1,
            homeLayoutList: [
                { value: 1, name: 'Home layout menu one', image: homeLayoutMenuOne, size: 3 },
                { value: 2, name: 'Home layout menu two', image: homeLayoutMenuTwo, size: 5 },
                { value: props.mainCustomerId == 4 ? 3 : 4 , name: props.mainCustomerId == 4 ? 'Home layout menu three' : 'Home layout menu four', image: homeLayoutMenuThree, size: 6 },
                { value: 5, name: 'Home layout menu five', image: homeLayoutMenuTwo, size: 12 },
                // { value: 6, name: 'Home layout menu six', image: homeLayoutMenuTwo, size: 12 },
                // { value: 7, name: 'Home layout menu seven', image: homeLayoutMenuTwo, size: 12 },
                // { value: 8, name: 'Home layout menu eight', image: homeLayoutMenuTwo, size: 12 },
                // { value: 9, name: 'Home layout menu nine', image: homeLayoutMenuTwo, size: 12 },
            ],

            settingLayoutList: [],
            settingLayoutTypeList: [
                { value: 'product', label: 'Product', extraList: [] },
                { value: 'product_category', label: 'Product Category', extraList: [] },
                { value: 'article', label: 'Article', extraList: [] },
                { value: 'article_category', label: 'Article Category', extraList: [] },

                { value: 'about_us', label: 'About Us', extraList: [] },
                { value: 'gallery', label: 'Gallery', extraList: [] },
                { value: 'map', label: 'Map', extraList: [] },
                { value: 'frequently_asked_questions', label: 'FAQ', extraList: [] },
                { value: 'contact_us', label: 'Contact Us', extraList: [] },
                { value: 'privacy', label: 'Privacy', extraList: [] },
                ...props.mainCustomerId == 5 ? [{ value: 'category_work', label: 'Work Category', extraList: [] }] : []
            ],
        }
    }


    getSettingHomeLayoutMenu() {
        const { folderName } = this.props
        let url = '/api/setting/layout';
        get(url)
            .then(responses => {
                let settingLayoutList = responses.map(item => {
                    let temp = Object.assign({}, item)
                    temp['imageList'] = [{
                        name: item.image,
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.image}`,
                        file: null
                    }]
                    return temp
                })
                this.setState({ settingLayoutList: settingLayoutList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getProductList() {
        let url = '/api/product';
        get(url)
            .then(responses => {
                let settingLayoutTypeList = this.state.settingLayoutTypeList.map(item => Object.assign({}, item))
                let index = settingLayoutTypeList.findIndex(item => item.value == 'product')
                if (index != -1) {
                    let extraList = responses.map(item => {
                        return { value: item.id, label: item.name }
                    })
                    settingLayoutTypeList[index].extraList = extraList
                    this.setState({ settingLayoutTypeList: settingLayoutTypeList })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getArticleList() {
        let url = '/api/article';
        get(url)
            .then(responses => {
                let settingLayoutTypeList = this.state.settingLayoutTypeList.map(item => Object.assign({}, item))
                let index = settingLayoutTypeList.findIndex(item => item.value == 'article')
                if (index != -1) {
                    let extraList = responses.map(item => {
                        return { value: item.id, label: item.name }
                    })
                    settingLayoutTypeList[index].extraList = extraList
                    this.setState({ settingLayoutTypeList: settingLayoutTypeList })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCategoryProductList() {
        let url = '/api/category/product';
        get(url)
            .then(responses => {
                let settingLayoutTypeList = this.state.settingLayoutTypeList.map(item => Object.assign({}, item))
                let index = settingLayoutTypeList.findIndex(item => item.value == 'product_category')
                if (index != -1) {
                    let extraList = responses.map(item => {
                        return { value: item.id, label: item.name }
                    })
                    settingLayoutTypeList[index].extraList = extraList
                    this.setState({ settingLayoutTypeList: settingLayoutTypeList })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCategoryArticleList() {
        let url = '/api/category/article';
        get(url)
            .then(responses => {
                let settingLayoutTypeList = this.state.settingLayoutTypeList.map(item => Object.assign({}, item))
                let index = settingLayoutTypeList.findIndex(item => item.value == 'article_category')
                if (index != -1) {
                    let extraList = responses.map(item => {
                        return { value: item.id, label: item.name }
                    })
                    settingLayoutTypeList[index].extraList = extraList
                    this.setState({ settingLayoutTypeList: settingLayoutTypeList })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    
    getCategoryWorkList() {
        let url = "/api/administrator/crm/category/work"
        get(url)
            .then(responses => {
                let settingLayoutTypeList = this.state.settingLayoutTypeList.map(item => Object.assign({}, item))
                let index = settingLayoutTypeList.findIndex(item => item.value == 'category_work')
                if (index != -1) {
                    let extraList = responses.map(item => {
                        return { value: item.id, label: item['Name'] }
                    })
                    settingLayoutTypeList[index].extraList = extraList
                    this.setState({ settingLayoutTypeList: settingLayoutTypeList })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getSettingScreenMobile() {
        let url = '/api/crm/administrator/setting/screen/mobile';
        get(url)
            .then(response => {
                this.setState({
                    settingScreenMobileId: response.id,
                    homeLayoutMenu: response.home_layout_menu
                })
                this.getSettingHomeLayoutMenu()
            })
            .catch(err => {
                console.log(err.message)
            })
    }
 


    componentDidMount() { 
        const {mainCustomerId} = this.props
        this.getProductList()
        this.getArticleList()
        this.getCategoryProductList()
        this.getCategoryArticleList() 
        this.getSettingScreenMobile()
        if(mainCustomerId == 5){
            this.getCategoryWorkList()
        }
        // let tempHomeLayoutList = Object.assign([],this.state.homeLayoutList)
        // let menu = { value: 4, name: 'Home layout menu four', image: homeLayoutMenuThree, size: 6 }
        // if(mainCustomerId == 4){
        //     menu = { value: 3, name: 'Home layout menu three', image: homeLayoutMenuThree, size: 6 }   
        // }
        // tempHomeLayoutList.push(menu)

        // this.setState({
        //     homeLayoutList: tempHomeLayoutList
        // })
        
    }

    updateSettingHomeLayoutMenu() {
        const { settingScreenMobileId, homeLayoutMenu } = this.state
        let url = '/api/crm/administrator/setting/screen/mobile/' + settingScreenMobileId + '/home/layout/menu';
        let data = { home_layout_menu: homeLayoutMenu }
        patch(url, data)
            .then(response => {
                this.updateSettingHomeLayoutMenuDetail()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async updateSettingHomeLayoutMenuDetail() {
        let settingLayoutList = await Promise.all(this.state.settingLayoutList.map(async (item) => {
            let temp = {
                layout_number: item.layout_number,
                layout_type: item.layout_type,
                image: item.image,
                name: item.name,
                extra_id: item.extra_id,
            }
            let uploadFileList = item.imageList.filter(item => item.file)
            if (uploadFileList.length) {
                let formData = new FormData();
                uploadFileList.map((item, index) => formData.append("image" + index, item.file))
                let fileNameList = await this.uploadImageToServer(formData)
                temp.image = fileNameList[0]
            }
            return temp
        }))
        let data = {
            settingLayoutList: JSON.stringify(settingLayoutList)
        }
        let url = '/api/setting/layout'
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save setting screen mobile home swipe success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    updateLayout(item) {
        let settingLayoutList = this.state.settingLayoutList.map(item => Object.assign({}, item)).slice(0, item.size)
        for (let index = settingLayoutList.length; index < item.size; index++) {
            settingLayoutList.push({
                layout_number: index,
                layout_type: null,
                image: null,
                name: null,
                extra_id: null,
                imageList: []
            })
        }
        this.setState({
            homeLayoutMenu: item.value,
            settingLayoutList: settingLayoutList,
            visible: true,
        })
    }

    onClickOk() {
        this.updateSettingHomeLayoutMenu()
    }
    onClickCancel() {
        this.getSettingHomeLayoutMenu()
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'mobile')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    render() {
        return (
            <div >
                <Row >
                    <Col span={12} style={{ textAlign: 'left' }}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <div className="mobile-screen-content-wrapper">
                    {this.state.homeLayoutList.map(item => {
                        return (
                            <div className="item-mobile-screen-wrapper">
                                <Checkbox
                                    className='item-mobile-screen-checkbox-wrapper'
                                    checked={item.value == this.state.homeLayoutMenu}
                                    onChange={() => this.updateLayout(item)}
                                />
                                <div
                                    className='item-mobile-screen-image-wrapper'
                                    onClick={() => this.updateLayout(item)}
                                >
                                    <img
                                        src={item.image}
                                        className='item-mobile-screen-image'
                                        style={{ border: item.value == this.state.homeLayoutMenu ? '4px solid #48c5eb' : 'none' }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <HomeLayoutMenuModal
                    visible={this.state.visible}
                    settingLayoutList={this.state.settingLayoutList}
                    settingLayoutTypeList={this.state.settingLayoutTypeList}
                    updateSettingLayoutList={(value) => this.setState({ settingLayoutList: value })}
                    onClose={() => this.setState({ visible: false })}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
    roleDetail: state.role.roleDetail,
    folderName: state.login.folderName,
    mainCustomerId: state.login.mainCustomerId,
    }
}

export default connect(mapStateToProps, null)(HomeLayoutMenuScreen);
