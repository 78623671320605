import React from 'react';
import { Col, Row, List, message } from 'antd';
import { connect } from 'react-redux';
import config from '../../../config.json'
import { post, get } from '../../../utils';
import defaultSquareSrc from '../../../resource/default/300x300-square.png'
import moment from 'moment';


class ChatListContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
            loading: false,
            hasMore: true,
        }
    }

    displayTime(minutes) {
        if (minutes >= 1440) {
            let calculate = minutes / 1440
            return calculate.toFixed(0) + ' Days'
        }
        else if (minutes >= 60) {
            let calculate = minutes / 60
            return calculate.toFixed(0) + ' Hours'
        } else {
            return minutes + ' Min'
        }

    }
    displayStatus(status, received) {
        if (received) {
            return (
                <p style={{ backgroundColor: '#47c5eb', fontSize: '10px', color: '#fff', borderRadius: '10px', margin: '5px', fontWeight: 'bold', textAlign: 'center' }}><div style={{width:10,height:10,borderRadius:'50%'}}></div></p>
            )
        }
        if (status == 'wait administrator') {
            return (
                <p style={{ backgroundColor: '#EB3A17', fontSize: '10px', color: '#fff', borderRadius: '3px', padding: '4px', marginBottom:'0px', paddingTop:'2px', fontWeight: 'bold', textAlign: 'center', width:'89px' }}>{'Waiting admin'}</p>
            )
        }
        if (status == 'wait customer') {
            return (
                <p style={{ backgroundColor: '#FFCE26', fontSize: '10px', color: '#fff', borderRadius: '3px', padding: '4px', marginBottom:'0px', paddingTop:'2px', fontWeight: 'bold', textAlign: 'center', width:'89px' }}>{'Waiting customer'}</p>
            )
        }
        if (status == 'pending') {
            return (
                <p style={{ backgroundColor: '#FF672C', fontSize: '10px', color: '#fff', borderRadius: '3px', padding: '4px', marginBottom:'0px', paddingTop:'2px', fontWeight: 'bold', textAlign: 'center', width:'89px' }}>{'In progress'}</p>
            )
        }
        if (status == 'close') {
            return (
                <p style={{ backgroundColor: '#46A786', fontSize: '10px', color: '#fff', borderRadius: '3px', padding: '4px', marginBottom:'0px', paddingTop:'2px', fontWeight: 'bold', textAlign: 'center', width:'89px' }}>{'Close'}</p>
            )
        }

    }
    displayMessage(message,file,image,received) {
        if (message != null) {

            if (message.slice(0, 2) == "#I") {
                return <p className={received ? 'message-read' : 'message' }>{'Send picture'}</p>
            }
            if (message.slice(0, 2) == "#P") {
                return <p className={received ? 'message-read' : 'message' }>{'Send product'}</p>
            }
            if (message.slice(0, 2) == "#V") {
                return <p className={received ? 'message-read' : 'message' }>{'Send video'}</p>
            }
            if (message.slice(0, 2) == "#C") {
                return <p className={received ? 'message-read' : 'message' }>{'Send Category'}</p>
            }
            if (message.slice(0, 2) == "#R") {
                return <p className={received ? 'message-read' : 'message' }>{'Send Link register'}</p>
            }
            if (message.slice(0, 2) == "#F") {
                return <p className={received ? 'message-read' : 'message' }>{'Send file'}</p>
            }
            if(message == "" && file != null) {
                return <p className={received ? 'message-read' : 'message' }>{'Send file'}</p>

            }if(message == "" && image != null) {
                return <p className={received ? 'message-read' : 'message' }>{'Send picture'}</p>
            }
            else {
                return <p className={received ? 'message-read' : 'message' }>{message.slice(0, 15)}</p>
            }
        }
        
        else {
            return <p className={received ? 'message-read' : 'message' }>{message != null ? message.slice(0, 15) : message}</p>
        }
    }
    render() {
        const { chatList, ticketId, folderName } = this.props
        console.log("==== chatList ====",chatList)
     
        return (
            <div className='chatlist-content-wrapper'>
                <List
                    itemLayout="vertical"
                    dataSource={chatList}
                    renderItem={item => (
                        <List.Item style={{ backgroundColor: item.id == ticketId ? '#f2f2f2' : '', padding: '20px', paddingRight:'10px', paddingLeft:'5px', cursor: 'pointer' }}>
                            <div className='item-wrapper' onClick={() => this.props.onChangeTicket(item.id)} >
                                <div className='image-wrapper'>
                                    <img
                                        width={40} height={40}
                                        style={{ borderRadius: '20px' }} 
                                        src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.customeravatar}`}
                                        onError={(image) => image.target.src = defaultSquareSrc}
                                    />
                                </div>
                                <div className="text-wrapper" >
                                    {/* <p className='name'>{item.name != null ? item.name.slice(0, 20) : item.id ? `#${item.id.toString().padStart(9, '0')}` : item.name }</p> */}
                                    {item.name ? 
                                        <p className='name'>{item.name}</p>
                                        : 
                                        <p className='name'>
                                            {item.customerName != null ? item.customerName : item.id ? `#${item.id.toString().padStart(9, '0')}` : item.name }, 
                                            {item.customerGroupName != null ? item.customerGroupName : item.id ? `#${item.id.toString().padStart(9, '0')}` : item.name }
                                        </p>
                                    }
                                    {this.displayMessage(item.message,item.file,item.image,item.received)}
                                </div>
                                <div className="time-wrapper" style={{ alignItems: 'center' }}> 
                                    <p className='datetime'>{this.displayTime(moment().diff(moment.utc(item.datetime).local().format("YYYY-MM-DD HH:mm:ss"), 'minutes'))}</p>
                                    {this.displayStatus(item.status, item.received)}
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    folderName: state.login.folderName,
})

export default connect(mapStateToProps, null)(ChatListContent);
