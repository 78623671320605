import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setUserAdministratorIdForgotPassword } from '../../actions/forgotpassword';
import { post } from '../../utils';
import { Row, Col, notification, Input, Icon } from 'antd';
import { Helmet } from "react-helmet";
import logoCodexConnectWhite from '../../resource/Logo/logo-codex-connect-white.png'
import forgetpasspic from '../../resource/assets/forgetpasspic.png'
import codexConnect from '../../resource/Logo/logoCodexConnect.png'
import './style.scss'
import  { Link } from 'react-router-dom'

notification.config({
    placement: 'topRight',
    duration: 1,
});

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class ValidateOTP extends Component {
    constructor(props) {
        super(props)
        this.state = {
            otp: ''
        }
    }



    handleClick() {
        const otp = this.refs.otp
        if (this.state.otp == '') {
            openNotificationWithIcon('error', 'Incorrect information entered.', 'Please enter "OTP".')
            otp.focus()
            return
        }
        if (this.state.otp.length != 10 && this.state.otp != '') {
            openNotificationWithIcon('error', 'Incorrect information entered.', 'Please enter the OTP to complete 10 digits.')
            otp.focus()
            return
        }

        let url = "/public/crm/administrator/user/administrator/forgotpassword/verification";
        let data = {
            otp: this.state.otp,
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    openNotificationWithIcon('success', 'OTP validation.', 'Change password.')
                    this.props.setUserAdministratorIdForgotPassword(response.userAdministratorId)
                    this.props.history.push('/forgot_password/changepassword')
                } else {
                    openNotificationWithIcon('error', 'OTP validation.', 'OTP validation failed. Try the transaction again.')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleEnterKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleClick()
        }
    }

    render() {
        const { colorTheme, buttonTheme } = this.props
        return (
            <Row className='forgetpassword-page-wrapper'>
                <Helmet>
                    <title>{"Validate OTP"}</title>
                </Helmet>
                <Col className='left-colunm-wrapper' span={10}>
                    <Link to={"/forgot_password/requestotp"}>
                        <div className='logo'>
                            <div>
                                <Icon style={{ fontSize: 50, fontWeight: 500, color: '#fff' }} type="left" />
                            </div>
                            <div>
                                <img width={300} src={logoCodexConnectWhite} />
                            </div>
                        </div>
                    </Link>
                    <p className='sub-title'>Validate OTP</p>
                    <img width={350}
                        className='left-pic'
                        src={forgetpasspic}
                    />
                    <div className="login-footer">
                        <p>Copyright &#169; 2020 Codex Digital Partner Co.,Ltd. All rights reserved.</p>
                    </div>

                </Col>
                <Col className='right-colunm-wrapper' span={14}>
                    <div className='container' >

                        <div className='input-group'>
                            <div className='logo'>
                                <img width={350} src={codexConnect} />
                            </div>
                            <p className='title' style={{ color: colorTheme.primary }}>Enter OTP Number</p>
                            <p className="sub-title">Please check your email and enter the OTP number</p>
                            <Input
                                required
                                size="large"
                                className="input-login"
                                type='text'
                                ref='otp'
                                placeholder="OTP Number"
                                value={this.state.otp}
                                onChange={(event) => this.setState({ otp: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="lock" style={{ color: colorTheme.primary }} />}
                            />
                            <button
                                // style={buttonTheme.primary}
                                onClick={(event) => this.handleClick(event)}
                            >
                                {'OK'}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }

}

const mapStateToProps = (state) => ({
    colorTheme: state.theme.color,
    buttonTheme: state.theme.button
})

const mapDispatchToProps = (dispatch) => ({
    setUserAdministratorIdForgotPassword: (payload) => dispatch(setUserAdministratorIdForgotPassword(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ValidateOTP);

