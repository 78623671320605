import { SET_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD , CLEAR_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD} from './types';

export function setUserAdministratorIdForgotPassword(payload) {
    return {
        type: SET_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD,
        payload: payload
    };
}

export function clearUserAdministratorIdForgotPassword(payload) {
    return {
        type: CLEAR_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD,
        payload: payload
    };
}
 