import React, { Fragment } from 'react'
import { Modal, Button, Select, Form, Row, Col, Input, Radio } from 'antd';
import ImageUpload from '../../../containers/ImageUpload';

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

const formItemLayout = {
    // labelCol: {
    //     xs: { span: 24 },
    //     sm: { span: 4 },
    // },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};
class ProductSkuModal extends React.Component {
    initState = {
        loading: false,
        error: [],

        id: null,
        image: [],
        // view: null,
        attr_1: '',
        weight: 0,
        total: 0,
        purchase: 0,
        inventory: 0,
        product_point: 0,
        price: 0,
        price_for_sale: 0,
    }

    constructor(props) {
        super(props);
        this.state = this.initState
    }
    componentWillReceiveProps(nextProps) {
        let _item;
        let item = nextProps.productSkuCurrentItem ? nextProps.productSkuCurrentItem : false
        if (!item) {
            _item = {
                id: null,
                image: [],
                // view: '',
                attr_1: '',
                weight: 0,
                total: 0,
                purchase: 0,
                inventory: 0,
                product_point: 0,
                price: 0,
                price_for_sale: 0,
            }
        } else {
            _item = {
                id: item.id,
                image: item.image,
                // view: item.view,
                attr_1: item.attr_1,
                weight: item.weight | 0,
                total: item.total | 0,
                purchase: item.purchase | 0,
                inventory: item.inventory | 0,
                product_point: item.product_point | 0,
                price: item.price | 0,
                price_for_sale: item.price_for_sale | 0,
            }
        }
        this.setState(_item)
        this.initInput(_item)

    }
    initInput = (item) => {
        let thisScope = this
        setTimeout(() => {
            thisScope.setState({
                id: item.id,
                image: item.image,
                attr_1: item.attr_1,
                weight: item.weight,
                total: item.total,
                purchase: item.purchase,
                inventory: item.inventory,
                product_point: item.product_point,
                price: item.price,
                price_for_sale: item.price_for_sale,
            });
        }, 100);
    }
    handleOk() {
        const { id, image, attr_1, weight, total, purchase, inventory, product_point,price, price_for_sale, } = this.state
        const { productSkuCurrentItem } = this.props
        const value = {
            id: id,
            image: image && image.length > 0 ? image : [],
            // view: image && image.length > 0 && image[0].view ? image.view : null,
            attr_1: attr_1,
            weight: weight,
            total: total,
            purchase: purchase,
            inventory: inventory,
            product_point: product_point,
            price: price,
            price_for_sale: price_for_sale
        }
        // let int = productSkuCurrentItem ? productSkuCurrentItem.index : false
        this.props.handleOk(value, productSkuCurrentItem ? productSkuCurrentItem.index : false)
    }
    handleCancel() {
        this.props.onClose()
    }

    render() {
        const { visible, isNotUsePriceForAllProduct, isUsePointIndividuallySku, productTitleName, attr_1 } = this.props;
        const { loading, product_point } = this.state;
        return (
            <div>
                <Modal
                    width='50vw'
                    visible={visible}
                    title={'Product SKU'}
                    onCancel={() => this.handleCancel()}
                    footer={[
                        <Button key="submit"
                            type="primary"
                            size="large"
                            loading={loading}
                            onClick={() => this.handleOk()}
                        // disabled={this.state.status == 'show' ? true : false} 
                        >
                            {"Save"}
                            {/* {this.renderText('course_page', 'save', 'Save')} */}
                        </Button>,
                    ]}
                >
                    <Form className='product-sku-modal-align-left'>
                        <Row>
                            <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                <FormItem {...formItemLayout} label={'Product SKU image'}>
                                    <ImageUpload
                                        label={'Upload product SKU image'}
                                        limit={1}
                                        dataSource={this.state.image}
                                        // previewImage={productSkuCurrentItem ? this.state.view : null}
                                        updateDataSource={(dataSource) => this.setState({ image: dataSource })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} required label={productTitleName ? productTitleName : "Name"}>
                                    <Input
                                        value={this.state.attr_1}
                                        onChange={(event) => this.setState({ attr_1: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} label={"Weight"}>
                                    <Input
                                        value={this.state.weight}
                                        type={'number'}
                                        onChange={(event) => this.setState({ weight: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} label={"Total"}>
                                    <Input
                                        value={this.state.total}
                                        type={'number'}
                                        onChange={(event) => this.setState({ total: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} label={"Purchase"}>
                                    <Input
                                        value={this.state.purchase}
                                        type={'number'}
                                        onChange={(event) => this.setState({ purchase: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} label={"Inventory"}>
                                    <Input
                                        value={this.state.inventory}
                                        type={'number'}
                                        onChange={(event) => this.setState({ inventory: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} label={"Point"}>
                                    <Input
                                        disabled={!isUsePointIndividuallySku}
                                        value={product_point}
                                        type={'number'}
                                        onChange={(event) => this.setState({ product_point: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} label={"Price"}>
                                    <Input
                                        disabled={!isNotUsePriceForAllProduct}
                                        value={this.state.price}
                                        type={'number'}
                                        onChange={(event) => this.setState({ price: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}>
                                <FormItem {...formItemLayout} label={"Price For Sale"}>
                                    <Input
                                        disabled={!isNotUsePriceForAllProduct}
                                        value={this.state.price_for_sale}
                                        type={'number'}
                                        onChange={(event) => this.setState({ price_for_sale: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <ul>
                        {this.state.error.map((item, index) => {
                            return (<li style={{ color: 'red' }} key={index}>{item}</li>)
                        })}
                    </ul>
                </Modal>
            </div>
        );
    }
}

export default (ProductSkuModal);
