import React from 'react';
import { Button, Form, Modal, Select, Tag } from 'antd'
import { notiSuccess, notiRemove } from '../../../actions/notification';
import { connect } from 'react-redux';
import { post, get, put, del } from '../../../utils';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {
        sm: { span: 6 },
        md: { span: 6 },
    },
    wrapperCol: {
        sm: { span: 18 },
        md: { span: 18 },
    },
};

class TicketTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            ticketTagList: [],
            tempTicketTagList: []

        }
    }

    getTicketTagList() {
        let { ticketId } = this.props
        if (!ticketId) {
            return
        }
        let url = '/api/administrator/crm/ticket/chat/tag/' + ticketId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } 
                this.setState({ ticketTagList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }



    componentDidMount() {
        this.getTicketTagList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ticketId != this.props.ticketId) {
            this.getTicketTagList()
        }
    }

    deleteTicketTag(ticket_tag_use_tag_id) { 
        let url = '/api/administrator/crm/ticket/chat/tag/' + ticket_tag_use_tag_id;
        del(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getTicketTagList()
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    onClickAdd() {
        let tempTicketTagList = this.state.ticketTagList.map(item => Object.assign({}, item))
        this.setState({ visible: true, tempTicketTagList: tempTicketTagList })
    }

    updateTicketTagList(index, value) {
        let tempTicketTagList = this.state.tempTicketTagList.map(item => Object.assign({}, item))
        tempTicketTagList[index].used = value
        this.setState({ tempTicketTagList: tempTicketTagList })
    }

    handleCancel() {
        this.setState({ visible: false, tempTicketTagList: [] })
    }

    handleOk() {
        let { ticketId } = this.props
        let url = '/api/administrator/crm/ticket/chat/tag/' + ticketId;
        let ticketTagList = this.state.tempTicketTagList.filter(item => item.used == 1)
        let data = { ticketTagList: JSON.stringify(ticketTagList) }
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ visible: false, tempTicketTagList: [] })
                this.getTicketTagList()
            })
            .catch(err => {
                console.log(err.message)
            })

    }

    render() {
        const { visible, ticketTagList, tempTicketTagList } = this.state

        return (
            <div>
                <div className="tag-container">
                    {ticketTagList.filter(item => item.used == 1).map(item => {
                        return (
                            <Tag color="#2db7f5" className="tag-list" closable onClose={() => this.deleteTicketTag(item.ticket_tag_use_tag_id)} >{item.name}</Tag>
                        )
                    })}
                    <Tag className="tag-btn" onClick={() => this.onClickAdd()}>{'+ Tag'}</Tag>
                </div>
                <Modal
                    visible={visible}
                    title="Tag"
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    closable={false}
                    style={{ textAlign: 'center' }}
                    footer={[
                        <Button key="back" onClick={() => this.handleCancel()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                            Cancel
                    </Button>,
                        <Button key="submit" onClick={() => this.handleOk()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                            Ok
                    </Button>,
                    ]}
                >
                    {tempTicketTagList.map((item, index) => {
                        if (item.used == 1) {
                            return (
                                <Tag color="#2db7f5" className="tag-list" onClick={() => this.updateTicketTagList(index, 0)} >{item.name}</Tag>
                            )
                        } else {
                            return (
                                <Tag className="tag-list" onClick={() => this.updateTicketTagList(index, 1)} >{item.name}</Tag>
                            )
                        }

                    })}
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    userName: state.login.username,
})


export default connect(mapStateToProps)(TicketTag);
