import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Row, Col, notification, Icon, Input } from 'antd';
import { loginSuccess } from '../../actions/login';
import { post } from '../../utils';
import logoCodexConnectWhite from '../../resource/Logo/logo-codex-connect-white.png'
import codexConnect from '../../resource/Logo/logoCodexConnect.png'
import './style.scss'

notification.config({
    placement: 'topRight',
    duration: 1,
});

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: ''
        }
    }


    componentDidMount() {

    }

    handleClick() {
        const username = this.refs.username
        const password = this.refs.password
        if (!this.state.username || this.state.username == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Username".')
            username.focus()
            return
        }
        if (!this.state.password || this.state.password == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Password".')
            password.focus()
            return
        }
        let url = "/api/user/login/administrator";
        let data = {
            username: this.state.username,
            password: this.state.password,
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    this.props.loginSuccess(response.user)
                    openNotificationWithIcon('success', "Authentication passed", "Login success")
                } else {
                    switch (response.error.code) {
                        case 'NOT_FOUND':
                            openNotificationWithIcon('error', "Authentication failed", "Username not found")
                            break;
                        case 'INCORRECT_PASSWORD':
                            openNotificationWithIcon('error', "Authentication failed", "Incorrect password")
                            break;
                    }
                }
            })
            .catch(error => {
                console.log(error)
                switch (error.message) {
                    case 'Unauthorized':
                        openNotificationWithIcon('error', "Authentication failed", "Incorrect password")
                        break;
                }
            })
    }

    handleEnterKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleClick()
        }
    }

    render() {
        const { colorTheme, buttonTheme } = this.props
        return (
            <Row className='login-page-wrapper'>
                <Helmet>
                    <title>{"Sign in"}</title>
                </Helmet>
                <Col className='left-colunm-signin-wrapper' sm={24} md={9} lg={9}>
                    <div className='logo'>
                        <img width={350} src={logoCodexConnectWhite} />
                    </div>


                    <div className="login-text">
                        <p className='sub-title'>Welcome to </p>
                        <p className="sub-title-2">CodexConnect</p>
                        <p className="sub-title-3">Don't have user account?</p>
                        <Link to={'/signup'}>
                            <div className="signup-button">
                                <p style={{ color: colorTheme.primary }}>{"Sign up"}</p>
                            </div>
                        </Link>
                    </div>

                    <div className="login-footer">
                        <p>Copyright &#169; 2020 Codex Digital Partner Co.,Ltd. All rights reserved.</p>
                    </div>
                </Col>
                <Col className='right-colunm-signin-wrapper' sm={24} md={15} lg={15}>
                    <div className='container' >
                        <div className='input-group'>
                            <div className='logo'>
                                <img width={350} src={codexConnect} />
                            </div>
                            <p className='title' style={{ color: colorTheme.primary }}><span className="title-bold">Sign</span> in</p>
                            <p className="sub-title">Enter your Email and password <span style={{ color: colorTheme.primary }}>to access</span></p>

                            <Input
                                autoFocus
                                className="input-login"
                                size="large"
                                type='text'
                                ref='username'
                                value={this.state.username}
                                onChange={(event) => this.setState({ username: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                required
                                placeholder="Email"
                                prefix={<Icon type="mail" style={{ color: colorTheme.primary }} />}
                            />
                            <Input
                                size="large"
                                type='password'
                                ref='password'
                                value={this.state.password}
                                onChange={(event) => this.setState({ password: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                required
                                placeholder="Password"
                                prefix={<Icon type="lock" style={{ color: colorTheme.primary }} />}
                            />
                            <button
                                // style={buttonTheme.primary}
                                onClick={() => this.handleClick()}
                            >
                                {'Sign in'}
                            </button>
                        </div>
                        <Link to={'/forgot_password/requestotp'}>
                            <p className='' style={{ color: colorTheme.primary }}>{"Forgot Password ?"}</p>
                        </Link>
                    </div>
                </Col>

            </Row>

        )
    }

}

const mapStateToProps = (state) => ({
    colorTheme: state.theme.color,
    buttonTheme: state.theme.button
})

const mapDispatchToProps = (dispatch) => ({
    loginSuccess: (payload) => dispatch(loginSuccess(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

