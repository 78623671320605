import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/login';
import { setActiveMenu, setActiveSubMenu } from '../../actions/leftmenu';
import BurgerMenu from 'react-burger-menu'
import { Link } from 'react-router-dom'
import LeftMenu from '../../containers/LeftMenu'
import './style.scss'
import { menuList } from '../../routes'
import { get, post } from '../../utils';
import codexConnect from '../../resource/Logo/logoCodexConnect.png'
import logoSrc from '../../resource/Logo/company_logo.png'
import defaultSquareSrc from '../../resource/default/300x300-square.png'
import config from '../../config.json'
import { Menu, Icon, Badge, Popover, Button } from 'antd';
const { SubMenu } = Menu;

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteList: [],
        }
    }

    getMenu() {
        const MenuBurger = BurgerMenu['slide'];
        var isMenuOpen = (state) => state.isOpen
        const styles = {
            bmBurgerButton: {
                position: 'absolute',
                top: '10px',
                left: '10px',
                width: '25px',
                height: '25px',
            },
            bmBurgerBars: {
                background: '#616161',
                borderRadius: '2px'
            },
            bmCrossButton: {
                height: '25px',
                width: '25px',
            },
            bmCross: {
                background: '#bdc3c7'
            },
            bmMenu: {
                background: '#ffffff',
                fontSize: '1.15em',
                height: '100vh',
                overflow: 'hidden',

            },
            bmMorphShape: {
                fill: '#373a47'
            },
            bmItemList: {
                color: '#b8b7ad',
                padding: '0em',
                textAlign: 'center'
            },
            bmItem: {
                display: 'inline-block',
            },
            bmOverlay: {
                background: 'rgba(0, 0, 0, 0.3)'
            }
        }

        return (
            <MenuBurger styles={styles} onStateChange={isMenuOpen}>
                <LeftMenu />
            </MenuBurger>
        )
    }

    getInviteList() {
        let url = '/api/crm/administrator/maincustomer/invite'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ inviteList: responses })
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        this.getInviteList()
    }

    onClickJoinInvitation(id) {
        let url = '/api/crm/administrator/maincustomer/invition/join'
        let data = { id: id }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    this.getInviteList()
                } else {
                    console.log("response error", response)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    onClickCancelInvitation(id) {
        let url = '/api/crm/administrator/maincustomer/invition/cancel'
        let data = { id: id }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    this.getInviteList()
                } else {
                    console.log("response error", response)
                }

            })
            .catch(error => {
                console.log(error)
            })
    }

    getRoleView(roleKey) {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === roleKey)
            if (index != -1) {
                return roleDetail[index].view
            }
            return false
        }
        return false
    }


    render() {
        const { packageDatail, activeMenu, activeSubMenu, name, roleName, folderName, avatar, title } = this.props
        const { inviteList } = this.state
        const text = <span>{'Notification'}</span>;
        const content = (
            inviteList.length ?
                <div className='notification-wrapper'>
                    {inviteList.map(item => {
                        return (
                            <div className='item-notification-wrapper'>
                                <p className='title' >{`You have a request for a company ${item.role_name} from ${item.name}`}</p>
                                <p>{'An administrator has sent you a request. Please check your email. Thank you'}</p>
                                <div className='button-wrapper'>
                                    <Button type="primary" onClick={() => this.onClickJoinInvitation(item.id)}>{'Join'}</Button>
                                    <Button onClick={() => this.onClickCancelInvitation(item.id)}>{'Cancel'}</Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                <div className='notification-empty-wrapper'>
                    <p>{'Emtpy'}</p>
                </div>
        );

        let tempMenuList = []
        if (packageDatail) {
            tempMenuList = menuList.filter(item => {
                if (!packageDatail.show_chat_system && item.name == 'Chat') {
                    return false
                }
                if (!packageDatail.show_notification_system && item.name == 'Notification') {
                    return false
                }
                return true
            })
        }
        return (
            <div className='header-component-wrapper' >
                <div className='header-leftmenu-wrapper'>
                    {this.getMenu()}
                </div>
                <div className='ticket-chat-page-wrapper'>
                    <div className="header-grid-space"></div>
                    <div className='header-grid-left' >

                        <div className="logo">
                            <Link to={'/'}>
                                <img
                                    src={codexConnect}
                                    style={{ height: '46px', marginRight: '10px', padding: '8px', paddingTop: '5px', paddingBottom: '11px' }}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className='header-grid-mid'>
                        <div className="new-menu">
                            <Menu
                                mode="horizontal"
                                selectedKeys={[activeMenu]}
                                onClick={(event) => this.props.setActiveMenu(event.key)}
                            >
                                <Menu.Item key={'home'}  >
                                    <Link to={'/'}>
                                        <div><p>{'Home'}</p></div>
                                    </Link>
                                </Menu.Item>
                                {tempMenuList.map((item, index) => {
                                    let showSubMenuList = item.subMenuList.filter(item => this.getRoleView(item.roleKey)).filter((item)=>{
                                        if(this.props.mainCustomerId !=5){
                                            return !item.isTechnicianApp
                                        }
                                        return true
                                    })
                                    // .filter((item)=>this.props.mainCustomerId !=5 && !item.isTechnicianApp)
                                    console.log('showSubMenuList',showSubMenuList)
                                    if (!showSubMenuList.length) {
                                        return null
                                    }
                                    if (item.name == 'Broad cast') {
                                        showSubMenuList = []
                                    }
                                    return (
                                        <SubMenu
                                            key={item.name}
                                            title={
                                                item.name == 'Broad cast' ?
                                                    <Link to={'/notification_manual_send'} >
                                                        <div className='broadcast-memu' onClick={() => item.name == 'Broad cast' ? this.props.setActiveSubMenu('Manual Send') && this.props.setActiveMenu('Broad cast') : null} >
                                                            <p style={{ color: activeSubMenu == 'Manual Send' ? '#1890ff' : '#000000A6' }}> 
                                                                {item.name}
                                                            </p>
                                                        </div>
                                                    </Link>
                                                    :
                                                    <div>
                                                        <p>
                                                            {item.name}
                                                        </p>
                                                    </div>
                                            }
                                        >
                                            {
                                                showSubMenuList.map((subItem, index) => {
                                                    return (
                                                        <Menu.Item
                                                            key={subItem.name}
                                                            onClick={(event) => this.props.setActiveSubMenu(event.key)}
                                                        >
                                                            <Link to={subItem.path} >
                                                                <div >
                                                                    <p >
                                                                        <img
                                                                            src={subItem.icon}
                                                                            style={{ width: '19px', marginRight: '7px' }}
                                                                        />
                                                                        {subItem.name}
                                                                    </p>
                                                                </div>
                                                            </Link>
                                                        </Menu.Item>
                                                    )
                                                })
                                            }
                                        </SubMenu>
                                    )
                                })}
                            </Menu>
                        </div>
                    </div>
                    <div className='header-grid-right' >
                        <div class="header-right">
                            <div class="header-right-item">
                                <div class="header-right-item-notification">
                                    <Popover
                                        visible={this.state.visible}
                                        title={text}
                                        content={content}
                                        placement="topRight"
                                        trigger="click"
                                        onVisibleChange={(visible) => this.setState({ visible: visible })}
                                    >
                                        <Badge count={inviteList.length} >
                                            <Icon style={{ fontSize: 22 }} type="bell" />
                                        </Badge >
                                    </Popover>
                                </div>
                                <div class="header-right-item-avatar">
                                    <img
                                        width={40} height={40}
                                        style={{ borderRadius: '50%', marginTop: -5 }}
                                        src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${avatar}`}
                                        onError={(image) => image.target.src = defaultSquareSrc}
                                    />
                                </div>
                                <div class="header-right-item-username">
                                    <p class="profile-name">{name}</p>
                                    <p class="profile-role">{roleName}</p>
                                </div>
                                <div class="header-right-item-logout">
                                    <Link to={'/'} onClick={() => this.props.logout()}>
                                        <Icon type="logout" style={{ fontSize: 26, paddingLeft: 5, paddingRight: 5 }} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    name: state.login.name,
    roleName: state.login.roleName,
    packageDatail: state.package.packageDatail,
    roleDetail: state.role.roleDetail,
    activeMenu: state.leftmenu.activeMenu,
    activeSubMenu: state.leftmenu.activeSubMenu,
    avatar: state.login.avatar,
    folderName: state.login.folderName,
    mainCustomerId: state.login.mainCustomerId,
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    setActiveMenu: (payload) => dispatch(setActiveMenu(payload)),
    setActiveSubMenu: (payload) => dispatch(setActiveSubMenu(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
