import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store'
import { get } from './utils'
import { clearActiveLeftMenu } from './actions/leftmenu'
import { initializePackage } from './actions/package'
import { initializeRole } from './actions/role'
import Authentication from './components/Authentication';
import TableLayout from './containers/TableLayout'; 

import 'antd/dist/antd.css';
import { adminPath, publicAdministratorPath } from './routes';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packageDatail: null,
            roleDatail: null
        }
    }

    getInitializePackageDetail() {
        const mainCustomerId = localStorage.getItem('mainCustomerId') ? localStorage.getItem('mainCustomerId') : null
        if (mainCustomerId != null && mainCustomerId != 'null') {
            let url = '/api/crm/administrator/setting/package';
            get(url)
                .then(responses => {
                    this.setState({ packageDatail: responses })
                    store.dispatch(initializePackage(responses))
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    getInitializeRoleDetail() {
        const roleId = localStorage.getItem('roleId') ? localStorage.getItem('roleId') : null
        if (roleId != null && roleId != 'null') {
            let url = '/api/account/role/detail/' + roleId;
            get(url)
                .then(responses => {
                    this.setState({ roleDatail: responses })
                    store.dispatch(initializeRole(responses))
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    componentDidMount() {
        store.dispatch(clearActiveLeftMenu())
        this.getInitializePackageDetail()
        this.getInitializeRoleDetail()
    }


    render() {
        let adminPathList = adminPath.map(item => Object.assign({}, item))
        return (
            <Provider store={store} >
                <Router>
                    <Switch>
                        {publicAdministratorPath.map((item, index) => <Route exact key={index} path={item.path} component={item.component} />)}
                        {
                            adminPathList.map((item, i) => <Route exact key={i} path={item.path} render={(props) =>
                                <TableLayout
                                    {...props}
                                    isShowTable={item.isShowTable}
                                    name={item.name}
                                    subName={item.subName}
                                    roleKey={item.roleKey}
                                    component={item.component}
                                    url={item.url}
                                    isConnectCodexLearn={item.isConnectCodexLearn}
                                />}
                            />)
                        } 
                        <Route exactpath={'/'} component={Authentication} />

                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default App;

