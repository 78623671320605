import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button,notification } from 'antd';
import { SketchPicker } from 'react-color';
import { get, post } from '../../../utils';

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class ThemeBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            themeColor:{
                id: null,
                code:'THEME_HEADER',
                bgPrimary:'#ffffff',
                displayColorPickerBackgroundPrimary:false,
                bgSecondary: '#ffffff',
                displayColorPickerBackgroundSecondary:false,
                bgTertiary: '#ffffff',
                displayColorPickerBackgroundTertiary:false,

                fontPrimary:'#ffffff',
                displayColorPickerFontPrimary:false,
                fontSecondary: '#ffffff',
                displayColorPickerFontSecondary:false,
                fontTertiary: '#ffffff',
                displayColorPickerFontTertiary:false,

                iconColor: '#ffffff',
                displayColorPickerIconColor:false,
                iconBg: '#ffffff',
                displayColorPickerIconBg:false,
            },
        }
    }

    componentDidMount() {
        this.getThemeList()
    }

    getThemeList() {
        let url = '/api/crm/appearance/theme/administrator';
        get(url)
            .then(responses => {
                this.setEditData(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    setEditData(responses) {
        let themeColor = Object.assign({}, this.state.themeColor)
        let themeColorIndex = responses.findIndex(item => item.theme_code == 'THEME_HEADER')
        if (themeColorIndex != -1) {
            themeColor.id= responses[themeColorIndex].id
            themeColor.bgPrimary= responses[themeColorIndex].theme_bg_primary
            themeColor.bgSecondary= responses[themeColorIndex].theme_bg_secondary
            themeColor.bgTertiary= responses[themeColorIndex].theme_bg_tertiary

            themeColor.fontPrimary= responses[themeColorIndex].theme_font_primary
            themeColor.fontSecondary= responses[themeColorIndex].theme_font_secondary
            themeColor.fontTertiary= responses[themeColorIndex].theme_font_tertiary

            themeColor.iconColor= responses[themeColorIndex].icon_color
            themeColor.iconBg= responses[themeColorIndex].icon_bg
        }

        this.setState({
            themeColor: themeColor
        })

    }

    onChangeValueColor(value,variant,stateTheme){
        let tempTheme = Object.assign({},this.state[`${stateTheme}`])
        tempTheme[`${variant}`] = value
        this.setState({
            [`${stateTheme}`]: tempTheme
        })
    }

    getRoleEnabled() {
        const {loading} = this.state 
        const { roleDetail } = this.props
        if(loading){
            return false
        }

        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'theme')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }

        
        return false
    }

    onClickOk() {
        this.updateTheme()
    }
    onClickCancel() {
        this.getThemeList()
    }

    updateTheme() {
        const {themeColor,} = this.state
        this.setState({loading:true})

        let data = {
            dataTheme : JSON.stringify(themeColor)
        }
        

        let url = '/api/crm/appearance/theme/administrator';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({loading:false})
                // this.getThemeList()
                openNotificationWithIcon('success', 'System notification', 'Save Theme success.')
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Save Theme error.')
                this.setState({loading:false})
            })
    }

    render() {
        const { themeColor,loading } = this.state
        const styles = ({
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '1000',
                paddingLeft: '12px',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        });

        const ColorItem = ({ title, bgColor,fontColor , themeColor, stateTheme }) => (
            <Row style={{ paddingLeft:50 }}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <h2>{title}</h2>
                    </Col>
                </Row>
                <Row style={{ paddingLeft:50 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Col className='color-item-wrapper-theme' >
                            <p className='title'>{'Background color'}</p>
                            <div>
                                <div className='color' style={{ backgroundColor: bgColor,cursor:!this.getRoleEnabled()?'not-allowed':'pointer' }} onClick={()=>!this.getRoleEnabled() ? null : this.onChangeValueColor(true,`displayColorPickerBackground${title}`,stateTheme)} />
                                <p className='code'>{bgColor}</p>
                            </div>
                        </Col>
                        {themeColor[`displayColorPickerBackground${title}`] ? 
                            <div style={styles.popover}>
                                <div style={styles.cover} onClick={()=>this.onChangeValueColor(false,`displayColorPickerBackground${title}`,stateTheme)} />
                                <SketchPicker 
                                    color={bgColor} 
                                    onChange={(color)=>this.onChangeValueColor(color.hex, `bg${title}` ,stateTheme)} 
                                    // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                />
                            </div> 
                        : null}
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} offset={1}>
                        <Col className='color-item-wrapper-theme' >
                            <p className='title'>{'Font color'}</p>
                            <div>
                                <div className='color' style={{ backgroundColor: fontColor,cursor:!this.getRoleEnabled()?'not-allowed':'pointer' }} onClick={()=>this.onChangeValueColor(true,`displayColorPickerFont${title}`,stateTheme)} />
                                <p className='code'>{fontColor}</p>
                            </div>
                        </Col>
                        {themeColor[`displayColorPickerFont${title}`] ? 
                            <div style={styles.popover}>
                                <div style={styles.cover} onClick={()=>this.onChangeValueColor(false,`displayColorPickerFont${title}`,stateTheme)} />
                                <SketchPicker 
                                    color={fontColor} 
                                    onChange={(color)=>this.onChangeValueColor(color.hex, `font${title}`,stateTheme)} 
                                    // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                />
                            </div> 
                        : null}
                    </Col>
                </Row>
            </Row>
        )

        return (
            <Row gutter={24}>
                <Row className="header-card-swiper" >
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => loading ? null : this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <ColorItem title={'Primary'} bgColor={themeColor.bgPrimary} fontColor={themeColor.fontPrimary} themeColor={themeColor} stateTheme={`themeColor`}/>
                    {/* <ColorItem title={'Secondary'} bgColor={themeColor.bgSecondary} fontColor={themeColor.fontSecondary} themeColor={themeColor} stateTheme={`themeColor`}/>
                    <ColorItem title={'Tertiary'} bgColor={themeColor.bgTertiary} fontColor={themeColor.fontTertiary}themeColor={themeColor} stateTheme={`themeColor`}/> */}
                </Row>
            </Row>
            );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(ThemeBody);