import React from 'react';
import { connect } from 'react-redux';
import { Row, Card } from 'antd';
import { get } from '../../utils';
import config from '../../config.json'
import defaultSquareSrc from '../../resource/default/300x300-square.png'
import './style.scss'

const { Meta } = Card;

class ChatCategoryProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            image: [],
        }
    }

    getCategoryProductDetail() {
        const { categoryProductId, folderName } = this.props
        let url = '/api/administrator/crm/category/product/' + categoryProductId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }

                let imageCoverList = []
                if (response.image) {
                    imageCoverList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                        file: null,
                        name: response.image
                    }]
                }


                this.setState({ name: response.name, image: imageCoverList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getCategoryProductDetail()
    }

    render() {
        const { name, image } = this.state
        return (
            <div style={{ padding: '10px', backgroundColor: '#fff', borderRadius: '10px' }}>
                <Row>
                    {image.length ?
                        <img
                            width={'200px'}
                            src={image[0].view}
                            onError={(image) => image.target.src = defaultSquareSrc}
                        />
                        :
                        null}
                </Row>
                <Row style={{ textAlign: 'center', fontSize: '18px', marginTop: '10px' }}>{name}</Row>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    folderName: state.login.folderName
})

export default connect(mapStateToProps)(ChatCategoryProductCard); 