import React from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Select, Switch, Progress, Input, Button, Icon, notification } from 'antd';
import { get, post,put } from '../../../utils';
import { Link } from 'react-router-dom'
import './style.scss'

const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packageName: '',

            totalStorage: 0,
            usedStorage: 0,
            freeStorage: 0,

            totalCustomer: 0,
            usedCustomer: 0,
            freeCustomer: 0,

            totalAdministrator: 0,
            usedAdministrator: 0,
            freeAdministrator: 0,

            loginDefault: 'username',
            loginDefaultList: [
                { value: 'username', label: 'Username' },
                { value: 'phonenumber', label: 'Phone Number' },
            ],
            loginUsername: false,
            loginPhoneNumber: false,
            loginFacebook: false,
            loginGoogle: false,

            protocolList: [
                { value: 'http', label: 'HTTP' },
                { value: 'https', label: 'HTTPS' },
            ],

            mainCartList: [
                { value: 'product', label: 'Product' },
                { value: 'course', label: 'Course' },
            ],

            mainCart: 'product',
            enabledFollowProduct: true,
            enabledFollowTicket: true,
            enabledNotification: true,

            isConnectCodexLearn: false,
            codexlearnApiProtocol: 'http',
            codexlearnApiHostname: '',
            codexlearnApiPort: '',
            codexlearnChatType: 'course',

            customerTechnicianOrder: null,
            customerTechnicianOrderBy: null,

            emailAdmin: '',
            emailServiceOption: 'other',
            senderName: '',
            gmailClientID: '',
            gmailClientSecret: '',
            gmailRefreshToken: '',

            versionNumber: '1.0.0',
            isRequireUpdate: 0,
            isShowModalUpdate: 0,
            linkAndroid: '',
            linkIos: '',
        }
    }


    getPackageDetail() {
        let url = '/api/crm/administrator/setting/package';
        get(url)
            .then(responses => {
                this.setState({ packageName: responses.name })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getEmailService() {
        let url = '/api/crm/administrator/setting/emailservice';
        get(url)
            .then(response => {
                if (response) {
                    this.setState({
                        emailAdmin: response.email_admin,
                        emailService: response.email_service,
                        emailUsername: response.email_username,
                        emailPassword: response.email_password,

                        emailServiceOption: response.email_service_option,
                        senderName: response.sender_name,
                        gmailClientID: response.gmail_client_id,
                        gmailClientSecret: response.gmail_client_secret,
                        gmailRefreshToken: response.gmail_refresh_token,
                    })
                }

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getStorageDetail() {
        let url = '/api/crm/administrator/setting/storage';
        get(url)
            .then(responses => {
                this.setState({
                    totalStorage: responses.total.toFixed(2),
                    usedStorage: responses.used.toFixed(2),
                    freeStorage: responses.free.toFixed(2),
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    
    getCustomerDetail() {
        let url = '/api/crm/administrator/setting/customer';
        get(url)
            .then(responses => {
                this.setState({
                    totalCustomer: responses.total,
                    usedCustomer: responses.used,
                    freeCustomer: responses.free,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    getAdministratorDetail() {
        let url = '/api/crm/administrator/setting/administrator';
        get(url)
            .then(responses => {
                this.setState({
                    totalAdministrator: responses.total,
                    usedAdministrator: responses.used,
                    freeAdministrator: responses.free,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getSettingDetail() {
        let url = '/api/setting';
        get(url)
            .then(response => {
                this.setState({
                    loginDefault: response.login_default,
                    loginUsername: response.login_username,
                    loginPhoneNumber: response.login_phonenumber,
                    loginFacebook: response.login_facebook,
                    loginGoogle: response.login_google,

                    mainCart: response.main_cart,
                    enabledFollowProduct: response.enabled_follow_product,
                    enabledFollowTicket: response.enabled_follow_ticket,
                    enabledNotification: response.enabled_notification,

                    isConnectCodexLearn: response.is_connect_codexlearn,
                    codexlearnApiProtocol: response.codexlearn_api_protocol,
                    codexlearnApiHostname: response.codexlearn_api_hostname,
                    codexlearnApiPort: response.codexlearn_api_port,
                    codexlearnChatType: response.codexlearn_chat_type,

                    customerTechnicianOrder: response.customer_technician_order,
                    customerTechnicianOrderBy: response.customer_technician_order_by,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getVersionApp() {
        let url = '/api/crm/administrator/version';
        get(url)
            .then(responses => {
                if(responses.success && responses.data.length){
                    this.setState({
                        versionNumber: responses.data[0].version_number,
                        isRequireUpdate: responses.data[0].is_require,
                        isShowModalUpdate: responses.data[0].is_show,
                        linkAndroid: responses.data[0].link_android,
                        linkIos: responses.data[0].link_ios,
                    })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getPackageDetail()
        this.getStorageDetail()
        this.getCustomerDetail()
        this.getAdministratorDetail()
        this.getSettingDetail()
        this.getEmailService()
        this.getVersionApp()
    }



    updateSetting() {
        const {
            emailService, emailUsername, emailPassword,
            loginDefault, loginUsername, loginPhoneNumber, loginFacebook,loginGoogle,
            mainCart, enabledFollowProduct, enabledFollowTicket, enabledNotification,
            isConnectCodexLearn, codexlearnApiProtocol, codexlearnApiHostname, codexlearnApiPort,codexlearnChatType,
            customerTechnicianOrder, customerTechnicianOrderBy,
            emailServiceOption,gmailClientID,gmailClientSecret,gmailRefreshToken
        } = this.state

        if (emailServiceOption == 'other' && (emailService === '' || !emailService)) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Email service".')
            return
        }
        if (emailUsername === '' || !emailUsername) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Email account".')
            return
        }
        if (emailServiceOption == 'other' && (emailPassword === '' || !emailPassword)) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Email Password".')
            return
        }

        if (emailServiceOption == 'gmail' && (gmailClientID === '' || !gmailClientID)) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Gmail Client ID".')
            return
        }
        if (emailServiceOption == 'gmail' && (gmailClientSecret === '' || !gmailClientSecret)) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Gmail Client Secret".')
            return
        }
        if (emailServiceOption == 'gmail' && (gmailRefreshToken === '' || !gmailRefreshToken)) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Gmail Refresh Token".')
            return
        }

        if (loginDefault === 'phonenumber' && !loginPhoneNumber) {
            openNotificationWithIcon('warning', 'System notification', 'Please turn on "Login Phone Number".')
            return
        }
        if (loginDefault === 'username' && !loginUsername) {
            openNotificationWithIcon('warning', 'System notification', 'Please turn on "Login Username".')
            return
        }

        let data = {
            login_default: loginDefault,
            login_username: loginUsername,
            login_phonenumber: loginPhoneNumber,
            login_facebook: loginFacebook,
            login_google: loginGoogle,

            main_cart: mainCart,
            enabled_follow_product: enabledFollowProduct,
            enabled_follow_ticket: enabledFollowTicket,
            enabled_notification: enabledNotification,

            is_connect_codexlearn: isConnectCodexLearn,
            codexlearn_api_protocol: codexlearnApiProtocol,
            codexlearn_api_hostname: codexlearnApiHostname,
            codexlearn_api_port: codexlearnApiPort,
            codexlearn_chat_type: codexlearnChatType,

            customer_technician_order:customerTechnicianOrder,
            customer_technician_order_by:customerTechnicianOrderBy
        }

        let url = '/api/setting'
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.updateVersionApp()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    updateVersionApp() {
        const { administratorId } = this.props
        const { 
            versionNumber,
            isRequireUpdate, 
            isShowModalUpdate,
            linkAndroid,
            linkIos
        } = this.state

        let data = {
            version_number: versionNumber,
            is_require: isRequireUpdate, 
            is_show: isShowModalUpdate,
            adminId: administratorId,
            link_android: linkAndroid,
            link_ios: linkIos
        }

        let url = '/api/crm/administrator/version'
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.updateSettingEmailService()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    updateSettingEmailService() {
        const { 
            emailAdmin,
            emailService, emailUsername, emailPassword,
            emailServiceOption,gmailClientID,gmailClientSecret,gmailRefreshToken,senderName
        } = this.state

        let data = {
            email_admin: emailAdmin,
            email_service: emailService,
            sender_name: senderName,
            email_username: emailUsername,
            email_password: emailPassword,
            email_service_option: emailServiceOption,
            gmail_client_id: gmailClientID,
            gmail_client_secret: gmailClientSecret,
            gmail_refresh_token: gmailRefreshToken,
        }

        let url = '/api/crm/administrator/setting/emailservice'
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save setting success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateSetting()
    }
    onClickCancel() {
        this.getSettingDetail()
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'setting')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    render() {
        const {
            packageName,
            totalStorage, usedStorage, freeStorage,
            totalCustomer, usedCustomer, freeCustomer,
            totalAdministrator, usedAdministrator, freeAdministrator,
            emailServiceOption
        } = this.state

        return (
            <div className='setting-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card-setting" >

                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }}>
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{'Setting'}</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Form>
                        <p className="package-header">Package Name : <span>{`${packageName}`}</span></p>

                        <div className="progress-container">
                            <div className="progress-box" style={{ backgroundColor: '#cfeac6' }}>
                                <div className="progress-title">
                                    <h3>Storage</h3>
                                </div>
                                <div className="custom-progress">
                                    <p className="first-p">{`${usedStorage} GB`}</p>
                                    <Progress percent={usedStorage * 100 / totalStorage} strokeWidth={30} strokeColor="#218A05" showInfo={false} className="progress-bar" />
                                    <p className="last-p" style={{ color: '#cfeac6' }}>{`${totalStorage} GB`}</p>
                                </div>
                                <div className="progress-info">
                                    <div className="progress-info-item">
                                        <h3>{`${totalStorage} GB`}</h3>
                                        <p>Total Storage</p>
                                    </div>
                                    <div style={{ borderLeft: '2px solid #218A05', height: '35px' }}></div>
                                    <div className="progress-info-item">
                                        <h3>{`${usedStorage} GB`}</h3>
                                        <p>Used Storage</p>
                                    </div>
                                    <div style={{ borderLeft: '2px solid #218A05', height: '35px' }}></div>
                                    <div className="progress-info-item">
                                        <h3>{`${freeStorage} GB`}</h3>
                                        <p>Free Storage</p>
                                    </div>
                                </div>
                            </div>
                            <div className="progress-box" style={{ backgroundColor: '#ffeeb2' }}>
                                <div className="progress-title">
                                    <h3>Customer</h3>
                                </div>
                                <div className="custom-progress">
                                    <p className="first-p">{`${usedCustomer} user`}</p>
                                    <Progress percent={usedCustomer * 100 / totalCustomer} strokeWidth={30} strokeColor="#FFCE26" showInfo={false} className="progress-bar" />
                                    <p className="last-p" style={{ color: '#ffeeb2' }}>{`${totalCustomer} user`}</p>
                                </div>
                                <div className="progress-info">
                                    <div className="progress-info-item">
                                        <h3>{`${totalCustomer} User`}</h3>
                                        <p>Total User</p>
                                    </div>
                                    <div style={{ borderLeft: '2px solid #FFCE26', height: '35px' }}></div>
                                    <div className="progress-info-item">
                                        <h3>{`${usedCustomer} User`}</h3>
                                        <p>Used User</p>
                                    </div>
                                    <div style={{ borderLeft: '2px solid #FFCE26', height: '35px' }}></div>
                                    <div className="progress-info-item">
                                        <h3>{`${freeCustomer} User`}</h3>
                                        <p>Free User</p>
                                    </div>
                                </div>
                            </div>
                            <div className="progress-box" style={{ backgroundColor: '#ccf4ff' }}>
                                <div className="progress-title">
                                    <h3>Staff</h3>
                                </div>
                                <div className="custom-progress">
                                    <p className="first-p">{`${usedAdministrator} User`}</p>
                                    <Progress percent={usedAdministrator * 100 / totalAdministrator} strokeWidth={30} strokeColor="#47C5EB" showInfo={false} className="progress-bar" />
                                    <p className="last-p" style={{ color: '#ccf4ff' }}>{`${totalAdministrator} User`}</p>
                                </div>
                                <div className="progress-info">
                                    <div className="progress-info-item">
                                        <h3>{`${totalAdministrator} User`}</h3>
                                        <p>Total Staff</p>
                                    </div>
                                    <div style={{ borderLeft: '2px solid #47C5EB', height: '35px' }}></div>
                                    <div className="progress-info-item">
                                        <h3>{`${usedAdministrator} User`}</h3>
                                        <p>Used Staff</p>
                                    </div>
                                    <div style={{ borderLeft: '2px solid #47C5EB', height: '35px' }}></div>
                                    <div className="progress-info-item">
                                        <h3>{`${freeAdministrator} User`}</h3>
                                        <p>Free Staff</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* section 2 */}
                        <p className="package-header">Setting : Email service</p>
                        <div className="setting-container" >
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Email Admin</p>
                                <Input
                                    style={{ width: '100%' }}
                                    value={this.state.emailAdmin}
                                    onChange={(event) => this.setState({ emailAdmin: event.target.value })}
                                />
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Email service</p>
                                <Select
                                    value={this.state.emailServiceOption}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ emailServiceOption: value })}
                                >
                                    <Option key={'other'} value={'other'}>{'Other'}</Option>
                                    <Option key={'gmail'} value={'gmail'}>{'Gmail'}</Option>
                                </Select>
                            </div>
                            {
                                emailServiceOption == 'other' && (
                                    <div className="setting-item">
                                        <p style={{ width: '60%', alignSelf: 'center' }}>Email service</p>
                                        <Input
                                            style={{ width: '100%' }}
                                            value={this.state.emailService}
                                            onChange={(event) => this.setState({ emailService: event.target.value })}
                                        />
                                    </div>
                                )
                            }
                            
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Email account</p>
                                <Input
                                    style={{ width: '100%' }}
                                    value={this.state.emailUsername}
                                    onChange={(event) => this.setState({ emailUsername: event.target.value })}
                                />
                            </div>
                            {
                                emailServiceOption == 'gmail' && (
                                    <div className="setting-item">
                                        <p style={{ width: '60%', alignSelf: 'center' }}>Sender Name</p>
                                        <Input
                                            style={{ width: '100%' }}
                                            value={this.state.senderName}
                                            onChange={(event) => this.setState({ senderName: event.target.value })}
                                        />
                                    </div>
                                )
                            }
                            {
                                emailServiceOption == 'other' && (
                                    <div className="setting-item">
                                        <p style={{ width: '60%', alignSelf: 'center' }}>Email password</p>
                                        <Input
                                            style={{ width: '100%' }}
                                            type={'password'}
                                            value={this.state.emailPassword}
                                            onChange={(event) => this.setState({ emailPassword: event.target.value })}
                                        />
                                    </div>
                                )
                            }

                            {
                                emailServiceOption == 'gmail' && (
                                    <div className="setting-item">
                                        <p style={{ width: '60%', alignSelf: 'center' }}>Gmail Client ID</p>
                                        <Input
                                            style={{ width: '100%' }}
                                            value={this.state.gmailClientID}
                                            onChange={(event) => this.setState({ gmailClientID: event.target.value })}
                                        />
                                    </div>
                                )
                            }
                            {
                                emailServiceOption == 'gmail' && (
                                    <div className="setting-item">
                                        <p style={{ width: '60%', alignSelf: 'center' }}>Gmail Client Secret</p>
                                        <Input
                                            style={{ width: '100%' }}
                                            value={this.state.gmailClientSecret}
                                            onChange={(event) => this.setState({ gmailClientSecret: event.target.value })}
                                        />
                                    </div>
                                )
                            }
                            {
                                emailServiceOption == 'gmail' && (
                                    <div className="setting-item">
                                        <p style={{ width: '60%', alignSelf: 'center' }}>Gmail Refresh Token</p>
                                        <Input
                                            style={{ width: '100%' }}
                                            value={this.state.gmailRefreshToken}
                                            onChange={(event) => this.setState({ gmailRefreshToken: event.target.value })}
                                        />
                                    </div>
                                )
                            }
                            
                        </ div>

                        {/* section 3 */}
                        <p className="package-header">Setting : login</p>
                        <div className="setting-container" >
                            <div className="setting-item">
                                <p style={{ width: '100%', alignSelf: 'center' }}>Login Default</p>
                                <Select
                                    value={this.state.loginDefault}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ loginDefault: value })}
                                >
                                    {this.state.loginDefaultList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                </Select>
                            </div>
                            <div className="setting-item">
                                <p>Login Username</p>
                                <Switch checked={this.state.loginUsername} onChange={(checked) => this.setState({ loginUsername: checked })} />
                            </div>
                            <div className="setting-item">
                                <p>Login Phone Number</p>
                                <Switch checked={this.state.loginPhoneNumber} onChange={(checked) => this.setState({ loginPhoneNumber: checked })} />
                            </div>
                            <div className="setting-item">
                                <p>Login Facebook</p>
                                <Switch checked={this.state.loginFacebook} onChange={(checked) => this.setState({ loginFacebook: checked })} />
                            </div>
                            <div className="setting-item">
                                <p>Login Google</p>
                                <Switch checked={this.state.loginGoogle} onChange={(checked) => this.setState({ loginGoogle: checked })} />
                            </div>
                            <div className="setting-item">
                                
                            </div>
                        </ div>
                        {/* section 4 */}
                        <p className="package-header">Setting : Function</p>
                        <div className="setting-container" >
                            <div className="setting-item">
                                <p style={{ width: '100%', alignSelf: 'center' }}>Main Cart</p>
                                <Select
                                    value={this.state.mainCart}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ mainCart: value })}
                                    disabled={!this.state.isConnectCodexLearn}
                                >
                                    {this.state.mainCartList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                </Select>
                            </div>
                            <div className="setting-item">
                                <p>Follow Product</p>
                                <Switch checked={this.state.enabledFollowProduct} onChange={(checked) => this.setState({ enabledFollowProduct: checked })} />
                            </div>
                            <div className="setting-item">
                                <p>Follow Ticket</p>
                                <Switch checked={this.state.enabledFollowTicket} onChange={(checked) => this.setState({ enabledFollowTicket: checked })} />
                            </div>
                            <div className="setting-item">
                                <p>Notification</p>
                                <Switch checked={this.state.enabledNotification} onChange={(checked) => this.setState({ enabledNotification: checked })} />
                            </div>
                        </ div>

                        {/* section 5 */}
                        <p className="package-header">Setting : CodexLearn</p>
                        <div className="setting-container" >
                            <div className="setting-item">
                                <p>Connect CodexLearn</p>
                                <Switch checked={this.state.isConnectCodexLearn} onChange={(checked) => this.setState({ isConnectCodexLearn: checked })} />
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '100%', alignSelf: 'center' }}>Protocol</p>
                                <Select
                                    value={this.state.codexlearnApiProtocol}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ codexlearnApiProtocol: value })}
                                    disabled={!this.state.isConnectCodexLearn}
                                >
                                    {this.state.protocolList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                </Select>
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Hostname</p>
                                <Input
                                    style={{ width: '100%' }}
                                    value={this.state.codexlearnApiHostname}
                                    onChange={(event) => this.setState({ codexlearnApiHostname: event.target.value })}
                                    disabled={!this.state.isConnectCodexLearn}
                                />
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Port</p>
                                <Input
                                    style={{ width: '100%' }}
                                    value={this.state.codexlearnApiPort}
                                    onChange={(event) => this.setState({ codexlearnApiPort: event.target.value })}
                                    disabled={!this.state.isConnectCodexLearn}
                                />
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '100%', alignSelf: 'center' }}>Chat Lecute Type</p>
                                <Select
                                    value={this.state.codexlearnChatType}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ codexlearnChatType: value })}
                                    disabled={!this.state.isConnectCodexLearn}
                                >
                                    <Option key={'course'} value={'course'}>{'Course'}</Option>
                                    <Option key={'lecture'} value={'lecture'}>{'Lecture'}</Option>
                                </Select>
                            </div>
                            <div className="setting-item">
                                {/* <p style={{ width: '100%', alignSelf: 'center' }}>Chat Lecute Type</p>
                                <Select
                                    value={this.state.codexlearnChatType}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ codexlearnChatType: value })}
                                    disabled={!this.state.isConnectCodexLearn}
                                >
                                    <Option key={'course'} value={'course'}>{'Course'}</Option>
                                    <Option key={'lecture'} value={'lecture'}>{'Lecture'}</Option>
                                </Select> */}
                            </div>
                        </ div>

                        {/* section 6 */}
                        <p className="package-header">Setting : Technician</p>
                        <div className="setting-container" >
                            <div className="setting-item">
                                <p style={{ width: '100%', alignSelf: 'center' }}>Customer Technician Order</p>
                                <Select
                                    value={this.state.customerTechnicianOrder}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ customerTechnicianOrder: value })}
                                    // disabled={!this.state.isConnectCodexLearn}
                                >
                                    <Option key={'Rate'} value={'rate'}>{'Rate'}</Option>
                                    <Option key={'Recent'} value={'recent'}>{'Recent'}</Option>
                                </Select>
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '100%', alignSelf: 'center' }}>Customer Technician Order By</p>
                                <Select
                                    value={this.state.customerTechnicianOrderBy}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ customerTechnicianOrderBy: value })}
                                    // disabled={!this.state.isConnectCodexLearn}
                                >
                                    <Option key={'ASC'} value={'ASC'}>{'ASC'}</Option>
                                    <Option key={'DESC'} value={'DESC'}>{'DESC'}</Option>
                                </Select>
                            </div>
                        </ div>

                        {/* section 7 */}
                        <p className="package-header">Setting : Version app</p>
                        <div className="setting-container" >
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Version Number</p>
                                <Input
                                    style={{ width: '100%' }}
                                    value={this.state.versionNumber}
                                    onChange={(event) => this.setState({ versionNumber: event.target.value })}
                                />
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Require update version</p>
                                <Select
                                    value={this.state.isRequireUpdate}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ isRequireUpdate: value })}
                                >
                                    <Option key={'yes'} value={1}>{'Yes'}</Option>
                                    <Option key={'no'} value={0}>{'No'}</Option>
                                </Select>
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Show update version</p>
                                <Select
                                    value={this.state.isShowModalUpdate}
                                    style={{ minWidth: 200 }}
                                    onChange={(value) => this.setState({ isShowModalUpdate: value })}
                                >
                                    <Option key={'yes'} value={1}>{'Yes'}</Option>
                                    <Option key={'no'} value={0}>{'No'}</Option>
                                </Select>
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Link Android</p>
                                <Input
                                    style={{ width: '100%' }}
                                    value={this.state.linkAndroid}
                                    onChange={(event) => this.setState({ linkAndroid: event.target.value })}
                                />
                            </div>
                            <div className="setting-item">
                                <p style={{ width: '60%', alignSelf: 'center' }}>Link IOS</p>
                                <Input
                                    style={{ width: '100%' }}
                                    value={this.state.linkIos}
                                    onChange={(event) => this.setState({ linkIos: event.target.value })}
                                />
                            </div>
                        </ div>
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    folderName: state.login.folderName,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(Setting);

