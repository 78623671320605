import React from 'react';
import { Row, Col, Button, Icon, notification, Checkbox } from 'antd';
import { get, post, patch, ppost } from '../../../utils'; 
import { connect } from 'react-redux';

import HomeVIPBannerModal from './component/HomeVIPBannerModal'

import homeLayoutMenuOne from '../../../resource/screen-mobile/home-layout-menu/home-layout-menu-one.png'

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class HomeVIPBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            vipBanner: 1,
            homeLayoutList: [
                { value: 1, name: 'Home layout menu one', image: homeLayoutMenuOne, size: 3 },
            ],

            settingLayoutList: [],
        }
    }


    getSettingVIPBannerImage() {
        const { folderName } = this.props
        let url = '/api/setting/banner/vip';
        get(url)
            .then(responses => {
                let settingLayoutList = responses.map(item => {
                    let temp = Object.assign({}, item)
                    temp['imageList'] = [{
                        name: item.image,
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.image}`,
                        file: null
                    }]
                    return temp
                })
                this.setState({ settingLayoutList: settingLayoutList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    
    getSettingScreenMobile() {
        let url = '/api/crm/administrator/setting/screen/mobile';
        get(url)
            .then(response => {
                this.setState({
                    settingScreenMobileId: response.id,
                    vipBanner: response.home_vip_banner
                })
                this.getSettingVIPBannerImage()
            })
            .catch(err => {
                console.log(err.message)
            })
    }
 


    componentDidMount() { 
        this.getSettingScreenMobile()
    }

    updateVIPBanner() {
        const { settingScreenMobileId, vipBanner } = this.state
        let url = '/api/crm/administrator/setting/screen/mobile/' + settingScreenMobileId + '/vip/banner';
        let data = { vip_banner: vipBanner }
        patch(url, data)
            .then(response => {
                this.updateVIPBannerImage()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async updateVIPBannerImage() {
        let settingLayoutList = await Promise.all(this.state.settingLayoutList.map(async (item) => {
            let temp = {
                layout_number: item.layout_number,
                image: item.image,
            }
            let uploadFileList = item.imageList.filter(item => item.file)
            if (uploadFileList.length) {
                let formData = new FormData();
                uploadFileList.map((item, index) => formData.append("image" + index, item.file))
                let fileNameList = await this.uploadImageToServer(formData)
                temp.image = fileNameList[0]
            }
            return temp
        }))
        let data = {
            settingLayoutList: JSON.stringify(settingLayoutList)
        }
        let url = '/api/setting/banner/vip'
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save setting screen mobile home swipe success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    updateLayout(item) {
        let settingLayoutList = this.state.settingLayoutList.map(item => Object.assign({}, item)).slice(0, item.size)
        for (let index = settingLayoutList.length; index < item.size; index++) {
            settingLayoutList.push({
                layout_number: index,
                image: null,
                imageList: []
            })
        }
        this.setState({
            vipBanner: item.value,
            settingLayoutList: settingLayoutList,
            visible: true,
        })
    }

    onClickOk() {
        this.updateVIPBanner()
    }
    onClickCancel() {
        this.getSettingVIPBannerImage()
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'mobile')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    render() {
        return (
            <div >
                <Row >
                    <Col span={12} style={{ textAlign: 'left' }}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <div className="mobile-screen-content-wrapper">
                    {this.state.homeLayoutList.map(item => {
                        return (
                            <div className="item-mobile-screen-wrapper">
                                <Checkbox
                                    className='item-mobile-screen-checkbox-wrapper'
                                    checked={item.value == this.state.vipBanner}
                                    onChange={() => this.updateLayout(item)}
                                />
                                <div
                                    className='item-mobile-screen-image-wrapper'
                                    onClick={() => this.updateLayout(item)}
                                >
                                    <img
                                        src={item.image}
                                        className='item-mobile-screen-image'
                                        style={{ border: item.value == this.state.vipBanner ? '4px solid #48c5eb' : 'none' }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <HomeVIPBannerModal
                    visible={this.state.visible}
                    settingLayoutList={this.state.settingLayoutList}
                    updateSettingLayoutList={(value) => this.setState({ settingLayoutList: value })}
                    onClose={() => this.setState({ visible: false })}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
    roleDetail: state.role.roleDetail,
    folderName: state.login.folderName,
    mainCustomerId: state.login.mainCustomerId,
    }
}

export default connect(mapStateToProps)(HomeVIPBanner);
