import React,{useEffect,useState} from 'react'
import {connect} from 'react-redux'
import { Table,Row,Button,Icon,Form,Input,Modal,Col,Rate,notification } from 'antd';
import { get, del, post } from '../../../utils';
import ModalReply from './ModalReply';

const FormItem = Form.Item;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

function TableReview({editId,administratorId}) {
    const [rate, setRate] = useState(0)
    const [reviewList, setReviewList] = useState([])
    const [selectDelete, setSelectDelete] = useState([])
    const [visibleCreateComment, setVisibleCreateComment] = useState(false)
    const [commentId, setCommentId] = useState(null)
    const [visibleReply, setVisibleVisibleReply] = useState(false)

    const columns = [
        {
            title: (
                <div className='title-table-comment'>
                    {'Rating'}
                </div>
            ),
            dataIndex: 'rate',
            key: 'rate',
            render: (text, item, index) => {
                return (
                    <div>
                        {text}
                    </div>
                )
            }
        },
        {
            title: (
                <div className='title-table-comment'>
                    {'Name Customer'}
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (text, item, index) => {
                return (
                    <div>
                        {text}
                    </div>
                )
            }
        },
        {
            title: (
                <div className='title-table-comment'>
                    {'Comment'}
                </div>
            ),
            dataIndex: 'comment',
            key: 'comment',
            render: (text, item, index) => {
                return (
                    <div>
                        {text}
                    </div>
                )
            }
        },
        {
            title: (
                <div className='title-table-comment'>
                    {'Reply'}
                </div>
            ),
            dataIndex: 'reply',
            key: 'reply',
            render: (text, item, index) => {
                return (
                    <div style={{ textAlign:'center' }}>
                        <Button onClick={()=> openModalReply(item)}>
                            View
                        </Button>
                    </div>
                )
            }
        },
    ]
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            let id = selectedRows.map( item =>item.id)
            setSelectDelete(id)
        },
        getCheckboxProps: record => ({
            checked: selectDelete.includes(record.id)
        }),
    };

    useEffect(()=>{
        getCustomerReviewList()
    },[])

    const getCustomerReviewList = () => {
        let url = '/api/crm/administrator/comment/technician/' + editId;
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(responses.success){
                    setRate(responses.data.rate)
                    setReviewList(responses.data.commentList)
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const OnDelete = () => {
        let url = '/api/crm/administrator/comment';
        let data = {
            technicianId: editId,
            selectDelete: JSON.stringify(selectDelete)
        }
        del(url,data)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(responses.success){
                    getCustomerReviewList()
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const openModalReply = (item) => {
        setCommentId(item.id)
        setVisibleVisibleReply(true)
    }

    return (
        <div className='review-container'>
            <Row>
                {`Rating: ${rate}`}
                <Icon type="star" theme="filled" style={{color:'yellow'}} />
            </Row>
            <Row style={{ textAlign:'right' }}>
                <Button 
                    className='create-button' 
                    onClick={() => setVisibleCreateComment(true)} 
                >
                        <Icon type="plus" />
                        {'Create'}
                </Button>
                <Button type="danger"
                    onClick={() => selectDelete.length > 0 ? OnDelete() : null}
                    disabled={!selectDelete.length}
                >
                    <Icon style={{ fontSize: 18 }} type="delete" />
                </Button>
            </Row>
            <Table 
                rowSelection={rowSelection}
                dataSource={reviewList} 
                columns={columns}
            />
            <ModalCreateComment 
                visibleCreateComment={visibleCreateComment}
                setVisibleCreateComment={(value)=> setVisibleCreateComment(value)}
                getCustomerReviewList={()=> getCustomerReviewList()}
                technicianId={editId}
                administratorId={administratorId}
            />
            <ModalReply
                commentId={commentId}
                visibleReply={visibleReply}
                onCloseReply={()=> setVisibleVisibleReply(false) & setCommentId(null)}
                technicianId={editId}
                administratorId={administratorId}
            />
        </div>
    )
}


function ModalCreateComment({visibleCreateComment,setVisibleCreateComment,getCustomerReviewList,technicianId,administratorId}){
    // const {visibleCreateComment,visibleCreateReply} = this.state
    const [rate,setRate] = useState(0)
    const [comment, setComment] = useState('')

    const formItemLayout = {
        // labelCol: {
        //     xs: { span: 8 },
        //     sm: { span: 4 },
        // },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    const SaveComment=()=>{
        if (!comment) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Comment".')
            return 
        }
        if (!administratorId) {
            openNotificationWithIcon('warning', 'System notification', 'Not found admin id".')
        }
        if (!technicianId) {
            openNotificationWithIcon('warning', 'System notification', 'Not found technician Id".')
            return 
        }

        let url = '/api/crm/administrator/comment'
        let data = {
            rate,
            comment,
            administratorId,
            technicianId
        }
        return post(url,data)
            .then(responses=>{
                if(responses.success){
                    setRate(0)
                    setComment('')
                    getCustomerReviewList()
                    setVisibleCreateComment(false)
                    return
                }else{
                    openNotificationWithIcon('warning', 'System notification', 'Please try again.')
                    return 
                }
                
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('warning', 'System notification', 'Please try again.')
                return 
            })
    }

    const onClose =()=>{
        setRate(0)
        setComment('')
        setVisibleCreateComment(false)
    }

    return(
        <Modal
            title={'Comment'}
            visible={visibleCreateComment}
            onOk={() => SaveComment()}
            onCancel={() => onClose()}
        >
            <Form>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <FormItem {...formItemLayout} label={'Rating'} required={true}>
                            <Rate value={rate} onChange={(value)=>setRate(value)} />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <FormItem {...formItemLayout} label={'Comment'} required={true}>
                            <Input
                                value={comment}
                                onChange={(event) => setComment(event.target.value)}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
})

export default connect(mapStateToProps)(TableReview);