import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Menu, Icon } from 'antd';
import { get, post } from '../../utils';
import { logout, signoutMainCustomerSuccess } from '../../actions/login';
import { setActiveMenu, setActiveSubMenu } from '../../actions/leftmenu';
import codexConnect from '../../resource/Logo/logoCodexConnect.png'
import defaultSquareSrc from '../../resource/default/300x300-square.png'

import './style.scss'
import { menuList } from '../../routes'
import config from '../../config.json'
import home from '../../resource/Icon/Home.png'

const { SubMenu } = Menu;

class LeftMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            change_company: true
        }
    }

    componentDidMount() {
        this.getCompanyList()
    }

    getRoleView(roleKey) {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === roleKey)
            if (index != -1) {
                return roleDetail[index].view
            }
            return false
        }
        return false
    }

    getCompanyList() {
        let url = '/api/crm/administrator/maincustomer/joined'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (responses.length == 1) {
                    this.setState({ change_company: false })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    signoutMainCustomer() {
        const { administratorId } = this.props
        let url = '/api/user/signout/maincustomer/administrator'
        let data = { user_administrator_id: administratorId }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.signoutMainCustomerSuccess(response.user)
            })
            .catch(error => {
                console.log(error)
            })
    }

    onClickItemMenu(id, rolekey) {
        this.props.setActiveMenu(id)
        this.props.setActiveSubMenu(null)
        this.setState({
            menuKey: rolekey
        })
    }

    onClickItemSubMenu(id) {
        this.props.setActiveSubMenu(id)
    }

    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    render() {
        const {
            activeMenu, activeSubMenu,
            packageDatail, name, roleName, folderName, avatar, isConnectCodexLearn
        } = this.props
        let tempMenuList = []
        if (packageDatail) {
            tempMenuList = menuList.filter(item => {
                if (!packageDatail.show_chat_system && item.name == 'Chat') {
                    return false
                }
                if (!packageDatail.show_notification_system && item.name == 'Notification') {
                    return false
                }
                return true
            })
        }
        tempMenuList.map(item => item.subMenuList = item.subMenuList.filter(r => !(r.isConnectCodexLearn && [0, '0'].includes(isConnectCodexLearn))))
        return (
            <div className='leftmenu-page-wrapper'>
                <div className='logo'>
                    <img width={150} src={codexConnect} />
                </div>
                <div className='profile-wrapper'>
                    <div className='avatar'>
                        <img
                            width={70} height={70}
                            style={{ borderRadius: '50%' }} src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${avatar}`}
                            onError={(image) => image.target.src = defaultSquareSrc}
                        />
                    </div>
                    <p style={{ margin: '5px 0 0', color: '#7b7b7b', fontWeight: '500' }}>{name}</p>
                    <p style={{ margin: '0', color: '#000', fontWeight: '500', fontSize: '12px' }}>{roleName}</p>
                </div>

                <div className="new-menu">
                    <Menu
                        mode="inline"
                        selectedKeys={[activeMenu]}
                        onClick={(event) => this.props.setActiveMenu(event.key)}
                    >
                        <Menu.Item key={'home'} >
                            <Link to={'/'}>
                                <div>
                                    <p>
                                        <img src={home} style={{ width: '17px', marginRight: '10px' }} />
                                        {'Home'}
                                    </p>
                                </div>
                            </Link>
                        </Menu.Item>

                        {tempMenuList.map((item, index) => {
                            let showSubMenuList = item.subMenuList.filter(item => this.getRoleView(item.roleKey)).filter((item)=>{
                                if(this.props.mainCustomerId !=5){
                                    return !item.isTechnicianApp
                                }
                                return true
                            })
                            // .filter((item)=>this.props.mainCustomerId !=5 && !item.isTechnicianApp)
                            if (!showSubMenuList.length) {
                                return null
                            }
                            if (item.name == 'Broad cast') {
                                showSubMenuList = []
                            }
                            return (
                                <SubMenu
                                    key={item.name}
                                    title={
                                        item.name == 'Broad cast' ?
                                            <Link to={'/notification_manual_send'} >
                                                <div className='broadcast-memu' onClick={() => item.name == 'Broad cast' ? this.props.setActiveSubMenu('Manual Send') && this.props.setActiveMenu('Broad cast') : null} >
                                                    <p style={{ color: activeSubMenu == 'Manual Send' ? '#1890ff' : '#000000A6' }}>
                                                        <img src={item.icon} style={{ width: '17px', marginRight: '10px' }} />
                                                        {item.name}
                                                    </p>
                                                </div>
                                            </Link>
                                            :
                                            <div>
                                                <p>
                                                    <img src={item.icon} style={{ width: '17px', marginRight: '10px' }} />
                                                    {item.name}
                                                </p>
                                            </div>
                                    }
                                >
                                    {
                                        showSubMenuList.map((subItem, index) => {
                                            return (
                                                <Menu.Item
                                                    key={subItem.name}
                                                    onClick={(event) => this.props.setActiveSubMenu(event.key)}
                                                >
                                                    <Link to={subItem.path} >
                                                        <div >
                                                            <p >
                                                                <img src={subItem.icon} style={{ width: '17px', marginRight: '10px' }} />
                                                                {subItem.name}
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </SubMenu>
                            )
                        })}

                    </Menu>
                    <Menu className='left-menu-bottom'>
                        {this.state.change_company ?
                            <Menu.Item key={'home'}>
                                <Link to={'/'} onClick={() => this.signoutMainCustomer()}>
                                    <div>
                                        <p>
                                            <Icon type="swap" style={{ width: '17px', marginRight: '10px', color: '#d0cccc' }} />

                                            {'Change Company'}
                                        </p>
                                    </div>
                                </Link>
                            </Menu.Item>
                            : null}
                        <Menu.Item key={'home'} >
                            <Link to={'/'} onClick={() => this.props.logout()}>
                                <div>
                                    <p>
                                        <Icon type="logout" style={{ width: '17px', marginRight: '10px', color: '#d0cccc' }} />
                                        {'logout'}
                                    </p>
                                </div>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </div>

            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    name: state.login.name,
    avatar: state.login.avatar,
    packageDatail: state.package.packageDatail,
    roleName: state.login.roleName,
    folderName: state.login.folderName,
    roleDetail: state.role.roleDetail,
    activeMenu: state.leftmenu.activeMenu,
    activeSubMenu: state.leftmenu.activeSubMenu,
    isConnectCodexLearn: state.login.is_connect_codexlearn,
    mainCustomerId: state.login.mainCustomerId,
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    signoutMainCustomerSuccess: (payload) => dispatch(signoutMainCustomerSuccess(payload)),
    setActiveMenu: (payload) => dispatch(setActiveMenu(payload)),
    setActiveSubMenu: (payload) => dispatch(setActiveSubMenu(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);

