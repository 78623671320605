// Login
export const INITIALIZE_LOGIN = 'INITIALIZE_LOGIN'
export const SIGNIN_MAIN_CUSTOMER_SUCCESS = 'SIGNIN_MAIN_CUSTOMER_SUCCESS'
export const SIGNOUT_MAIN_CUSTOMER_SUCCESS = 'SIGNOUT_MAIN_CUSTOMER_SUCCESS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
// export const LOGIN_FAILURE = 'LOGIN_FAILURE'

//Noti
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE'

// package
export const INITIALIZE_PACKAGE = 'INITIALIZE_PACKAGE'

//role
export const INITIALIZE_ROLE = 'INITIALIZE_ROLE'


//modal
export const SHOW_MODAL_EXPIRED = 'SHOW_MODAL_EXPIRED'
export const HIDE_MODAL_EXPIRED = 'HIDE_MODAL_EXPIRED'

// forgotPassword
export const SET_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD = 'SET_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD'
export const CLEAR_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD = 'CLEAR_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD'

//left menu
export const CLEAR_ACTIVE_LEFT_MENU = 'CLEAR_ACTIVE_LEFT_MENU'
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'
export const SET_ACTIVE_SUBMENU = 'SET_ACTIVE_SUBMENU'