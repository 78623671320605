import React from 'react';
import { Row, Col, Button, notification, Checkbox } from 'antd';
import { get, patch } from '../../../utils'; 
import { connect } from 'react-redux';
import homeSwipeOne from '../../../resource/screen-mobile/home-swipe/home-swipe-one.png'
import homeSwipeTwo from '../../../resource/screen-mobile/home-swipe/home-swipe-two.png'


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class HomeVideoSwiper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingScreenMobileId: null,
            videoHomeSwiper: 1,
            homeSwipeList: [
                { value: 1, name: 'Home Video Swipe One', image: homeSwipeOne },
                { value: 2, name: 'Home Video Swipe Two', image: homeSwipeTwo },
            ],
        }
    }

    getSettingScreenMobile() {
        let url = '/api/crm/administrator/setting/screen/mobile';
        get(url)
            .then(response => {
                this.setState({
                    settingScreenMobileId: response.id,
                    videoHomeSwiper: response.video_home_swiper
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getSettingScreenMobile()
    }


    updateSettingScreenMobile() {
        const { settingScreenMobileId, videoHomeSwiper } = this.state
        let url = '/api/crm/administrator/setting/screen/mobile/' + settingScreenMobileId + '/home/video';
        let data = { video_home_swiper: videoHomeSwiper }
        patch(url, data)
            .then(response => {
                openNotificationWithIcon('success', 'System notification', 'Save setting screen mobile home video swipe success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateSettingScreenMobile()
    }
    onClickCancel() {
        this.getSettingScreenMobile()
    }


    updateHomeSwiper(value) {
        this.setState({ videoHomeSwiper: value })
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'mobile')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    render() {
        const { homeSwipeList } = this.state
        return (
            <div >
                <Row >
                    <Col span={12} style={{ textAlign: 'left' }}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <div className="mobile-screen-content-wrapper">
                    {homeSwipeList.map(item => {
                        return (
                            <div className="item-mobile-screen-wrapper">
                                <Checkbox
                                    className='item-mobile-screen-checkbox-wrapper'
                                    checked={item.value == this.state.videoHomeSwiper}
                                    onChange={() => this.updateHomeSwiper(item.value)}
                                />
                                <div
                                    className='item-mobile-screen-image-wrapper'
                                    onClick={() => this.updateHomeSwiper(item.value)}
                                >
                                    <img
                                        src={item.image}
                                        className='item-mobile-screen-image'
                                        style={{ border: item.value == this.state.videoHomeSwiper ? '4px solid #48c5eb' : 'none' }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(HomeVideoSwiper);
