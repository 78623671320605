import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, List, Card, Progress,Icon,Select } from 'antd';
import CustomCircularProgressbar from '../../components/CustomCircularProgressbar';
import { post, get } from '../../utils';
import moment from 'moment';

const { Option } = Select;
class Statisticstag extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            guests:[],
            userRegister:[],
            allTicket:[],
            TicketWaitAdmin:[],
            Ticketfollowproduct:[],

        }

    }

    componentDidMount() {
        // this.setState({tagList:this.props.detailstatic})
        // this.getContentList(pathname)

    }

    render() {
        const {tempFilter,datastatic,detailstatic} = this.props 
        return (
            <div>
                <Row
                    type="flex" align="end"
                    style={{ border: 'solid 0px #d6d6d645', padding: '10px' }}
                >
                    <div className='filter'>
                                <Select value={tempFilter} style={{ width: 120 }} onChange={(value) => this.props.setdate(value)}>
                                    <Option value={'day'}>{'Today'}</Option>
                                    <Option value={'week'}>{'7 Day ago'}</Option>
                                    <Option value={'month'}>{'1 Month ago'}</Option>
                                    <Option value={'year'}>{'1 Year ago'}</Option>
                                </Select>
                    </div>
                </Row>
                <div style={{ backgroundColor:'#f8f8f8',height:'75vh',overflow:'auto' }}>
                    <div style={{ padding: '10px', backgroundColor: '#F8F8F8' }} >
                        <CustomCircularProgressbar
                            title={'Number Of Tag'}
                            progressType={'increase'}
                            percentage={datastatic.percen}
                            allValue={datastatic.alltag}
                            changeValue={datastatic.alltagBefore}
                            allLabel={'All Chat'}
                            changeLabel={'New Chat'}
                            primaryColor={'#53B7B7'}
                            secondaryColor={'#CFE8E8'}
                            positionDetail={'bottom'}
                            borderWrapper={'unset'}
                        />
                    </div>
                    <div style={{ padding: '10px', backgroundColor: '#F8F8F8'}} >
                        {detailstatic.map(tag => {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <p style={{ minWidth: 100 }}>{tag.name}</p>
                                    <Progress percent={datastatic.alltag > 0 ? ((tag.percent/datastatic.alltag)*100).toFixed(0) : 0 } strokeWidth={15} strokeColor={'#53B7B7'} trailColor={'#ffffff'} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )

    }
}


export default Statisticstag;