import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Icon,notification,Menu } from 'antd';
import './style.scss'
import { Link } from 'react-router-dom'

// Theme Color 
import ThemeColor from './ThemeColor';
// Theme body 
import ThemeBody from './ThemeBody';
// Theme Color 
import ThemeHeader from './ThemeHeader';

import ThemeFooter from './ThemeFooter';

// comment for future
// import ThemeButton from './ThemeButton';
// import ThemeButtonProductHeader from './ThemeButtonProductHeader';
// import ThemeButtonProductCard from './ThemeButtonProductCard';

// import ThemeBackground from './Background/ThemeBackground';
// import ThemeBackgroundProduct from './Background/ThemeBackgroundProduct';
// // import ThemeBackgroundProductDetail from './Background/ThemeBackgroundProductDetail';

// header
// import ThemeHeaderMain from './Header/ThemeHeaderMain';
// import ThemeHeaderProduct from './Header/ThemeHeaderProduct';
// import ThemeHeaderProductDetail from './Header/ThemeHeaderProductDetail';


// import ThemeCardProductBackground from './ThemeCardProductBackground';

import ThemeChatHeader from './Chat/ThemeChatHeader';
import ThemeChatBody from './Chat/ThemeChatBody';
import ThemeChatFooter from './Chat/ThemeChatFooter';

const { SubMenu } = Menu;
const { Meta } = Card;

class Theme extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexMenu: 0,
            indexSubMenu: 0,
            menuList: [
                {
                    key: 'theme_color',
                    title: 'Theme Color',
                    component: <ThemeColor  {...this.props} />,
                    subMenuList: []
                },
                {
                    key: 'header',
                    title: 'Header',
                    component: <ThemeHeader  {...this.props} />,
                    subMenuList: []
                },
                {
                    key: 'background',
                    title: 'Body',
                    component: <ThemeBody  {...this.props} />,
                    subMenuList: []
                },
                {
                    key: 'navigation',
                    title: 'Footer',
                    component: <ThemeFooter  {...this.props} />,
                    subMenuList:[]
                },
                // {
                //     key: 'button',
                //     title: 'Button',
                //     subMenuList: [
                //         { key: 'header-01', title: 'Main', component: <ThemeButton  {...this.props} /> },
                //         { key: 'header-02', title: 'Product header', component: <ThemeButtonProductHeader  {...this.props} /> },
                //         { key: 'header-03', title: 'Product card', component: <ThemeButtonProductCard  {...this.props} /> },
                //     ]
                // },
                // {
                //     key: 'header',
                //     title: 'Header',
                //     subMenuList: [
                //         { key: 'header-01', title: 'Main', component: <ThemeHeaderMain  {...this.props} /> },
                //         { key: 'header-02', title: 'Product', component: <ThemeHeaderProduct  {...this.props} /> },
                //         { key: 'header-03', title: 'Product Detail', component: <ThemeHeaderProductDetail  {...this.props} /> },
                //     ]
                // },
                // {
                //     key: 'background',
                //     title: 'Background',
                //     subMenuList: [
                //         { key: 'background-01', title: 'Main', component: <ThemeBackground  {...this.props} /> },
                //         { key: 'background-02', title: 'Product', component: <ThemeBackgroundProduct  {...this.props} /> },
                //         // { key: 'background-03', title: 'Product Detail', component: <ThemeBackgroundProductDetail  {...this.props} /> },
                //     ]
                // },
                // {
                //     key: 'card',
                //     title: 'Card',
                //     subMenuList: [
                //         { key: 'card-01', title: 'Product', component: <ThemeCardProductBackground  {...this.props} /> },
                //     ]
                // },
                {
                    key: 'chat',
                    title: 'Chat',
                    subMenuList: [
                        { key: 'chat-01', title: 'Header', component: <ThemeChatHeader  {...this.props} /> },
                        { key: 'chat-02', title: 'Body', component: <ThemeChatBody  {...this.props} /> },
                        { key: 'chat-03', title: 'Footer', component: <ThemeChatFooter  {...this.props} /> },
                    ]
                },
            ]
        }
    }


    // setEditData(responses) {
    //     let themeColor = Object.assign({}, this.state.themeColor)
    //     let colorIndex = responses.findIndex(item => item.theme_code == 'COLOR')
    //     if (colorIndex != -1) {
    //         themeColor.id= responses[colorIndex].id
    //         themeColor.primary= responses[colorIndex].theme_primary
    //         themeColor.secondary= responses[colorIndex].theme_secondary
    //         themeColor.tertiary= responses[colorIndex].theme_tertiary
    //     }
    //     let themeFont = Object.assign({}, this.state.themeFont)
    //     let fontIndex = responses.findIndex(item => item.theme_code == 'FONT')
    //     if (fontIndex != -1) {
    //         themeFont.id= responses[fontIndex].id
    //         themeFont.primary= responses[fontIndex].theme_primary
    //         themeFont.secondary= responses[fontIndex].theme_secondary
    //         themeFont.tertiary= responses[fontIndex].theme_tertiary
    //     }
    //     let themeBackgroundColor = Object.assign({}, this.state.themeBackgroundColor)
    //     let backgroundColorIndex = responses.findIndex(item => item.theme_code == 'BACKGROUND_COLOR')
    //     if (backgroundColorIndex != -1) {
    //         themeBackgroundColor.id= responses[backgroundColorIndex].id
    //         themeBackgroundColor.primary= responses[backgroundColorIndex].theme_primary
    //         themeBackgroundColor.secondary= responses[backgroundColorIndex].theme_secondary
    //         themeBackgroundColor.tertiary= responses[backgroundColorIndex].theme_tertiary
    //     }

    //     let themeButton = Object.assign({}, this.state.themeButton)
    //     let themeButtonIndex = responses.findIndex(item => item.theme_code == 'BUTTON')
    //     if (themeButtonIndex != -1) {
    //         themeButton.id= responses[themeButtonIndex].id
    //         themeButton.primary= responses[themeButtonIndex].theme_primary
    //         themeButton.secondary= responses[themeButtonIndex].theme_secondary
    //         themeButton.tertiary= responses[themeButtonIndex].theme_tertiary
    //     }

    //     this.setState({
    //         themeColor: themeColor,
    //         themeFont: themeFont,
    //         themeBackgroundColor: themeBackgroundColor,
    //         themeButton: themeButton
    //     })

    // }
    // getThemeList() {
    //     let url = '/api/crm/appearance/theme/administrator';
    //     get(url)
    //         .then(responses => {
    //             this.setEditData(responses)
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }

    componentDidMount() {
        // this.getThemeList()
    }

    onClickMenu(indexMenu) {
        this.setState({ indexMenu: indexMenu })
    }
    
    onClickSubMenu(indexMenu, indexSubMenu) {
        this.setState({ indexMenu: indexMenu, indexSubMenu: indexSubMenu })
    }

    render() {
        const { menuList } = this.state

        return (
            <div className='theme-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-theme" >
                    <Link to={'/'}>
                        <Col span={24} style={{cursor: 'pointer',textAlign: 'left'}}>
                            <Icon style={{ fontSize: 25,fontWeight:500,color:'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25,fontWeight:500,color:'rgb(72, 197, 235)' }} >Theme</span>
                        </Col>
                    </Link>
                </Row>
                <Card className='cardstyle'>
                    <Row>
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} >
                            <div style={{ backgroundColor:'#fff',width: '256px' }}>
                                <p style={{ margin: '0', color: '#000', fontWeight: '500', fontSize: '15px' }}>
                                    {'Setting color'}
                                </p>
                            </div>
                            <Menu
                                onClick={this.handleClick}
                                style={{ width: 256 }}
                                defaultSelectedKeys={['home']}
                                defaultOpenKeys={['home-01']}
                                mode="inline"
                            >
                                {menuList.map((menu, indexMenu) => {
                                    if(menu.subMenuList.length > 0){
                                        return (
                                            <SubMenu
                                                key={menu.key}
                                                title={
                                                    <span>
                                                        <span>{menu.title}</span>
                                                    </span>
                                                }
                                            >
                                                {menu.subMenuList.map((subMenu, indexSubMenu) => {
                                                    return (
                                                        <Menu.Item 
                                                            key={subMenu.key} 
                                                            onClick={() => this.onClickSubMenu(indexMenu, indexSubMenu)}>
                                                            {subMenu.title}
                                                        </Menu.Item>
                                                    )
                                                })}
                                            </SubMenu>
                                        )
                                    }
                                    return (
                                        <Menu.Item 
                                            key={menu.key} 
                                            onClick={() => this.onClickMenu(indexMenu)}
                                        >
                                            {menu.title}
                                        </Menu.Item>
                                    )
                                })}
                            </Menu>
                        </Col>
                        <Col xs={12} sm={14} md={16} lg={18} xl={19}>
                            {menuList[this.state.indexMenu].subMenuList.length > 0 ?
                                menuList[this.state.indexMenu].subMenuList[this.state.indexSubMenu].component
                            :
                                menuList[this.state.indexMenu].component
                            }
                        </Col>
                    </Row>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(Theme);

