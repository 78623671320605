import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Row, Col, Button, Icon, Table, Modal, Select, Input, DatePicker, } from 'antd';
import { get, put, post } from '../../../../utils';
import config from '../../../../config.json'
import './style.scss'

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;


class PaymentOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visiblePreview: false,
            itemPreview: null,

            visibleCreate: false,

            paymentMethodList: [],
            tempPaymentMethodId: null,

            tempStatus: 'approve',
            tempReason: '',
            tempPaymentDatetime: null,

            orderNumber: '',
            userCustomerName: '',
            paymentStatus: 'pending',
            totalPrice: 0,

            dataSource: [],
            columns: [
                {
                    title: 'Line No.',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center ',
                    width: '15%',
                    render: (text, item, index) => (index + 1).toString().padStart(5, 0)
                },
                {
                    title: 'Payment Method',
                    dataIndex: 'payment_method_name',
                    key: 'payment_method_name',
                    align: 'center',
                    width: '30%',
                },
                // {
                //     title: 'Price',
                //     dataIndex: 'total_price',
                //     key: 'total_price',
                //     align: 'center',
                //     width: '15%',
                // },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center',
                    width: '20%',
                    render: (text, item, index) => this.renderStatusColumn(text, item, index)
                },
                {
                    title: 'Slip',
                    dataIndex: 'slip',
                    key: 'slip',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => <Button onClick={() => this.onClickViewModal(item)} style={{ width: '100%' }}>{'View'}</Button>
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => this.renderActionColumn(text, item, index)
                },
            ]
        }
    }

    renderStatusColumn(text, item, index) {
        switch (item.status) {
            case 'approve':
                return <span style={{ color: 'green' }}>{'Approve'}</span>;
            case 'reject':
                return <span style={{ color: 'red' }}>{'Reject'}</span>;
            case 'pending':
                return <span style={{ color: '#000' }}>{'Pending'}</span>;
        }
    }

    renderActionColumn(text, item, index) {
        let disabled = item.status === 'reject' || item.status === 'approve'
        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                <div style={{
                    margin: 5,
                    width: 30, height: 30,
                    borderRadius: 15,
                    backgroundColor: '#EAEAEA',
                    cursor: !disabled ? 'pointer' : 'none'
                }}
                    onClick={() => !disabled ? this.onClickApprove(item) : null}
                >
                    <Icon type="check" style={{ fontSize: 16, padding: 5, color: !disabled ? 'green' : '#fff' }} />
                </div>
                <div style={{
                    margin: 5,
                    width: 30, height: 30, borderRadius: 15,
                    backgroundColor: '#EAEAEA',
                    cursor: !disabled ? 'pointer' : 'none'
                }}
                    onClick={() => !disabled ? this.onClickReject(item) : null}
                >
                    <Icon type="close" style={{ fontSize: 16, padding: 5, color: !disabled ? 'red' : '#fff' }} />
                </div>
            </div>
        )
    }

    getSalesOrder() {
        const { editId } = this.props
        let url = '/api/crm/administrator/sales/order/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    orderNumber: response.order_number,
                    userCustomerName: response.user_customer_name,
                    paymentStatus: response.payment_status,
                    totalPrice: response.total_price,
                    dataSource: response.paymentList,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getPaymentOrderList() {
        const { editId } = this.props
        let url = '/api/crm/administrator/sales/order/' + editId + '/payment/order';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log("getPaymentOrderList", responses)
                this.setState({ dataSource: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    getPaymentMethod(){
        let url = '/api/crm/administrator/payment/method?isPublished=1';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({paymentMethodList : responses})
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getPaymentMethod()
        if (this.props.editId) {
            this.getSalesOrder()
            // this.getPaymentOrderList()
        }
    }

    onClickCreateModal() {
        this.setState({ visibleCreate: true })
    }

    onClickCancelCreateModal() {
        this.setState({ visibleCreate: false })
    }

    onClickOkCreateModal(item) {
        const { editId, administratorId } = this.props
        const { tempPaymentMethodId, tempStatus, tempReason, tempPaymentDatetime } = this.state
        let url = '/api/crm/administrator/sales/order/' + editId + '/payment/order/approve';
        let data = {
            payment_method_id: tempPaymentMethodId,
            status: tempStatus,
            image_slip: null,
            reason: tempReason,
            payment_datetime: tempPaymentDatetime,
            create_by: administratorId,
        }
        post(url, data)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getPaymentOrderList()
                this.getSalesOrder()
                this.setState({
                    visiblePreview: false,
                    itemPreview: null,
                    visibleCreate:false
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    renderCreateModal() {
        const { visibleCreate } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 8 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <Modal
                title={'Create Payment'}
                visible={visibleCreate}
                onOk={() => this.onClickOkCreateModal()}
                onCancel={() => this.onClickCancelCreateModal()}
            >
                <Form>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <FormItem {...formItemLayout} label={'Payment Method'} required={true}>
                                <Select
                                    value={this.state.tempPaymentMethodId}
                                    onChange={(value) => this.setState({ tempPaymentMethodId: value })}
                                >
                                    {this.state.paymentMethodList.map((item, index) => <Option key={index} value={item.id}>{item.Name}</Option>)}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <FormItem {...formItemLayout} label={'Status'} required={true}>
                                <Input
                                    value={this.state.tempStatus}
                                    disabled={true}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <FormItem {...formItemLayout} label={'Date Time'} required={true}>
                                <DatePicker
                                    showTime
                                    value={this.state.tempPaymentDatetime ? moment(this.state.tempPaymentDatetime) : null}
                                    onChange={(date, dateString) => this.setState({ tempPaymentDatetime: dateString })}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <FormItem {...formItemLayout} label={'Create By'} required={true}>
                                <Input
                                    value={this.props.administratorName}
                                    disabled={true}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Reason'} required={true}>
                                <TextArea
                                    rows={4}
                                    value={this.state.tempReason}
                                    onChange={(event) => this.setState({ tempReason: event.target.value })}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    onClickViewModal(item) {
        this.setState({
            visiblePreview: true,
            itemPreview: item
        })
    }

    onClickCloseViewModal() {
        this.setState({
            visiblePreview: false,
            itemPreview: null
        })
    }

    onClickApprove(item) {
        const { editId } = this.props
        let url = '/api/crm/administrator/sales/order/' + editId + '/payment/order/' + item.id + '/approve';
        put(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getPaymentOrderList()
                this.setState({
                    visiblePreview: false,
                    itemPreview: null
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickReject(item) {
        const { editId } = this.props
        let url = '/api/crm/administrator/sales/order/' + editId + '/payment/order/' + item.id + '/reject';
        put(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getPaymentOrderList()
                this.setState({
                    visiblePreview: false,
                    itemPreview: null
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    renderViewModal() {
        const { visiblePreview, itemPreview } = this.state
        const { folderName } = this.props
        let disabled = !itemPreview || itemPreview.status === 'reject' || itemPreview.status === 'approve'
        return (
            <Modal
                title={'Payment Infomation'}
                visible={visiblePreview}
                onOk={() => this.onClickCloseViewModal()}
                onCancel={() => this.onClickCloseViewModal()}
                width={500}
                footer={[
                    <Button key="reject" onClick={() => this.onClickReject(itemPreview)} disabled={disabled}>{"Reject"}</Button>,
                    <Button key="approve" type="primary" onClick={() => this.onClickApprove(itemPreview)} disabled={disabled}>{"Approve"}</Button>,
                ]}
            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ margin: 0 }}>{'Order Number : '}</p>
                        <p style={{ margin: 0 }}>{this.state.orderNumber}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ margin: 0 }}>{'Customer Name : '}</p>
                        <p style={{ margin: 0 }}>{this.state.userCustomerName}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ margin: 0 }}>{'Total Price : '}</p>
                        <p style={{ margin: 0 }}>{this.state.totalPrice}</p>
                    </div>
                    <div>
                        {/* {itemPreview.slip} */}
                        {itemPreview && itemPreview.image_slip ?
                            <img
                                src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${itemPreview.image_slip}`} 
                                // src={itemPreview.slip} 
                                style={{ width: '100%' }} 
                            /> 
                            : 
                            null}
                    </div>

                </div>
            </Modal>
        )
    }

    render() {
        const { dataSource, columns } = this.state
        return (
            <div className='paymentorder-sub-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{'Payment Order'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        {/* <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} disabled={['pending-approve', 'approve'].includes(this.state.paymentStatus)} onClick={() => this.onClickCreateModal()}>{'Create'}</Button> */}
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Row className='action-button-wrapper' gutter={24} type="flex" justify='end'>
                        <Button className='create-button' onClick={() => this.onClickCreateModal()} disabled={['pending-approve', 'approve','cancel'].includes(this.state.paymentStatus)} ><Icon type="plus" />{'Create'}</Button>
                    </Row>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </Card>
                {this.renderCreateModal()}
                {this.renderViewModal()}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    administratorName: state.login.name,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(PaymentOrder);

