import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, List, Card, Progress, Icon } from 'antd';
// import CustomCircularProgressbar from '../../components/CustomCircularProgressbar';
import CustomAreaCharts from '../../components/CustomAreaCharts';
import CustomAreaChartsSkeleton from '../../components/CustomAreaCharts/CustomAreaChartsSkeleton';
import CustomAreaChartsExtra from '../../components/CustomAreaChartsExtra';
import CustomAreaChartsExtraSkeleton from '../../components/CustomAreaChartsExtra/CustomAreaChartsExtraSkeleton';
import { post, get } from '../../utils';
import config from '../../config.json'
import moment from 'moment';
const { Meta } = Card;
class DashboardManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfInterval: 0,
            tagList: [],
            articleList: [],
            indexStart: 0,
            indexEnd: 2,
            guests: [],
            userRegister: [],
            allTicket: [],
            TicketWaitAdmin: [],
            Ticketfollowproduct: [],
            datastatic: [],

            chartsNoAssign: null,
            chartsAssigned: null,
            chartsWaitingStaff: null,
            chartsInProgress: null,
            chartsWaitingCustomers: null,
            chartsComplete: null,

            chartsUserGuest: null,
            chartsUserRegistrant: null,
            chartsTag: null,
        }
        this.initializeChartsInterval = null
    }


    // getArticle(){
    //     const { folderName } = this.props
    //     let url = '/api/crm/administrator/dashboard/article';
    //     get(url)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             response.map(item=>{
    //                 let image = item.image
    //                 item['image'] = `${window.location.protocol}//${window.location.hostname}:${config.PORT}/uploads/${folderName}/${image}`
    //                 return item
    //             })

    //             this.setState({ articleList: response }) 
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }

    componentDidMount() {
        const { tempFilter } = this.props
        let date = null
        let dateList = []
        switch (tempFilter) {
            case 'day':
                let previous = moment().subtract(1, 'days').format('YYYY-MM-DD')
                date = moment().format('YYYY-MM-DD')
                dateList.push(previous)
                dateList.push(date)
                break;
            case 'week':
                date = moment().subtract(1, 'weeks').format('YYYY-MM-DD')
                for (let indexWeek = 0; indexWeek < 7; indexWeek += 1) {
                    let tempDate = moment().subtract(indexWeek + 1, 'days').format('YYYY-MM-DD')
                    dateList.push(tempDate);
                }
                break;
            case 'month':
                date = moment().subtract(1, 'months').format('YYYY-MM-DD')
                for (let indexMonth = 0; indexMonth < 30; indexMonth += 7) {
                    let tempDate = moment().subtract(indexMonth + 1, 'days').format('YYYY-MM-DD')
                    dateList.push(tempDate);
                }
                break;
            case 'year':
                date = moment().subtract(1, 'years').format('YYYY-MM-DD')
                for (let indexYear = 0; indexYear < 365; indexYear += 30) {
                    let tempDate = moment().subtract(indexYear + 1, 'days').format('YYYY-MM-DD')
                    dateList.push(tempDate);
                }
                break;
        }

        // this.getArticle()

        this.getChartsNoAssignPercentage(date)
        this.getChartsNoAssignGraph(dateList)

        this.getChartsAssignedPercentage(date)
        this.getChartsAssignedGraph(dateList)

        this.getChartsWaitingStaffPercentage(date)
        this.getChartsWaitingStaffGraph(dateList)

        this.getChartsInProgressPercentage(date)
        this.getChartsInProgressGraph(dateList)

        this.getChartsWaitingCustomersPercentage(date)
        this.getChartsWaitingCustomersGraph(dateList)

        this.getChartsCompletePercentage(date)
        this.getChartsCompleteGraph(dateList)

        this.getChartsUserGuestPercentage(date)
        this.getChartsUserGuestGraph(dateList)

        this.getChartsUserRegistrantPercentage(date)
        this.getChartsUserRegistrantGraph(dateList)

        this.getChartsTagPercentage(date)
        this.getChartsTagGraph(dateList)
        this.getChartsTagDetail(date)

        this.initializeChartsInterval = setInterval(() => {
            if (!this.state.chartsNoAssign || !this.state.chartsNoAssign.dataGraphList) {
                this.getChartsNoAssignPercentage(date)
                this.getChartsNoAssignGraph(dateList)
            }
            if (!this.state.chartsAssigned || !this.state.chartsAssigned.dataGraphList) {
                this.getChartsAssignedPercentage(date)
                this.getChartsAssignedGraph(dateList)
            }
            if (!this.state.chartsWaitingStaff || !this.state.chartsWaitingStaff.dataGraphList) {
                this.getChartsWaitingStaffPercentage(date)
                this.getChartsWaitingStaffGraph(dateList)
            }
            if (!this.state.chartsInProgress || !this.state.chartsInProgress.dataGraphList) {
                this.getChartsInProgressPercentage(date)
                this.getChartsInProgressGraph(dateList)
            }
            if (!this.state.chartsWaitingCustomers || !this.state.chartsWaitingCustomers.dataGraphList) {
                this.getChartsWaitingCustomersPercentage(date)
                this.getChartsWaitingCustomersGraph(dateList)
            }
            if (!this.state.chartsComplete || !this.state.chartsComplete.dataGraphList) {
                this.getChartsCompletePercentage(date)
                this.getChartsCompleteGraph(dateList)
            }
            if (!this.state.chartsUserGuest || !this.state.chartsUserGuest.dataGraphList) {
                this.getChartsUserGuestPercentage(date)
                this.getChartsUserGuestGraph(dateList)
            }
            if (!this.state.chartsUserRegistrant || !this.state.chartsUserRegistrant.dataGraphList) {
                this.getChartsUserRegistrantPercentage(date)
                this.getChartsUserRegistrantGraph(dateList)
            }
            if (!this.state.chartsTag || !this.state.chartsTag.dataGraphList) {
                this.getChartsTagPercentage(date)
                this.getChartsTagGraph(dateList)
                this.getChartsTagDetail(date)
            }
            if (this.state.numberOfInterval > 10) {
                clearInterval(this.initializeChartsInterval);
            } else {
                this.setState({ numberOfInterval: this.state.numberOfInterval + 1 })
            }
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.initializeChartsInterval)
    }
    
    getChartsNoAssignPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/noassign/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsNoAssign = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsNoAssign: chartsNoAssign })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsNoAssignGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/noassign/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsNoAssign = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsNoAssign)
                    this.setState({ chartsNoAssign: chartsNoAssign })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsAssignedPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/assigned/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsAssigned = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsAssigned: chartsAssigned })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsAssignedGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/assigned/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsAssigned = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsAssigned)
                    this.setState({ chartsAssigned: chartsAssigned })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingStaffPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/waitingstaff/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingStaff = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsWaitingStaff: chartsWaitingStaff })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingStaffGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/waitingstaff/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingStaff = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsWaitingStaff)
                    this.setState({ chartsWaitingStaff: chartsWaitingStaff })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsInProgressPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/inprogress/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsInProgress = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsInProgress: chartsInProgress })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsInProgressGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/inprogress/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsInProgress = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsInProgress)
                    this.setState({ chartsInProgress: chartsInProgress })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingCustomersPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/waitingcustomers/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingCustomers = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsWaitingCustomers: chartsWaitingCustomers })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingCustomersGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/waitingcustomers/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingCustomers = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsWaitingCustomers)
                    this.setState({ chartsWaitingCustomers: chartsWaitingCustomers })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsCompletePercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/complete/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsComplete = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsComplete: chartsComplete })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsCompleteGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/complete/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsComplete = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsComplete)
                    this.setState({ chartsComplete: chartsComplete })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsUserGuestPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/user/guest/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsUserGuest = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsUserGuest: chartsUserGuest })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsUserGuestGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/user/guest/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsUserGuest = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsUserGuest)
                    this.setState({ chartsUserGuest: chartsUserGuest })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsUserRegistrantPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/user/registrant/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsUserRegistrant = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsUserRegistrant: chartsUserRegistrant })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsUserRegistrantGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/user/registrant/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsUserRegistrant = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsUserRegistrant)
                    this.setState({ chartsUserRegistrant: chartsUserRegistrant })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsTagPercentage(date) {
        let url = '/api/crm/administrator/home/manager/charts/tag/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsTag = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsTag: chartsTag })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsTagGraph(dateList) {
        let url = '/api/crm/administrator/home/manager/charts/tag/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsTag = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsTag)
                    this.setState({ chartsTag: chartsTag })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsTagDetail(date) {
        let url = '/api/crm/administrator/home/manager/charts/tag/detail';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsTag = Object.assign({ dataDetailList: response.dataDetailList }, this.state.chartsTag)
                    this.setState({ chartsTag: chartsTag })
                }
                console.log("getChartsTagDetail", response)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    // onClickPrevious() {
    //     const { indexStart, indexEnd } = this.state
    //     let previousIndexStart = indexStart - 1
    //     let previousIndexEnd = indexEnd - 1
    //     if (previousIndexStart >= 0) {
    //         this.setState({ indexStart: previousIndexStart, indexEnd: previousIndexEnd })
    //     }
    // }

    // onClickNext() {
    //     const { indexStart, indexEnd,articleList } = this.state
    //     let nextIndexStart = indexStart + 1
    //     let nextIndexEnd = indexEnd + 1
    //     if (nextIndexEnd <= articleList.length) {
    //         this.setState({ indexStart: nextIndexStart, indexEnd: nextIndexEnd })
    //     }
    // }

    render() {
        const {
            tagList, indexStart, indexEnd, articleList, guests, userRegister, allTicket, TicketWaitAdmin, Ticketfollowproduct, datastatic,
            chartsNoAssign, chartsAssigned, chartsWaitingStaff, chartsInProgress, chartsWaitingCustomers, chartsComplete,
            chartsUserGuest, chartsUserRegistrant, chartsTag
        } = this.state
        const { tempFilter } = this.props
        // let filterarticleList = articleList.slice(indexStart, indexEnd) 
        // console.log('filterarticleList',filterarticleList)
        return (
            <div className='dashboard-manager-wrapper'>
                <Row >
                    <Col sm={24} md={18} lg={18}>
                        <Row>
                            <p className='title'>{'Statistics Status'}</p>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsNoAssign && chartsNoAssign.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'No Assign'}
                                        value={chartsNoAssign ? chartsNoAssign.value : 0}
                                        progressType={chartsNoAssign ? chartsNoAssign.progressType : 'increase'}
                                        percentage={chartsNoAssign ? chartsNoAssign.percentage : 0}
                                        dataGraphList={chartsNoAssign ? chartsNoAssign.dataGraphList : []}
                                        primaryColor={'#91C8FB'}
                                        secondaryColor={'#F1F9FF'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsAssigned && chartsAssigned.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'Assigned'}
                                        value={chartsAssigned ? chartsAssigned.value : 0}
                                        progressType={chartsAssigned ? chartsAssigned.progressType : 'increase'}
                                        percentage={chartsAssigned ? chartsAssigned.percentage : 0}
                                        dataGraphList={chartsAssigned ? chartsAssigned.dataGraphList : []}
                                        primaryColor={'#FEDD78'}
                                        secondaryColor={'#FEF6DC'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsWaitingStaff && chartsWaitingStaff.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'Waiting Staff'}
                                        value={chartsWaitingStaff ? chartsWaitingStaff.value : 0}
                                        progressType={chartsWaitingStaff ? chartsWaitingStaff.progressType : 'increase'}
                                        percentage={chartsWaitingStaff ? chartsWaitingStaff.percentage : 0}
                                        dataGraphList={chartsWaitingStaff ? chartsWaitingStaff.dataGraphList : []}
                                        primaryColor={'#ED2E2C'}
                                        secondaryColor={'#FCDDD7'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsInProgress && chartsInProgress.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'In Progress'}
                                        value={chartsInProgress ? chartsInProgress.value : 0}
                                        progressType={chartsInProgress ? chartsInProgress.progressType : 'increase'}
                                        percentage={chartsInProgress ? chartsInProgress.percentage : 0}
                                        dataGraphList={chartsInProgress ? chartsInProgress.dataGraphList : []}
                                        primaryColor={'#35932E'}
                                        secondaryColor={'#F6FAF5'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsWaitingCustomers && chartsWaitingCustomers.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'Waiting Customers'}
                                        value={chartsWaitingCustomers ? chartsWaitingCustomers.value : 0}
                                        progressType={chartsWaitingCustomers ? chartsWaitingCustomers.progressType : 'increase'}
                                        percentage={chartsWaitingCustomers ? chartsWaitingCustomers.percentage : 0}
                                        dataGraphList={chartsWaitingCustomers ? chartsWaitingCustomers.dataGraphList : []}
                                        primaryColor={'#FF7E35'}
                                        secondaryColor={'#FFEEE7'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsComplete && chartsComplete.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'Complete'}
                                        value={chartsComplete ? chartsComplete.value : 0}
                                        progressType={chartsComplete ? chartsComplete.progressType : 'increase'}
                                        percentage={chartsComplete ? chartsComplete.percentage : 0}
                                        dataGraphList={chartsComplete ? chartsComplete.dataGraphList : []}
                                        primaryColor={'#33B6B6'}
                                        secondaryColor={'#ECF7F7'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                        </Row>
                        <Row>
                            <p className='title'>{'Statistics User'}</p>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsUserGuest && chartsUserGuest.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'Guest'}
                                        value={chartsUserGuest ? chartsUserGuest.value : 0}
                                        progressType={chartsUserGuest ? chartsUserGuest.progressType : 'increase'}
                                        percentage={chartsUserGuest ? chartsUserGuest.percentage : 0}
                                        dataGraphList={chartsUserGuest ? chartsUserGuest.dataGraphList : []}
                                        primaryColor={'#B464ED'}
                                        secondaryColor={'#FDFAFF'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                            <Col sm={24} md={8} lg={8} style={{ padding: '0px 5px' }}>
                                {chartsUserRegistrant && chartsUserRegistrant.dataGraphList ?
                                    < CustomAreaCharts
                                        title={'Registrant'}
                                        value={chartsUserRegistrant ? chartsUserRegistrant.value : 0}
                                        progressType={chartsUserRegistrant ? chartsUserRegistrant.progressType : 'increase'}
                                        percentage={chartsUserRegistrant ? chartsUserRegistrant.percentage : 0}
                                        dataGraphList={chartsUserRegistrant ? chartsUserRegistrant.dataGraphList : []}
                                        primaryColor={'#FDCAEC'}
                                        secondaryColor={'#FDF9FE'}
                                        filterBy={tempFilter}
                                    />
                                    : <CustomAreaChartsSkeleton />}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={24} md={6} lg={6} style={{ padding: '0px 5px' }}>
                        <p className='title'>{'Statistics Tag'}</p>
                        <Col sm={24} md={24} lg={24} style={{ padding: '0px 5px' }}>
                            {chartsTag && chartsTag.dataGraphList ?
                                < CustomAreaChartsExtra
                                    title={'Tag'}
                                    value={chartsTag ? chartsTag.value : 0}
                                    progressType={chartsTag ? chartsTag.progressType : 'increase'}
                                    percentage={chartsTag ? chartsTag.percentage : 0}
                                    dataGraphList={chartsTag ? chartsTag.dataGraphList : []}
                                    dataDetailList={chartsTag ? chartsTag.dataDetailList : []}
                                    primaryColor={'#B464ED'}
                                    secondaryColor={'#FDFAFF'}
                                    filterBy={tempFilter}
                                />
                                : <CustomAreaChartsExtraSkeleton />}
                        </Col>
                    </Col>
                </Row>
                {/* <Row >
                    <p className='title'>{'Article'}</p>
                    <div className='article-list-wrapper' >
                        <div
                            style={{ margin: 'auto', padding: 5, cursor: 'pointer' }}
                            onClick={() => this.onClickPrevious()}
                        >
                            <Icon style={{ fontSize: 24 }} type="left" />
                        </div>
                        <List
                            grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
                            dataSource={filterarticleList}
                            
                            pagination={false}
                            renderItem={item => (
                                
                                <List.Item >
                                    
                                    <div className="card-container">
                                        <div className="left-img">
                                            <img alt="example" src={item.image? item.image :"https://images.unsplash.com/photo-1593158778894-0af193eb325c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"} />
                                        </div>
                                        <div className="right-text">
                                            <div className="text-header"><h3>{item.title}</h3></div>
                                            <div className="text-description"><p>{item.description ? item.description.slice(0,80) : 'Description'}</p></div>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                        <div
                            style={{ margin: 'auto', padding: 5, cursor: 'pointer' }}
                            onClick={() => this.onClickNext()}
                        >
                            <Icon style={{ fontSize: 24 }} type="right" />
                        </div>
                    </div>
                </Row> */}


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(DashboardManager);

