const initialState = {
    notification: '0'
};

const notification = (state = initialState, action) => {
    switch (action.type) {
        case 'NOTIFICATION_SUCCESS': {
            return Object.assign({}, state, {
                notification: '1',
            })
        }
        case 'NOTIFICATION_REMOVE': {
    
            return Object.assign({}, state, {
                notification: '0',
            })
        }
        default:
            return state;
    }
}

export default notification;