import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, notification,Select } from 'antd';
import ImageUpload from '../../../containers/ImageUpload';
import CustomerOverLimitModal from '../../../containers/CustomModal/CustomerOverLimitModal';
import { get, put, post, ppost } from '../../../utils';
import config from '../../../config.json'

import CustomerNotification from './CustomerNotification'
import CustomerAddress from './CustomerAddress'
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;

const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Customer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            telephone: '',
            email: '',
            username: '',
            password: null,

            imageAvatarList: [],
            visible: false,

            isEyebrowDesign: 0,
        }
    }


    getCustomerDetail() {
        const { editId, folderName } = this.props
        let url = '/api/user/customer/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let imageAvatarList = []
                if (response.avatar) {
                    imageAvatarList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.avatar}`,
                        file: null,
                        name: response.avatar
                    }]
                }
                this.setState({
                    name: response.name,
                    telephone: response.telephone,
                    email: response.email,
                    username: response.username,
                    isEyebrowDesign: response.is_eyebrow_design,
                    imageAvatarList: imageAvatarList
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getCustomerDetail()
        }
    }


    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateCustomer() {
        const { name, telephone, email, imageAvatarList, username, password,isEyebrowDesign } = this.state
        if (!name || name == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Name".')
            return
        }
        if (!telephone || telephone == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Telephone".')
            return
        }
        if (!email || email == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "E-mail".')
            return
        }
        if (!username || username == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Username".')
            return
        }
        if (!this.props.editId && (!password || password == '')) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Password".')
            return
        }
        if (username.length < 6) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter your username least 6 characters.')
            return
        }
        if ((!this.props.editId && !password) && password.length < 6) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter your password least 6 characters.')
            return
        }

        let data = {
            name: name,
            telephone: telephone,
            email: email,
            avatar: null,
            username: username,
            password: password,
            isEyebrowDesign: isEyebrowDesign,
            user: this.props.administratorId
        }

        let uploadFileList = imageAvatarList.filter(item => item.file)
        if (uploadFileList.length) {
            let formData = new FormData();
            uploadFileList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                data.avatar = fileNameList[0]
            }
        } else if (imageAvatarList.length) {
            data.avatar = imageAvatarList[0].name
        }
        if (this.props.editId) {
            let url = '/api/user/customer/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    if (!response.success) {
                        switch (response.error.code) {
                            case 'DUPLICATE_TELEPHONE':
                                openNotificationWithIcon('error', 'System notification', 'Duplicate telephone.')
                                break;
                            case 'DUPLICATE_EMAIL':
                                openNotificationWithIcon('error', 'System notification', 'Duplicate email.')
                                break;
                        }
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/user/customer';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    if (!response.success) {
                        switch (response.error.code) {
                            case 'OVER_LIMIT_CUSTOMER':
                                this.showCustomerOverLimitModal()
                                break;
                            case 'DUPLICATE_USERNAME':
                                openNotificationWithIcon('error', 'System notification', 'Duplicate username.')
                                break;
                            case 'DUPLICATE_TELEPHONE':
                                openNotificationWithIcon('error', 'System notification', 'Duplicate telephone.')
                                break;
                            case 'DUPLICATE_EMAIL':
                                openNotificationWithIcon('error', 'System notification', 'Duplicate email.')
                                break;
                        }
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }
    showCustomerOverLimitModal() {
        this.setState({ visible: true })
    }

    onClickOk() {
        this.updateCustomer()
    }
    onClickCancel() {
        this.setState({
            name: '',
            telephone: '',
            email: '',
            username: '',
            password: '',
        })
        this.props.backpage()
    }
    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <div className='customer-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit User Customer' : 'Create User Customer'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>

                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Name'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Telephone'} required={true}>
                                    <Input
                                        value={this.state.telephone}
                                        onChange={(event) => this.setState({ telephone: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'E-mail'} required={true}>
                                    <Input
                                        value={this.state.email}
                                        onChange={(event) => this.setState({ email: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Username'} required={true}>
                                    <Input
                                        value={this.state.username}
                                        onChange={(event) => this.setState({ username: event.target.value })}
                                        disabled={this.props.editId}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Password'} required={true}>
                                    <Input
                                        type={'password'}
                                        value={this.state.password}
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            {
                                this.props.mainCustomerId == 4 ?
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <FormItem {...formItemLayout} label={'isEyebrowDesign'} required={true}>
                                        <Select 
                                            value={this.state.isEyebrowDesign}
                                            onChange={(value) => this.setState({ isEyebrowDesign: value })}
                                        >
                                            <Option key={0} value={0} >No</Option>
                                            <Option key={1} value={1} >Yes</Option>
                                        </Select>

                                    </FormItem>
                                </Col>
                                :
                                null
                            }
                            

                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row>
                                    <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                        <ImageUpload
                                            label={'Upload image avatar'}
                                            limit={1}
                                            dataSource={this.state.imageAvatarList}
                                            updateDataSource={(dataSource) => this.setState({ imageAvatarList: dataSource })}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <CustomerOverLimitModal
                            visible={this.state.visible}
                            onClose={() => this.setState({ visible: false })}
                        />
                    </Form>
                    <CustomerAddress
                        editId={this.props.editId} 
                    />
                    <CustomerNotification
                        editId={this.props.editId}
                    />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
    mainCustomerId: state.login.mainCustomerId,
})

export default connect(mapStateToProps, null)(Customer);

