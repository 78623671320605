import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon } from 'antd';
import { get, post, put, ppost } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import config from '../../../config.json'
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;

class CategoryProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            imageCoverList: [],
        }
    }


    getCategoryProductDetail() {
        const { editId, folderName } = this.props
        let url = '/api/administrator/crm/category/product/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }

                let imageCoverList = []
                if (response.image) {
                    imageCoverList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                        file: null,
                        name: response.image
                    }]
                }

                this.setState({
                    name: response.name,
                    imageCoverList: imageCoverList,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getCategoryProductDetail()
        }
    }


    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateCategoryProduct() {
        const { name, imageCoverList } = this.state
        let data = {
            name: name,
            user: this.props.administratorId,
            image: null,
        }

        let uploadFileList = imageCoverList.filter(item => item.file)
        if (uploadFileList.length) {
            let formData = new FormData();
            uploadFileList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                data.image = fileNameList[0]
            }
        } else if (imageCoverList.length) {
            data.image = imageCoverList[0].name
        }
        if (this.props.editId) {
            let url = '/api/administrator/crm/category/product/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/administrator/crm/category/product';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    onClickOk() {
        this.updateCategoryProduct()
    }
    onClickCancel() {
        this.setState({
            name: '',
        })
        this.props.backpage()
    }
    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <div className='categoryproduct-page-wrapper'  style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >Create Category Product</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle"> 
                    <Form> 
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Name'}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <ImageUpload
                                    label={'Upload image cover'}
                                    limit={1}
                                    dataSource={this.state.imageCoverList}
                                    updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                                />
                            </Col>
                            
                        </Row>
                    </Form>
                </Card>

            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps, null)(CategoryProduct);

