import { SHOW_MODAL_EXPIRED, HIDE_MODAL_EXPIRED } from './types';
export function showExpiredModal() { 
    return {
        type: SHOW_MODAL_EXPIRED
    };
}
export function hideExpiredModal() {
    return {
        type: HIDE_MODAL_EXPIRED
    };
}
