import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, post, put } from '../../../utils';
import { Col, Row, Input, Button, Form, Select, Modal, Icon, Checkbox, Tabs } from 'antd';
import Chat from '../../../containers/Chat';
import Register from './Register';
import ChatListContent from './ChatListContent';
import TicketTag from './TicketTag';
import TicketProduct from './TicketProduct';
import TicketAttachment from './TicketAttachment';
import defaultSquareSrc from '../../../resource/default/300x300-square.png'
import './style.scss'
import BurgerMenu from 'react-burger-menu'
import TicketModal from './TicketModal'

import config from '../../../config.json';

const base64 = require('base-64');
const utf8 = require('utf8');

const FormItem = Form.Item;
const Option = Select.Option;
const { TabPane } = Tabs;

class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closepage: false,
            visible: false,
            visibleCreateModal: false,
            categoryList: [],
            statusList: [],
            chatList: [],
            typeList: [],
            assigneeList: [],
            create: null,
            modify: null,
            customer: null,
            type: null,
            specs: '',
            ticketCategoryId: null,
            status: 'wait administrator',
            originalStatus: '',
            poIdList: [],
            purchaseOrderList: [],
            administratorNote: [],
            customerNote: [],
            note: '',
            noteClient: '',
            proofScreenList: [],
            original_status: '',
            ticketname: '',
            ticketId: null,
            noteAdministrator: '',
            open:false,
            

            height: 0,
            width: 0
        }
        window.addEventListener("resize", this.updateWindowBrowser);
    }

    updateWindowBrowser = () => {
        this.setState({
          height: window.innerHeight,
          width: window.innerWidth,
          open:false
        });
      };



    getAssigneeList() {
        let url = '/api/crm/administrator/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ assigneeList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    getcategoryList() {
        let url = '/api/category/ticket';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ categoryList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChatList() {
        let url = '/api/administrator/crm/ticket/chat/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ chatList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getPurchaseOrderList(customer) {
        let url = '/api/administrator/purchaseorder/' + customer;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ purchaseOrderList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getOrderTypeList() {
        let url = '/api/order/type';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ typeList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    getTicketDetail(id) {
        let url = '/api/administrator/crm/ticket/chat/' + id;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setStateData(response)
                // if (response) {
                //     this.getPurchaseOrderList(response.customer)
                // }

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getAdministratorNote(id) {
        let url = '/api/administrator/crm/message/note/' + id;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ administratorNote: response })

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCustomerNote(id) {
        let url = '/api/customer/crm/message/note/' + id;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ customerNote: response })

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    updateClientNote() {
        const { noteClient, ticketId } = this.state
        let url = '/api/customer/crm/message/note/'
        this.updateNote(url, noteClient, (result) => {
            if (result) {
                this.setState({ noteClient: '' })
                this.getCustomerNote(ticketId)
            } else {
                console.log("Note error")
            }
        })
    }

    updateAdministratorNote() {
        const { noteAdministrator, ticketId } = this.state
        let url = '/api/administrator/crm/message/note/';
        this.updateNote(url, noteAdministrator, (result) => {
            if (result) {
                this.setState({ noteAdministrator: '' })
                this.getAdministratorNote(ticketId)
            } else {
                console.log("Note error")
            }
        })
    }

    updateNote(url, note, done) {
        const { administratorId, userName } = this.props
        const { ticketId } = this.state
        let data = {
            ticket: ticketId,
            note: note,
            noteBy: 'administrator',
            updateByID: administratorId,
            updateBy: userName

        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                done(true)
            })
            .catch(err => {
                console.log(err.message)
                done(false)
            })
    }


    setStateData(response) {
        const { folderName } = this.props
        let imageCoverList = []
        if (response.customerAvatar) {
            imageCoverList = [{
                view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.customerAvatar}`,
                file: null,
                name: response.customerAvatar
            }]
        }


        let modify = {
            adminId: response.modifyName,
            datetime: response.modify_datetime
        }
        let customer = {
            id: response.customer,
            username: response.customerUsername,
            name: response.customerName,
            telephone: response.customerTelephone,
            email: response.customerEmail,
            avatar: imageCoverList
        }
        let create = {
            datetime: response.createDatetime,
        }
        this.setState({
            ticketname: response.ticketName,
            specs: response.specs,
            type: response.type,
            ticketCategoryId: response.ticket_category_id,
            originalStatus: response.status,
            status: response.status,
            assignee: response.assignee,
            modify: modify,
            create: create,
            customer: customer,
            original_status: response.status,
            poIdList: response.po ? JSON.parse(response.po) : [],
        })
    }


    componentDidMount() {
        const { editId } = this.props
        const urlParams = new URLSearchParams(window.location.search);
        let ticketId = urlParams.get('ticket');
        if(editId){
            ticketId = editId
        }  
        this.getOrderTypeList()
        this.getChatList()
        this.getTicketStatus()
        this.getcategoryList()
        this.getAssigneeList()

        this.setState({ ticketId: ticketId })
        this.getTicketDetail(ticketId)
        this.getAdministratorNote(ticketId)
        this.updateWindowBrowser()
        // this.getCustomerNote(ticketId)
        // this.getMessage(ticketId)
    }


    onChangeTicket(id) {
        this.setState({ ticketId: id })
        this.getTicketDetail(id)
        this.getAdministratorNote(id)
        // this.getCustomerNote(id)
    }


    getTicketStatus() {
        let url = '/api/administrator/crm/ticket/chatstatus'
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ statusList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    updateTicket() {
        const { ticketCategoryId, status, assignee, customer, poIdList, original_status, ticketname, ticketId } = this.state
        // let url = '/api/administrator/ticket/create';
        // if (ticketId) {
        let url = '/api/administrator/crm/ticket/chat/' + ticketId
        // }
        let data = {
            ticket_category_id: ticketCategoryId,
            status: status,
            assignee: assignee,
            // customer: customer,
            modify_by: this.props.administratorId,
            original_status: original_status,
            name: ticketname,
            po: JSON.stringify(poIdList)
        }
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    // getMessage(ticketId) {
    //     const url = '/api/administrator/crm/message/ticket/' + ticketId
    //     let storeDataString = localStorage.getItem(`massages-${ticketId}`);
    //     let storeData = []
    //     if (storeDataString) {
    //         storeData = JSON.parse(storeDataString)
    //     }

    //     get(url)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             if (response.length > 0) {
    //                 let fileList= []
    //                 let imageList= []
    //                 console.log('get message respone', response)
    //                 let receivedList = []
    //                 let messages = response.map(message => {
    //                     if (!message.received && message.user.permission == 'client') {
    //                         receivedList.push(message.id)
    //                         message.received = 1
    //                     }

    //                     let passIndex = storeData.findIndex(item => item.id == message.id)
    //                     if (passIndex != -1) {
    //                         storeData.splice(passIndex, 1);
    //                     }



    //                     const splitTexts = message.text.split('-')
    //                     const type = splitTexts[0]
    //                     if(type=='#PRODUCT' || type=='#CATEGORY_PRODUCT'){
    //                     if (message.imageName != null) {
    //                         console.log('testimg')
    //                         message['image'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${message.imageName}`
    //                         imageList.push(message['image'])
    //                     }
    //                     if (message.fileName != null) {
    //                         console.log('testimgfile')
    //                         message['file'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${message.fileName}`
    //                         imageList.push(message['file'])
    //                     }
    //                     } 

    //                     //     const firstChar = message.text ? message.text[0] : ''
    //                     //     console.log('firstChar',firstChar)
    //                     //     if (firstChar == '#') {
    //                     //         const splitTexts = message.text.split('-')
    //                     //         const type = splitTexts[0]
    //                     //         const id = splitTexts[1]
    //                     //         console.log('type',type)
    //                     //         if(type=='#PRODUCT' || type=='#CATEGORY_PRODUCT'){
    //                     //             console.log('testmmmi',message)
    //                     //             console.log('testmmmi',message.imageName)
    //                     //             console.log('testmmmf',message.fileName)
    //                     //             if(message.imageName !='null' || message.imageNaem != null ){
    //                     //                 message['image'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${message.imageName}`
    //                     //                 console.log('testimageNameif',message['image'])
    //                     //                 imageList.push(message['image'])
    //                     //             }else if(message.fileName !='null' || message.fileName != null ){

    //                     //                 console.log('mmmmfileName',message.fileName)
    //                     //                 message['image'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${message.fileName}`
    //                     //                 console.log('testfileNameelse',message['image'])
    //                     //                 imageList.push(message['image'])
    //                     //             }
    //                     //     }
    //                     // }
    //                     message['error'] = false
    //                     return message
    //                 })
    //                 // if (receivedList.length > 0) {
    //                 //     this.updateReceived(receivedList)
    //                 // } 
    //                 localStorage.setItem(`massages-${ticketId}`, JSON.stringify(storeData));
    //                 storeData.map(item => {
    //                     let temp = Object.assign({}, item)
    //                     temp['error'] = true
    //                     messages.unshift(temp)
    //                 })
    //                 this.setState({ messages: messages , imageList:imageList,fileList:fileList})
    //                 console.log('getMessage', imageList)
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })
    // }


    onClickRegister() {
        this.setState({
            visible: true
        })
    }

    typeListToElem() {
        let result = this.state.typeList.map((item, i) => <Option key={i} value={item.id}>{item.name}</Option>)
        return result
    }

    categoryListToElem() {
        let result = this.state.categoryList.map((item, i) => <Option key={i} value={item.id}>{item.name}</Option>)
        return result
    }

    statusListToElem() {
        let result = this.state.statusList.map((item, i) => <Option key={i} value={item.value} >{item.label}</Option>)
        return result
    }

    assigneeListToElem() {
        let result = this.state.assigneeList.map((item, i) => <Option key={i} value={item.id}>{item.name}</Option>)
        return result
    }

    purchaseOrderListToElem() {
        let result = this.state.purchaseOrderList.map((item, i) => <Option key={i} value={item.id}>{`${item.poNumber}/${item.name}`}</Option>)
        return result
    }

    onCloseCreateModal() {
        this.setState({ visibleCreateModal: false })
    }
    onClickOk() {
        const { originalStatus, status } = this.state
        // if (originalStatus == status) {
        //     message.warning('กรุณาเปลี่ยนสถานะ');
        //     return
        // }
        this.setState({
            closepage: true,
        })
        this.updateTicket()
    }
    onClickCancel() {
        this.setState({
            ticketCategoryId: null,
            ticketname: ''
        })
        this.props.backpage()
    }

    onCloseRegister() {
        const { ticketId } = this.state
        this.getTicketDetail(ticketId)
        this.getChatList()
        this.setState({ visible: false })
    }

    base64Encoder(text) {
        var bytes = utf8.encode(text);
        var encoded = base64.encode(bytes);
        return encoded
    }


    render() {
        const { ticketId, customer, assigneeList, modify, ticketname, imageList, fileList } = this.state
        const formItemLayout = {
            labelCol: {
                sm: { span: 24 },
                md: { span: 24 },
            },
            wrapperCol: {
                sm: { span: 24 },
                md: { span: 24 },
            },
        };
        let modifyName = ''
        if (assigneeList.length > 0 && modify) {
            let filter = assigneeList.filter(assignee => assignee.id == modify.adminId)
            if (filter.length > 0) {
                modifyName = filter[0].name
            }
        }
        return (
            <div className="chatroom-ticket">
                <div className='ticket-chat-page-wrapper'>
                    <div className="left-section-chat" >
                        <div className="left-section-chat-top-box" style={{ display: 'flex', padding: 5 }}>
                            {/* <Col md={24} lg={24} className="left-sec-chat-header" onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                                <Icon style={{ fontSize: 25, fontWeight: 500, color: '#000000', marginLeft: 5, marginRight: 17 }} type="left" />
                                <span style={{ fontSize: 20, fontWeight: 500, color: '#000000' }} >Chat Customer</span>
                            </Col> */}
                            <Button
                                style={{ margin: 5, color: '#ffffff', backgroundColor: '#47C5EB', width: '50%', }}
                                onClick={() => this.props.backpage()}
                            >
                                <Icon style={{ fontSize: 14, fontWeight: 500, color: '#fffffff' }} type="search" />
                                {"Search Chat"}
                            </Button>
                            <Button
                                style={{ margin: 5, color: '#ffffff', backgroundColor: '#17BF4F', width: '50%', }}
                                onClick={() => this.setState({ visibleCreateModal: true })}
                            >
                                <Icon style={{ fontSize: 14, fontWeight: 500, color: '#ffffff' }} type="plus" />
                                {"Create Chat"}
                            </Button>
                        </div>
                        <div className="left-sec-chat-list">
                            <ChatListContent
                                ticketId={ticketId}
                                onChangeTicket={(id) => this.onChangeTicket(id)}
                                chatList={this.state.chatList}
                            />
                        </div>
                    </div>

                    <div className="center-section-chat"  >
                        <div style={{display:'flex' , justifyContent:'space-between'}}>
                            <Col md={12} lg={12} className="center-sec-chat-header" onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                                <Icon style={{ fontSize: 18, fontWeight: 500, color: '#000000' }} type="left" />
                                <span style={{ fontSize: 18, fontWeight: 500, color: '#000000' }} >Chat Customer</span>
                            </Col>
                            <Col md={12} lg={12} className="center-sec-chat-header" onClick={() => this.setState({open: !this.state.open})} style={{ cursor: 'pointer',zIndex: 99,textAlign:'right' }}>
                                {this.state.open? <Icon style={{ fontSize: 18, fontWeight: 500, color: '#000000' }} type="right" /> : <Icon style={{ fontSize: 18, fontWeight: 500, color: '#000000' }} type="left" />}
                                {/* <span style={{ fontSize: 18, fontWeight: 500, color: '#000000' }} >X</span> */}
                            </Col>
                        </div>
                        <Chat
                            ticketId={ticketId}
                            customerId={customer ? customer.id ? customer.id : null : null}
                            closepage={this.closepage}
                            getChatList={() => this.getChatList()}
                            onClickRegister={() => this.onClickRegister()}
                            onChangeTicket={(id) => this.onChangeTicket(id)}
                        />
                    </div>

                    <div className="right-section-chat" style={{transform: this.state.width <1243 ? this.state.open ? 'translateX(0%)' : 'translateX(0%)' : 'translateX(0%)'  , width:this.state.width <1243 ? this.state.open ? '90%' : '0%' : '100%',backgroundColor:'#fff'}}>
                        <Tabs defaultActiveKey="1" className="right-tab-css" >
                            <TabPane tab="Staff" key="1">
                                <Row className='detail' >
                                    <div>
                                        <Col sm={24} md={24} lg={24}>
                                            <FormItem {...formItemLayout} >
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span style={{ fontSize: '20px', fontWeight: '700', paddingBottom: 5 }}>Ticket</span>
                                                    <span style={{ fontSize: '20px', color: '#000' }}>{ticketId ? `#${ticketId.toString().padStart(9, '0')}` : ''}</span>
                                                </div>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={24}>
                                            <FormItem {...formItemLayout} label={'Name'}>
                                                <Input value={this.state.ticketname} onChange={(event) => this.setState({ ticketname: event.target.value })}></Input>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={24}>
                                            <FormItem {...formItemLayout} label={'Status'} className="label-color">
                                                {/* <Select
                                                    value={this.state.status}
                                                    style={{ width: '100%' }}
                                                    placeholder={'please select ...'}
                                                    onChange={(value) => this.setState({ status: value })}
                                                >
                                                    {this.statusListToElem()}
                                                </Select> */}
                                                {this.state.statusList.map(item => {
                                                    return (
                                                        <div
                                                            style={{
                                                                cursor: 'pointer',
                                                                border:  item.value != 'wait customer' ? '1px solid #F5F5F5' : '1px solid #FFD031',
                                                                borderRadius: 5,
                                                                margin: 2,
                                                                backgroundColor : item.value != 'wait customer' ? '#ffffff':'#FFF9E4',
                                                                color : item.value != 'wait customer' ? '#000000':'#FFD031'

                                                            }}
                                                            onClick={() => this.setState({ status: item.value })}>
                                                            <p
                                                                style={{
                                                                    margin: 7,
                                                                    textAlign: 'left',
                                                                }}>
                                                                <span style={{ marginRight: 10, }}><Checkbox checked={this.state.status == item.value} /></span>
                                                                <span>{item.label}</span>
                                                            </p>
                                                        </div>
                                                    )
                                                })}
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={24} style={{ display: this.state.type == null ? 'none' : 'block' }}>
                                            <FormItem {...formItemLayout} label={'ประเภทงาน'}>
                                                <Select
                                                    value={this.state.type}
                                                    style={{ width: '100%', backgroundColor: 'red' }}
                                                    placeholder={'please select ...'}
                                                    onChange={(value) => this.setState({ type: value })}
                                                    disabled={true}
                                                >
                                                    {this.typeListToElem()}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={24}>
                                            <FormItem {...formItemLayout} label={'Admin'}>
                                                <Select
                                                    value={this.state.assignee}
                                                    style={{ width: '100%' }}
                                                    placeholder={'please select ...'}
                                                    onChange={(value) => this.setState({ assignee: value })}
                                                >
                                                    {this.assigneeListToElem()}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={24}>
                                            <FormItem {...formItemLayout} label={'Categories'}>
                                                <Select
                                                    value={this.state.ticketCategoryId}
                                                    style={{ width: '100%' }}
                                                    placeholder={'please select ...'}
                                                    onChange={(value) => this.setState({ ticketCategoryId: value })}
                                                >
                                                    {this.categoryListToElem()}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={24}>
                                            <FormItem {...formItemLayout} label={'Last updated datetime'}>
                                                <span>{modify ? modify.datetime ? moment(modify.datetime).local().format('YYYY-MM-DD HH:mm:ss') : '' : ''}</span>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={24}>
                                            <FormItem {...formItemLayout} label={'Last updated by'}>
                                                <span>{modify ? modify.adminId : ''}</span>
                                            </FormItem>
                                        </Col>
                                    </div>
                                    <div style={{ borderTop: '0.5px dashed #e7eef0', paddingTop: '10px' }}>
                                        <Col sm={24} md={24} lg={24}>
                                            <Row className='detail' type="flex" justify="space-around" align="middle" style={{ padding: '0px' }}>
                                                <Col sm={24} md={24} lg={24}>
                                                    <h4 style={{ fontWeight: 'bold', margin: '0', color: '#8c8c8c' }}>{"Note"}</h4>
                                                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }} >
                                                        <Col sm={24} md={20} lg={20}>
                                                            <Input style={{ borderRadius: '0' }} value={this.state.noteAdministrator} onChange={(event) => this.setState({ noteAdministrator: event.target.value })} />
                                                        </Col>
                                                        <Col sm={24} md={4} lg={4}>
                                                            <Button
                                                                disabled={this.state.noteAdministrator != "" ? false : true}
                                                                onClick={() => this.updateAdministratorNote()}
                                                                style={{ backgroundColor: this.state.noteAdministrator != '' ? 'rgb(72, 197, 235)' : '#ececec', color: this.state.noteAdministrator != '' ? '#ffffff' : '#6e6e6e', border: this.state.noteAdministrator != '' ? '1px solid rgb(72, 197, 235)' : '#ececec', width: '100%', borderRadius: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                            >
                                                                {'Post'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    {this.state.administratorNote.map(note => {
                                                        return (
                                                            <Row className="note-container">
                                                                <Col sm={24} md={24} lg={24}>
                                                                    <span className="note-text2">{note.note}</span>
                                                                </Col>
                                                                <Col sm={24} md={24} lg={24} style={{ overflow: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    <span className="note-time">{moment(note.create_datetime).subtract({ hour: 7 }).format('DD-MM-YYYY HH:mm')} </span><br></br>
                                                                    <span className="note-text1"> โดย : {note.update_by_name}</span>
                                                                </Col>

                                                            </Row>
                                                        )
                                                    })}

                                                </Col>
                                            </Row>
                                        </Col>
                                    </div>
                                </Row>
                                <Row className='btn-section' gutter={16}>
                                    <Col sm={12} md={12} lg={12} >
                                        <Button style={{ marginLeft: 10, marginRight: 10, backgroundColor: '#ffffff', color: '#F5222D', border: '1px solid #F5222D', width: '100%' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Button style={{ marginLeft: 10, marginRight: 10, backgroundColor: '#07941E', color: '#ffffff', border: '1px solid #07941E', width: '100%' }} onClick={() => this.onClickOk()}>{'Save'}</Button>
                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tab="Customer" key="2">
                                <Row className="detail" >
                                    <div className='image-wrapper'>
                                        <img
                                            width={100} height={100}
                                            src={this.state.customer && this.state.customer.avatar.length ? this.state.customer.avatar[0].view : null}
                                            onError={(image) => image.target.src = defaultSquareSrc}
                                        />
                                    </div>
                                    <p style={{ fontSize: 25, margin: 10 }} >{this.state.customer ? this.state.customer.username : ''}</p>
                                    <div className="tag-section">
                                        <TicketTag ticketId={ticketId} />
                                    </div>
                                    <div className='divider' />
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'left', paddingLeft: '50px' }}>
                                        <Icon type="phone" style={{ padding: 5, marginRight: '20px' }} />
                                        <p style={{ margin: 0 }}>{this.state.customer ? this.state.customer.telephone : ''}</p>
                                    </Col>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'left', paddingLeft: '50px' }}>
                                        <Icon type="mail" style={{ padding: 5, marginRight: '20px' }} />
                                        <p style={{ margin: 0 }}>{this.state.customer ? this.state.customer.email : ''}</p>
                                    </Col>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                        <div className='divider' />
                                        <button className='edit-button' onClick={() => this.onClickRegister()} style={{ display: this.state.customer && this.state.customer.username ? 'unset' : 'none' }}>{'Edit Profile Customer'}</button>
                                        {/* <div className='divider' style={{ display: this.state.customer && this.state.customer.username ? 'unset' : 'none' }} /> */}
                                        <TicketProduct ticketId={ticketId} />

                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tab="File" key="3">
                                <TicketAttachment ticketId={ticketId} />
                            </TabPane>
                        </Tabs>
                    </div>
                    <TicketModal
                        visible={this.state.visibleCreateModal}
                        onClose={() => this.onCloseCreateModal()}
                    />
                    <Register
                        type={this.state.customer ? this.state.customer.username ? 'edit' : 'update' : 'update'}
                        customer={this.state.customer}
                        visible={this.state.visible}
                        userName={this.props.administratorId}
                        onClose={() => this.onCloseRegister()}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    userName: state.login.username,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(Ticket);
