
const colorPrimary = '#48c5eb'
const buttonStyle = {
    borderRadius: '3px',
    fontSize: '18px',
    padding: '5px',
    cursor: 'pointer',
}
const initialState = {
    color: {
        primary: colorPrimary
    },

    button: {
        primary: {
            ...buttonStyle,
            color: '#ffffff',
            backgroundColor: colorPrimary,
        }
    }

};

const theme = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COLOR_PRIMARY': {
            let color = Object.assign(state.color, { primary: action.payload })

            let buttonPrimary = Object.assign(state.button.primary, { backgroundColor: action.payload })
            let button = Object.assign(state.button, { primary: buttonPrimary })
            
            return Object.assign({}, state, { color: color, button: button })
        }
        default:
            return state;
    }
}

export default theme;