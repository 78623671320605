import React from 'react';
import { Col, Row, DatePicker, Button, Form, Icon, notification, Switch, Modal, Select, Input } from 'antd'
import { notiSuccess, notiRemove } from '../../../actions/notification';
import { connect } from 'react-redux';
import { post, get } from '../../../utils';

notification.config({
    placement: 'topRight',
    duration: 1,
});

const FormItem = Form.Item;
const Option = Select.Option;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const formItemLayout = {
    labelCol: {
        sm: { span: 6 },
        md: { span: 6 },
    },
    wrapperCol: {
        sm: { span: 18 },
        md: { span: 18 },
    },
};

class TicketModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: null,
            categoryList: [],
            ticketname: '',
            status: 'wait administrator',
            customer: 1,
            customerList: []

        }
    }



    componentDidMount() {
        this.getCustommer()
        this.getcategoryList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible != this.props.visible) {
            this.getCustommer()
            this.getcategoryList()
        }
    }
    getCustommer() {
        let url = '/api/crm/administrator/user/customer';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ customerList: response })
            })
            .catch(err => {
                console.log(err.message)
            })

    }

    getcategoryList() {
        let url = '/api/category/ticket';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ categoryList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    CreateTicket() {
        let { category, ticketname, customer } = this.state
        if (!category || category == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Catagory".')
            return
        }
        let data = {
            category: category,
            name: ticketname,
            customer: customer,
        }
        let url = '/api/administrator/crm/ticket/chat';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.onClose()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    render() {
        const { visible } = this.props
        return (
            <Modal
                visible={visible}
                title={"Create Ticket chat"}
                onOk={this.CreateTicket}
                onCancel={() => this.props.onClose()}
                closable={false}
                style={{ textAlign: 'center' }}
                footer={[
                    <Button key="back" onClick={() => this.props.onClose()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={() => this.CreateTicket()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                        Create
                    </Button>,
                ]}
            >
                {/* <FormItem {...formItemLayout} label={'Catagory'} >
                    <Select
                        value={this.state.category}
                        onChange={(value) => this.setState({ category: value })}
                        style={{ width: '100%', fontSize: '50px' }}
                    >
                        {this.state.categoryList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                    </Select>
                </FormItem> */}
                <FormItem {...formItemLayout} label={'Catagory'} required={true} >
                    <div style={{ textAlign: 'left' }}>
                        {this.state.categoryList.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    style={{
                                        margin : 2,
                                        color: this.state.category == item.id ? '#ffffff' : '#000000',
                                        backgroundColor: this.state.category == item.id ? '#17BF4F' : '#ffffff'
                                    }}
                                    onClick={() => this.setState({ category: item.id })}
                                >
                                    {item.name}
                                </Button>
                            )
                        })}
                    </div>
                </FormItem>
                <FormItem {...formItemLayout} label={'Name ticket'} required={true}>
                    <Input
                        value={this.state.ticketname}
                        onChange={(event) => this.setState({ ticketname: event.target.value })}
                    />
                </FormItem>
                <FormItem {...formItemLayout} label={'Customer'} required={true}>
                    <Select
                        value={this.state.customer}
                        onChange={(value) => this.setState({ customer: value })}
                        style={{ width: '100%' }}
                    >
                        {this.state.customerList.map((item, index) => <Option key={index} value={item.id}>{item.Name}</Option>)}
                    </Select>
                </FormItem>
            </Modal>
        )
    }
}
const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    userName: state.login.username,
})


export default connect(mapStateToProps)(TicketModal);
