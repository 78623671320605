import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';
import './style.scss'

class LoadingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    handleOk() {
        this.props.onClose()
    }

    handleCancel() {
        this.props.onClose()
    }

    render() {
        const { visible } = this.props
        return (
            <div className="home-page-wrapper">
                <Modal
                    title={null}
                    closable = {false}
                    maskClosable = {false}
                    visible={visible}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    footer={null}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Spin tip="Loading..." />
                    </div>
                </Modal>
            </div>
        )
    }
}

LoadingModal.propTypes = {
    onClose: PropTypes.func,
}

LoadingModal.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    roleName: state.login.roleName,
})

export default connect(mapStateToProps)(LoadingModal);

