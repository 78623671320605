import React from 'react';
import { Row, Col, Button, notification, Checkbox } from 'antd';
import { get, post, patch, ppost } from '../../../utils';
import { connect } from 'react-redux';

import ProductLayoutButtonModal from './component/ProductLayoutButtonModal'

import productDetailLayoutButtonOne from '../../../resource/screen-mobile/product-detail-layout-button/product-detail-layout-button-one.png'
import productDetailLayoutButtonTwo from '../../../resource/screen-mobile/product-detail-layout-button/product-detail-layout-button-two.png'
import productDetailLayoutButtonThree from '../../../resource/screen-mobile/product-detail-layout-button/product-detail-layout-button-three.png'
import productDetailLayoutButtonFour from '../../../resource/screen-mobile/product-detail-layout-button/product-detail-layout-button-four.png'

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class ProductDetailLayoutButtonScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            productDetailLayoutButton: 1,
            homeLayoutList: [
                { value: 1, name: 'Home layout menu one', image: productDetailLayoutButtonOne, size: 0 },
                { value: 2, name: 'Home layout menu two', image: productDetailLayoutButtonTwo, size: 1 },
                { value: 3, name: 'Home layout menu three', image: productDetailLayoutButtonThree, size: 2 },
                { value: 4, name: 'Home layout menu four', image: productDetailLayoutButtonFour, size: 2 },
            ],

            settingLayoutList: [],
            settingLayoutTypeList: [

                { value: 'follow', label: 'Follow' },
                { value: 'add_to_cart', label: 'Add to cart' },
                { value: 'chat', label: 'Chat' },
                { value: 'buy_now', label: 'Buy now' },
            ],
        }
    }


    getSettingHomeLayoutMenu() {
        let url = '/api/setting/product/detail/layout/button';
        get(url)
            .then(responses => {
                this.setState({ settingLayoutList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    getSettingScreenMobile() {
        let url = '/api/crm/administrator/setting/screen/mobile';
        get(url)
            .then(response => {
                this.setState({
                    settingScreenMobileId: response.id,
                    productDetailLayoutButton: response.product_detail_layout_button
                })
                this.getSettingHomeLayoutMenu()
            })
            .catch(err => {
                console.log(err.message)
            })
    }



    componentDidMount() {
        this.getSettingScreenMobile()
    }

    updateSettingHomeLayoutMenu() {
        const { settingScreenMobileId, productDetailLayoutButton } = this.state
        let url = '/api/crm/administrator/setting/screen/mobile/' + settingScreenMobileId + '/product/detail/layout/button';
        let data = { product_detail_layout_button: productDetailLayoutButton }
        patch(url, data)
            .then(response => {
                this.updateSettingHomeLayoutMenuDetail()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async updateSettingHomeLayoutMenuDetail() {
        let settingLayoutList = await Promise.all(this.state.settingLayoutList.map(async (item) => {
            let temp = {
                layout_number: item.layout_number,
                layout_type: item.layout_type,
                name: item.name,
            } 
            return temp
        }))
        let data = {
            settingLayoutList: JSON.stringify(settingLayoutList)
        }
        let url = '/api/setting/product/detail/layout/button'
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save setting screen mobile home swipe success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

 

    updateLayout(item) {
        let settingLayoutList = this.state.settingLayoutList.map(item => Object.assign({}, item)).slice(0, item.size)
        for (let index = settingLayoutList.length; index < item.size; index++) {
            settingLayoutList.push({
                layout_number: index,
                layout_type: null,
                name: null,
                imageList: []
            })
        }
        this.setState({
            productDetailLayoutButton: item.value,
            settingLayoutList: settingLayoutList,
            visible: item.size === 0 ? false : true,
        })
    }

    onClickOk() {
        this.updateSettingHomeLayoutMenu()
    }
    onClickCancel() {
        this.getSettingHomeLayoutMenu()
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    render() {
        return (
            <div >
                <Row >
                    <Col span={12} style={{ textAlign: 'left' }}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <div className="mobile-screen-content-wrapper">
                    {this.state.homeLayoutList.map(item => {
                        return (
                            <div className="item-mobile-screen-wrapper">
                                <Checkbox
                                    className='item-mobile-screen-checkbox-wrapper'
                                    checked={item.value == this.state.productDetailLayoutButton}
                                    onChange={() => this.updateLayout(item)}
                                />
                                <div
                                    className='item-mobile-screen-image-wrapper'
                                    onClick={() => this.updateLayout(item)}
                                >
                                    <img
                                        src={item.image}
                                        className='item-mobile-screen-image'
                                        style={{ border: item.value == this.state.productDetailLayoutButton ? '4px solid #48c5eb' : 'none' }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <ProductLayoutButtonModal
                    visible={this.state.visible}
                    settingLayoutList={this.state.settingLayoutList}
                    settingLayoutTypeList={this.state.settingLayoutTypeList}
                    updateSettingLayoutList={(value) => this.setState({ settingLayoutList: value })}
                    onClose={() => this.setState({ visible: false })}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(ProductDetailLayoutButtonScreen);