import React, { Component } from 'react'
import { connect } from 'react-redux';
import { clearUserAdministratorIdForgotPassword } from '../../actions/forgotpassword';
import { post } from '../../utils';
import { Row, Col, notification, Input, Icon } from 'antd';
import { Helmet } from "react-helmet";
import logoCodexConnectWhite from '../../resource/Logo/logo-codex-connect-white.png'
import forgetpasspic from '../../resource/assets/forgetpasspic.png'
import codexConnect from '../../resource/Logo/logoCodexConnect.png'
import './style.scss'
import { Link } from 'react-router-dom'

notification.config({
    placement: 'topRight',
    duration: 1,
});

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            confirmPassword: ''
        }
    }

    handleClick() {
        const password = this.refs.password
        const confirmPassword = this.refs.confirmPassword
        if (this.state.password == '') {
            openNotificationWithIcon('error', 'Incorrect information entered.', 'Please enter "Password".')
            password.focus()
            return
        }
        if (this.state.password.length < 6 && this.state.password != '') {
            openNotificationWithIcon('error', 'Incorrect information entered.', 'Please enter the password to complete 6 digits.')
            confirmPassword.focus()
            return
        }
        if (this.state.password != this.state.confirmPassword) {
            openNotificationWithIcon('error', 'Incorrect information entered.', 'Enter enter the "Password" that matches the "Confirm Password"')
            confirmPassword.focus()
            return
        }
        let url = "/public/crm/administrator/user/administrator/forgotpassword/changepassword";
        let data = {
            password: this.state.password,
            user_administrator_id: this.props.userAdministratorId,
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    openNotificationWithIcon('success', 'Change Password.', 'Change password success.')
                    this.props.clearUserAdministratorIdForgotPassword()
                    this.props.history.push('/')
                } else {
                    openNotificationWithIcon('error', 'Change Password.', 'Change password failed. Try the transaction again.')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleEnterKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleClick()
        }
    }

    render() {
        const { colorTheme, buttonTheme } = this.props
        return (
            <Row className='forgetpassword-page-wrapper'>
                <Helmet>
                    <title>{"Change Password"}</title>
                </Helmet>
                <Col className='left-colunm-wrapper' span={10}>
                    <Link to={"/forgot_password/validateotp"}>
                        <div className='logo'>
                            <div>
                                <Icon style={{ fontSize: 50, fontWeight: 500, color: '#fff' }} type="left" />
                            </div>
                            <div>
                                <img width={300} src={logoCodexConnectWhite} />
                            </div>
                        </div>
                    </Link>
                    <p className='sub-title'>Change Password</p>
                    <img width={350}
                        className='left-pic'
                        src={forgetpasspic}
                    />
                    <div className="login-footer">
                        <p>Copyright &#169; 2020 Codex Digital Partner Co.,Ltd. All rights reserved.</p>
                    </div>
                </Col>
                <Col className='right-colunm-wrapper' span={14}>
                    <div className='container' >

                        <div className='input-group'>
                            <div className='logo'>
                                <img width={350} src={codexConnect} />
                            </div>
                            <p className='title' style={{ color: colorTheme.primary }}>Enter New password</p>
                            <p className="sub-title">Please enter a new password and confirm the password</p>
                            <Input
                                required
                                size="large"
                                className="input-login"
                                type='text'
                                ref='password'
                                placeholder="Password"
                                value={this.state.password}
                                onChange={(event) => this.setState({ password: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="lock" style={{ color: colorTheme.primary }} />}


                            />
                            <Input
                                required
                                size="large"
                                className="input-login"
                                type='text'
                                placeholder="Confirm password"
                                ref='confirmPassword'
                                value={this.state.confirmPassword}
                                onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="lock" style={{ color: colorTheme.primary }} />}
                            />
                            <p className='sub-title-2-forgot'><span className="circle"></span>{'Enter 6 or more characters'}</p>
                            <p className='sub-title-2-forgot'><span className="circle"></span>{'Please use only letters (a-z), number (0-9)'}</p>

                            <button
                                //  style={buttonTheme.primary}
                                onClick={() => this.handleClick()}
                            >
                                {'OK'}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }

}

const mapStateToProps = (state) => ({
    colorTheme: state.theme.color,
    buttonTheme: state.theme.button,
    userAdministratorId: state.forgotpassword.userAdministratorId
})

const mapDispatchToProps = (dispatch) => ({
    clearUserAdministratorIdForgotPassword: () => dispatch(clearUserAdministratorIdForgotPassword())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

