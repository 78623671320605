import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, Select } from 'antd';
// import CustomCircularProgressbar from '../../components/CustomCircularProgressbar';
import CustomAreaCharts from '../../components/CustomAreaCharts';
import CustomAreaChartsSkeleton from '../../components/CustomAreaCharts/CustomAreaChartsSkeleton';

import { post, get, put } from '../../utils';
import moment from 'moment';

const { Option } = Select;

class DashboardAdministrator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfInterval : 0,
            chartsNoStaff: null,

            // chartsAssigned: null,
            // chartsWaitingStaff: null,
            // chartsInProgress: null,
            // chartsWaitingCustomers: null,
            // chartsComplete: null,
        }
        this.initializeChartsInterval = null
    }

    componentDidMount() {
        const { tempFilter } = this.props
        let date = null
        let dateList = []
        switch (tempFilter) {
            case 'day':
                let previous = moment().subtract(1, 'days').format('YYYY-MM-DD')
                date = moment().format('YYYY-MM-DD')
                dateList.push(previous)
                dateList.push(date)
                break;
            case 'week':
                date = moment().subtract(1, 'weeks').format('YYYY-MM-DD')
                for (let indexWeek = 0; indexWeek < 7; indexWeek += 1) {
                    let tempDate = moment().subtract(indexWeek + 1, 'days').format('YYYY-MM-DD')
                    dateList.push(tempDate);
                }
                break;
            case 'month':
                date = moment().subtract(1, 'months').format('YYYY-MM-DD')
                for (let indexMonth = 0; indexMonth < 30; indexMonth += 7) {
                    let tempDate = moment().subtract(indexMonth + 1, 'days').format('YYYY-MM-DD')
                    dateList.push(tempDate);
                }
                break;
            case 'year':
                date = moment().subtract(1, 'years').format('YYYY-MM-DD')
                for (let indexYear = 0; indexYear < 365; indexYear += 30) {
                    let tempDate = moment().subtract(indexYear + 1, 'days').format('YYYY-MM-DD')
                    dateList.push(tempDate);
                }
                break;
        }

        this.getChartsNoAssignPercentage(date)
        this.getChartsNoAssignGraph(dateList)

        this.getChartsYourChatPercentage(date)
        this.getChartsYourChatGraph(dateList)

        this.getChartsWaitingYouPercentage(date)
        this.getChartsWaitingYouGraph(dateList)

        this.getChartsInProgressPercentage(date)
        this.getChartsInProgressGraph(dateList)

        this.getChartsWaitingCustomerPercentage(date)
        this.getChartsWaitingCustomerGraph(dateList)

        this.getChartsCompletePercentage(date)
        this.getChartsCompleteGraph(dateList)


        this.initializeChartsInterval = setInterval(() => {
            if (!this.state.chartsNoStaff || !this.state.chartsNoStaff.dataGraphList) {
                this.getChartsNoAssignPercentage(date)
                this.getChartsNoAssignGraph(dateList)
            }
            if (!this.state.chartsYourChat || !this.state.chartsYourChat.dataGraphList) {
                this.getChartsYourChatPercentage(date)
                this.getChartsYourChatGraph(dateList)
            }
            if (!this.state.chartsWaitingYou || !this.state.chartsWaitingYou.dataGraphList) {
                this.getChartsWaitingYouPercentage(date)
                this.getChartsWaitingYouGraph(dateList)
            }
            if (!this.state.chartsInProgress || !this.state.chartsInProgress.dataGraphList) {
                this.getChartsInProgressPercentage(date)
                this.getChartsInProgressGraph(dateList)
            }
            if (!this.state.chartsWaitingCustomer || !this.state.chartsWaitingCustomer.dataGraphList) {
                this.getChartsWaitingCustomerPercentage(date)
                this.getChartsWaitingCustomerGraph(dateList)
            }
            if (!this.state.chartsComplete || !this.state.chartsComplete.dataGraphList) {
                this.getChartsCompletePercentage(date)
                this.getChartsCompleteGraph(dateList)
            }

            if (this.state.numberOfInterval > 10) {
                clearInterval(this.initializeChartsInterval);
            } else {
                this.setState({ numberOfInterval: this.state.numberOfInterval + 1 })
            }
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.initializeChartsInterval)
    }
    getChartsNoAssignPercentage(date) {
        let url = '/api/crm/administrator/home/administrator/charts/noassign/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsNoStaff = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsNoStaff: chartsNoStaff })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsNoAssignGraph(dateList) {
        let url = '/api/crm/administrator/home/administrator/charts/noassign/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsNoStaff = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsNoStaff)
                    this.setState({ chartsNoStaff: chartsNoStaff })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsYourChatPercentage(date) {
        let url = '/api/crm/administrator/home/administrator/charts/yourchat/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsYourChat = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsYourChat: chartsYourChat })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsYourChatGraph(dateList) {
        let url = '/api/crm/administrator/home/administrator/charts/yourchat/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsYourChat = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsYourChat)
                    this.setState({ chartsYourChat: chartsYourChat })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingYouPercentage(date) {
        let url = '/api/crm/administrator/home/administrator/charts/waitingyou/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingYou = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsWaitingYou: chartsWaitingYou })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingYouGraph(dateList) {
        let url = '/api/crm/administrator/home/administrator/charts/waitingyou/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingYou = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsWaitingYou)
                    this.setState({ chartsWaitingYou: chartsWaitingYou })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsInProgressPercentage(date) {
        let url = '/api/crm/administrator/home/administrator/charts/inprogress/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsInProgress = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsInProgress: chartsInProgress })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsInProgressGraph(dateList) {
        let url = '/api/crm/administrator/home/administrator/charts/inprogress/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsInProgress = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsInProgress)
                    this.setState({ chartsInProgress: chartsInProgress })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingCustomerPercentage(date) {
        let url = '/api/crm/administrator/home/administrator/charts/waitingcustomer/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingCustomer = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsWaitingCustomer: chartsWaitingCustomer })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsWaitingCustomerGraph(dateList) {
        let url = '/api/crm/administrator/home/administrator/charts/waitingcustomer/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsWaitingCustomer = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsWaitingCustomer)
                    this.setState({ chartsWaitingCustomer: chartsWaitingCustomer })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsCompletePercentage(date) {
        let url = '/api/crm/administrator/home/administrator/charts/complete/percentage';
        let data = { date: date }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsComplete = {
                        value: response.result.value,
                        percentage: response.result.percentage,
                        progressType: response.result.progressType,
                    }
                    this.setState({ chartsComplete: chartsComplete })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getChartsCompleteGraph(dateList) {
        let url = '/api/crm/administrator/home/administrator/charts/complete/graph';
        let data = { dateList: JSON.stringify(dateList) }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    let chartsComplete = Object.assign({ dataGraphList: response.dataGraphList }, this.state.chartsComplete)
                    this.setState({ chartsComplete: chartsComplete })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    render() {
        const { chartsNoStaff, chartsYourChat, chartsWaitingYou, chartsInProgress, chartsWaitingCustomer, chartsComplete } = this.state
        const { name, tempFilter } = this.props
        return (
            <div className='dashboard-administrator-wrapper'>
                <div className="section1-dashboard">
                    <p className='title'>Statistics Chat Status ( {name} )</p>
                    <div className="circle-chart">
                        <Col sm={24} md={24} lg={24} style={{ padding: '0px 5px' }}>
                            {chartsNoStaff && chartsNoStaff.dataGraphList ?
                                < CustomAreaCharts
                                    title={'No Staff'}
                                    value={chartsNoStaff ? chartsNoStaff.value : 0}
                                    progressType={chartsNoStaff ? chartsNoStaff.progressType : 'increase'}
                                    percentage={chartsNoStaff ? chartsNoStaff.percentage : 0}
                                    dataGraphList={chartsNoStaff ? chartsNoStaff.dataGraphList : []}
                                    primaryColor={'#91C8FB'}
                                    secondaryColor={'#F1F9FF'}
                                    filterBy={tempFilter}
                                />
                                : <CustomAreaChartsSkeleton />}
                        </Col>
                        <Col sm={24} md={24} lg={24} style={{ padding: '0px 5px' }}>
                            {chartsYourChat && chartsYourChat.dataGraphList ?
                                < CustomAreaCharts
                                    title={'Your Chat'}
                                    value={chartsYourChat ? chartsYourChat.value : 0}
                                    progressType={chartsYourChat ? chartsYourChat.progressType : 'increase'}
                                    percentage={chartsYourChat ? chartsYourChat.percentage : 0}
                                    dataGraphList={chartsYourChat ? chartsYourChat.dataGraphList : []}
                                    primaryColor={'#FEDD78'}
                                    secondaryColor={'#FEF6DC'}
                                    filterBy={tempFilter}
                                />
                                : <CustomAreaChartsSkeleton />}
                        </Col>
                        <Col sm={24} md={24} lg={24} style={{ padding: '0px 5px' }}>
                            {chartsWaitingYou && chartsWaitingYou.dataGraphList ?
                                < CustomAreaCharts
                                    title={'Waiting You'}
                                    value={chartsWaitingYou ? chartsWaitingYou.value : 0}
                                    progressType={chartsWaitingYou ? chartsWaitingYou.progressType : 'increase'}
                                    percentage={chartsWaitingYou ? chartsWaitingYou.percentage : 0}
                                    dataGraphList={chartsWaitingYou ? chartsWaitingYou.dataGraphList : []}
                                    primaryColor={'#ED2E2C'}
                                    secondaryColor={'#FCDDD7'}
                                    filterBy={tempFilter}
                                />
                                : <CustomAreaChartsSkeleton />}
                        </Col>
                        <Col sm={24} md={24} lg={24} style={{ padding: '0px 5px' }}>
                            {chartsInProgress && chartsInProgress.dataGraphList ?
                                < CustomAreaCharts
                                    title={'In Progress'}
                                    value={chartsInProgress ? chartsInProgress.value : 0}
                                    progressType={chartsInProgress ? chartsInProgress.progressType : 'increase'}
                                    percentage={chartsInProgress ? chartsInProgress.percentage : 0}
                                    dataGraphList={chartsInProgress ? chartsInProgress.dataGraphList : []}
                                    primaryColor={'#35932E'}
                                    secondaryColor={'#F6FAF5'}
                                    filterBy={tempFilter}
                                />
                                : <CustomAreaChartsSkeleton />}
                        </Col>
                        <Col sm={24} md={24} lg={24} style={{ padding: '0px 5px' }}>
                            {chartsWaitingCustomer && chartsWaitingCustomer.dataGraphList ?
                                < CustomAreaCharts
                                    title={'Waiting Customer'}
                                    value={chartsWaitingCustomer ? chartsWaitingCustomer.value : 0}
                                    progressType={chartsWaitingCustomer ? chartsWaitingCustomer.progressType : 'increase'}
                                    percentage={chartsWaitingCustomer ? chartsWaitingCustomer.percentage : 0}
                                    dataGraphList={chartsWaitingCustomer ? chartsWaitingCustomer.dataGraphList : []}
                                    primaryColor={'#FF7E35'}
                                    secondaryColor={'#FFEEE7'}
                                    filterBy={tempFilter}
                                />
                                : <CustomAreaChartsSkeleton />}
                        </Col>
                        <Col sm={24} md={24} lg={24} style={{ padding: '0px 5px' }}>
                            {chartsComplete && chartsComplete.dataGraphList ?
                                < CustomAreaCharts
                                    title={'Complete by you'}
                                    value={chartsComplete ? chartsComplete.value : 0}
                                    progressType={chartsComplete ? chartsComplete.progressType : 'increase'}
                                    percentage={chartsComplete ? chartsComplete.percentage : 0}
                                    dataGraphList={chartsComplete ? chartsComplete.dataGraphList : []}
                                    primaryColor={'#33B6B6'}
                                    secondaryColor={'#ECF7F7'}
                                    filterBy={tempFilter}
                                />
                                : <CustomAreaChartsSkeleton />}
                        </Col>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    name: state.login.name,
})

export default connect(mapStateToProps)(DashboardAdministrator);

