import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';
import { Icon } from 'antd'
import './style.scss'

class CustomAreaChartsExtra extends PureComponent {

    render() {
        const { title, value, progressType, percentage, primaryColor, secondaryColor, filterBy, dataGraphList, dataDetailList } = this.props
        let remarkMessage = ''
        switch (filterBy) {
            case 'year':
                remarkMessage = 'Compared to last 365 days'
                break;
            case 'month':
                remarkMessage = 'Compared to last 30 days'
                break;
            case 'week':
                remarkMessage = 'Compared to last 7 days'
                break;
            default:
                remarkMessage = 'Compared to last 1 days'
                break;
        }
        console.log("dataGraphList", dataGraphList)
        return (
            <div className='customareachartsextra-component-wrapper' >
                <div className='header-wrapper'>
                    <p className='title'>{title}</p>
                    <div className='value-wrapper'>
                        <p className='value'>{value}</p>
                        {progressType == 'increase' ?
                            <p className='percentage-increase'><Icon type="caret-up" style={{ color: '#51CF7A' }} /> {`${percentage}%`}</p>
                            :
                            <p className='percentage-decrease'><Icon type="caret-down" style={{ color: '#EB3C19' }} /> {`${percentage}%`}</p>
                        }
                    </div>
                    <p className='remark'>{remarkMessage}</p>
                </div>
                <div className='graph-wrapper'>
                    <ResponsiveContainer width={'100%'} height={150}>
                        <AreaChart
                            data={dataGraphList}
                            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                        >
                            <Area
                                type={'temperature'}
                                dataKey={'total'}
                                stroke={primaryColor}
                                fill={secondaryColor} />
                        </AreaChart>
                    </ResponsiveContainer >
                </div>
                <div className='datadetaillist-wrapper'>
                    {dataDetailList.map((item, index) => {
                        return (
                            <div key={index} className='item-datadetaillist-wrapper'>
                                <p className = 'name'>{item.name}</p>
                                {item.progressType == 'increase' ?
                                    <p className='value-increase'><Icon type="caret-up" style={{ color: '#51CF7A' }} /> {`${item.value} ${item.unit}`}</p>
                                    :
                                    <p className='value-decrease'><Icon type="caret-down" style={{ color: '#EB3C19' }} /> {`${item.value} ${item.unit}`}</p>
                                } 
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        );
    }
}

CustomAreaChartsExtra.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,

    progressType: PropTypes.string,
    percentage: PropTypes.string,

    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,

    filterBy: PropTypes.string,
}

CustomAreaChartsExtra.defaultProps = {
    title: '',
    value: '',

    progressType: 'increase',
    percentage: 0,

    primaryColor: '#47C5EB',
    secondaryColor: '#B8E8F7',

    filterBy: 'day',
    dataGraphList: [],
    dataDetailList: []
};


export default CustomAreaChartsExtra;
