import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { initializeRole } from "../../../actions/role";
import { Form, Input, Card, Row, Col, Button, Table, Checkbox, Icon,notification } from 'antd';
import { get, post,put } from '../../../utils';
import './style.scss'
import { roleList } from '../../../routes';

notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item; 

class UserRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            originalName : null,
            description: '',
            dataSource: [],

            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'left ',
                    width: '55%'
                },
                {
                    title: 'View',
                    dataIndex: 'view',
                    key: 'view',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => <Checkbox checked={item.view} onChange={(event) => this.onChangeCheckbox(event.target.checked, index, 'view')} />
                },
                {
                    title: 'Edit',
                    dataIndex: 'edit',
                    key: 'edit',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => <Checkbox checked={item.edit} onChange={(event) => this.onChangeCheckbox(event.target.checked, index, 'edit')} />
                },
                {
                    title: 'Export',
                    dataIndex: 'export',
                    key: 'export',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => <Checkbox checked={item.export} onChange={(event) => this.onChangeCheckbox(event.target.checked, index, 'export')} />
                },
            ]
        }
    }


    onChangeCheckbox(checked, index, name) {
        let dataSource = Object.assign([], this.state.dataSource)
        dataSource[index][name] = checked
        this.setState({ dataSource: dataSource })
    }

    getRoleDetail() {
        const { editId } = this.props
        let url = '/api/account/role/detail/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }

                let roleDetailList = JSON.parse(response.role_detail)
                let dataSource = roleList.map(item => {
                    let tempItem = Object.assign({}, item)
                    let index = roleDetailList.findIndex(role => role.key == item.key)
                    if (index != -1) { 
                        tempItem.view = roleDetailList[index].view
                        tempItem.edit = roleDetailList[index].edit
                        tempItem.export = roleDetailList[index].export
                    } 
                    return tempItem
                }) 
                this.setState({
                    name: response.name,
                    originalName: response.name,
                    description: response.description,
                    dataSource: dataSource
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getRoleDetail()
        } else {
            this.setState({ dataSource: roleList })
        }
    }

    getInitializeRoleDetail() {
        const { roleId } = this.props
        let url = '/api/account/role/detail/' + roleId;
        get(url)
            .then(responses => {
                this.props.initializeRole(responses)
                this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    updateRole() {
        const { name, description, dataSource } = this.state
        if (!name || name == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Header".')
            return
        }
        let data = {
            name: name,
            description: description,
            role_detail: JSON.stringify(dataSource),
            adminId: this.props.administratorId
        }
        if (this.props.editId) {
            let url = '/api/crm/administrator/account/role/' + this.props.editId
            put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getInitializeRoleDetail() 
            })
            .catch(err => {
                console.log(err.message)
            })
        }else{
            let url = '/api/crm/administrator/account/role';
            post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
            })

        }
    }

    onClickOk() {
        this.updateRole()
    }
    onClickCancel() {
        this.setState({
            name: '',
            status: 'ยังไม่ส่ง',
            description: ''
        })
        this.props.backpage()
    }
    render() {
        const { dataSource, columns } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        
        return (
            <div className='userrole-page-wrapper'  style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{cursor: 'pointer'}}>
                        <Icon style={{ fontSize: 25,fontWeight:500,color:'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25,fontWeight:500,color:'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit  Role' : 'Create Role'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2,backgroundColor:'#ffffff',color:'rgb(72, 197, 235)',border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2,backgroundColor:'rgb(72, 197, 235)',color:'#ffffff',border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle"> 
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Header'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                        disabled = {['Manager', 'Administrator'].includes(this.state.originalName)}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Description'}>
                                    <Input
                                        value={this.state.description}
                                        onChange={(event) => this.setState({ description: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Table dataSource={dataSource} columns={columns} pagination={false} /> 
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    roleId: state.login.roleId,
})

const mapDispatchToProps = (dispatch) => ({ 
    initializeRole: (payload) => dispatch(initializeRole(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(UserRole);

