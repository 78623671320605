import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { signinMainCustomerSuccess, logout } from '../../../actions/login';
import { get, post } from '../../../utils';
import { Row, Col, Icon, Popover, Button, Badge, Alert } from 'antd';
import { Helmet } from "react-helmet";
import companyEmpty from '../../../resource/images/company-empty.png'
import companySelectItem from '../../../resource/images/company-select-item.png'
import logoCodexConnectWhite from '../../../resource/Logo/logo-codex-connect-white.png'
import codexConnect from '../../../resource/Logo/logoCodexConnect.png'
import forgetpasspic from '../../../resource/assets/Info.png'

import './style.scss'


class MainHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            companyList: [],
            inviteList: [],
        }
    }

    getCompanyList() {
        let url = '/api/crm/administrator/maincustomer/joined'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ companyList: responses })
                if (responses.length == 1) {
                    this.handleClick(responses[0].id)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    getInviteList() {
        let url = '/api/crm/administrator/maincustomer/invite'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ inviteList: responses })
            })
            .catch(error => {
                console.log(error)
            })
    }



    componentDidMount() {
        this.getCompanyList()
        this.getInviteList()
    }

    handleClick(mainCustomerId) {
        const { administratorId } = this.props
        let url = '/api/user/signin/maincustomer/administrator'
        let data = { main_customer_id: mainCustomerId, user_administrator_id: administratorId }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log('signinMainCustomerSuccess', response.user)
                this.props.signinMainCustomerSuccess(response.user)
            })
            .catch(error => {
                console.log(error)
            })
    }

    onClickJoinInvitation(id) {
        let url = '/api/crm/administrator/maincustomer/invition/join'
        let data = { id: id }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    this.getCompanyList()
                    this.getInviteList()
                } else {
                    console.log("response error", response)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    onClickCancelInvitation(id) {
        let url = '/api/crm/administrator/maincustomer/invition/cancel'
        let data = { id: id }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    this.getCompanyList()
                    this.getInviteList()
                } else {
                    console.log("response error", response)
                }

            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { companyList, inviteList } = this.state
        const { colorTheme, administratorName, administratorUsername, emailConfirmed } = this.props
        const text = <span>{'Notification'}</span>;
        const content = (
            inviteList.length ?
                <div className='notification-wrapper'>
                    {inviteList.map(item => {
                        return (
                            <div className='item-notification-wrapper'>
                                <p className='title' >{`You have a request for a company ${item.role_name} from ${item.name}`}</p>
                                <p>{'An administrator has sent you a request. Please check your email. Thank you'}</p>
                                <div className='button-wrapper'>
                                    <Button type="primary" onClick={() => this.onClickJoinInvitation(item.id)}>{'Join'}</Button>
                                    <Button onClick={() => this.onClickCancelInvitation(item.id)}>{'Cancel'}</Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                <div className='notification-empty-wrapper'>
                    <p>{'Emtpy'}</p>
                </div>
        );

        return (
            <Row className='login-page-wrapper'>
                <Helmet>
                    <title>{"User page"}</title>
                </Helmet>
                <Col className='left-colunm-wrapper-login' sm={24} md={9} lg={9}>


                    <div className='logo'>
                        <img width={350} src={logoCodexConnectWhite} />
                    </div>
                    <div className="login-text">
                        <p className='sub-title'>Welcome to </p>
                        <p className="sub-title-2">CodexConnect</p>
                        <img width={350}
                            className='left-pic'
                            src={forgetpasspic}
                        />
                    </div>

                    <div className="login-footer">
                        <p>Copyright &#169; 2020 Codex Digital Partner Co.,Ltd. All rights reserved.</p>
                    </div>

                </Col>
                <Col className='right-colunm-wrapper' sm={24} md={15} lg={15}>

                    <div className='header-wrapper' >
                        <Row>
                            <Col className='header-wrapper-left' sm={12} md={4} lg={4} >
                                <div className='logo'>
                                    <img width={150} src={codexConnect} />
                                </div>
                            </Col>
                            <Col className='header-wrapper-right' sm={12} md={20} lg={20} >
                                <p className='name'>{administratorName}</p>
                                <div className='popover'>
                                    <Popover
                                        visible={this.state.visible}
                                        title={text}
                                        content={content}
                                        placement="topRight"
                                        trigger="click"
                                        onVisibleChange={(visible) => this.setState({ visible: visible })}
                                    >
                                        <Badge count={inviteList.length} ><Icon type="bell" /></Badge >
                                    </Popover>
                                </div>
                                <div className='logout'>
                                    <Link to={'/'} onClick={() => this.props.logout()}>
                                        <Icon type="logout" />
                                    </Link>
                                </div>
                            </Col>

                        </Row>



                    </div>
                    {emailConfirmed == false || emailConfirmed == 'false' ?


                        <div className='email-confirmed-wrapper' >

                            <Alert
                                message="Alert"
                                description="Please activate your account via the activation link that has been sent to your email."
                                type="warning"
                                showIcon
                            />

                        </div>
                        : null}
                    {companyList.length ?
                        <div className='container-wrapper' >
                            <p className='title' style={{ color: colorTheme.primary }}>{"Choose The Company"}</p>
                            <div className='company-name-list-wrapper' >
                                {companyList.map(item => {
                                    return (
                                        <Button
                                            className='item-company-name-wrapper'
                                            onClick={() => this.handleClick(item.id)}
                                            size="large"
                                        >
                                            <span>{item.name}</span>
                                            <Icon type="arrow-right" />
                                        </Button>
                                    )
                                })}
                            </div>
                        </div>
                        :
                        <div className='container-empty-wrapper' >
                            <div className='image-wrapper'>
                                <img src={companyEmpty} />
                            </div>
                            <div className='description-wrapper'>
                                <p className='title'>{`Don't have company`}</p>
                                <p className='detail'>{`Please have the company send your invitation`}</p>
                                <p className='detail'>{`via the administrator system`}</p>
                            </div>
                        </div>
                    }
                </Col>
            </Row>

        )
    }

}

const mapStateToProps = (state) => ({
    colorTheme: state.theme.color,
    buttonTheme: state.theme.button,
    administratorId: state.login.id,
    administratorUsername: state.login.username,
    administratorName: state.login.name,
    emailConfirmed: state.login.emailConfirmed,
})

const mapDispatchToProps = (dispatch) => ({
    signinMainCustomerSuccess: (payload) => dispatch(signinMainCustomerSuccess(payload)),
    logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(MainHome);

