import { LOGIN_SUCCESS,LOGOUT,NOTIFICATION_REMOVE,NOTIFICATION_SUCCESS } from '../actions/types';
export function notiSuccess(payload) {
    return {
        type: NOTIFICATION_SUCCESS,
    };
}

export function notiRemove(payload) { 
    return {
        type: NOTIFICATION_REMOVE,
    };
}

