const initialState = {
    showModalExpired: false,
};


const systemmodalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_MODAL_EXPIRED': {
            return Object.assign({}, state, { showModalExpired: true })
        }
        case 'HIDE_MODAL_EXPIRED': {
            return Object.assign({}, state, { showModalExpired: false })
        }
        default:
            return state;
    }
}

export default systemmodalReducer;