
import { CLEAR_ACTIVE_LEFT_MENU, SET_ACTIVE_MENU, SET_ACTIVE_SUBMENU } from '../actions/types';

const initialState = {
    activeMenu: null,
    activeSubMenu: null,
};

const leftmenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ACTIVE_LEFT_MENU: {
            console.log('CLEAR_ACTIVE_LEFT_MENU')
            return Object.assign({}, state, { activeMenu: null, activeSubMenu: null })
        }
        case SET_ACTIVE_MENU: {
            return Object.assign({}, state, { activeMenu: action.payload })
        }
        case SET_ACTIVE_SUBMENU: {
            return Object.assign({}, state, { activeSubMenu: action.payload })
        }
        default:
            return state;
    }
}

export default leftmenuReducer;