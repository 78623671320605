import React from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Icon, notification, Button,Modal,Spin } from 'antd';
import { get, post, ppost } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import VideoUpload from '../../../containers/VideoUpload';
import config from '../../../config.json'
import './style.scss'
import { Link } from 'react-router-dom'
import swiperHomePage from '../../../resource/swiper/swiper-home-page.png'
import swiperGetStartedPage from '../../../resource/swiper/swiper-get-started-page.png'
const { Meta } = Card;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Swiper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            swiperHomePageList: [],
            swiperGetStartedPageList: [],

            swiperBannerList: [],
            headerBackground: [],
            swiperThumbnailVideoList: [],
            swiperVideoList: [],
        }
    }


    async setEditData(responses) {
        console.log('responsesresponsesresponses',responses)
        
        
        let swiperHomePageList = await this.filterList(responses,'HOME_PAGE')

        let swiperGetStartedPageList = await this.filterList(responses,'GET_STARTED_PAGE')
        // responses.filter(item => item.code == 'GET_STARTED_PAGE').map(item => {
        //     let temp = Object.assign({}, item)
        //     temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
        //     temp['file'] = null
        //     return temp
        // })

        let swiperBannerList = await this.filterList(responses,'BANNER_HOME_PAGE')
        // responses.filter(item => item.code == 'BANNER_HOME_PAGE').map(item => {
        //     let temp = Object.assign({}, item)
        //     temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
        //     temp['file'] = null
        //     return temp
        // })

        let swiperVideoList = await this.filterList(responses,'VIDEO_HOME_PAGE')
        // responses.filter(item => item.code == 'VIDEO_HOME_PAGE').map(item => {
        //     let temp = Object.assign({}, item)
        //     temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
        //     temp['file'] = null
        //     return temp
        // })

        let swiperThumbnailVideoList = await this.filterList(responses,'THUMBNAIL_VIDEO_HOME_PAGE')
        // responses.filter(item => item.code == 'THUMBNAIL_VIDEO_HOME_PAGE').map(item => {
        //     let temp = Object.assign({}, item)
        //     temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
        //     temp['file'] = null
        //     return temp
        // })

        let headerBackground = await this.filterList(responses,'HEADER_BACKGROUND_IMAGE')

        this.setState({
            swiperHomePageList: swiperHomePageList,
            swiperGetStartedPageList: swiperGetStartedPageList,
            swiperBannerList: swiperBannerList,
            swiperVideoList: swiperVideoList,
            swiperThumbnailVideoList: swiperThumbnailVideoList,
            headerBackground: headerBackground,
        })
    }

    filterList(responses,itemCode){
        const { folderName } = this.props
        let filterList = responses.filter(item => item.code == itemCode).map(item => {
            let temp = Object.assign({}, item)
            temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
            temp['file'] = null
            return temp
        })
        return filterList
    }

    getSwiperList() {
        let url = '/api/crm/appearance/swiper/administrator';
        get(url)
            .then(responses => {
                this.setEditData(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getSwiperList()
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateSwiper() {
        const { swiperHomePageList, swiperGetStartedPageList,swiperVideoList,swiperBannerList,swiperThumbnailVideoList,headerBackground } = this.state
        this.setState({loading:true})
        let uploadDataHomePageList = await this.setUpdateDataAndUpload(swiperHomePageList,'HOME_PAGE',"image")
        // let uploadFileHomePageList = swiperHomePageList.filter(item => item.file)
        // let uploadDataHomePageList = swiperHomePageList.filter(item => !item.file)

        // if (uploadFileHomePageList.length) {
        //     let formDataHomePage = new FormData();
        //     uploadFileHomePageList.map((item, index) => formDataHomePage.append("image" + index, item.file))
        //     let fileNameHomePageList = await this.uploadImageToServer(formDataHomePage)
        //     fileNameHomePageList.map(fileName => {
        //         uploadDataHomePageList.push({
        //             code: 'HOME_PAGE',
        //             name: fileName
        //         })
        //     })
        // }

        let uploadDataGetStartedPageList = await this.setUpdateDataAndUpload(swiperGetStartedPageList,'GET_STARTED_PAGE',"image")
        // let uploadFileGetStartedPageList = swiperGetStartedPageList.filter(item => item.file)
        // let uploadDataGetStartedPageList = swiperGetStartedPageList.filter(item => !item.file)

        // if (uploadFileGetStartedPageList.length) {
        //     let formDataGetStartedPage = new FormData();
        //     uploadFileGetStartedPageList.map((item, index) => formDataGetStartedPage.append("image" + index, item.file))
        //     let fileNameGetStartedPageList = await this.uploadImageToServer(formDataGetStartedPage)
        //     fileNameGetStartedPageList.map(fileName => {
        //         uploadDataGetStartedPageList.push({
        //             code: 'GET_STARTED_PAGE',
        //             name: fileName
        //         })
        //     })
        // }

        let uploadDataThumbnailVideoHomePageList = await this.setUpdateDataAndUpload(swiperThumbnailVideoList,'THUMBNAIL_VIDEO_HOME_PAGE',"image")
        // let uploadFileThumbnailVideoHomePageList = swiperThumbnailVideoList.filter(item => item.file)
        // let uploadDataThumbnailVideoHomePageList = swiperThumbnailVideoList.filter(item => !item.file)

        // if (uploadFileThumbnailVideoHomePageList.length) {
        //     let formData = new FormData();
        //     uploadFileThumbnailVideoHomePageList.map((item, index) => formData.append("image " + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     fileNameList.map(fileName => {
        //         uploadDataThumbnailVideoHomePageList.push({
        //             code: 'THUMBNAIL_VIDEO_HOME_PAGE',
        //             name: fileName
        //         })
        //     })
        // }

        let uploadDataVideoHomePageList = await this.setUpdateDataAndUpload(swiperVideoList,'VIDEO_HOME_PAGE',"video")
        // let uploadFileVideoHomePageList = swiperVideoList.filter(item => item.file)
        // let uploadDataVideoHomePageList = swiperVideoList.filter(item => !item.file)

        // if (uploadFileVideoHomePageList.length) {
        //     let formData = new FormData();
        //     uploadFileVideoHomePageList.map((item, index) => formData.append("video" + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     fileNameList.map(fileName => {
        //         uploadDataVideoHomePageList.push({
        //             code: 'VIDEO_HOME_PAGE',
        //             name: fileName
        //         })
        //     })
        // }

        let uploadDataBannerHomePageList = await this.setUpdateDataAndUpload(swiperBannerList,'BANNER_HOME_PAGE',"image")
        // let uploadFileBannerHomePageList = swiperBannerList.filter(item => item.file)
        // let uploadDataBannerHomePageList = swiperBannerList.filter(item => !item.file)

        // if (uploadFileBannerHomePageList.length) {
        //     let formData = new FormData();
        //     uploadFileBannerHomePageList.map((item, index) => formData.append("image" + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     fileNameList.map(fileName => {
        //         uploadDataBannerHomePageList.push({
        //             code: 'BANNER_HOME_PAGE',
        //             name: fileName
        //         })
        //     })
        // }

        let uploadDataHeaderBackgroundHomeList = await this.setUpdateDataAndUpload(headerBackground,'HEADER_BACKGROUND_IMAGE',"image")

        let data = {
            swiperHomePageList: JSON.stringify(uploadDataHomePageList),
            swiperGetStartedPageList: JSON.stringify(uploadDataGetStartedPageList),
            swiperVideoList: JSON.stringify(uploadDataVideoHomePageList),
            swiperBannerList: JSON.stringify(uploadDataBannerHomePageList),
            swiperThumbnailVideoList: JSON.stringify(uploadDataThumbnailVideoHomePageList),
            swiperHeaderBackgroundHomeList: JSON.stringify(uploadDataHeaderBackgroundHomeList),
        }
        
        

        let url = '/api/crm/administrator/appearance/swiper';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({loading:false})
                openNotificationWithIcon('success', 'System notification', 'Save swiper success.')
            })
            .catch(err => {
                console.log(err.message)
                this.setState({loading:false})
            })
    }
    
    async setUpdateDataAndUpload(datasource,itemCode,type){
        let uploadDatasourceList = datasource.filter(item => item.file)
        let uploadDataDatasourceList = datasource.filter(item => !item.file)

        if (uploadDatasourceList.length) {
            let formData = new FormData();
            uploadDatasourceList.map((item, index) => formData.append(type + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            fileNameList.map(fileName => {
                uploadDataDatasourceList.push({
                    code: itemCode,
                    name: fileName
                })
            })
        }

        return uploadDataDatasourceList
    }

    onClickOk() {
        this.updateSwiper()
    }

    onClickCancel() {
        this.getSwiperList()
    }

    getRoleEnabled() {
        const { loading } = this.state
        const { roleDetail } = this.props
        if(loading){
            return true
        }
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    renderModalUpload() {
        return(
            <Modal 
                title={null}
                footer={null}
                visible={this.state.loading} 
                closable={false}
            >
                <div style={{ textAlign:'center' }}>
                    <Spin />
                </div>
                <div style={{ textAlign:'center' }}>
                    กรุณารออัพโหลด...
                </div>
            </Modal>
        )
    }

    render() {
        const { swiperHomePageList, swiperGetStartedPageList,swiperVideoList,swiperBannerList,swiperThumbnailVideoList,headerBackground } = this.state

        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} >
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >Swiper</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Form>
                        <Row gutter={24} style={{ backgroundColor: '#FFFFFF' }}>
                            <ImageUpload
                                className={'image-swiper-get-started-page'}
                                label={'Upload image swiper get started page'}
                                previewImage={swiperGetStartedPage}
                                limit={10}
                                dataSource={swiperGetStartedPageList}
                                updateDataSource={(dataSource) => this.setState({ swiperGetStartedPageList: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload image swiper home page'}
                                previewImage={swiperHomePage}
                                limit={10}
                                dataSource={swiperHomePageList}
                                updateDataSource={(dataSource) => this.setState({ swiperHomePageList: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload image banner'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperBannerList}
                                updateDataSource={(dataSource) => this.setState({ swiperBannerList: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload background header'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={headerBackground}
                                updateDataSource={(dataSource) => this.setState({ headerBackground: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload thumbnail video'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperThumbnailVideoList}
                                updateDataSource={(dataSource) => this.setState({ swiperThumbnailVideoList: dataSource })}
                            />
                        </Row>
                        <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <VideoUpload
                                className={'video-swiper-home-page'}
                                label={'Upload video'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperVideoList}
                                updateDataSource={(dataSource) => this.setState({ swiperVideoList: dataSource })}
                            />
                        </Row>
                    </Form>
                </Card>
                {this.renderModalUpload()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    folderName: state.login.folderName,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(Swiper);

