import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon, Switch, notification } from 'antd';
import { get, post } from '../../../utils';
import './style.scss'
import { Link } from 'react-router-dom'
const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;

class CustomRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeList: [
                { value: 'inputtext', label: 'Input Text' },
                { value: 'select', label: 'Select' },
                { value: 'multiple', label: 'Multiple Select' },
                { value: 'checkbox', label: 'Checkbox' },
                { value: 'radio', label: 'Radio Button' },
                { value: 'image', label: 'Image' },
            ],
            dataSource: [],
            deleteDataSource: [],
            alertMsg: {
                show: false
            }
        }
    }

    getCustomRegister() {
        let url = '/api/account/register'
        get(url)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let dataSource = response.map(item => {
                    return {
                        id: item.id,
                        type: item.type_label,
                        label: item.label,
                        order: item.order_label,
                        required: item.required,
                        itemList: JSON.parse(item.item_list),
                        isDelete: item.is_delete
                    }
                })
                this.setState({ dataSource: dataSource })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getCustomRegister()
    }

    showAlertMsg(msg) {
        this.setState({
            alertMsg: {
                show: true,
                message: msg,
                close: () => this.setState({ alertMsg: { show: false } }),
                confirm: () => this.setState({ alertMsg: { show: false } }),
            }
        })
    }

    validation() {
        let { dataSource } = this.state
        let validation = dataSource.map((data, index) => {
            if (data.label === '') {
                notification['error']({
                    message: 'error',
                    description: `Please enter "Label" to Custom Label ${index + 1}`,
                });
                // this.showAlertMsg(`กรุณากรอกข้อมูล "Label" ที่ Custom Label ${index + 1}`)
                return false
            } else if (data.order === '') {
                notification['error']({
                    message: 'error',
                    description: `Please enter "Order" to Custom Label ${index + 1}`,
                });
                // this.showAlertMsg(`กรุณากรอกข้อมูล "Order" ที่ Custom Label ${index + 1}`)
                return false
            } else {
                if (['inputtext', 'image'].includes(data.type)) {
                    return true
                } else {
                    let validationItem = data.itemList.map((item, indexItem) => {
                        if (item === '') {
                            notification['error']({
                                message: 'error',
                                description: `Please enter "Item" ${indexItem + 1} to Custom Label ${index + 1}`,
                            });
                            // this.showAlertMsg(`กรุณากรอกข้อมูล "Item" ${indexItem + 1} ที่ Custom Label ${index + 1}`)
                            return false
                        } else {
                            return true
                        }
                    }).filter(validationItem => !validationItem)
                    if (validationItem.length > 0) {
                        return false
                    } else {
                        return true
                    }
                }
            }

        }).filter(validation => !validation)
        if (validation.length > 0) {
            return false
        } else {
            return true
        }
    }
    updateCustomLabel() {

        if (!this.validation()) {
            return
        }
        let dataList = JSON.stringify(this.state.dataSource)
        let url = '/api/account/register/update'
        let data = {}
        data = {
            "dataList": dataList,
        }

        post(url, data)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getCustomRegister()
                notification['success']({
                    message: 'Save success',
                    description: 'Save custom register success',
                });
            })
            .catch(err => {
                notification['error']({
                    message: 'Save error',
                    description: 'Save custom register error',
                });
                console.log(err.message)
            })

    }

    onClickOk() {
        this.updateCustomLabel()
    }
    onClickCancel() {
        this.getCustomRegister()
    }


    typeListToElem() {
        let result = this.state.typeList.map((result, i) => <Option key={i} value={result.value}>{result.label}</Option>)
        return result
    }
    updateItemList(value, index, indexItem) {
        let { dataSource } = this.state
        dataSource[index].itemList.splice(indexItem, 1, value)
        this.setState({ dataSource: dataSource })
    }


    updateDataSource(value, index, parameter) {
        let { dataSource } = this.state
        dataSource[index][`${parameter}`] = value
        this.setState({ dataSource: dataSource })
    }

    addDataSource() {
        let { dataSource } = this.state
        let addDataSource = {
            type: 'inputtext',
            label: '',
            order: 0,
            required: true,
            itemList: [''],
            isDelete: true
        }
        this.setState({ dataSource: [...dataSource, addDataSource] })
    }
    addItemList(index) {
        let { dataSource } = this.state
        dataSource[index].itemList.push('')
        this.setState({ dataSource: dataSource })
    }
    deleteItemList(index, indexItem) {
        let { dataSource } = this.state
        dataSource[index].itemList.splice(indexItem, 1)
        this.setState({ dataSource: dataSource })
    }
    deleteDataSource(index) {
        let { dataSource, deleteDataSource } = this.state
        let addDeleteDataSource = dataSource.splice(index, 1)
        // if (addDeleteDataSource[0].id != null) {
        //     deleteDataSource.push(addDeleteDataSource[0].id)
        // }
        this.setState({ dataSource: dataSource })
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'register_customer')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    getLabel() {
        const isEnglish = true
        const formItemLayoutType = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 8 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutLabel = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 8 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutOrder = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 12 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 10 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayout2 = {
            labelCol: {
                xs: { span: 10 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 16 },
            },
        };
        let { dataSource } = this.state
        if (dataSource.length > 0) {
            return (
                dataSource.map((data, index) => {
                    return (
                        <Card style={{ background: 'none', marginTop: 20 }}>
                            <Meta
                                title={isEnglish ? `Custom Label  ${index + 1}` : `คำถามที่  ${index + 1}`}
                            />
                            <Form style={{ paddingTop: '20px' }}>
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={12} lg={6} xl={6} >
                                        <FormItem
                                            {...formItemLayoutType}
                                            label={isEnglish ? 'Type' : 'ประเภท'}
                                            required
                                        >
                                            <Select ref="type" value={data.type} onChange={(value) => this.updateDataSource(value, index, 'type')}>
                                                {this.typeListToElem()}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={8} xl={8} >
                                        <FormItem
                                            {...formItemLayoutLabel}
                                            label={isEnglish ? 'Label' : 'คำถาม'}
                                            required
                                        >
                                            <Input placeholder='Label' value={data.label} onChange={(event) => this.updateDataSource(event.target.value, index, 'label')} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={5} xl={5} >
                                        <FormItem
                                            {...formItemLayoutOrder}
                                            label={isEnglish ? 'Order' : 'ลำดับที่'}
                                            required
                                        >
                                            <Input type={'number'} min={0} placeholder='Order' value={data.order} onChange={(event) => this.updateDataSource(event.target.value, index, 'order')} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={5} xl={5} >
                                        <FormItem
                                            {...formItemLayout}
                                            label={isEnglish ? 'Required' : 'จำเป็น'}
                                            required
                                        >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <Switch defaultChecked checked={data.required} onChange={(value) => this.updateDataSource(value, index, 'required')} />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <Button style={{ textAlign: 'right' }} type="danger" onClick={() => this.deleteDataSource(index)} disabled={!data.isDelete}><Icon type="delete" theme="outlined" /></Button>
                                            </Col>
                                        </FormItem>
                                    </Col>

                                </Row>
                                {['select', 'multiple', 'checkbox', 'radio'].includes(data.type) ?
                                    <Row gutter={24}>
                                        <Col sm={12} md={24} lg={4} style={{ textAlign: 'right' }}>
                                            <Button onClick={() => this.addItemList(index)}><Icon type="plus-circle" theme="outlined" />{isEnglish ? 'Add Item' : 'เพิ่มตัวเลือก'}</Button>
                                        </Col>
                                        <Col sm={12} md={24} lg={20} offset={1} style={{ textAlign: 'left' }}>
                                            {data.itemList.map((item, indexItem) => {
                                                return (
                                                    <Row gutter={24}>
                                                        <Col xs={18} sm={18} md={18} lg={18} xl={18} >
                                                            <FormItem
                                                                {...formItemLayout2}
                                                                label={isEnglish ? 'Item ' + (indexItem + 1) : 'ตัวเลือก ' + (indexItem + 1)}
                                                                required
                                                            >
                                                                <Input placeholder={'Item ' + (indexItem + 1)} value={item} onChange={(event) => this.updateItemList(event.target.value, index, indexItem)} />
                                                            </FormItem>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                                                            <Button type="danger" onClick={() => this.deleteItemList(index, indexItem)}><Icon type="delete" theme="outlined" /></Button>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                    : null
                                }
                            </Form>
                        </Card>
                    )
                })
            )
        } else {
            return (
                <Card style={{ background: 'none', marginTop: 20, textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: 16 }}>{'No custom register'}</p>
                </Card>
            )
        }
    }



    render() { 
        return (
            <div className='customregister-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card-register" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 20, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >Custom Register</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{
                            marginLeft: 2, marginRight: 2,
                            backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                            color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                            border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff'
                        }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff'
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row type="flex" justify="end" align="top">
                            <Button
                                style={{
                                    backgroundColor: this.getRoleEnabled() ? '#17BF4F' : '#BFBFBF',
                                    color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                    border: 'none'
                                }}
                                disabled={!this.getRoleEnabled()}
                                onClick={() => this.addDataSource()}
                            >
                                <Icon type="plus" theme="outlined" />{'Add Label'}
                            </Button>
                        </Row>
                        {this.getLabel()}
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(CustomRegister);

