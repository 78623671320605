import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { initializeRole } from "../../../actions/role";
import { Form, Input, Card, Row, Col, Button, Icon, Table, Modal, notification, Switch } from 'antd';
import { get, post, put, patch, del } from '../../../utils';
import './style.scss'

notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

const FormItem = Form.Item;
const { TextArea } = Input;

class CustomerAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,

            userCustomerAddressId: null,
            name: '',
            telephone: '',
            address: '',
            province: '',
            district: '',
            postCode: '',

            dataSource: [],
            columns: [
                {
                    title: 'Line ID',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center ',
                    width: '10%',
                    render: (text, item, index) => (index + 1).toString().padStart(5, 0)
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'left',
                    width: '20%',
                },
                {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                    align: 'left',
                    width: '30%',
                    render: (text, item, index) => `${item.address} ${item.district} ${item.province} ${item.post_code}`
                },
                {
                    title: 'Telephone',
                    dataIndex: 'telephone',
                    key: 'telephone',
                    align: 'center',
                    width: '10%',
                },
                {
                    title: 'Default',
                    dataIndex: 'is_default',
                    key: 'is_default',
                    align: 'center',
                    width: '10%',
                    render: (text, item, index) => {
                        if (!item.is_delete) {
                            return <Switch checked={item.is_default} checkedChildren={'Yes'} unCheckedChildren={"No"} onChange={(checked) => this.onChangeDefault(item, checked)} />
                        }
                        return <span style={{ color: 'red' }}>{'Deleted'}</span>
                    }
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    align: 'center',
                    width: '10%',
                    render: (text, item, index) => this.renderActionColumn(text, item, index)
                },
            ]
        }
    }


    renderActionColumn(text, item, index) {
        let disabled = item.is_delete === 1
        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                <div style={{
                    margin: 5,
                    width: 30, height: 30,
                    borderRadius: 15,
                    backgroundColor: '#EAEAEA',
                    cursor: !disabled ? 'pointer' : 'default'
                }}
                    onClick={() => !disabled ? this.onClickActionEdit(item) : null}
                >
                    <Icon type="edit" style={{ fontSize: 16, padding: 5, color: !disabled ? '#000' : '#fff' }} />
                </div>
                <div style={{
                    margin: 5,
                    width: 30, height: 30, borderRadius: 15,
                    backgroundColor: '#EAEAEA',
                    cursor: !disabled ? 'pointer' : 'default'
                }}
                    onClick={() => !disabled ? this.onClickActionDelete(item) : null}
                >
                    <Icon type="close" style={{ fontSize: 16, padding: 5, color: !disabled ? 'red' : '#fff' }} />
                </div>
            </div>
        )
    }

    onChangeDefault(item, checked) {
        const { editId } = this.props
        let url = '/api/crm/administrator/address/' + item.id + '/isdefault';
        let data = { is_default: checked, user_customer_id: editId }
        patch(url, data)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getCustomerAddressDetail()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickActionEdit(item) {
        this.setState({
            visible: true,
            userCustomerAddressId: item.id,
            name: item.name,
            telephone: item.telephone,
            address: item.address,
            province: item.province,
            district: item.district,
            postCode: item.post_code,
        })
    }

    onClickActionDelete(item) {
        let url = '/api/crm/administrator/address/' + item.id;
        del(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getCustomerAddressDetail()
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    getCustomerAddressDetail() {
        const { editId } = this.props
        let url = '/api/address?user_customer_id=' + editId;
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ dataSource: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getCustomerAddressDetail()
        }
    }

    updateCustomerAddress() {
        const { editId } = this.props
        const { userCustomerAddressId, name, telephone, address, province, district, postCode } = this.state

        if (!name || name == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Name".')
            return
        }

        if (!telephone || telephone == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Telephone".')
            return
        }

        if (!address || address == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Address".')
            return
        }

        if (!province || province == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Province".')
            return
        }

        if (!district || district == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "District".')
            return
        }

        if (!postCode || postCode == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Post Code".')
            return
        }

        let data = {
            name: name,
            telephone: telephone,
            address: address,
            province: province,
            district: district,
            post_code: postCode
        }

        let url = '/api/crm/administrator/address/';
        let update = post
        if (userCustomerAddressId) {
            url = '/api/crm/administrator/address/' + userCustomerAddressId;
            update = put
        } else {
            data['user_customer_id'] = editId
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getCustomerAddressDetail()
                this.closeModal()
            })
            .catch(err => {
                console.log(err.message)
            })

    }

    onClickCreate() {
        this.setState({ visible: true })
    }

    onClickOk() {
        this.updateCustomerAddress()
    }

    onClickCancel() {
        this.closeModal()
    }

    closeModal() {
        this.setState({
            visible: false,
            userCustomerAddressId: null,
            name: '',
            telephone: '',
            address: '',
            province: '',
            district: '',
            postCode: '',
        })
    }

    render() {
        const { visible, dataSource, columns } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='address-customer-container-wrapper'>
                <Row className='action-button-wrapper' gutter={24} type="flex" justify='space-between'>
                    <p className='title-header'>{'Address'}</p>
                    <Button className='create-button' onClick={() => this.onClickCreate()}  ><Icon type="plus" />{'Create'}</Button>
                </Row>
                <Table
                    className='address-customer-table'
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
                <Modal
                    title={'Address'}
                    visible={visible}
                    onOk={() => this.onClickOk()}
                    onCancel={() => this.onClickCancel()}
                >
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Name'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Address'} required={true}>
                                    <TextArea
                                        rows={4}
                                        value={this.state.address}
                                        onChange={(event) => this.setState({ address: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'District'} required={true}>
                                    <Input
                                        value={this.state.district}
                                        onChange={(event) => this.setState({ district: event.target.value })}
                                    />

                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Province'} required={true}>
                                    <Input
                                        value={this.state.province}
                                        onChange={(event) => this.setState({ province: event.target.value })}
                                    />

                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Post Code'} required={true}>
                                    <Input
                                        value={this.state.postCode}
                                        onChange={(event) => this.setState({ postCode: event.target.value })}
                                    />

                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Telephone'} required={true}>
                                    <Input
                                        value={this.state.telephone}
                                        onChange={(event) => this.setState({ telephone: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    roleId: state.login.roleId,
})

const mapDispatchToProps = (dispatch) => ({
    initializeRole: (payload) => dispatch(initializeRole(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddress);

