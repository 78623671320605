import React from 'react';
import { Col, Row, Icon, List, Modal, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { get } from '../../utils';
import config from '../../config.json'
import defaultSquareSrc from '../../resource/default/300x300-square.png'
import './style.scss'


class ChatRegisterCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            categoryProductId: '',
            categoryTicketId: '',
            detail: '',
            total: 0,
            purchase: 0,
            inventory: 0,
            price: 0,
            priceForSale: 0,
            detailList: [],
            imageCoverList: [],
            imageDetailList: []
        }
    }

    render() {
        return (
            <div className="chat-register-card" >
                <div style={{ height: '100%' }}>
                    <img
                        src={'https://images.unsplash.com/photo-1531206715517-5c0ba140b2b8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'}
                        style={{ borderRadius: '6px', width: '100%' }}
                        onError={(image) => image.target.src = defaultSquareSrc}
                    />
                </div>
                <div className="chat-regis-text">
                    <p>{'Hi, Come to be'}</p>
                    <p>{'the same family as us'}</p>
                </div>
                <div className="chat-regis-btn-container">
                    <button className="chat-regis-btn">Start sign up</button>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(ChatRegisterCard);