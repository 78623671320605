import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import './style.scss'

class StorageOverLimitModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    handleOk() {
        this.props.onClose()
    }

    handleCancel() {
        this.props.onClose()
    }

    render() {
        const { visible } = this.props
        return (
            <div className="home-page-wrapper">
                <Modal
                    title={"Storage Over Limit"}
                    visible={visible}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                >
                    <div style={{ textAlign: 'center' }}>
                        <h1>{'Storage Over Limit'}</h1>
                    </div>
                </Modal>
            </div>
        )
    }
}

StorageOverLimitModal.propTypes = {
    onClose: PropTypes.func,
}

StorageOverLimitModal.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    roleName: state.login.roleName,
})

export default connect(mapStateToProps)(StorageOverLimitModal);

