import { CLEAR_ACTIVE_LEFT_MENU, SET_ACTIVE_MENU, SET_ACTIVE_SUBMENU } from '../actions/types';

export function clearActiveLeftMenu() {
    return {
        type: CLEAR_ACTIVE_LEFT_MENU
    };
}

export function setActiveMenu(payload) {
    return {
        type: SET_ACTIVE_MENU,
        payload: payload
    };
}

export function setActiveSubMenu(payload) {
    return {
        type: SET_ACTIVE_SUBMENU,
        payload: payload
    };
}