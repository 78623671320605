import React, { PureComponent } from 'react';
import ContentLoader from "react-content-loader"

class CustomAreaChartsExtraSkeleton extends PureComponent {

    render() {
        return (
            <div className='customareachartsextra-component-wrapper' >
                <ContentLoader
                    speed={5}
                    width={'100%'}
                    height={520}
                    backgroundColor="#ffffff"
                    foregroundColor="#ecebeb"
                >

                    <rect x="5" y="10" rx="3" ry="3" width="200" height="25" />
                    <rect x="5" y="45" rx="3" ry="3" width="75" height="30" />
                    <rect x="90" y="45" rx="3" ry="3" width="50" height="30" />
                    <rect x="5" y="80" rx="3" ry="3" width="300" height="20" />
                    <rect x="0" y="110" rx="2" ry="2" width="100%" height="150" />

                    <rect x="5" y="270" rx="3" ry="3" width="96%" height="15" />
                    <rect x="5" y="300" rx="3" ry="3" width="96%" height="15" />
                    <rect x="5" y="330" rx="3" ry="3" width="96%" height="15" />
                    <rect x="5" y="360" rx="3" ry="3" width="96%" height="15" />
                    <rect x="5" y="390" rx="3" ry="3" width="96%" height="15" />
                    <rect x="5" y="420" rx="3" ry="3" width="96%" height="15" />
                    <rect x="5" y="450" rx="3" ry="3" width="96%" height="15" />
                    <rect x="5" y="480" rx="3" ry="3" width="96%" height="15" /> 
                </ContentLoader>
            </div>
        );
    }
}


export default CustomAreaChartsExtraSkeleton;
