import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Select, Row, Col, Button, Modal, notification } from 'antd';
import { get, post } from '../../../utils';
import './style.scss'
notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const Option = Select.Option;

class InviteAdministratorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            roleId: null,
            roleList: [],
            messageError: null,
        }
    }


    getRoleList() {
        let url = '/api/account/role/list';
        get(url)
            .then(responses => {
                this.setState({ roleList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }



    componentDidMount() {
        this.getRoleList()
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.visible != this.props.visible) {
    //         if (this.props.editId) {
    //             this.getCategoryArticleDetail()
    //         }
    //     }
    // }

    updateInviteAdmin() {
        const { username, roleId } = this.state
        if (!username || username == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "E-mail".')
            return
        }

        if (!roleId || roleId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Role".')
            return
        }

        let url = '/api/crm/administrator/user/administrator/invite';
        let data = {
            username: username,
            role_id: roleId,
            user: this.props.administratorId
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    this.setState({ username: '', role_id: null })
                    this.props.refreshPage()
                    this.props.onClose()
                }else{
                    switch (response.error.code) {
                        case 'OVER_LIMIT_ADMINISTRATOR':
                            this.setState({ messageError: 'Over Limit Administrator' })
                            break;
                        case 'DUPLICATE_USER':
                            this.setState({ messageError: 'E-mail duplicate' })
                            break;
                    }
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateInviteAdmin()
    }
    onClickCancel() {
        this.setState({ username: '', roleId: null,messageError : null })
        this.props.onClose()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 6 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 18 },
            },
        };
        const { visible } = this.props
        return (
            <div>
                <Modal
                    title={'Invite'}
                    visible={visible}
                    onOk={() => this.onClickOk()}
                    onCancel={() => this.onClickCancel()}
                    style={{ textAlign: 'center' }}
                    footer={[
                        <Button key="back" onClick={() => this.onClickCancel()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                            {'Cancel'}
                        </Button>,
                        <Button key="submit" onClick={() => this.onClickOk()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                            {'Invite'}
                        </Button>,
                    ]}
                >
                    <Row gutter={24}>
                        {this.state.messageError ?
                            <p style={{color:'red'}}>{this.state.messageError}</p>
                            : null}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'E-mail'} style={{ fontSize: 20 }} required={true}>
                                <Input
                                    value={this.state.username}
                                    onChange={(event) => this.setState({ username: event.target.value })}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Role'} required={true}>
                                <Select
                                    value={this.state.roleId}
                                    style={{ width: '100%' }}
                                    onChange={(value) => this.setState({ roleId: value })}
                                >
                                    {this.state.roleList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

InviteAdministratorModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

InviteAdministratorModal.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
})

export default connect(mapStateToProps)(InviteAdministratorModal);

