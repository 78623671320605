import React,{useEffect,useState,useMemo} from 'react'
import { Table,Row,Button,Form,Input,Modal,Col,notification } from 'antd';
import { get,post } from '../../../utils';

const FormItem = Form.Item;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

function ModalReply({commentId,visibleReply,onCloseReply,administratorId}) {
    const [comment, setComment] = useState('')
    const [replyList,setReplyList] = useState({loading:true , dataList: []})

    const formItemLayout = {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    const columns = [
        {
            title: (
                <div className='title-table-comment'>
                    {'Name Customer'}
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (text, item, index) => {
                return (
                    <div>
                        {text}
                    </div>
                )
            }
        },
        {
            title: (
                <div className='title-table-comment'>
                    {'Comment'}
                </div>
            ),
            dataIndex: 'comment',
            key: 'comment',
            render: (text, item, index) => {
                return (
                    <div>
                        {text}
                    </div>
                )
            }
        },
    ]

    useEffect(() => {
        if(commentId){
            getReplyCommet()
        }
    },[commentId]);

    const getReplyCommet = () =>{
        setReplyList({loading:true , dataList: []})
        let url = '/api/crm/administrator/comment/'+ commentId +'/reply'
        return get(url)
            .then(response=>{
                setReplyList({loading:false , dataList: response.data})
                return
            })
            .catch(err=>{
                setReplyList({loading:false , dataList:[] })
            })
    }


    const SaveComment=()=>{
        if (!comment) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Comment".')
            return 
        }
        if (!administratorId) {
            openNotificationWithIcon('warning', 'System notification', 'Not found admin id".')
        }
        if (!commentId) {
            openNotificationWithIcon('warning', 'System notification', 'Not found comment Id".')
            return 
        }
        
        let url = '/api/crm/administrator/reply'
        let data = {
            commentId,
            comment,
            administratorId
        }
        return post(url,data)
            .then(responses=>{
                
                if(responses.success){
                    setComment('')
                    getReplyCommet()
                    return
                }else{
                    openNotificationWithIcon('warning', 'System notification', 'Please try again.')
                    return 
                }
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('warning', 'System notification', 'Please try again.')
                    return 
            })
    }


    return (
        <Modal
            title={'Reply'}
            visible={visibleReply}
            footer={null}
            onCancel={() => setReplyList({loading:false , dataList: []}) & onCloseReply()}
        >
            <Form>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <Table
                            loading={replyList.loading}
                            dataSource={replyList.dataList} 
                            columns={columns}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <FormItem {...formItemLayout} label={'Comment'} required={true}>
                            <Input
                                value={comment}
                                onChange={(event) => setComment(event.target.value)}
                            />
                            <div style={{textAlign:'right'}}>
                                <Button
                                    onClick={() => SaveComment()}
                                >
                                    {'Comment'}
                                </Button>
                            </div>
                        </FormItem>
                        
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}


export default React.memo(ModalReply,(prevProps, nextProps) => {
    if (prevProps.visibleReply != nextProps.visibleReply) {
      return false; // props are not equal -> update the component
    }
    return true
})