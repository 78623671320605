import React from 'react';
import fuzzysearch from 'fuzzysearch';
import { Col, Row, Input, Form, Modal, notification, Select, Checkbox, Avatar, Icon } from 'antd';
import SocketIOClient from 'socket.io-client';
import { post, get,ppost,put } from '../../../utils';
import config from '../../../config.json'
import ImageUpload from '../../../containers/ImageUpload';
import './style.scss'

const FormItem = Form.Item;
const Option = Select.Option;

notification.config({
    placement: 'topRight',
    duration: 1,
});


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            companyListFilter: [],
            id: null,
            username : null,
            password : null,
            confirmPassword : null,
            name: '',
            company: 0,
            telephone: '',
            email: '',
            line: '',
            newCustomer: false,
            customer_name: '',
            customer_address: '',
            customer_tax: '',
            avatar: [],
        }
        this.socket = SocketIOClient(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}`);
    }

    openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
        });
    };

    getCompanyList() {
        let url = '/api/administrator/company/all';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    companyList: response,
                    companyListFilter: response.slice(0, 100),
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            this.getCompanyList()
            if (this.props.customer) {
                this.setState({
                    id: this.props.customer.id,
                    name: this.props.customer.name,
                    username: this.props.customer.username,
                    company: this.props.customer.company,
                    telephone: this.props.customer.telephone,
                    email: this.props.customer.email,
                    line: this.props.customer.line,
                    avatar: this.props.customer.avatar
                }) 
            }
        }
    }


    handleOk() {
        
        const {
            id, name, company, telephone, email, line,
            newCustomer, customer_name, customer_address, customer_tax
        } = this.state
        if (name == "") {
            this.openNotificationWithIcon('error', "Required failed", 'กรุณากรอกข้อมูล "ชื่อ"')
            return
        }
        if (newCustomer && customer_name == "" || customer_name == null) {
            this.openNotificationWithIcon('error', "Required failed", 'กรุณากรอกข้อมูล "ชื่อบริษัท"')
            return
        }
        if (telephone == "") {
            this.openNotificationWithIcon('error', "Required failed", 'กรุณากรอกข้อมูล "เบอร์ติดต่อ"')
            return
        }
        this.updateCustomerAvatar()
    }

    async updateCustomerAvatar() {
        const {
            id, name, company, telephone, email, line, 
            customer_name, customer_address, customer_tax,avatar
        } = this.state
        
        let data = {
            id: id,
            name: name,
            company: company,
            telephone: telephone,
            email: email,
            line: line,
            image:null,
            user:this.props.userName,

            customer_name: customer_name,
            customer_address: customer_address,
            customer_tax: customer_tax,
        }


        let uploadFileList = avatar.filter(item => item.file)
        if (uploadFileList.length) {
            let formData = new FormData();
            uploadFileList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                data.image = fileNameList[0]
            }
        } else if (avatar.length) {
            data.image = avatar[0].name
        }
        await this.updateCustomer(data)

        // let fileList = []
        // avatar.map(item => {
        //     if (item.file != null) {
        //         fileList.push(item)
        //     }
        // })

        // if (fileList.length > 0) {
        //     let formData = new FormData();
        //     fileList.map((item, index) => formData.append("article" + index, item.file))
        //     ppost('/api/upload/file/image/customer', formData)
        //         .then(response => {
        //             if (response.status >= 400) {
        //                 throw new Error("Bad response from server");
        //             }
        //             if (response.fileName.length > 0) {
        //                 let images = response.fileName.map(image => image)
        //                 if (avatar.length>0) {
        //                     data.image = images.shift()
        //                 } 
        //             }
        //             this.updateCustomer(data)
        //         })
        //         .catch(err => {
        //             console.log(err.message)
        //         })
        // }else{
        //     if(avatar.length>0){
        //         let nameImgCover = avatar.map(item => {
        //             return item.name
        //         })
        //         data.image = nameImgCover
        //     }
        //     this.updateCustomer(data)
        // }
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }


    updateCustomer(data){
        const {id,newCustomer} = this.state
        let url = '/api/administrator/user/customer/' + this.props.type; 
        if (newCustomer) {
            url += '/new'
        }
        if (this.props.type == 'edit') {
            url = '/api/administrator/user/customer/'
            put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (this.props.type == 'update') {
                    this.socket.emit('register', id);
                }
                this.setState({newCustomer : false})
                this.props.onClose()
            })
            .catch(err => {
                console.log(err.message)
            })
        }else{
            post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (this.props.type == 'update') {
                    this.socket.emit('register', id);
                }
                this.setState({newCustomer : false})
                this.props.onClose()
            })
            .catch(err => {
                console.log(err.message)
            })
        }



        
    }

    companyListToElem() {
        let result = this.state.companyListFilter.map((item, i) => <Option key={i} value={item.id} title={item.name}>{item.name}</Option>)
        return result
    }


    onSearchCompany(value) {
        let companyListFilter = this.state.companyList.filter(item => {
            let result = fuzzysearch((value).toString().toLowerCase(), (item.name).toString().toLowerCase())
            return result
        })
        this.setState({ companyListFilter: companyListFilter.slice(0, 100) })
    }

    render() {
        const formItemLayout = {
            // labelCol: {
            //     sm: { span: 24 },
            //     md: { span: 6 },
            // },
            // wrapperCol: {
            //     sm: { span: 24 },
            //     md: { span: 18 },
            // },
        };
        return (
            <Modal
                title="Register"
                visible={this.props.visible}
                onOk={() => this.handleOk()}
                onCancel={() => this.props.onClose()}
            >
                <Row className='detail' >                
                    <Col span={24} >
                        <FormItem {...formItemLayout} >
                            <ImageUpload
                                label={'Upload image cover'}
                                limit={1}
                                dataSource={this.state.avatar}
                                updateDataSource={(dataSource) => this.setState({ avatar: dataSource })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem {...formItemLayout} required={true} >
                            <Input
                                value={this.state.name}
                                prefix={<Icon type="user" style={{ color:'rgb(72, 197, 235)' }} />}
                                placeholder={'Name'}
                                onChange={(event) => this.setState({ name: event.target.value })}
                            />
                        </FormItem>
                    </Col>

                    <Col span={24}>
                        <FormItem {...formItemLayout} required={true} >
                            <Input
                                value={this.state.telephone}
                                prefix={<Icon type="mobile" style={{ color:'rgb(72, 197, 235)' }} />}
                                placeholder={'Telephone'}
                                onChange={(event) => this.setState({ telephone: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem {...formItemLayout} required={false} >
                            <Input
                                value={this.state.email}
                                prefix={<Icon type="mail" style={{ color:'rgb(72, 197, 235)' }} />}
                                placeholder={'E-mail'}
                                onChange={(event) => this.setState({ email: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem {...formItemLayout} required={false} >
                            <Input
                                value={this.state.username}
                                prefix={<Icon type="user" style={{ color:'rgb(72, 197, 235)' }} />}
                                placeholder={'Username'}
                                onChange={(event) => this.setState({ username: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem {...formItemLayout} required={false} >
                            <Input
                                value={this.state.password}
                                prefix={<Icon type="lock" style={{ color:'rgb(72, 197, 235)' }} />}
                                placeholder={'Password'}
                                onChange={(event) => this.setState({ password: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem {...formItemLayout} required={false} >
                            <Input
                                value={this.state.confirmPassword}
                                prefix={<Icon type="lock" style={{ color:'rgb(72, 197, 235)' }} />}
                                placeholder={'Confirm Password'}
                                onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                            />
                        </FormItem>
                    </Col>

                    {/* <Col span={24}>
                        <FormItem {...formItemLayout} required={false} label={'ไลน์ไอดี'}>
                            <Input
                                value={this.state.line}
                                placeholder={'Line'}
                                onChange={(event) => this.setState({ line: event.target.value })}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        {!this.state.newCustomer ?
                            <FormItem {...formItemLayout} required={true} label={'บริษัท'}>
                                <Col span={this.state.username ? 24 : 18}>
                                    <Select
                                        showSearch
                                        optionFilterProp={'title'}
                                        onSearch={(value) => this.onSearchCompany(value)}
                                        value={this.state.company}
                                        style={{ width: '100%' }}
                                        placeholder={'please select ...'}
                                        onChange={(value) => this.setState({ company: value })}
                                    >
                                        {this.companyListToElem()}
                                    </Select>
                                </Col>
                                {!this.state.username ? 
                                    <Col span={6}>
                                        <Checkbox
                                            style={{ padding: 5 }}
                                            checked={this.state.newCustomer}
                                            onChange={(event) => this.setState({ newCustomer: event.target.checked })}
                                        >
                                            New
                                        </Checkbox>
                                    </Col>
                                    :
                                    null
                                }
                            </FormItem>
                            :
                            null
                        }
                        {this.state.newCustomer ?
                            <FormItem {...formItemLayout} required={true} label={'ชื่อบริษัท'}>
                                <Col span={18}>
                                    <Input
                                        value={this.state.customer_name}
                                        placeholder={'Customer name'}
                                        onChange={(event) => this.setState({ customer_name: event.target.value })}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Checkbox
                                        style={{ padding: 5 }}
                                        checked={this.state.newCustomer}
                                        onChange={(event) => this.setState({ newCustomer: event.target.checked })}
                                    >
                                        New
                                </Checkbox>
                                </Col>
                            </FormItem>
                            :
                            null
                        }
                        {this.state.newCustomer ?
                            <FormItem {...formItemLayout} label={'เลขที่ภาษีบริษัท'}>
                                <Col span={18}>
                                    <Input
                                        value={this.state.customer_tax}
                                        placeholder={'Customer tax'}
                                        onChange={(event) => this.setState({ customer_tax: event.target.value })}
                                    />
                                </Col>
                            </FormItem>
                            :
                            null
                        }
                        {this.state.newCustomer ?
                            <FormItem {...formItemLayout} label={'ที่อยู่บริษัท'}>
                                <Col span={18}>
                                    <Input
                                        value={this.state.customer_address}
                                        placeholder={'Customer address'}
                                        onChange={(event) => this.setState({ customer_address: event.target.value })}
                                    />
                                </Col>
                            </FormItem>
                            :
                            null
                        }
                    </Col> */}
                </Row>
            </Modal>
        )
    }
}

export default Register
