const initialState = {
    userAdministratorId: null,
};


const forgotpasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD': {
            return Object.assign({}, state, { userAdministratorId: action.payload })
        }
        case 'CLEAR_USER_ADMINISTRATOR_ID_FORGOT_PASSWORD': {
            return Object.assign({}, state, { userAdministratorId: null })
        }
        default:
            return state;
    }
}

export default forgotpasswordReducer;