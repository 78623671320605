import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Modal,notification } from 'antd';
import { get, post, put, ppost } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import config from '../../../config.json'
import './style.scss'
notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const { Meta } = Card;

class CategoryWork extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            imageCoverList: [],
        }
    }


    getCategoryWorkDetail() {
        const { editId, folderName } = this.props
        let url = '/api/administrator/crm/category/work/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }

                let imageCoverList = []
                if (response.image) {
                    imageCoverList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                        file: null,
                        name: response.image
                    }]
                }

                this.setState({
                    name: response.name,
                    imageCoverList: imageCoverList,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getCategoryWorkDetail()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible != this.props.visible) {
            if (this.props.editId) {
                this.getCategoryWorkDetail()
            }
        }
    }



    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }



    async updateCategoryWork() {
        const { name,imageCoverList } = this.state
        if (!name || name == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Category Work Name".')
            return
        }
        let data = {
            name: name,
            user: this.props.administratorId,
        }

        let uploadFileList = imageCoverList.filter(item => item.file)
        if (uploadFileList.length) {
            let formData = new FormData();
            uploadFileList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                data.image = fileNameList[0]
            }
        } else if (imageCoverList.length) {
            data.image = imageCoverList[0].name
        }
       
        if (this.props.editId) {
            let url = '/api/administrator/crm/category/work/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.setState({
                        name: '',
                        imageCoverList: []
                    })
                    this.props.onClose()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/administrator/crm/category/work';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.setState({
                        name: '',
                        imageCoverList: []
                    })
                    this.props.onClose()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }


    }

    onClickOk() {
        this.updateCategoryWork()
    }
    onClickCancel() {
        this.setState({
            name: '',
            imageCoverList: []
        })
        this.props.onClose()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 20 },
            },
        };
        const { visible } = this.props
        return (
            <div>
                <Modal
                    title={this.props.editId ? 'Edit Category Work' : 'Create Category Work'}
                    visible={visible}
                    onOk={() => this.onClickOk()}
                    onCancel={() => this.onClickCancel()}
                    style={{ textAlign: 'center' }}
                    footer={[
                        <Button key="back" onClick={() => this.onClickCancel()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                            {'Cancel'}
                        </Button>,
                        <Button key="submit" onClick={() => this.onClickOk()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                            {this.props.editId ? 'Edit' : 'Create'}
                        </Button>,
                    ]}
                >
                    <Row gutter={24} style={{textAlign:'center'}}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Name'} required={true}>
                                <Input
                                    value={this.state.name}
                                    onChange={(event) => this.setState({ name: event.target.value })}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <ImageUpload
                                label={'Upload image icon'}
                                limit={1}
                                dataSource={this.state.imageCoverList}
                                updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

CategoryWork.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

CategoryWork.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps, null)(CategoryWork);

