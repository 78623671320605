import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon } from 'antd';
import { get, post, put } from '../../../utils';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;

class NotificationAutoSend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            catagory: 'NEW_PRODUCT',
            description: '',
            status: 1,
            catagoryList: [
                { value: 'NEW_PRODUCT', label: 'New Product' },
            ],
            statusList: [
                { value: 1, label: 'On' },
                { value: 0, label: 'Off' },
            ]
        }
    }


    getArticleDetail() {
        const { editId } = this.props
        let url = '/api/administrator/crm/notification/auto/send/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    catagory: response.catagory,
                    description: response.description,
                    status: response.status
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getArticleDetail()
        }
    }


    updateArticle() {
        const { catagory, description, status } = this.state
        let data = {
            catagory: catagory,
            description: description,
            status: status,
            user: this.props.administratorId
        }
        let url = '/api/administrator/crm/notification/auto/send/';
        if (this.props.editId) {
            url = '/api/administrator/crm/notification/auto/send/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    onClickOk() {
        this.updateArticle()
    }
    onClickCancel() {
        this.setState({
            catagory: 'NEW_PRODUCT',
            description: '',
            status: 1
        })
        this.props.backpage()
    }
    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <div className='notificationautosend-page-wrapper'  style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                        <Icon style={{ fontSize: 25, fontWeight: 10, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 10, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Broad cast Auto Send' : 'Create Broad cast Auto Send'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Catagory'}>
                                    <Select
                                        value={this.state.catagory}
                                        onChange={(value) => this.setState({ catagory: value })}
                                        disabled
                                    >
                                        {this.state.catagoryList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Description'}>
                                    <Input
                                        value={this.state.description}
                                        onChange={(event) => this.setState({ description: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Status'}>
                                    <Select
                                        value={this.state.status}
                                        onChange={(value) => this.setState({ status: value })}
                                    >
                                        {this.state.statusList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
})

export default connect(mapStateToProps, null)(NotificationAutoSend);

