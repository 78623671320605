import { LOGIN_SUCCESS, LOGOUT, SIGNIN_MAIN_CUSTOMER_SUCCESS, SIGNOUT_MAIN_CUSTOMER_SUCCESS, INITIALIZE_LOGIN } from '../actions/types';

export function initializeLogin(payload) {
    return {
        type: INITIALIZE_LOGIN,
        payload: payload
    };
}


export function loginSuccess(payload) {
    return {
        type: LOGIN_SUCCESS,
        payload: payload
    };
}

export function logout(payload) {
    return {
        type: LOGOUT,
    };
}

export function signinMainCustomerSuccess(payload) {
    return {
        type: SIGNIN_MAIN_CUSTOMER_SUCCESS,
        payload: payload
    };
}

export function signoutMainCustomerSuccess(payload) {
    return {
        type: SIGNOUT_MAIN_CUSTOMER_SUCCESS,
        payload: payload
    };
}

// export function loginFailure(payload) {
//     return {
//         type: LOGIN_FAILURE,
//         payload: payload
//     };
// }
