import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Button, Icon, Input, Select, notification } from 'antd';
import { get, post } from '../../../utils';
import { Link } from 'react-router-dom'
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class FrequentlyAskedQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            frequentlyAskedQuestionsId: null,
            languageList: [],
            frequentlyAskedQuestionsLanguageList: []
        }
    }


    getLanguageList() {
        let url = '/api/crm/administrator/language/raw';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ languageList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getFrequentlyAskedQuestions() {
        let url = '/api/crm/administrator/faq';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log("getFrequentlyAskedQuestions", response)
                this.setState({ frequentlyAskedQuestionsLanguageList: response.frequently_asked_questions_language_list })

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getLanguageList()
        this.getFrequentlyAskedQuestions()
    }

    validateFrequentlyAskedQuestionsLanguageList() {
        let result = {
            success: true,
            message: 'System notification',
            description: ''
        }
        this.state.frequentlyAskedQuestionsLanguageList.map((item, index) => {
            if (result.success) {
                if (!item.language_id || item.language_id == '') {
                    result.success = false
                    result.description = `Please enter "Language" in line ID 0000${index + 1} .`
                    return
                }
                if (!item.name || item.name == '') {
                    result.success = false
                    result.description = `Please enter "Name" in line ID 0000${index + 1} .`
                    return
                }
            }
        })
        return result
    }

    updateFrequentlyAskedQuestions() {
        let validateFrequentlyAskedQuestionsLanguageListResult = this.validateFrequentlyAskedQuestionsLanguageList()
        if (!validateFrequentlyAskedQuestionsLanguageListResult.success) {
            openNotificationWithIcon('warning', 'System notification', validateFrequentlyAskedQuestionsLanguageListResult.description)
            return
        }

        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item, { detail_list: JSON.stringify(item.detail_list) }))
        let data = {
            frequently_asked_questions_language_list: JSON.stringify(frequentlyAskedQuestionsLanguageList),
        }

        let url = '/api/crm/administrator/faq';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save swiper success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateFrequentlyAskedQuestions()
    }

    onClickCancel() {
        this.getFrequentlyAskedQuestions()
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }


    updateFrequentlyAskedQuestionsLanguageStep(dataIndex, detailIndex, index, name, value) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList[dataIndex].detail_list[detailIndex].step_list[index][name] = value
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }

    addFrequentlyAskedQuestionsLanguageStep(dataIndex, detailIndex) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList[dataIndex].detail_list[detailIndex].step_list.push({
            name: '',
        })
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }
    deleteFrequentlyAskedQuestionsLanguageStep(dataIndex, detailIndex, index) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList[dataIndex].detail_list[detailIndex].step_list = frequentlyAskedQuestionsLanguageList[dataIndex].detail_list[detailIndex].step_list.filter((item, i) => i !== index)
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }

    renderFrequentlyAskedQuestionsLanguageStep(stepList, dataIndex, detailIndex) {
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        return (
            <div>
                <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Button style={{ width: '100%' }} onClick={() => this.addFrequentlyAskedQuestionsLanguageStep(dataIndex, detailIndex)}>{'+ Add Step'}</Button>
                    </Col>
                </Row>
                {stepList.map((item, index) => {
                    return (
                        <Card style={{ marginBottom: 10 }}>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Line Step ID'}>
                                        <p style={{ margin: 0, fontSize: 14, padding: 7 }}>{`0000${index + 1}`}</p>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={14} xl={14} >
                                    <FormItem {...formItemLayout} label={'Name'} required={true}>
                                        <Input
                                            value={item.name}
                                            onChange={(event) => this.updateFrequentlyAskedQuestionsLanguageStep(dataIndex, detailIndex, index, 'name', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} >
                                    <Button style={{ width: '100%' }} type={'danger'} onClick={() => this.deleteFrequentlyAskedQuestionsLanguageStep(dataIndex, detailIndex, index)}>{'Delete'}</Button>
                                </Col>
                            </Row>
                        </Card>
                    )
                })}
                <Row gutter={24} type="flex" style={{ justifyContent: "center", marginBottom: 10 }} >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Button style={{ width: '100%' }} type={'danger'} onClick={() => this.deleteFrequentlyAskedQuestionsLanguageDetail(dataIndex, detailIndex)}>{'Delete Detail'}</Button>
                    </Col>
                </Row>
            </div>
        )
    }


    updateFrequentlyAskedQuestionsLanguageDetail(dataIndex, index, name, value) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList[dataIndex].detail_list[index][name] = value
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }

    addFrequentlyAskedQuestionsLanguageDetail(dataIndex) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList[dataIndex].detail_list.push({
            title: '',
            description: '',
            step_list: []
        })
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }
    deleteFrequentlyAskedQuestionsLanguageDetail(dataIndex, index) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList[dataIndex].detail_list = frequentlyAskedQuestionsLanguageList[dataIndex].detail_list.filter((item, i) => i !== index)
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }

    renderFrequentlyAskedQuestionsLanguageDetail(detailList, dataIndex) {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div>
                <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Button style={{ width: '100%' }} onClick={() => this.addFrequentlyAskedQuestionsLanguageDetail(dataIndex)}>{'+ Add Detail'}</Button>
                    </Col>
                </Row>
                {detailList.map((item, index) => {
                    return (
                        <Card style={{ marginBottom: 10 }}>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <FormItem {...formItemLayout} label={'Line Detail ID'}>
                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16} >
                                    <FormItem {...formItemLayout} label={'Title'} required={true}>
                                        <Input
                                            value={item.title}
                                            onChange={(event) => this.updateFrequentlyAskedQuestionsLanguageDetail(dataIndex, index, 'title', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Description'} required={true}>
                                        <TextArea
                                            rows={4}
                                            value={item.description}
                                            onChange={(event) => this.updateFrequentlyAskedQuestionsLanguageDetail(dataIndex, index, 'description', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            {this.renderFrequentlyAskedQuestionsLanguageStep(item.step_list, dataIndex, index)}
                        </Card>
                    )
                })}
                <Row gutter={24} type="flex" style={{ justifyContent: "center", marginBottom: 10 }} >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Button style={{ width: '100%' }} type={'danger'} onClick={() => this.deleteFrequentlyAskedQuestionsLanguage(dataIndex)}>{'Delete'}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    updateFrequentlyAskedQuestionsLanguage(index, name, value) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList[index][name] = value
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }

    addFrequentlyAskedQuestionsLanguage() {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.map(item => Object.assign({}, item))
        frequentlyAskedQuestionsLanguageList.push({
            language_id: null,
            name: '',
            detail_list: [],
        })
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }

    deleteFrequentlyAskedQuestionsLanguage(index) {
        let frequentlyAskedQuestionsLanguageList = this.state.frequentlyAskedQuestionsLanguageList.filter((item, i) => i !== index)
        this.setState({ frequentlyAskedQuestionsLanguageList: frequentlyAskedQuestionsLanguageList })
    }
    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left' }} style={{ cursor: 'pointer' }}>
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{"FAQ"}</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <Button style={{ width: '100%' }} onClick={() => this.addFrequentlyAskedQuestionsLanguage()}>{'+ Add'}</Button>
                        </Col>
                    </Row>
                    {this.state.frequentlyAskedQuestionsLanguageList.map((item, index) => {
                        return (
                            <Card style={{ marginBottom: 10 }}>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                        <FormItem {...formItemLayout} label={'Line ID'}>
                                            <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Language'} required={true}>
                                            <Select
                                                allowClear={true}
                                                value={item.language_id}
                                                onChange={(value) => this.updateFrequentlyAskedQuestionsLanguage(index, 'language_id', value)}
                                                style={{ width: '100%' }}
                                            >
                                                {this.state.languageList.length ?
                                                    this.state.languageList.map((item, index) => <Option key={index} value={item.id} disabled={this.state.frequentlyAskedQuestionsLanguageList.map(r => r.language_id).includes(item.id)} >{item.name}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                        <FormItem {...formItemLayout} label={'Name'} required={true}>
                                            <Input
                                                value={item.name}
                                                onChange={(event) => this.updateFrequentlyAskedQuestionsLanguage(index, 'name', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                {this.renderFrequentlyAskedQuestionsLanguageDetail(item.detail_list, index)}
                            </Card>
                        )
                    })}
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(FrequentlyAskedQuestions);
