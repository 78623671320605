import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, notification,Select,Tabs,TimePicker,Table,DatePicker,Upload   } from 'antd';
import moment from 'moment';
import ImageUpload from '../../../containers/ImageUpload';
import CustomerOverLimitModal from '../../../containers/CustomModal/CustomerOverLimitModal';
import { get, put, post, ppost,del } from '../../../utils';
import config from '../../../config.json'
import defaultSquareSrc from '../../../resource/default/300x300-square.png'

import TableReview from './TableReview'
// import CustomerAddress from './CustomerAddress'
import './style.scss'

const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Meta } = Card;
const { Search } = Input;

const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

let dataFormat = 'DD-MM-YYYY'

class Technician extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryWorkList: [],
            levelMemberList: [],
            provinceList:[],
            districtList:[],
            subDistrictList:[],

            name: '',
            telephone: '',
            email: '',
            username: '',
            password: null,
            levelMember: 2,
            categoryWork: [],
            imageList: [],
            imageListDelete: [],

            districtId: null,
            subDistrictId: null,
            provinceId: null,
            webSite: '',
            startTime:null,
            endTime:null,

            verifiedStatus:'wait_verify',
            idCardImage: [],
            idCardWithPeopleImage: [],
            companyName: '',
            taxNumber: '',
            location: '',
            phoneNumber: '',
            companyFile: [],
            companyOtherFileOne: [],
            companyOtherFileTwo: [],
            

            imageAvatarList: [],
            imageCoverList: [],
            visible: false,

            deleteFileList:[],

            accountPackageList:[],
            paymentMethodList:[],

            searchOrderNumber:'',
            searchPackage:'',
            searchPrice:'',
            searchPayment:'',
            searchStartDate:null,
            searchEndDate:null,
            filters: {},
            packageColumns :[],
            packageList:[]
        }
    }

    renderColumn(column) {
        if (column.includes('searchStartDate','searchEndDate')) {
            return <DatePicker
                size="small"
                // value={this.state.filters[column] && moment(this.state.filters[column], 'YYYY-MM-DD')}
                format={dataFormat}
                // onChange={date => this.setFilterState([column], date ? date.format('YYYY-MM-DD') : '')} 
                />
        }
        return <Search
            size="small"
            value={this.state[column]}
            onChange={e => this.setState({ [column]: e.target.value })}
            onBlur={e => this.setFilterState([column], e.target.value)}
            onKeyPress={e => {
                if (e.which === 13) {
                    this.setFilterState([column], e.target.value)
                }
            }}
        />
    }

    setFilterState(key, value) {
        const filters = Object.assign({}, this.state.filters);
        filters[key] = value;
        this.setState({filters: filters})
        this.getCustomerPackageList()
    }

    getCustomerDetail() {
        const { editId, folderName } = this.props
        let url = '/api/crm/administrator/user/technician/' + editId;
        get(url)
            .then( async (response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                
                if(response.success){
                    let imageAvatarList = []
                    let imageCoverList = []
                    let imageIdCardList = []
                    let imageidCardWithPeopleImageList = []
                    let companyFileList = []
                    let companyOtherFileOneList = []
                    let companyOtherFileTwoList = []
                    if (response.data.user_detail.avatar) {
                        imageAvatarList = await this.setFileArray(response.data.user_detail.avatar)
                        // imageAvatarList = [{
                        //     view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.data.user_detail.avatar}`,
                        //     file: null,
                        //     name: response.data.user_detail.avatar
                        // }]
                    }

                    if (response.data.user_detail.cover_photo) {
                        imageCoverList = await this.setFileArray(response.data.user_detail.cover_photo)
                        // imageCoverList = [{
                        //     view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.data.user_detail.cover_photo}`,
                        //     file: null,
                        //     name: response.data.user_detail.cover_photo
                        // }]
                    }

                    if (response.data.user_verified && response.data.user_verified.id_card_image) {
                        imageIdCardList = await this.setFileArray(response.data.user_verified.id_card_image)
                        // imageIdCardList = [{
                        //     view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.data.user_verified.id_card_image}`,
                        //     file: null,
                        //     name: response.data.user_verified.id_card_image
                        // }]
                    }
                    if (response.data.user_verified && response.data.user_verified.id_card_with_people_image) {
                        imageidCardWithPeopleImageList = await this.setFileArray(response.data.user_verified.id_card_with_people_image)
                    }

                    if (response.data.user_verified && response.data.user_verified.company_file) {
                        companyFileList = await this.setFileArray(response.data.user_verified.company_file)
                        // companyFileList = [{
                        //     view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.data.user_verified.company_file}`,
                        //     file: null,
                        //     name: response.data.user_verified.company_file
                        // }]
                    }
                    if (response.data.user_verified && response.data.user_verified.other_file_one) {
                        companyOtherFileOneList = await this.setFileArray(response.data.user_verified.other_file_one)
                    }

                    if (response.data.user_verified && response.data.user_verified.other_file_two) {
                        companyOtherFileTwoList = await this.setFileArray(response.data.user_verified.other_file_two)
                    }

                    let imageList = response.data.image_list.map(item => {
                        let temp = Object.assign({}, item)
                        temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
                        temp['file'] = null
                        return temp
                    })
                    if(response.data.user_detail.province_id){
                        this.getDistrict(response.data.user_detail.province_id)
                        
                    }
                    if(response.data.user_detail.district_id){
                        this.getSubDistrict(response.data.user_detail.district_id)
                        
                    }
                    
                    this.setState({
                        name: response.data.user_detail.customer_name,
                        telephone: response.data.user_detail.telephone,
                        email: response.data.user_detail.email,
                        levelMember: response.data.user_detail.level_member_id,
                        imageAvatarList: imageAvatarList,
                        imageCoverList: imageCoverList,
                        categoryWork: response.data.category_work,
                        imageList: imageList,

                        districtId: response.data.user_detail.district_id,
                        subDistrictId: response.data.user_detail.sub_district_id,
                        provinceId: response.data.user_detail.province_id,
                        webSite: response.data.user_detail.web_site,
                        startTime: response.data.user_detail.time_start,
                        endTime: response.data.user_detail.time_end,
                        companyFile: companyFileList,
                        companyOtherFileOne: companyOtherFileOneList,
                        companyOtherFileTwo: companyOtherFileTwoList,
                        idCardImage: imageIdCardList,
                        idCardWithPeopleImage: imageidCardWithPeopleImageList,

                        companyName: response.data.user_verified ? response.data.user_verified.company_name : null,
                        taxNumber: response.data.user_verified ? response.data.user_verified.tax_number : null,
                        location: response.data.user_verified ? response.data.user_verified.location : null,
                        phoneNumber: response.data.user_verified ? response.data.user_verified.phone_number : null,
                        verifiedStatus: response.data.user_verified ? response.data.user_verified.status : null,
                    })
            
                    
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async setFileArray(fileName){
        const { folderName } = this.props
        let data = [{
            view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${fileName}`,
            file: null,
            name: fileName
        }]
        return data
    }

    getCategoryWork() {
        let url = '/api/administrator/crm/category/work';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    categoryWorkList: response
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getLevelMember() {
        let url = '/api/crm/administrator/levelmember';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({
                        levelMemberList: response.data
                    })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    get columns() {
        return [{
            title: (
                <div>
                    {this.renderColumn('searchOrderNumber')}
                    <div className="column-title">
                        {'Order'}
                    </div>
                </div>
            ),
            dataIndex: 'order_number',
            key: 'order_number',
            render: (text, item, index) => {
                return (
                    <div>
                        {(text).toString().padStart(8, '0')}
                    </div>
                )
            }
            
          },
          {
            title: (
                <div>
                    {this.renderColumn('searchPackage')}
                    <div className="column-title">
                        {'Package'}
                    </div>
                </div>
            ),
            dataIndex: 'package',
            key: 'package',
            render: (text, item, index) => {
                return (
                    <Select
                        disabled={item.id && this.checkDisableCreate()}
                        id='package'
                        showSearch
                        optionFilterProp={'title'}
                        value={text}
                        onChange={(value) => this.onChangePackage(item,index,value)}
                    >
                        {this.accountPackageListToElem()}
                    </Select>
                )
            }
          },
          {
            title: (
                <div>
                    {this.renderColumn('searchPrice')}
                    <div className="column-title">
                        {'Price'}
                    </div>
                </div>
            ),
            dataIndex: 'price',
            key: 'price',
            render: (text, item, index) => {
                return (
                    <Input
                        disabled={item.id && this.checkDisableCreate()}
                        value={text}
                        onChange={(event) => this.onChangeData(index, event.target.value,'price')}
                    />
                )
            }
          },
          {
            title: (
                <div>
                    {this.renderColumn('searchPayment')}
                    <div className="column-title">
                        {'Payment'}
                    </div>
                </div>
            ),
            dataIndex: 'payment',
            key: 'payment',
            render: (text, item, index) => {
                return (
                    <Select
                        disabled={item.id && this.checkDisableCreate()}
                        id='payment'
                        showSearch
                        optionFilterProp={'title'}
                        value={text}
                        onChange={(value) => this.onChangeData(index, value,'payment')}
                    >
                        {this.paymentMethodListToElem()}
                    </Select>
                )
            }
          },
          {
            title: (
                <div>
                    {this.renderColumn('searchStartDate')}
                    <div className="column-title">
                        {'Start Date'}
                    </div>
                </div>
            ),
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text, item, index) => {
                return (
                    <DatePicker 
                        disabled={item.id && this.checkDisableCreate()}
                        value={text ? moment(text,'YYYY-MM-DD') : null}
                        onChange={(date, dateString) => this.onChangeData(index, moment(dateString,dataFormat).format('YYYY-MM-DD'),'start_date')} 
                        format={dataFormat}
                    />
                )
            }
          },
          {
            title: (
                <div>
                    {this.renderColumn('searchEndDate')}
                    <div className="column-title">
                        {'Expiration Date'}
                    </div>
                </div>
            ),
            dataIndex: 'end_date',
            key: 'end_date',
            render: (text, item, index) => {
                return (
                    <DatePicker 
                        disabled={item.id && this.checkDisableCreate()}
                        value={text ? moment(text,'YYYY-MM-DD') : null}
                        onChange={(date, dateString) => this.onChangeData(index, moment(dateString,dataFormat).format('YYYY-MM-DD'),'end_date')} 
                        format={dataFormat}
                    />
                )
            }
          },]
    }
    
    accountPackageListToElem(){
        let result = this.state.accountPackageList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name}`}>{i.name}</Option>
        })
        return result
    }
    paymentMethodListToElem(){
        let result = this.state.paymentMethodList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name}`}>{i.name}</Option>
        })
        return result
    }

    onChangePackage(item,index,value){
        let tempPackageList = Object.assign([],this.state.packageList)
        let tempAcocuntPackageList = Object.assign([],this.state.accountPackageList)
        let indexPackage = tempAcocuntPackageList.findIndex(item=> item.id == value)
        let priceForSale = item.price
        let endDate = null
        // เช็คว่ามี package id ตาม list ไหม
        if(indexPackage != -1){
            priceForSale = tempAcocuntPackageList[indexPackage].price_for_sale // ตั้งค่าราคาตาม list
            let term = tempAcocuntPackageList[indexPackage].term
            let termUnit = 'M'
            switch(tempAcocuntPackageList[indexPackage].term_unit){
                case 'day':
                    termUnit = 'd'
                    break;
                case 'month':
                    termUnit = 'M'
                    break;
                case 'year':
                    termUnit = 'y'
                    break;
                default:
                    termUnit = 'M'
                    break;
            }
            endDate = moment().add(term,termUnit).format('YYYY-MM-DD')  // ตั้งค่าวันหมดอายุตาม list
        }

        tempPackageList[index].package = value
        tempPackageList[index].price = priceForSale
        tempPackageList[index].start_date = moment().format('YYYY-MM-DD')
        tempPackageList[index].end_date = endDate
        tempPackageList[index].is_update = true

        this.setState({packageList: tempPackageList})
    }

    onChangeData(index,value,param){
        let tempPackageList = Object.assign([],this.state.packageList)
        tempPackageList[index][param] = value
        tempPackageList[index].is_update = true
        this.setState({
            packageList: tempPackageList
        })
    }

    componentDidMount() {
        this.getCategoryWork()
        this.getLevelMember()
        this.getProvince()
        this.getAccountPackage()
        this.getPaymentMethodList()
        if (this.props.editId) {
            this.getCustomerDetail()
            this.getCustomerPackageList()
            
        }
    }

    getProvince() {
        let url = '/api/crm/administrator/provinces';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({provinceList: response.data})
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getDistrict(provinceId = null) {
        let url = '/api/crm/administrator/districts';
        if(provinceId){
            url += '?province=' + provinceId
        }
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({districtList: response.data})
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getSubDistrict(districtId = null) {
        let url = '/api/crm/administrator/subdistricts';
        if(districtId){
            url += '?districts='+districtId
        }
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({subDistrictList: response.data})
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getAccountPackage() {
        let url = '/api/package';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    this.setState({accountPackageList: response.data})
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getPaymentMethodList() {
        let url = '/api/payment/method';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    paymentMethodList: responses
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    
    getCustomerPackageList() {
        const {filters} = this.state
        let url = '/api/crm/administrator/package/order/user/' + this.props.editId;
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(responses.success){
                    this.setState({
                        packageList: responses.data
                    })
                }
                
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    async uploadImageToServer(formData) {
        // upload all file type
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async uploadImageToServerCompressed(formData){
        // compressed image to width 1080
        let url = '/api/crm/administrator/upload/image'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateCustomer() {
        const { 
            name, telephone, email,levelMember, imageAvatarList,imageCoverList,categoryWork, username, password,
            districtId,subDistrictId, provinceId,webSite,startTime,endTime,
            imageListDelete,imageList,
            verifiedStatus,idCardImage,idCardWithPeopleImage,companyName,taxNumber,location,phoneNumber,companyFile,companyOtherFileOne,companyOtherFileTwo,
            packageList
        } = this.state

        if (!name || name == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Name".')
            return
        }
        if (!telephone || telephone == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Telephone".')
            return
        }
        // if (!email || email == '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please enter "E-mail".')
        //     return
        // }
        // let indexCategory = categoryWork.findIndex(item=> !item.category_work_id )
        // if(indexCategory != -1){
        //     openNotificationWithIcon('warning', 'System notification', `Please select "Category ${indexCategory + 1}".`)
        //     return
        // }

        if((startTime||endTime) && (!startTime || !endTime)){
            openNotificationWithIcon('warning', 'System notification', `Please select "business hours".`)
            return
        }

        if((startTime||endTime) && (!startTime || !endTime)){
            openNotificationWithIcon('warning', 'System notification', `Please select "business hours".`)
            return
        }
        if((startTime && endTime) && (moment(endTime,'HH:mm:ss') < moment(startTime,'HH:mm:ss'))){
            openNotificationWithIcon('warning', 'System notification', `End time less Start time".`)
            return
        }

        let data = {
            name: name,
            telephone: telephone,
            levelMember: levelMember,
            email: email,
            categoryWork: JSON.stringify(categoryWork),
            avatar: null,
            imageCover: null,

            districtId: districtId,
            subDistrictId: subDistrictId, 
            provinceId: provinceId,
            webSite: webSite,
            startTime: startTime,
            endTime: endTime,

            imageList: [],
            imageListDelete: JSON.stringify(imageListDelete),

            packageList: JSON.stringify(packageList),

            verifiedStatus: verifiedStatus ,
            idCardImage: null,
            idCardWithPeopleImage: null,
            companyName: companyName,
            taxNumber: taxNumber,
            location: location,
            phoneNumber: phoneNumber,
            companyFile: null,
            companyOtherFileOne: null,
            companyOtherFileTwo: null,
            user: this.props.administratorId
        }

        let uploadFileList = await this.setUpdateDataAndUpload(imageAvatarList,"image")
        data.avatar = uploadFileList
        // let uploadFileList = imageAvatarList.filter(item => item.file)
        // if (uploadFileList.length) {
        //     let formData = new FormData();
        //     uploadFileList.map((item, index) => formData.append("image" + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     if (fileNameList.length) {
        //         data.avatar = fileNameList[0]
        //     }
        // } else if (imageAvatarList.length) {
        //     data.avatar = imageAvatarList[0].name
        // }
        
        let uploadFileCoverList = await this.setUpdateDataAndUpload(imageCoverList,"image")
        data.imageCover = uploadFileCoverList
        // let uploadFileCoverList = imageCoverList.filter(item => item.file)
        // if (uploadFileCoverList.length) {
        //     let formData = new FormData();
        //     uploadFileCoverList.map((item, index) => formData.append("image" + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     if (fileNameList.length) {
        //         data.imageCover = fileNameList[0]
        //     }
        // } else if (imageCoverList.length) {
        //     data.imageCover = imageCoverList[0].name
        // }

        let uploadFileImageList = imageList.filter(item => item.file)
        if (uploadFileImageList.length) {
            let formData = new FormData();
            uploadFileImageList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await this.uploadImageToServerCompressed(formData)
            data.imageList = fileNameList
        } 

        // upload id card
        let uploadIdCardList = await this.setUpdateDataAndUpload(idCardImage,"image")
        data.idCardImage = uploadIdCardList
        // let uploadIdCardList = idCardImage.filter(item => item.file)
        // if (uploadIdCardList.length) {
        //     let formData = new FormData();
        //     uploadIdCardList.map((item, index) => formData.append("image" + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     if (fileNameList.length) {
        //         data.idCardImage = fileNameList[0]
        //     }
        // } else if (idCardImage.length) {
        //     data.idCardImage = idCardImage[0].name
        // }

        // upload id card with people
        let uploadIdCardWithPeopleList = await this.setUpdateDataAndUpload(idCardWithPeopleImage,"image")
        data.idCardWithPeopleImage = uploadIdCardWithPeopleList

        // upload companyFile
        let uploadCompanyFileList = await this.setUpdateDataAndUpload(companyFile,"file")
        data.companyFile = uploadCompanyFileList
        // let uploadCompanyFileList = companyFile.filter(item => item.file)
        // if (uploadCompanyFileList.length) {
        //     let formData = new FormData();
        //     uploadCompanyFileList.map((item, index) => formData.append("image" + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     if (fileNameList.length) {
        //         data.companyFile = fileNameList[0]
        //     }
        // } else if (companyFile.length) {
        //     data.companyFile = companyFile[0].name
        // }
        
        // upload companyOtherFile one
        let uploadCompanyOtherFileOneList = await this.setUpdateDataAndUpload(companyOtherFileOne,"file")
        data.companyOtherFileOne = uploadCompanyOtherFileOneList

        // upload companyOtherFile two
        let uploadCompanyOtherFileTwoList = await this.setUpdateDataAndUpload(companyOtherFileTwo,"file")
        data.companyOtherFileTwo = uploadCompanyOtherFileTwoList


        if (this.props.editId) {
            this.saveDeleteFileList()
            let url = '/api/crm/administrator/user/technician/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    if (!response.success) {
                        switch (response.error.code) {
                            case 'DUPLICATE_TELEPHONE':
                                openNotificationWithIcon('error', 'System notification', 'Duplicate telephone.')
                                break;
                            case 'DUPLICATE_EMAIL':
                                openNotificationWithIcon('error', 'System notification', 'Duplicate email.')
                                break;
                        }
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            // let url = '/api/user/customer';
            // post(url, data)
            //     .then(response => {
            //         if (response.status >= 400) {
            //             throw new Error("Bad response from server");
            //         }
            //         if (!response.success) {
            //             switch (response.error.code) {
            //                 case 'OVER_LIMIT_CUSTOMER':
            //                     this.showCustomerOverLimitModal()
            //                     break;
            //                 case 'DUPLICATE_USERNAME':
            //                     openNotificationWithIcon('error', 'System notification', 'Duplicate username.')
            //                     break;
            //                 case 'DUPLICATE_TELEPHONE':
            //                     openNotificationWithIcon('error', 'System notification', 'Duplicate telephone.')
            //                     break;
            //                 case 'DUPLICATE_EMAIL':
            //                     openNotificationWithIcon('error', 'System notification', 'Duplicate email.')
            //                     break;
            //             }
            //         } else {
            //             this.props.backpage()
            //         }
            //     })
            //     .catch(err => {
            //         console.log(err.message)
            //     })
        }
    }

    saveDeleteFileList() {
        let deleteFileList = this.state.deleteFileList
        deleteFileList.map(deleteFile => {
            this.deleteFileInServer(deleteFile)
        })
    }

    deleteFileInServer(fileName) {
        del('/api/crm/customer/upload/file', { fileName })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async setUpdateDataAndUpload(datasource,type){
        let uploadDatasourceList = datasource.filter(item => item.file)
        if (uploadDatasourceList.length) {
            let formData = new FormData();
            uploadDatasourceList.map((item, index) => formData.append(type + index, item.file))
            let fileNameList = null
            if(type == 'image'){
                fileNameList = await this.uploadImageToServerCompressed(formData)
            }else{
                fileNameList = await this.uploadImageToServer(formData)
            }

            if (fileNameList.length) {
                return fileNameList[0]
            }
            return null
        }
        else if (datasource.length) {
            return datasource[0].name
        }

        return null
    }


    showCustomerOverLimitModal() {
        this.setState({ visible: true })
    }

    onClickOk() {
        this.updateCustomer()
    }
    onClickCancel() {
        this.setState({
            name: '',
            telephone: '',
            email: '',
            username: '',
            password: '',
        })
        this.props.backpage()
    }

    onChangeProvince(value){
        this.setState({
            provinceId: value,
            districtId :null,
            subdistrictId:null
        })
        this.getDistrict(value)
    }
    onChangeDistrict(value){
        this.setState({
            districtId: value,
            subdistrictId:null
         })
        
        this.getSubDistrict(value)
    }

    setCategoryWork(value,index){
        const tempCategoryWork = Object.assign([],this.state.categoryWork)
        tempCategoryWork[index].category_work_id = value
        
        this.setState({
            categoryWork: tempCategoryWork
        })
    }
    

    levelMemberOption(){
        const {levelMemberList} = this.state
        let option = levelMemberList.map(item=><Option key={item.id} value={item.id} title={item['level_name']} >{item['level_name']}</Option>)
        return option
    }
    categoryWorkOption(){
        const {categoryWorkList} = this.state
        let option = categoryWorkList.map(item=><Option key={item.id} value={item.id} title={item['Name']} >{item['Name']}</Option>)
        return option
    }

    titleVerified(title){
        return(
            <Col style={{display:'flex',alignItems:'center'}}  xs={24} sm={24} md={24} lg={24} xl={24} >
                <span>{title}</span>
                <div style={{marginLeft:5,flex:1,height :1,background:'#e8e8e8'}}/>
            </Col>
        )
    }

    provinceListToElem() {
        let result = this.state.provinceList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }

    districtListToElem() {
        let result = this.state.districtList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }
    
    subdistrictListToElem() {
        let result = this.state.subDistrictList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }

    getStorageDetail(callback) {
        let url = '/api/crm/administrator/setting/storage';
        get(url)
            .then(responses => {
                callback({ success: true, storage: responses })
            })
            .catch(error => {
                console.log(error.message)
                callback({ success: false, error: error })
            })
    }

    // onClickButtonUploadFileIdCard(event) {
    //     let fileSelect = Array.from(this.refs.idCard.files)
    //     this.getStorageDetail((storageDetail) => {
    //         if (storageDetail.success) {
    //             let dataSource = this.state.idCardImage.map(item => Object.assign({}, item))
    //             if (fileSelect && fileSelect.length > 0) {
    //                 let fileSize = fileSelect[0].size / (1024 * 1024 * 1024)
    //                 if (storageDetail.storage.free < fileSize) {
    //                     // this.showStorageOverLimitModal()
    //                 } else {
    //                     let temp = { file: fileSelect[0],name:fileSelect[0].name }
    //                     let reader = new FileReader();
    //                     reader.readAsDataURL(fileSelect[0]);
    //                     reader.onload = (e) => {
    //                         temp['view'] = e.target.result
    //                         dataSource.push(temp);
    //                         this.setState({ idCardImage: dataSource })
    //                     };
    //                 }
    //                 event.target.value = null;
    //             }
    //         }
    //     })
    // }

    onUploadFileImage(event,stateName) {
        let fileSelect = Array.from([event])
        this.getStorageDetail((storageDetail) => {
            if (storageDetail.success) {
                let dataSource = this.state[`${stateName}`].map(item => Object.assign({}, item))
                if (fileSelect && fileSelect.length > 0) {
                    let fileSize = fileSelect[0].size / (1024 * 1024 * 1024)
                    if (storageDetail.storage.free < fileSize) {
                        // this.showStorageOverLimitModal()
                    } else {
                        let temp = { file: fileSelect[0],name:fileSelect[0].name }
                        let reader = new FileReader();
                        reader.readAsDataURL(fileSelect[0]);
                        reader.onload = (e) => {
                            temp['view'] = e.target.result
                            dataSource.push(temp);
                            this.setState({ [`${stateName}`]: dataSource })
                        };
                    }
                    event.target.value = null;
                }
            }
        })
        return false
    }

    // onClickButtonUploadFileCompany(event) {
    //     let fileSelect = Array.from(this.refs.fileCompany.files)
    //     this.getStorageDetail((storageDetail) => {
    //         if (storageDetail.success) {
    //             let dataSource = this.state.companyFile.map(item => Object.assign({}, item))
    //             if (fileSelect && fileSelect.length > 0) {
    //                 let fileSize = fileSelect[0].size / (1024 * 1024 * 1024)
    //                 if (storageDetail.storage.free < fileSize) {
    //                     // this.showStorageOverLimitModal()
    //                 } else {
    //                     let temp = { file: fileSelect[0],name:fileSelect[0].name }
    //                     let reader = new FileReader();
    //                     reader.readAsDataURL(fileSelect[0]);
    //                     reader.onload = (e) => {
    //                         temp['view'] = e.target.result
    //                         dataSource.push(temp);
    //                         console.log('companyFilecompanyFile2',dataSource)
    //                         this.setState({ companyFile: dataSource })
    //                     };
    //                 }
    //                 event.target.value = null;
    //             }
    //         }
    //     })
    // }

    // deleteFileIdCard(){
    //     const { idCardImage } = this.state
    //     let tempDeleteFileList = Object.assign([],this.state.deleteFileList) 
    //     tempDeleteFileList.push(idCardImage[0].name)
    //     if(!idCardImage[0].file){
    //         tempDeleteFileList.push(idCardImage[0].name)
    //     }
    //     this.setState({
    //         idCardImage:[],
    //         deleteFileList: tempDeleteFileList
    //     })
    // }

    deleteFile(stateName){
        let tempDeleteFileList = Object.assign([],this.state.deleteFileList)
        if(!this.state[`${stateName}`][0].file){
            tempDeleteFileList.push(this.state[`${stateName}`][0].name)
        }
        this.setState({
            [`${stateName}`]:[],
            deleteFileList: tempDeleteFileList
        })
    }

    // deleteFileCompany(){
    //     const { companyFile } = this.state
    //     let tempDeleteFileList = Object.assign([],this.state.deleteFileList)
    //     if(!companyFile[0].file){
    //         tempDeleteFileList.push(companyFile[0].name)
    //     } 
    //     this.setState({
    //         companyFile:[],
    //         deleteFileList: tempDeleteFileList
    //     })
    // }

    onClickCreate(){
        let tempPackageList = Object.assign([],this.state.packageList)
        tempPackageList.unshift(
            {
                id: null,
                order_number: '',
                package: null,
                price: '',
                payment: null,
                start_date: null,
                end_date: null,
                is_update:true
            })
        this.setState({
            packageList : tempPackageList,
            levelMember: 3
        })
    }
    checkDisableCreate(){
        let tempPackageList = Object.assign([],this.state.packageList)
        let index = tempPackageList.findIndex(item=> !item.id)
        if(index == -1){
            return false
        }
        return true
    }

    render() {
        const { 
            categoryWork ,
            districtId,subDistrictId,provinceId,webSite,startTime,endTime,
            companyName,taxNumber,location,phoneNumber,idCardImage,idCardWithPeopleImage,companyFile,companyOtherFileOne,companyOtherFileTwo,
            packageList,packageColumns,filters,searchOrderNumber
        } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        console.log('deleteFileListdeleteFileListdeleteFileList',this.state.deleteFileList)

        return (
            <div className='customer-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => this.props.backpage()} >
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit User Technician' : 'Create User Technician'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>

                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Name'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Telephone'} required={true}>
                                    <Input
                                        value={this.state.telephone}
                                        onChange={(event) => this.setState({ telephone: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'E-mail'}>
                                    <Input
                                        value={this.state.email}
                                        onChange={(event) => this.setState({ email: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Level'} required={true}>
                                    <Select 
                                        value={this.state.levelMember}
                                        onChange={(value) => this.setState({ levelMember: value })}
                                    >
                                        {this.levelMemberOption()}
                                    </Select>

                                </FormItem>
                            </Col>
                            {
                                categoryWork.map((item,index)=>{
                                    return(
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            <FormItem {...formItemLayout} label={'Category ' + (index+1) } >
                                                <Select 
                                                    value={item.category_work_id}
                                                    onChange={(value) => this.setCategoryWork(value,index)}
                                                >
                                                    {this.categoryWorkOption()}
                                                </Select>

                                            </FormItem>
                                        </Col>
                                    )
                                })
                            }
                            

                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row>
                                    <Col sm={24} md={8} lg={8} style={{ flexDirection: 'row' }}>
                                        <ImageUpload
                                            customCss={{ maxWidth:'unset' }}
                                            label={'Upload image avatar'}
                                            limit={1}
                                            dataSource={this.state.imageAvatarList}
                                            updateDataSource={(dataSource) => this.setState({ imageAvatarList: dataSource })}

                                            dataSourceDelete={[]}
                                            deleteFileList={this.state.deleteFileList}
                                            isSaveDelete={true}
                                            updateDataDelete={(dataSource)=> null}
                                            updateDeleteFileList={(dataSource)=>this.setState({deleteFileList: dataSource})}
                                        />
                                    </Col>
                                    <Col sm={24} md={14} lg={14} style={{ flexDirection: 'row' }}>
                                        <ImageUpload
                                            customCss={{ maxWidth:'unset',width:'100%' }}
                                            label={'Upload image avatar'}
                                            limit={1}
                                            dataSource={this.state.imageCoverList}
                                            updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}

                                            dataSourceDelete={[]}
                                            deleteFileList={this.state.deleteFileList}
                                            isSaveDelete={true}
                                            updateDataDelete={(dataSource)=> null}
                                            updateDeleteFileList={(dataSource)=>this.setState({deleteFileList: dataSource})}
                                        />
                                    </Col>
                                    
                                </Row>
                            </Col>
                            
                        </Row>
                        <CustomerOverLimitModal
                            visible={this.state.visible}
                            onClose={() => this.setState({ visible: false })}
                        />
                    </Form>
                    <Tabs style={{ maxWidth:850,margin:'0 auto' }} onChange={() => null} type="card">
                        <TabPane tab={"Profile"} key={"1"}>
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Province'} required={true}>
                                        <Select
                                            style={{width:'100%'}}
                                            id='Province'
                                            showSearch
                                            optionFilterProp={'title'}
                                            value={provinceId}
                                            onChange={(value) => this.onChangeProvince(value)}
                                        >
                                            {this.provinceListToElem()}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'District'} required={true}>
                                        <Select
                                            style={{width:'100%'}}
                                            id='District'
                                            showSearch
                                            optionFilterProp={'title'}
                                            value={districtId}
                                            onChange={(value) => this.onChangeDistrict(value)}
                                        >
                                            {this.districtListToElem()}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Subdistrict'} required={true}>
                                        <Select
                                            style={{width:'100%'}}
                                            id='Subdistrict'
                                            showSearch
                                            optionFilterProp={'title'}
                                            value={subDistrictId}
                                            onChange={(value) => this.setState({subDistrictId: value})}
                                        >
                                            {this.subdistrictListToElem()}
                                        </Select>
                                    </FormItem>
                                </Col>
                                    {/* districtId,subDistrictId,provinceId */}
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                        <FormItem {...formItemLayout} label={'Website'} required={true}>
                                            <Input
                                                value={webSite}
                                                onChange={(event) => this.setState({ webSite: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                        <FormItem {...formItemLayout} label={'business hours'} required={true}>
                                            <div style={{ display:'flex',alignItems:'center' }} >
                                                <TimePicker
                                                    value={startTime ? moment(startTime,'HH:mm:ss'):null}
                                                    onChange={(time, timeString) => this.setState({ startTime: timeString })}
                                                    format="HH:mm:ss"
                                                />
                                                <span style={{ margin:'0px 6px' }} >
                                                -
                                                </span>
                                                <TimePicker
                                                    value={endTime ? moment(endTime,'HH:mm:ss'):null}
                                                    onChange={(time, timeString) => this.setState({ endTime: timeString })}
                                                    format="HH:mm:ss"
                                                />
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </TabPane>
                        <TabPane tab={"Image"} key={"2"}>
                            <Card className='cardstyle'>
                                <Row>
                                    <ImageUpload
                                        customCss={{ width: 160,height:195 }}
                                        label={'Upload image avatar'}
                                        limit={32}
                                        dataSource={this.state.imageList}
                                        dataSourceDelete={this.state.imageListDelete}
                                        deleteFileList={this.state.deleteFileList}
                                        isSaveDelete={true}
                                        updateDataDelete={(dataSource)=>this.setState({imageListDelete: dataSource})}
                                        updateDeleteFileList={(dataSource)=>this.setState({deleteFileList: dataSource})}
                                        updateDataSource={(dataSource) => this.setState({ imageList: dataSource })}
                                    />
                                </Row>
                            </Card>
                        </TabPane>
                        <TabPane tab={"Verified"} key={"3"}>
                            <Card className='cardstyle'>
                                <Row>
                                    <Col style={{display:'flex',alignItems:'center'}}  xs={24} sm={24} md={12} lg={12} xl={12} >
                                    <span>{`Verified : `}</span>
                                    <Select
                                        style={{flex: 1}} 
                                        value={this.state.verifiedStatus}
                                        onChange={(value) => this.setState({ verifiedStatus: value })}
                                    >
                                        <Option key={0} value={'wait_verify'} title={'Wait verified'} ><span>Wait verified</span></Option>
                                        <Option key={1} value={'approved'} title={'Complete'} ><span>Complete</span></Option>
                                        <Option key={2} value={'reject'} title={'Reject'} ><span>Reject</span></Option>
                                    </Select>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    {this.titleVerified('Verified Bussiness Account()')}
                                    <Row>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FormItem {...formItemLayout} label={'ID card image'}>
                                            
                                            {idCardImage.map((file, i) =>
                                                <img
                                                    id="idCard"
                                                    style={{width:'100%'}}
                                                    src={file.view}
                                                    onError={(image) => image.target.src = defaultSquareSrc}
                                                />
                                                )}
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FormItem {...formItemLayout} label={'Image File'}>
                                            <div className="upload-id-card">
                                            {idCardImage.map((file, i) =>
                                                <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center',flex: 1,padding:'0px 5px' }}>
                                                    <div style={{ width : 300,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }} >
                                                        {file.name}
                                                    </div>
                                                    <div>
                                                        <span style={{ color: 'red', cursor: 'pointer' }} onClick={()=>this.deleteFile('idCardImage')}>
                                                            <Icon type="delete" /> Delete
                                                        </span>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                            {/* <Button disabled={idCardImage.length >= 1 ? true : false} value={'large'} type="primary" onClick={() => this.refs.idCard.click()}><Icon type="upload" />Upload</Button>
                                            <input style={{ display: 'none' }} type="file" ref="idCard" id="idCard" accept="image/*" onChange={(e) => this.onClickButtonUploadFileIdCard(e)} /> */}
                                            <Upload
                                                showUploadList={false}
                                                beforeUpload={(e)=>this.onUploadFileImage(e,'idCardImage')}
                                                accept="image/*"
                                            >
                                                <Button disabled={idCardImage.length >= 1 ? true : false} value={'large'} type="primary"><Icon type="upload" />Upload</Button>
                                            </Upload>
                                        </FormItem>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <FormItem {...formItemLayout} label={'ID card with people image'}>
                                                
                                                {idCardWithPeopleImage.map((file, i) =>
                                                    <img
                                                        id="idCardWithPeople"
                                                        style={{width:'100%'}}
                                                        src={file.view}
                                                        onError={(image) => image.target.src = defaultSquareSrc}
                                                    />
                                                    )}
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <FormItem {...formItemLayout} label={'Image File'}>
                                                <div className="upload-id-card">
                                                {idCardWithPeopleImage.map((file, i) =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center',flex: 1,padding:'0px 5px' }}>
                                                        <div style={{ width : 300,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }} >
                                                            {file.name}
                                                        </div>
                                                        <div>
                                                            <span style={{ color: 'red', cursor: 'pointer' }} onClick={()=>this.deleteFile('idCardWithPeopleImage')}>
                                                                <Icon type="delete" /> Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div>
                                                <Upload
                                                    showUploadList={false}
                                                    beforeUpload={(e)=>this.onUploadFileImage(e,'idCardWithPeopleImage')}
                                                    accept="image/*"
                                                >
                                                    <Button disabled={idCardWithPeopleImage.length >= 1 ? true : false} value={'large'} type="primary"><Icon type="upload" />Upload</Button>
                                                </Upload>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Row>
                                <Row gutter={24}>
                                    {this.titleVerified('Verified Bussiness Account(Corporation)')}
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FormItem {...formItemLayout} label={'Company Name'}>
                                            <Input
                                                value={companyName}
                                                onChange={(event) => this.setState({ companyName: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FormItem {...formItemLayout} label={'Tax Number'}>
                                            <Input
                                                value={taxNumber}
                                                onChange={(event) => this.setState({ taxNumber: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FormItem {...formItemLayout} label={'Company address'}>
                                            <Input
                                                value={location}
                                                onChange={(event) => this.setState({ location: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FormItem {...formItemLayout} label={'Telephone Number'}>
                                            <Input
                                                value={phoneNumber}
                                                onChange={(event) => this.setState({ phoneNumber: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                        <FormItem {...formItemLayout} label={'File'} >
                                            <div style={{ display: 'flex' }}>
                                                <div className="upload-file-compant">
                                                {companyFile.map((file, i) =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center',flex: 1,padding:'0px 5px' }}>
                                                        <div style={{ width : 300,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }} >
                                                            {
                                                                file.file ?
                                                                <span>{file.name}</span>
                                                                :
                                                                <a target="_blank" rel="noopener" href={file.view}>{file.name}</a>
                                                            }
                                                            
                                                        </div>
                                                        <div>
                                                            <span style={{ color: 'red', cursor: 'pointer' }} onClick={()=> this.deleteFile('companyFile')} >
                                                                <Icon type="delete" /> Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div>
                                                <Upload
                                                    showUploadList={false}
                                                    beforeUpload={(e)=>this.onUploadFileImage(e,'companyFile')}
                                                    accept="image/*,.pdf"
                                                >
                                                    <Button disabled={companyFile.length >= 1 ? true : false} value={'large'} type="primary"><Icon type="upload" />Upload</Button>
                                                </Upload>
                                                {/* <Button disabled={companyFile.length >= 1 ? true : false} value={'large'} type="primary" onClick={() => this.refs.fileCompany.click()}><Icon type="upload" />Upload</Button>
                                                <input style={{ display: 'none' }} type="file" ref="fileCompany" id="fileCompany" accept="file_extension" onChange={(e) => this.onClickButtonUploadFileCompany(e)} /> */}
                                            </div>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                        <FormItem {...formItemLayout} label={'Other file one'} >
                                            <div style={{ display: 'flex' }}>
                                                <div className="upload-file-compant">
                                                {companyOtherFileOne.map((file, i) =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center',flex: 1,padding:'0px 5px' }}>
                                                        <div style={{ width : 300,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }} >
                                                            {
                                                                file.file ?
                                                                <span>{file.name}</span>
                                                                :
                                                                <a target="_blank" rel="noopener" href={file.view}>{file.name}</a>
                                                            }
                                                            
                                                        </div>
                                                        <div>
                                                            <span style={{ color: 'red', cursor: 'pointer' }} onClick={()=> this.deleteFile('companyOtherFileOne')} >
                                                                <Icon type="delete" /> Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div>
                                                <Upload
                                                    showUploadList={false}
                                                    beforeUpload={(e)=>this.onUploadFileImage(e,'companyOtherFileOne')}
                                                    accept="image/*,.pdf"
                                                >
                                                    <Button disabled={companyOtherFileOne.length >= 1 ? true : false} value={'large'} type="primary"><Icon type="upload" />Upload</Button>
                                                </Upload>
                                                {/* <Button disabled={companyFile.length >= 1 ? true : false} value={'large'} type="primary" onClick={() => this.refs.fileCompany.click()}><Icon type="upload" />Upload</Button>
                                                <input style={{ display: 'none' }} type="file" ref="fileCompany" id="fileCompany" accept="file_extension" onChange={(e) => this.onClickButtonUploadFileCompany(e)} /> */}
                                            </div>
                                        </FormItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                        <FormItem {...formItemLayout} label={'Other file two'} >
                                            <div style={{ display: 'flex' }}>
                                                <div className="upload-file-compant">
                                                {companyOtherFileTwo.map((file, i) =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center',flex: 1,padding:'0px 5px' }}>
                                                        <div style={{ width : 300,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }} >
                                                            {
                                                                file.file ?
                                                                <span>{file.name}</span>
                                                                :
                                                                <a target="_blank" rel="noopener" href={file.view}>{file.name}</a>
                                                            }
                                                            
                                                        </div>
                                                        <div>
                                                            <span style={{ color: 'red', cursor: 'pointer' }} onClick={()=> this.deleteFile('companyOtherFileTwo')} >
                                                                <Icon type="delete" /> Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </div>
                                                <Upload
                                                    showUploadList={false}
                                                    beforeUpload={(e)=>this.onUploadFileImage(e,'companyOtherFileTwo')}
                                                    accept="image/*,.pdf"
                                                >
                                                    <Button disabled={companyOtherFileTwo.length >= 1 ? true : false} value={'large'} type="primary"><Icon type="upload" />Upload</Button>
                                                </Upload>
                                                {/* <Button disabled={companyFile.length >= 1 ? true : false} value={'large'} type="primary" onClick={() => this.refs.fileCompany.click()}><Icon type="upload" />Upload</Button>
                                                <input style={{ display: 'none' }} type="file" ref="fileCompany" id="fileCompany" accept="file_extension" onChange={(e) => this.onClickButtonUploadFileCompany(e)} /> */}
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </TabPane>
                        <TabPane tab={"Review/Rating"} key={"4"}>
                            <Card className='cardstyle'>
                                <Row>
                                    <TableReview editId={this.props.editId}/>
                                </Row>
                            </Card>
                        </TabPane>
                    </Tabs>
                    
                    <Form>
                        <div className='package-container'>
                            <Button 
                                className='create-button' 
                                onClick={() => this.checkDisableCreate() ? null : this.onClickCreate()}  
                                disabled={this.checkDisableCreate()}
                            >
                                <Icon type="plus" />
                                {'Create'}
                            </Button>
                        </div>
                        
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Table 
                                    dataSource={packageList} 
                                    columns={this.columns}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
    mainCustomerId: state.login.mainCustomerId,
})

export default connect(mapStateToProps, null)(Technician);

