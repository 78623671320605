import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../../utils';
import config from '../../../config.json'
import defaultSquareSrc from '../../../resource/default/300x300-square.png'
import { Icon } from 'antd';
class TicketAttachment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketAttachmentFileList: [],
            ticketAttachmentImageList: []
        }
    }

    getTicketAttachmentFileList() {
        let { ticketId } = this.props
        if (!ticketId) {
            return
        }
        let url = '/api/administrator/crm/ticket/chat/attachment/file/' + ticketId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } 
                this.setState({ ticketAttachmentFileList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    getTicketAttachmentImageList() {
        let { ticketId } = this.props
        if (!ticketId) {
            return
        }
        let url = '/api/administrator/crm/ticket/chat/attachment/image/' + ticketId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } 
                this.setState({ ticketAttachmentImageList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    componentDidMount() {
        this.getTicketAttachmentFileList()
        this.getTicketAttachmentImageList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ticketId != this.props.ticketId) {
            this.getTicketAttachmentFileList()
            this.getTicketAttachmentImageList()
        }
    }

    render() {
        const { ticketAttachmentFileList, ticketAttachmentImageList } = this.state
        const { folderName } = this.props
        return (
            <div>
                <p className='attachment-title'>{'Videos'}</p>
                <div className="ticket-attachment-video">
                    {ticketAttachmentFileList.map(item => {
                        let video = item.file.split('.')
                        if(video[1] == 'mp4' && 'm4v' && 'wmv' && 'flv' && 'mov' && 'AVI'){
                            return (
                                <div>
                                    <video width="130" style={{backgroundColor:'#ececec'}} >
                                        <source src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.file}`} type="video/mp4" />
                                    </video>
                                </div>
                            )
                        }
                    })}
                </div>
                <p className='attachment-title'>{'Image'}</p>
                <div className="ticket-attachment">
                    {ticketAttachmentImageList.map(item => {
                        return (
                            <div>
                                <img width={90} height={90} src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.image}`}
                                     onError={(image) => image.target.src = defaultSquareSrc}
                                />
                            </div>
                        )
                    })}
                </div>
                <p className='attachment-title'>{'File'}</p>
                <div className="ticket-attachment-file">
                    {ticketAttachmentFileList.map(item => {
                        let filetype = item.file.split('.')
                        if(filetype[1] != 'mp4' && 'm4v' && 'wmv' && 'flv' && 'mov' && 'AVI') {
                            return (
                                <div style={{display:'flex',flexDirection:'row'}}>
                                    <Icon type="file" style={{fontSize:20,marginRight:'20px'}} />
                                    <p style={{fontSize:14}}>{item.file}</p> 
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    userName: state.login.username,
    folderName: state.login.folderName,
})


export default connect(mapStateToProps)(TicketAttachment);
