import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, notification,Select,DatePicker,Spin, Typography } from 'antd';
import moment from 'moment';
// import ImageUpload from '../../../containers/ImageUpload';
// import CustomerOverLimitModal from '../../../containers/CustomModal/CustomerOverLimitModal';
import { get, put, post, ppost } from '../../../utils';
import config from '../../../config.json'

import './style.scss'

const { TextArea } = Input;
const FormItem = Form.Item;
const { Meta } = Card;

const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

const dateFormat = 'YYYY-MM-DD';

class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingCustomer:false,

            customeList: [],
            categoryWorkList: [],
            provinceList: [],
            districtList: [],
            subdistrictList: [],

            customeId: null,
            categoryWorkId: null,
            provinceId: null,
            districtId: null,
            subdistrictId: null,
            isPublish: 0,
            jobDescription: '',
            dateStart:null,
            dateEnd:null,

            reportDetail: []
        }

        this.timeOut = null
    }

    calOffSet(page,pageSize){
        let offSet = (page-1) * pageSize
        return offSet
    }

    // getPostDetail() {
    //     const { editId } = this.props
    //     console.log("==== editId ====",editId)
    //     let url = '/api/crm/administrator/user/postwork/' + editId;
    //     get(url)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             if(response.success){
    //                 this.setState({
    //                     customeId: response.data[0].customer_id,
    //                     categoryWorkId: response.data[0].category_work_id,
    //                     provinceId: response.data[0].province_id,
    //                     districtId :response.data[0].district_id,
    //                     subdistrictId:response.data[0].subdistrict_id,
    //                     isPublish: response.data[0].is_public,
    //                     jobDescription: response.data[0].job_description,
    //                     dateStart:response.data[0].start_date,
    //                     dateEnd:response.data[0].end_date
    //                 })
    //                 this.getCustomerList('getuser',response.data[0].customer_id)
    //                 this.getDistrict(response.data[0].province_id)
    //                 this.getSubDistrict(response.data[0].district_id)
    //             }
                
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }
    componentWillMount(){
        this.getReportDetail()
        console.log("==== props ====",this.props)
    }

    componentDidMount() {
        // this.getReportDetail()
        // this.getProvince()
        // console.log("==== props ====",this.props)

        // if (this.props.editId) {
        //     this.getPostDetail()
        // }else{
        //     this.getCustomerList()
        // }
    }

    // getProvince() {
    //     let url = '/api/crm/administrator/provinces';
    //     get(url)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             if(response.success){
    //                 console.log("==== response getProvince====",response)
    //                 this.setState({provinceList: response.data})
    //             }
                
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }

    // getDistrict(provinceId = null) {
    //     let url = '/api/crm/administrator/districts';
    //     if(provinceId){
    //         url += '?province=' + provinceId
    //     }
    //     get(url)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             if(response.success){
    //                 this.setState({districtList: response.data})
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }

    // getSubDistrict(districtId = null) {
    //     let url = '/api/crm/administrator/subdistricts';
    //     if(districtId){
    //         url += '?districts='+districtId
    //     }
    //     get(url)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             if(response.success){
    //                 this.setState({subdistrictList: response.data})
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }

    // async uploadImageToServer(formData) {
    //     let url = '/api/crm/administrator/upload/file'
    //     return await ppost(url, formData)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             return response
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //             return []
    //         })
    // }

    // async updatePost() {
    //     const { 
    //         customeId,categoryWorkId,provinceId,districtId,subdistrictId,isPublish,
    //         dateStart,dateEnd,
    //         jobDescription
    //     } = this.state
    //     if (!customeId || customeId == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'Please select "Name Customer".')
    //         return
    //     }
    //     if (!categoryWorkId || categoryWorkId == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'Please select "Category".')
    //         return
    //     }
    //     if (!provinceId || provinceId == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'Please enter "Province".')
    //         return
    //     }
    //     if (!districtId || districtId == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'Please enter "District".')
    //         return
    //     }
    //     if (!subdistrictId || subdistrictId == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'Please enter "Subdistrict".')
    //         return
    //     }

    //     if (!dateStart || dateStart == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'เวลาจบ น้อยกว่าเวลาเริ่มต้น')
    //         return
    //     }

    //     if (!dateEnd || dateEnd == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'เวลาจบ น้อยกว่าเวลาเริ่มต้น')
    //         return
    //     }

    //     if (moment(dateEnd, dateFormat) < moment(dateStart, dateFormat)) {
    //         openNotificationWithIcon('warning', 'System notification', 'เวลาจบ น้อยกว่าเวลาเริ่มต้น')
    //         return
    //     }

        
    //     if (!jobDescription || jobDescription == '') {
    //         openNotificationWithIcon('warning', 'System notification', 'Please enter "Job Description".')
    //         return
    //     }
    //     let data = {
    //         customeId: customeId,
    //         categoryWorkId: categoryWorkId,
    //         provinceId: provinceId,
    //         districtId: districtId,
    //         subdistrictId:subdistrictId,
    //         isPublish: isPublish,
    //         dateStart: dateStart,
    //         dateEnd: dateEnd,
    //         jobDescription: jobDescription,
    //         permission:'administrator',
    //         userId: this.props.administratorId
    //     }

    //     if (this.props.editId) {
    //         let url = '/api/crm/administrator/user/postwork/' + this.props.editId
    //         put(url, data)
    //             .then(response => {
    //                 if (response.status >= 400) {
    //                     throw new Error("Bad response from server");
    //                 }
    //                 if (response.success) {
    //                     this.props.backpage()
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log(err.message)
    //             })
    //     } else {
    //         let url = '/api/crm/administrator/user/postwork';
    //         post(url, data)
    //             .then(response => {
    //                 if (response.status >= 400) {
    //                     throw new Error("Bad response from server");
    //                 }
    //                 if (response.success) {
    //                     this.props.backpage()
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log(err.message)
    //             })
    //     }
    // }

    showCustomerOverLimitModal() {
        this.setState({ visible: true })
    }

    // onClickOk() {
    //     this.updatePost()
    // }
    onClickCancel() {
        // this.setState({
        //     name: '',
        //     telephone: '',
        //     email: '',
        //     username: '',
        //     password: '',
        // })
        this.props.backpage()
    }

    
    onChangeDateStart = (value, dateString) => {
        this.setState({ dateStart: dateString })
    }

    onChangeDateEnd = (value, dateString) => {
        this.setState({ dateEnd: dateString })
    }

    onSearchCustomer(val) {
        if(this.timeOut){
            clearTimeout(this.timeOut)
        }
        this.timeOut = setTimeout(() => this.getCustomerList('search',val) , 300);
    }

    // async getCustomerList(option = null,val = null){
    //     this.setState({loadingCustomer:true})
    //     let pageSize = 300
    //     let offSet = await this.calOffSet(1 , pageSize)

    //     let url = "/api/v2/crm/administrator/user/customer?pageSize=" + pageSize+"&offSet="+offSet
    //     if(option == 'search'){
    //         url += '&searchUser=' + val
    //     }

    //     if(option == 'getuser'){
    //         url += '&userId=' + val
    //     }

    //     get(url)
    //         .then((response) => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             if(response.success){
    //                 this.setState({ customeList: response.data,loadingCustomer:false})
    //             }
                
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //             this.setState({loadingCustomer:false})
    //         })
    // }

    getReportDetail(){
        const { editId } =this.props
        let url = "/api/crm/customer/user/reportuser/"+ editId
        get(url)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.length){
                    response.map(result => { 
                        // console.log("==== results Test ====",result)
                        this.setState({ reportDetail: result})
                    })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    
    onChangeCustomer(value){
        this.setState({ customeId: value })
        this.getCustomerList()
    }

    // onChangeProvince(value){
    //     this.setState({
    //         provinceId: value,
    //         districtId :null,
    //         subdistrictId:null
    //     })
    //     this.getDistrict(value)
    // }
    // onChangeDistrict(value){
    //     this.setState({
    //         districtId: value,
    //         subdistrictId:null
    //      })
        
    //     this.getSubDistrict(value)
    // }

    customerListToElem() {
        let result = this.state.customeList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.Username}-${i.Name}`}>{i.Name}</Option>
        })
        return result
    }

    categoryWorkListToElem() {
        let result = this.state.categoryWorkList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.Name}`}>{i.Name}</Option>
        })
        return result
    }

    onClickRejectReport(){
        const {editId} = this.props
        let url = "/api/crm/customer/user/reportuser"
        let data = {
            status : 'reject',
            reportID: editId
        }
        post(url, data)
            .then((response) => {
                // console.log(response)
                this.props.backpage()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    onClickApprovedReport(){
        const {editId} = this.props
        let url = "/api/crm/customer/user/reportuser"
        let data = {
            status : 'approved',
            reportID: editId
        }
        
        post(url,data)
            .then((response) => {
                this.props.backpage()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // provinceListToElem() {
    //     let result = this.state.provinceList.map(i => {
    //         return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
    //     })
    //     return result
    // }

    // districtListToElem() {
    //     let result = this.state.districtList.map(i => {
    //         return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
    //     })
    //     return result
    // }
    
    // subdistrictListToElem() {
    //     let result = this.state.subdistrictList.map(i => {
    //         return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
    //     })
    //     return result
    // }
    

    render() {
        const {reportDetail, dateStart,dateEnd } = this.state

        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        // console.log("==== After Render ====",reportDetail.Content)
        return (
            <div className='post-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => this.props.backpage()} >
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{' Report by User'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        {/* <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button> */}
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Reporter User ID'} >
                                    <Typography><p>{reportDetail.Reporter }</p> </Typography>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Name'} >
                                        <Typography><p>{reportDetail.Reported_name ? reportDetail.Reported_name : "ไม่พบชื่อผู้ใช้งาน"}</p> </Typography>
                                    </FormItem>
                                </Col>
                                
                                <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Report Type'} >
                                        <Typography><p>{reportDetail.Report_type }</p></Typography>
                                    </FormItem>
                                </Col>

                                <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Time'} >
                                        <Typography><p>{reportDetail.report_time ? moment(reportDetail.report_time).format('YYYY-MM-DD HH:mm:ss') : "ไม่มีการบันทึกเวลา" }</p> </Typography>
                                    </FormItem>
                                </Col>

                                { reportDetail.Report_type == 'post' ?
                                    <div>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            <FormItem {...formItemLayout} label={'Post ID'} >
                                                <Typography><p>{reportDetail.post_id }</p></Typography>
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            <FormItem {...formItemLayout} label={'Post Cotent'} >
                                                <Typography><p>{reportDetail.Post }</p></Typography>
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            <FormItem {...formItemLayout} label={'Post Date'} >
                                                <Typography><p>{reportDetail.Post_date ? moment(reportDetail.Post_date).format('YYYY-MM-DD HH:mm:ss'): "ไม่มีการบันทึกเวลา"}</p></Typography>
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            
                                        </Col>
                                    </div>
                                    :
                                    reportDetail.Report_type == 'comment' ?
                                    <div>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            <FormItem {...formItemLayout} label={'Comment ID'} >
                                                <Typography><p>{reportDetail.comment_id }</p></Typography>
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            <FormItem {...formItemLayout} label={'Comment Cotent'} >
                                                <Typography><p>{reportDetail.comment }</p></Typography>
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            <FormItem {...formItemLayout} label={'Comment Date'} >
                                                <Typography><p>{reportDetail.Comment_date ? moment(reportDetail.Comment_date).format('YYYY-MM-DD HH:mm:ss'): "ไม่มีการบันทึกเวลา" }</p></Typography>
                                            </FormItem>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} >
                                            
                                        </Col>
                                    </div>
                                    : null
                                }

                                <Col xs={24} sm={24} md={8} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Report Cotent'} >
                                        <Typography><p>{reportDetail.Content }</p></Typography>
                                    </FormItem>
                                </Col>
                                
                            
                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Job Description'}>
                                    <TextArea
                                        autosize={{ minRows: 6 }}
                                        value={this.state.jobDescription}
                                        onChange={(event) => this.setState({jobDescription: event.target.value})}
                                    />
                                </FormItem>
                            </Col> */}
                        </Row>
                        {reportDetail.Status == 'pending' ?
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Button style={{borderColor : 'red'}} onClick = {()=> this.onClickRejectReport()}>{'Reject'}</Button>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{display:'flex', justifyContent:'flex-start'}}>
                                    <Button type="primary" onClick ={() => this.onClickApprovedReport()}> {'Approved'}</Button>
                                </Col>
                            </Row>
                            :
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Typography><p>Status : {reportDetail.Status }</p></Typography>
                                </Col>
                            </Row>
                        }
                        
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
    mainCustomerId: state.login.mainCustomerId,
})

export default connect(mapStateToProps, null)(Report);

