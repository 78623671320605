import React from 'react';
import { Row, Col, Modal, Select, Form, Input } from 'antd';
import ImageUpload from '../../../../containers/ImageUpload';
import './style.scss'


const Option = Select.Option;
const FormItem = Form.Item;

class ProductListLayoutButtonModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    getSettingTypeListToEnum() {
        const { settingLayoutTypeList } = this.props
        return settingLayoutTypeList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)
    }


    updateSettingNameLayoutList(value, index) {
        let settingLayoutList = this.props.settingLayoutList.map(item => Object.assign({}, item))
        settingLayoutList[index].name = value
        this.props.updateSettingLayoutList(settingLayoutList)
    }
    updateSettingTypeLayoutList(value, index) {
        let settingLayoutList = this.props.settingLayoutList.map(item => Object.assign({}, item))
        settingLayoutList[index].layout_type = value
        this.props.updateSettingLayoutList(settingLayoutList)
    }

    updateSettingExtraLayoutList(value, index) {
        let settingLayoutList = this.props.settingLayoutList.map(item => Object.assign({}, item))
        settingLayoutList[index].extra_id = value
        this.props.updateSettingLayoutList(settingLayoutList)
    }

    updateSettingImageLayoutList(value, index) {
        let settingLayoutList = this.props.settingLayoutList.map(item => Object.assign({}, item))
        settingLayoutList[index].imageList = value
        this.props.updateSettingLayoutList(settingLayoutList)
    }


    handleOk() {
        this.props.onClose()
    }
    handleCancel() {
        this.props.onClose()
    }



    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 4, },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 20 },
            },
        };

        const { visible, settingLayoutList } = this.props
        console.log("settingLayoutList", settingLayoutList)
        return (
            <div className={'layout-mobile-wrapper'}>
                <Modal
                    title={'Layout mobile'}
                    visible={visible}
                    width={800}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                >
                    <div className="layout-setting-container">
                        {settingLayoutList.map((item, index) => {
                            return (
                                <div>
                                    <Row className="layout-setting-item" >
                                        <div className="layout-setting-item-label"><p style={{ color: '#47C5EB', fontWeight: '700' }}>{`เมนูที่ : ${index + 1}`}</p></div>
                                    </Row>
                                    <Row className="layout-setting-item" >
                                        <Col
                                            sm={24} md={24} lg={12} xl={12}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >

                                            <FormItem {...formItemLayout} label={'Name'} style={{ margin: 0, width: '100%' }}>
                                                <Input
                                                    style={{ width: '100%' }}
                                                    value={item.name}
                                                    onChange={(event) => this.updateSettingNameLayoutList(event.target.value, index)}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={12} xl={12}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <FormItem {...formItemLayout} label={'Type'} style={{ margin: 0, width: '100%' }}>
                                                <Select
                                                    value={item.layout_type}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => this.updateSettingTypeLayoutList(value, index)}
                                                >
                                                    {this.getSettingTypeListToEnum()}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ProductListLayoutButtonModal;

