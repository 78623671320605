import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Row, Col, Button, Icon, Card, Select, Form, Input, notification} from 'antd'
import { get, post } from '../../../utils';
import { Link } from 'react-router-dom'
import { SketchPicker } from 'react-color';
import './style.scss'

const Option = Select.Option;
const FormItem = Form.Item;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            generalType: [
                {value: 'website', label:'Website',defaultButtonColor:'#B8860B',defaultFontColor:'#FFFFFF' },
                {value: 'email', label:'E-mail',defaultButtonColor:'#006666',defaultFontColor:'#FFFFFF' },
            ],
            socialType: [
                {value: 'facebook', label:'Facebook',defaultButtonColor:'#356BC4',defaultFontColor:'#FFFFFF' },
                {value: 'twitter', label:'Twitter',defaultButtonColor:'#00A9E8',defaultFontColor:'#FFFFFF' },
                {value: 'line', label:'Line',defaultButtonColor:'#00B900',defaultFontColor:'#FFFFFF'},
                {value: 'instagram', label:'Instagram',defaultButtonColor:'#F2724F',defaultFontColor:'#FFFFFF'},
                {value: 'tiktok', label:'TikTok',defaultButtonColor:'#000000',defaultFontColor:'#FFFFFF'},
                {value: 'whatsapp', label:'WhatsApp',defaultButtonColor:'#25D366',defaultFontColor:'#FFFFFF'},
                {value: 'we_chat', label:'WeChat',defaultButtonColor:'#2DC100',defaultFontColor:'#FFFFFF'},
                {value: 'youtube', label:'Youtube',defaultButtonColor:'#FE0000',defaultFontColor:'#FFFFFF'},
            ],
            courseType: [
                {value: 'email', label:'E-mail',defaultButtonColor:'#006666',defaultFontColor:'#FFFFFF' },
            ],
            contactUsGeneralList:[],
            contactUsSocialList:[],
            contactUsCourseList:[],
            // aboutUsLanguageList: []
        }
    }

    componentDidMount() {
        this.getContactUs()
    }

    getContactUs(){
        let url = '/api/crm/administrator/contactus';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                

                if(response.length > 0){
                    let contactUsGeneralList = response.filter(item=>item.category == 'general').map(item=>{
                        let data = {
                            category: item.category,
                            type: item.type,
                            label: item.label,
                            link: item.link,
                            buttonColor: item.button_color,
                            fontColor: item.font_color,
                            displayColorPickerButton: false,
                            displayColorPickerFont: false,
                        }
                        return data
                    })

                    let contactUsSocialList = response.filter(item=>item.category == 'social').map(item=>{
                        let data = {
                            category: item.category,
                            type: item.type,
                            label: item.label,
                            link: item.link,
                            buttonColor: item.button_color,
                            fontColor: item.font_color,
                            displayColorPickerButton: false,
                            displayColorPickerFont: false,
                        }
                        return data
                    })

                    let contactUsCourseList = response.filter(item=>item.category == 'course').map(item=>{
                        let data = {
                            category: item.category,
                            type: item.type,
                            label: item.label,
                            link: item.link,
                            buttonColor: item.button_color,
                            fontColor: item.font_color,
                            displayColorPickerButton: false,
                            displayColorPickerFont: false,
                        }
                        return data
                    })

                    this.setState({ 
                        contactUsGeneralList:contactUsGeneralList, 
                        contactUsSocialList:contactUsSocialList, 
                        contactUsCourseList:contactUsCourseList
                    })
                }
                

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateContactUs()
    }

    validateContactUsList(variant,type) {
        let result = {
            success: true,
            message: 'System notification',
            description: ''
        }

        this.state[`${variant}`].map((item, index) => {
            if (result.success) {
                if (!item.type || item.type == '') {
                    result.success = false
                    result.description = `Please enter "Type" in ${type} line ID 0000${index + 1} .`
                    return
                }
                if (!item.label || item.label == '') {
                    result.success = false
                    result.description = `Please enter "Label" in ${type} line ID 0000${index + 1} .`
                    return
                }
                if (!item.link || item.link == '') {
                    result.success = false
                    result.description = `Please enter "Link" in ${type} line ID 0000${index + 1} .`
                    return
                }
            }
        })
        return result
    }

    updateContactUs() {
        this.setState({loading:true})
        const {isConnectCodexLearn} = this.props 
        let validateContactUsGeneralListResult = this.validateContactUsList('contactUsGeneralList','General')
        if (!validateContactUsGeneralListResult.success) {
            this.setState({loading:false})
            openNotificationWithIcon('warning', 'System notification', validateContactUsGeneralListResult.description)
            return
        }

        let validateContactUsSocialListResult = this.validateContactUsList('contactUsSocialList','Social')
        if (!validateContactUsSocialListResult.success) {
            this.setState({loading:false})
            openNotificationWithIcon('warning', 'System notification', validateContactUsSocialListResult.description)
            return
        }
        
        if(isConnectCodexLearn){
            let validateContactUsCourseListResult = this.validateContactUsList('contactUsCourseList','Course')
            if (!validateContactUsCourseListResult.success) {
                this.setState({loading:false})
                openNotificationWithIcon('warning', 'System notification', validateContactUsCourseListResult.description)
                return
            }
        }
        


        let mergeData = [...this.state.contactUsGeneralList,...this.state.contactUsSocialList,...this.state.contactUsCourseList ]

        let data = {}

        mergeData.map((item,index)=>{
            data[`datalist_${index}`] = JSON.stringify(item)
        })
        
        let url = '/api/crm/administrator/contactus';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({loading:false})
                openNotificationWithIcon('success', 'System notification', 'Save swiper success.')
            })
            .catch(err => {
                console.log(err.message)
                this.setState({loading:false})
            })
    }

    getRoleEnabled() {
        const {loading} = this.state 
        const { roleDetail } = this.props
        if(loading){
            return false
        }

        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }

        
        return false
    }

    onClickCancel() {
        this.getContactUs()
    }


    addContactUsGeneral(){
        let tempContactUsGeneralList = Object.assign([],this.state.contactUsGeneralList)
        let newLine = {
            category:'general',
            type: null,
            label: '',
            link:'',
            buttonColor: '#ffffff',
            fontColor: '#ffffff',
            displayColorPickerButton:false,
            displayColorPickerFont:false,
        }

        tempContactUsGeneralList.push(newLine)
        this.setState({
            contactUsGeneralList: tempContactUsGeneralList
        })
    }

    addContactUsSocial(){
        let tempContactUsSocialList = Object.assign([],this.state.contactUsSocialList)
        let newLine = {
            category:'social',
            type: null,
            label: '',
            link:'',
            buttonColor: '#ffffff',
            fontColor: '#ffffff',
            displayColorPickerButton:false,
            displayColorPickerFont:false,
        }

        tempContactUsSocialList.push(newLine)
        this.setState({
            contactUsSocialList: tempContactUsSocialList
        })
    }

    addContactUsCourse(){
        let tempContactUsCourseList = Object.assign([],this.state.contactUsCourseList)
        let newLine = {
            category:'course',
            type: null,
            label: '',
            link:'',
            buttonColor: '#ffffff',
            fontColor: '#ffffff',
            displayColorPickerButton:false,
            displayColorPickerFont:false,
        }

        tempContactUsCourseList.push(newLine)
        this.setState({
            contactUsCourseList: tempContactUsCourseList
        })
    }

    deleteContactUsGeneral(index) {
        let tempContactUsGeneralList = this.state.contactUsGeneralList.filter((item, i) => i !== index) 
        this.setState({ contactUsGeneralList: tempContactUsGeneralList })
    }

    onChangeValueGeneral(value,index,variant){
        let tempContactUsGeneralList = Object.assign([],this.state.contactUsGeneralList)
        tempContactUsGeneralList[index][`${variant}`] = value
        this.setState({ contactUsGeneralList: tempContactUsGeneralList })
    }

    updateGeneralType(value,index){
        let tempContactUsGeneralList = Object.assign([],this.state.contactUsGeneralList)
        let tempGeneralType = Object.assign([],this.state.generalType)
        let indexType = tempGeneralType.findIndex(item=> item.value ==  value)
        tempContactUsGeneralList[index].type = value
        if(indexType!=-1){
            tempContactUsGeneralList[index].buttonColor = tempGeneralType[indexType].defaultButtonColor
            tempContactUsGeneralList[index].fontColor = tempGeneralType[indexType].defaultFontColor
        }
        this.setState({ contactUsGeneralList: tempContactUsGeneralList })
    }

    deleteContactUsSocial(index) {
        let tempContactUsSocialList = this.state.contactUsSocialList.filter((item, i) => i !== index) 
        this.setState({ contactUsSocialList: tempContactUsSocialList })
    }

    onChangeValueSocial(value,index,variant){
        let tempContactUsSocialList = Object.assign([],this.state.contactUsSocialList)
        tempContactUsSocialList[index][`${variant}`] = value
        this.setState({ contactUsSocialList: tempContactUsSocialList })
    }

    updateSocialType(value,index){
        let tempContactUsSocialList = Object.assign([],this.state.contactUsSocialList)
        let tempSocialType = Object.assign([],this.state.socialType)
        let indexType = tempSocialType.findIndex(item=> item.value ==  value)
        tempContactUsSocialList[index].type = value
        if(indexType!=-1){
            tempContactUsSocialList[index].buttonColor = tempSocialType[indexType].defaultButtonColor
            tempContactUsSocialList[index].fontColor = tempSocialType[indexType].defaultFontColor
        }
        this.setState({ contactUsSocialList: tempContactUsSocialList })
    }

    deleteContactUsCourse(index) {
        let tempContactUsCourseList = this.state.contactUsCourseList.filter((item, i) => i !== index) 
        this.setState({ contactUsCourseList: tempContactUsCourseList })
    }

    onChangeValueCourse(value,index,variant){
        let tempContactUsCourseList = Object.assign([],this.state.contactUsCourseList)
        tempContactUsCourseList[index][`${variant}`] = value
        this.setState({ contactUsCourseList: tempContactUsCourseList })
    }

    updateCourseType(value,index){
        let tempContactUsCourseList = Object.assign([],this.state.contactUsCourseList)
        let tempCourseType = Object.assign([],this.state.courseType)
        let indexType = tempCourseType.findIndex(item=> item.value ==  value)
        tempContactUsCourseList[index].type = value
        if(indexType!=-1){
            tempContactUsCourseList[index].buttonColor = tempCourseType[indexType].defaultButtonColor
            tempContactUsCourseList[index].fontColor = tempCourseType[indexType].defaultFontColor
        }
        this.setState({ contactUsCourseList: tempContactUsCourseList })
    }

    render() {
        const {loading} = this.state 
        const {isConnectCodexLearn} = this.props 
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        const styles = ({
            colorFontCourse: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: this.state.font_color_course,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '1000',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        });

        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left' }} style={{ cursor: 'pointer' }}>
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{"Contact Us"}</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => loading ? null : this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Row gutter={24} type="flex" style={{ justifyContent: "space-between",margin: '0 10%', marginBottom: 10 }} >
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <h2>
                                {`General`}
                            </h2>
                        </Col>
                        <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                            <Button className='add-button-contactus' onClick={() => this.addContactUsGeneral()} disabled={!this.getRoleEnabled()} >{'+ Add Contact'}</Button>
                        </Col>
                    </Row>
                    {this.state.contactUsGeneralList.map((item, index) => {
                        return (
                            <Card style={{ marginBottom: 10,margin: '0 10%' }}>
                                <Row style={{ display:'flex',justifyContent:'flex-start'}}>
                                    <Col>
                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>
                                            {`Contact Label ${index + 1}`}
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Type'} required={true}>
                                            <Select
                                                value={item.type}
                                                onChange={(value) => this.updateGeneralType(value,index )}
                                                style={{ width: '100%' }}
                                                disabled={!this.getRoleEnabled()}
                                            >
                                                {this.state.generalType.length ?
                                                    this.state.generalType.map((item, index) => <Option key={index} value={item.value} >{item.label}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Button Text'} required={true}>
                                            <Input value={item.label} onChange={(e)=>this.onChangeValueGeneral(e.target.value,index,'label')} disabled={!this.getRoleEnabled()} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Link to'} required={true}>
                                            <Input value={item.link} onChange={(e)=>this.onChangeValueGeneral(e.target.value,index,'link')} disabled={!this.getRoleEnabled()} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Button Color'} required={false}>
                                            <Col className='color-item-wrapper-contactus' >
                                                <div>
                                                    <div className='color' style={{ backgroundColor: item.buttonColor,cursor: !this.getRoleEnabled()? 'not-allowed' :'pointer', }} onClick={()=>!this.getRoleEnabled() ? null : this.onChangeValueGeneral(true,index,'displayColorPickerButton')} />
                                                    <p className='code'>{item.buttonColor}</p>
                                                </div>
                                            </Col>
                                            {item.displayColorPickerButton ? 
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={()=>this.onChangeValueGeneral(false,index,'displayColorPickerButton')} />
                                                    <SketchPicker 
                                                        color={item.buttonColor} 
                                                        onChange={(color)=>this.onChangeValueGeneral(color.hex,index,'buttonColor')} 
                                                        // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                                    />
                                                </div> 
                                            : null}
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Font Color'} required={false}>
                                                <Col className='color-item-wrapper-contactus' >
                                                    <div>
                                                        <div className='color' style={{ backgroundColor: item.fontColor,cursor: !this.getRoleEnabled()? 'not-allowed' :'pointer', }} onClick={()=>!this.getRoleEnabled()? null :this.onChangeValueGeneral(true,index,'displayColorPickerFont')} />
                                                        <p className='code'>{item.fontColor}</p>
                                                    </div>
                                                </Col>
                                            {item.displayColorPickerFont ? 
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={()=>this.onChangeValueGeneral(false,index,'displayColorPickerFont')} />
                                                    <SketchPicker 
                                                        color={item.fontColor} 
                                                        onChange={(color)=>this.onChangeValueGeneral(color.hex,index,'fontColor')} 
                                                        // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                                    />
                                                </div> 
                                            : null}
                                        </FormItem>
                                    </Col>
                                    <Col style={{ paddingTop:32,textAlign: 'right' }} xs={24} sm={24} md={8} lg={8} xl={8}>
                                        <Button type={'danger'} onClick={() => !this.getRoleEnabled()? null : this.deleteContactUsGeneral(index)} disabled={!this.getRoleEnabled()} >
                                            <Icon type="rest" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                    
                    <Row gutter={24} type="flex" style={{ justifyContent: "space-between",margin: '10px 10%' }} >
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <h2>
                                {`Social`}
                            </h2>
                        </Col>
                        <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                            <Button className='add-button-contactus' onClick={() => this.addContactUsSocial()} disabled={!this.getRoleEnabled()} >{'+ Add Contact'}</Button>
                        </Col>
                    </Row>
                    {this.state.contactUsSocialList.map((item, index) => {
                        return (
                            <Card style={{ marginBottom: 10,margin: '0 10%' }}>
                                <Row style={{ display:'flex',justifyContent:'flex-start'}}>
                                    <Col>
                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>
                                            {`Contact Label ${index + 1}`}
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Type'} required={true}>
                                            <Select
                                                value={item.type}
                                                onChange={(value) => this.updateSocialType(value,index )}
                                                style={{ width: '100%' }}
                                                disabled={!this.getRoleEnabled()}
                                            >
                                                {this.state.socialType.length ?
                                                    this.state.socialType.map((item, index) => <Option key={index} value={item.value} >{item.label}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Button Text'} required={true}>
                                            <Input value={item.label} onChange={(e)=>this.onChangeValueSocial(e.target.value,index,'label')} disabled={!this.getRoleEnabled()} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Link to'} required={true}>
                                            <Input value={item.link} onChange={(e)=>this.onChangeValueSocial(e.target.value,index,'link')} disabled={!this.getRoleEnabled()} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Button Color'} required={false}>
                                            <Col className='color-item-wrapper-contactus' >
                                                <div>
                                                    <div className='color' style={{ backgroundColor: item.buttonColor,cursor: !this.getRoleEnabled()? 'not-allowed' :'pointer', }} onClick={()=>!this.getRoleEnabled()? null : this.onChangeValueSocial(true,index,'displayColorPickerButton')} />
                                                    <p className='code'>{item.buttonColor}</p>
                                                </div>
                                            </Col>
                                            {item.displayColorPickerButton ? 
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={()=>this.onChangeValueSocial(false,index,'displayColorPickerButton')} />
                                                    <SketchPicker 
                                                        color={item.buttonColor} 
                                                        onChange={(color)=>this.onChangeValueSocial(color.hex,index,'buttonColor')} 
                                                        // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                                    />
                                                </div> 
                                            : null}
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Font Color'} required={false}>
                                                <Col className='color-item-wrapper-contactus' >
                                                    <div>
                                                        <div className='color' style={{ backgroundColor: item.fontColor,cursor: !this.getRoleEnabled()? 'not-allowed' :'pointer', }} onClick={()=>!this.getRoleEnabled()? null : this.onChangeValueGeneral(true,index,'displayColorPickerFont')} />
                                                        <p className='code'>{item.fontColor}</p>
                                                    </div>
                                                </Col>
                                            {item.displayColorPickerFont ? 
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={()=>this.onChangeValueSocial(false,index,'displayColorPickerFont')} />
                                                    <SketchPicker 
                                                        color={item.fontColor} 
                                                        onChange={(color)=>this.onChangeValueSocial(color.hex,index,'fontColor')} 
                                                        // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                                    />
                                                </div> 
                                            : null}
                                        </FormItem>
                                    </Col>
                                    <Col style={{ paddingTop:32,textAlign: 'right' }} xs={24} sm={24} md={8} lg={8} xl={8}>
                                        <Button type={'danger'} onClick={() => !this.getRoleEnabled()? null : this.deleteContactUsSocial(index)} disabled={!this.getRoleEnabled()}>
                                            <Icon type="rest" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}


                    {/* {
                        isConnectCodexLearn? */}
                        <Row gutter={24} type="flex" style={{ justifyContent: "space-between",margin: '10px 10%' }} >
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <h2>
                                    {`Course`}
                                </h2>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                                <Button className='add-button-contactus' onClick={() => this.addContactUsCourse()} disabled={!this.getRoleEnabled()}>{'+ Add Contact'}</Button>
                            </Col>
                        </Row>
                        {/* :
                        null
                    } */}

                    {this.state.contactUsCourseList.map((item, index) => {
                        return (
                            <Card style={{ marginBottom: 10,margin: '0 10%' }}>
                                <Row style={{ display:'flex',justifyContent:'flex-start'}}>
                                    <Col>
                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>
                                            {`Contact Label ${index + 1}`}
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Type'} required={true}>
                                            <Select
                                                value={item.type}
                                                onChange={(value) => this.updateCourseType(value,index )}
                                                style={{ width: '100%' }}
                                                disabled={!this.getRoleEnabled()}
                                            >
                                                {this.state.courseType.length ?
                                                    this.state.courseType.map((item, index) => <Option key={index} value={item.value} >{item.label}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Button Text'} required={true}>
                                            <Input value={item.label} onChange={(e)=>this.onChangeValueCourse(e.target.value,index,'label')} disabled={!this.getRoleEnabled()}/>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Link to'} required={true}>
                                            <Input value={item.link} onChange={(e)=>this.onChangeValueCourse(e.target.value,index,'link')} disabled={!this.getRoleEnabled()}/>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Button Color'} required={false}>
                                            <Col className='color-item-wrapper-contactus' >
                                                <div>
                                                    <div className='color' style={{ backgroundColor: item.buttonColor,cursor: !this.getRoleEnabled()? 'not-allowed' :'pointer', }} onClick={()=>!this.getRoleEnabled()? null : this.onChangeValueGeneral(true,index,'displayColorPickerButton')} />
                                                    <p className='code'>{item.buttonColor}</p>
                                                </div>
                                            </Col>
                                            {item.displayColorPickerButton ? 
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={()=>this.onChangeValueCourse(false,index,'displayColorPickerButton')} />
                                                    <SketchPicker 
                                                        color={item.buttonColor} 
                                                        onChange={(color)=>this.onChangeValueCourse(color.hex,index,'buttonColor')} 
                                                        // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                                    />
                                                </div> 
                                            : null}
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Font Color'} required={false}>
                                                <Col className='color-item-wrapper-contactus' >
                                                    <div>
                                                        <div className='color' style={{ backgroundColor: item.fontColor,cursor: !this.getRoleEnabled()? 'not-allowed' :'pointer', }} onClick={()=>!this.getRoleEnabled()? null : this.onChangeValueGeneral(true,index,'displayColorPickerFont')} />
                                                        <p className='code'>{item.fontColor}</p>
                                                    </div>
                                                </Col>
                                            {item.displayColorPickerFont ? 
                                                <div style={styles.popover}>
                                                    <div style={styles.cover} onClick={()=>this.onChangeValueCourse(false,index,'displayColorPickerFont')} />
                                                    <SketchPicker 
                                                        color={item.fontColor} 
                                                        onChange={(color)=>this.onChangeValueCourse(color.hex,index,'fontColor')} 
                                                        // disabled={this.props.type == 'create' ? false : this.props.editRole ? false : true}
                                                    />
                                                </div> 
                                            : null}
                                        </FormItem>
                                    </Col>
                                    <Col style={{ paddingTop:32,textAlign: 'right' }} xs={24} sm={24} md={8} lg={8} xl={8}>
                                        <Button type={'danger'} onClick={() => !this.getRoleEnabled()? null : this.deleteContactUsCourse(index)} disabled={!this.getRoleEnabled()}>
                                            <Icon type="rest" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                    
                </Card>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    roleDetail: state.role.roleDetail,
    isConnectCodexLearn: state.login.is_connect_codexlearn,
})

export default connect(mapStateToProps, null)(ContactUs);