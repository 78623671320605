import React, { Component } from 'react'
import { connect } from 'react-redux';
import { post } from '../../utils';
import { Row, Col, notification, Input, Icon } from 'antd';
import { Helmet } from "react-helmet";
import logoCodexConnectWhite from '../../resource/Logo/logo-codex-connect-white.png'
import forgetpasspic from '../../resource/assets/forgetpasspic.png'
import codexConnect from '../../resource/Logo/logoCodexConnect.png'
import './style.scss'
import { Link } from 'react-router-dom';

notification.config({
    placement: 'topRight',
    duration: 1,
});

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class RequestOTP extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username : '',
        }
    }
 
    handleClick() {
        const username = this.refs.username
        if (!this.state.username || this.state.username == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Email".')
            username.focus()
            return
        }
        let url = "/public/crm/administrator/user/administrator/forgotpassword/request"
        let data = {
            username: this.state.username,
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    openNotificationWithIcon('success', "Forgot password.", "OTP has been sent to your email.")
                    this.props.history.push('/forgot_password/validateotp');
                } else {
                    switch (response.error.code) {
                        case 'NOT_FOUND_EMAIL':
                            openNotificationWithIcon('error', "Forgot password.", "You do not have an email in the system. Please contact the service provider.")
                            break;
                        case 'NOT_FOUND':
                            openNotificationWithIcon('error', "Forgot password.", "Not found username. Please contact the service provider.")
                            break;
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleEnterKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleClick()
        }
    }

    render() {
        const { colorTheme, buttonTheme } = this.props
        return (
            <Row className='forgetpassword-page-wrapper'>
                <Helmet>
                    <title>{"Forget Password"}</title>
                </Helmet>
                <Col className='left-colunm-wrapper' span={10}>
                    <Link to={"/"}>
                    <div className='logo'>
                        <div>
                            <Icon style={{ fontSize: 50, fontWeight: 500, color: '#fff' }} type="left" />
                        </div>
                        <div>
                            <img width={300} src={logoCodexConnectWhite} />
                        </div>
                    </div>
                    </Link>
                    <p className='sub-title'>Forgot Password</p>
                    <img width={350}
                        className='left-pic'
                        src={forgetpasspic}
                    />


                    <div className="login-footer">
                        <p>Copyright &#169; 2020 Codex Digital Partner Co.,Ltd. All rights reserved.</p>
                    </div>
                </Col>
                <Col className='right-colunm-wrapper' span={14}>
                    <div className='container' >

                        <div className='input-group'>
                            <div className='logo'>
                                <img width={350} src={codexConnect} />
                            </div>
                            <p className='title' style={{ color: colorTheme.primary }}>Enter your Email</p>
                            <p className="sub-title">The system will register the OTP number to your registered email</p>
                            <Input
                                className="input-login"
                                type='text'
                                size="large"
                                ref='username'
                                value={this.state.username}
                                onChange={(event) => this.setState({ username: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                required
                                prefix={<Icon type="user" style={{ color: colorTheme.primary }} />}
                                placeholder="E-mail" />
                            <button
                                // style={buttonTheme.primary}
                                onClick={() => this.handleClick()}
                            >
                                {'OK'}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }

}

const mapStateToProps = (state) => ({
    colorTheme: state.theme.color,
    buttonTheme: state.theme.button
})


export default connect(mapStateToProps)(RequestOTP);

