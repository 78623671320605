import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Button, Icon, Input, Select, notification } from 'antd';
import { get, post } from '../../../utils';
import { Link } from 'react-router-dom'
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            termsAndConditionsId: null,
            display: 1,
            languageList: [],
            privacyPolicyLanguageList: []
        }
    }


    getLanguageList() {
        let url = '/api/crm/administrator/language/raw';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ languageList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getTermsAndConditions() {
        let url = '/api/crm/administrator/privacy/policy';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log("getTermsAndConditions", response)
                this.setState({
                    display: response.display,
                    privacyPolicyLanguageList: response.terms_and_conditions_language_list
                })

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getLanguageList()
        this.getTermsAndConditions()
    }

    validateTermsAndConditionsLanguageList() {
        let result = {
            success: true,
            message: 'System notification',
            description: ''
        }
        this.state.privacyPolicyLanguageList.map((item, index) => {
            if (result.success) {
                if (!item.language_id || item.language_id == '') {
                    result.success = false
                    result.description = `Please enter "Language" in line ID 0000${index + 1} .`
                    return
                }
                if (!item.name || item.name == '') {
                    result.success = false
                    result.description = `Please enter "Name" in line ID 0000${index + 1} .`
                    return
                }
            }
        })
        return result
    }

    updateTermsAndConditions() {
        const { display } = this.state
        let validateTermsAndConditionsLanguageListResult = this.validateTermsAndConditionsLanguageList()
        if (!validateTermsAndConditionsLanguageListResult.success) {
            openNotificationWithIcon('warning', 'System notification', validateTermsAndConditionsLanguageListResult.description)
            return
        }

        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => {
            let tempStringify = { detail_list: JSON.stringify(item.detail_list), accept_list: JSON.stringify(item.accept_list) }
            return Object.assign({}, item, tempStringify)
        })
        let data = {
            display: display,
            terms_and_conditions_language_list: JSON.stringify(privacyPolicyLanguageList),
        }

        let url = '/api/crm/administrator/privacy/policy';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save swiper success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateTermsAndConditions()
    }

    onClickCancel() {
        this.getTermsAndConditions()
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    updateTermsAndConditionsLanguageAccept(dataIndex, index, name, value) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList[dataIndex].accept_list[index][name] = value
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    addTermsAndConditionsLanguageAccept(dataIndex) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList[dataIndex].accept_list.push({
            name: '',
            description: '',
        })
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    deleteTermsAndConditionsLanguageAccept(dataIndex, index) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList[dataIndex].accept_list = privacyPolicyLanguageList[dataIndex].accept_list.filter((item, i) => i !== index)
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    renderTermsAndConditionsLanguageAccept(detailList, dataIndex) {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div>
                <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Button style={{ width: '100%' }} onClick={() => this.addTermsAndConditionsLanguageAccept(dataIndex)}>{'+ Add Accept'}</Button>
                    </Col>
                </Row>
                {detailList.map((item, index) => {
                    return (
                        <Card style={{ marginBottom: 10 }}>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <FormItem {...formItemLayout} label={'Line Accept ID'}>
                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16} >
                                    <FormItem {...formItemLayout} label={'Name'} required={true}>
                                        <Input
                                            value={item.name}
                                            onChange={(event) => this.updateTermsAndConditionsLanguageAccept(dataIndex, index, 'name', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24} type="flex" style={{ justifyContent: "center", marginBottom: 10 }} >
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <Button style={{ width: '100%' }} type={'danger'} onClick={() => this.deleteTermsAndConditionsLanguageAccept(dataIndex, index)}>{'Delete Accept'}</Button>
                                </Col>
                            </Row>
                        </Card>
                    )
                })}
            </div>
        )
    }

    updateTermsAndConditionsLanguageDetail(dataIndex, index, name, value) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList[dataIndex].detail_list[index][name] = value
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    addTermsAndConditionsLanguageDetail(dataIndex) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList[dataIndex].detail_list.push({
            name: '',
            description: '',
        })
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    deleteTermsAndConditionsLanguageDetail(dataIndex, index) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList[dataIndex].detail_list = privacyPolicyLanguageList[dataIndex].detail_list.filter((item, i) => i !== index)
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }
    renderTermsAndConditionsLanguageDetail(detailList, dataIndex) {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div>
                <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Button style={{ width: '100%' }} onClick={() => this.addTermsAndConditionsLanguageDetail(dataIndex)}>{'+ Add Detail'}</Button>
                    </Col>
                </Row>
                {detailList.map((item, index) => {
                    return (
                        <Card style={{ marginBottom: 10 }}>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <FormItem {...formItemLayout} label={'Line Detail ID'}>
                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16} >
                                    <FormItem {...formItemLayout} label={'Name'} required={true}>
                                        <Input
                                            value={item.name}
                                            onChange={(event) => this.updateTermsAndConditionsLanguageDetail(dataIndex, index, 'name', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Description'} required={true}>
                                        <TextArea
                                            rows={4}
                                            value={item.description}
                                            onChange={(event) => this.updateTermsAndConditionsLanguageDetail(dataIndex, index, 'description', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24} type="flex" style={{ justifyContent: "center", marginBottom: 10 }} >
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <Button style={{ width: '100%' }} type={'danger'} onClick={() => this.deleteTermsAndConditionsLanguageDetail(dataIndex, index)}>{'Delete Detail'}</Button>
                                </Col>
                            </Row>
                        </Card>
                    )
                })}
            </div>
        )
    }

    updateTermsAndConditionsLanguage(index, name, value) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList[index][name] = value
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    addTermsAndConditionsLanguage() {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.map(item => Object.assign({}, item))
        privacyPolicyLanguageList.push({
            language_id: null,
            name: '',
            detail_list: [],
            accept_list: [],
        })
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    deleteTermsAndConditionsLanguage(index) {
        let privacyPolicyLanguageList = this.state.privacyPolicyLanguageList.filter((item, i) => i !== index)
        this.setState({ privacyPolicyLanguageList: privacyPolicyLanguageList })
    }

    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left' }} style={{ cursor: 'pointer' }}>
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{"Privacy Policy"}</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <FormItem {...formItemLayout} label={'Display'} required={true}>
                                <Select
                                    allowClear={true}
                                    value={this.state.display}
                                    onChange={(value) => this.setState({ display: value })}
                                    style={{ width: '100%' }}
                                >
                                    <Option key={1} value={1}  >{"Yes"}</Option>
                                    <Option key={0} value={0}  >{"No"}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <Button style={{ width: '100%' }} onClick={() => this.addTermsAndConditionsLanguage()}>{'+ Add'}</Button>
                        </Col>
                    </Row>
                    {this.state.privacyPolicyLanguageList.map((item, index) => {
                        return (
                            <Card style={{ marginBottom: 10 }}>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                        <FormItem {...formItemLayout} label={'Line ID'}>
                                            <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Language'} required={true}>
                                            <Select
                                                allowClear={true}
                                                value={item.language_id}
                                                onChange={(value) => this.updateTermsAndConditionsLanguage(index, 'language_id', value)}
                                                style={{ width: '100%' }}
                                            >
                                                {this.state.languageList.length ?
                                                    this.state.languageList.map((item, index) => <Option key={index} value={item.id} disabled={this.state.privacyPolicyLanguageList.map(r => r.language_id).includes(item.id)} >{item.name}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                        <FormItem {...formItemLayout} label={'Name'} required={true}>
                                            <Input
                                                value={item.name}
                                                onChange={(event) => this.updateTermsAndConditionsLanguage(index, 'name', event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                {this.renderTermsAndConditionsLanguageDetail(item.detail_list, index)}
                                {this.renderTermsAndConditionsLanguageAccept(item.accept_list, index)}
                                <Row gutter={24} type="flex" style={{ justifyContent: "center", marginBottom: 10 }} >
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <Button style={{ width: '100%' }} type={'danger'} onClick={() => this.deleteTermsAndConditionsLanguage(index)}>{'Delete'}</Button>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(PrivacyPolicy);
