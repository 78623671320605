import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Row, Col, notification, Icon, Input } from 'antd';
import { loginSuccess } from '../../actions/login';
import { post } from '../../utils';
import logoCodexConnectWhite from '../../resource/Logo/logo-codex-connect-white.png'
import codexConnect from '../../resource/Logo/logoCodexConnect.png'
import './style.scss'

notification.config({
    placement: 'topRight',
    duration: 1,
});

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstname: '',
            lastname: '',
            username: '',
            password: '',
            confirmPassword: '',
        }
    }



    handleClick() {
        const firstname = this.refs.firstname
        const lastname = this.refs.lastname
        const username = this.refs.username
        const password = this.refs.password
        const confirmPassword = this.refs.confirmPassword

        let showNotification = false
        let showNotificationDescription = ''
        if (!this.state.firstname || this.state.firstname == '') {
            // firstname.style.border = '1px solid red'
            if (!showNotification) {
                firstname.focus()
                showNotification = true
                showNotificationDescription = 'Please enter "Firstname".'
            }
        }
        if (!this.state.lastname || this.state.lastname == '') {
            // lastname.style.border = '1px solid red'
            if (!showNotification) {
                lastname.focus()
                showNotification = true
                showNotificationDescription = 'Please enter "Lastname".'
            }
        }
        if (!this.state.username || this.state.username.value == '') {
            // username.style.border = '1px solid red'
            if (!showNotification) {
                username.focus()
                showNotification = true
                showNotificationDescription = 'Please enter "Email".'
            }
        }
        if (!this.state.password || this.state.password == '') {
            // password.style.border = '1px solid red'
            if (!showNotification) {
                password.focus()
                showNotification = true
                showNotificationDescription = 'Please enter "Password".'
            }
        }
        if (!this.state.confirmPassword || this.state.confirmPassword == '') {
            // confirmPassword.style.border = '1px solid red'
            if (!showNotification) {
                confirmPassword.focus()
                showNotification = true
                showNotificationDescription = 'Please enter "Confirm Password".'
            }
        }
        if (this.state.password != this.state.confirmPassword) {
            // password.style.border = '1px solid red'
            // confirmPassword.style.border = '1px solid red'
            if (!showNotification) {
                password.focus()
                showNotification = true
                showNotificationDescription = 'Please enter "Password" that matches the "Confirm Password".'
            }
        }
        if (showNotification) {
            openNotificationWithIcon('warning', 'System notification', showNotificationDescription)
            return
        }
        let url = "/api/user/signup/administrator";
        let data = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            username: this.state.username,
            password: this.state.password
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.success) {
                    openNotificationWithIcon('success', "Sign up passed", "SignUp success")
                    this.initializeSignIn(response.userAdministratorId)

                } else {
                    switch (response.error.code) {
                        case 'DUPLICATE_USERNAME':
                            openNotificationWithIcon('error', "Sign up failed", "Duplicate email")
                            break;
                    }
                }
            })
            .catch(error => {
                console.log(error)
                openNotificationWithIcon('error', "Sign up failed", "error")
            })
    }

    initializeSignIn(userAdministratorId) {
        let url = "/api/crm/administrator/user/administrator/initialize";
        let data = {
            user_administrator_id: userAdministratorId
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.loginSuccess(response)
                this.props.history.push('/main/home')
            })
            .catch(error => {
                console.log(error)
                openNotificationWithIcon('error', "Sign up failed", "error")
            })
    }
    handleEnterKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleClick()
        }
    }

    render() {
        const { colorTheme, buttonTheme } = this.props
        return (
            <Row className='login-page-wrapper'>
                <Helmet>
                    <title>{"Sign up"}</title>
                </Helmet>
                <Col className='left-colunm-signup-wrapper' sm={24} md={9} lg={9}>
                    <div className='logo'>
                        <img width={350} src={logoCodexConnectWhite} />
                    </div>
                    <div className="login-text">
                        <p className='sub-title'>Welcome to </p>
                        <p className="sub-title-2">CodexConnect</p>
                        <p className="sub-title-3">Already have user account?</p>
                        <Link to={'/'}>
                            <div className="signup-button">
                                <p style={{ color: colorTheme.primary }}>{"Back to Sign in"}</p>
                            </div>
                        </Link>
                    </div>

                    <div className="login-footer">
                        <p>Copyright &#169; 2019 Codex Digital Partner Co.,Ltd. All rights reserved.</p>
                    </div>
                </Col>
                <Col className='right-colunm-signup-wrapper' sm={24} md={15} lg={15}>
                    <div className='container' >

                        <div className='input-group'>
                            <div className='logo'>
                                <img width={350} src={codexConnect} />
                            </div>
                            <p className='title' style={{ color: colorTheme.primary }}><span className="title-bold">Sign</span> up</p>
                            <p className="sub-title">Enter your Information <span style={{ color: colorTheme.primary }}>to Register</span></p>

                            <Input
                                autoFocus
                                className="input-login"
                                size="large"
                                type='text'
                                ref='firstname'
                                required
                                placeholder="Firstname"
                                value={this.state.firstname}
                                onChange={(event) => this.setState({ firstname: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="user" style={{ color: colorTheme.primary }} />}
                            />
                            <Input
                                className="input-login"
                                size="large"
                                type='text'
                                ref='lastname'
                                required
                                placeholder="Lastname"
                                value={this.state.lastname}
                                onChange={(event) => this.setState({ lastname: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="user" style={{ color: colorTheme.primary }} />}
                            />
                            <Input
                                className="input-login"
                                size="large"
                                type='email'
                                ref='username'
                                required
                                placeholder="Email"
                                value={this.state.username}
                                onChange={(event) => this.setState({ username: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="mail" style={{ color: colorTheme.primary }} />}
                            />
                            <Input
                                className="input-login"
                                size="large"
                                type='password'
                                ref='password'
                                required
                                placeholder="Password"
                                value={this.state.password}
                                onChange={(event) => this.setState({ password: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="lock" style={{ color: colorTheme.primary }} />}
                            />
                            <Input
                                className="input-login"
                                size="large"
                                type='password'
                                ref='confirmPassword'
                                required
                                placeholder="Confirm Password"
                                value={this.state.confirmPassword}
                                onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                                onKeyPress={(event) => this.handleEnterKeyPress(event)}
                                prefix={<Icon type="lock" style={{ color: colorTheme.primary }} />}
                            />
                            <button
                                // style={buttonTheme.primary}
                                onClick={() => this.handleClick()}
                            >
                                {'Sign up'}
                            </button>
                        </div>
                    </div>
                </Col>

            </Row>

        )
    }

}

const mapStateToProps = (state) => ({
    colorTheme: state.theme.color,
    buttonTheme: state.theme.button
})

const mapDispatchToProps = (dispatch) => ({
    loginSuccess: (payload) => dispatch(loginSuccess(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

