import React from 'react';
import { Col, Row, Icon, List, Modal, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { get } from '../../utils';
import config from '../../config.json'
import defaultSquareSrc from '../../resource/default/300x300-square.png'
import './style.scss'


class ChatProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            categoryProductId: '',
            categoryTicketId: '',
            detail: '',
            total: 0,
            purchase: 0,
            inventory: 0,
            price: 0,
            priceForSale: 0,
            detailList: [],
            imageCoverList: [],
            imageDetailList: [],
            viewer: 0
        }
    }

    getProductDetail() {
        const { productId, folderName } = this.props
        let url = '/api/administrator/product/' + productId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let imageCoverList = []
                if (response.image) {
                    imageCoverList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                        file: null,
                        name: response.image
                    }]
                }
                let imageDetailList = response.imageDetailList.map(item => {
                    return {
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`,
                        file: null,
                        name: item.name
                    }
                })
                this.setState({
                    name: response.name,
                    categoryProductId: response.category_product_id,
                    categoryTicketId: response.category_ticket_id,
                    detail: response.detail,
                    total: response.total,
                    purchase: response.purchase,
                    inventory: response.inventory,
                    price: response.price,
                    priceForSale: response.price_for_sale,
                    detailList: response.detailList,
                    imageCoverList: imageCoverList,
                    imageDetailList: imageDetailList,
                    viewer: response.view,
                    favorite: response.favorite,

                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getProductDetail()
    }

    //ใส่คอมม่าและทศนิยมให้ค่าเงิน
    formatCurrency(number) {
        number = parseFloat(number);
        return number.toFixed(2).replace(/./g, function(c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
    }

    render() {
        const { name, price, imageCoverList, priceForSale, viewer, detailList, favorite } = this.state
        return (
            <div className="img-card-chat">
                <div className="first-sec">
                    <div className="left-card-chat">
                        {imageCoverList.length ?
                            <img
                                width={'200px'}
                                style={{ borderRadius: '6px' }}
                                src={imageCoverList[0].view}
                                onError={(image) => image.target.src = defaultSquareSrc}
                            />
                            :
                            null}
                    </div>
                    <div className="right-card-chat">
                        <p className="right-card-chat-header">{name}</p>
                        {
                            detailList.map(item => {
                                return (
                                    <div>
                                        <p style={{ margin: '0', fontSize: '12' }}> <div className="iconlist"></div> {item.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="second-sec">
                    <div className="left-card-sec2">
                        <p><Icon type="eye" theme="filled" style={{ color: '#48c5eb' }} /> {viewer} คนกำลังดูสิ่งนี้อยู่</p>
                        <p><Icon type="heart" theme="filled" style={{ color: 'red' }} /> {favorite} คนติดตาม</p>
                    </div>
                    <div className="right-card-sec2">
                        <p>฿ {this.formatCurrency(price)}</p>
                        {/* <p>{priceForSale} baht</p> */}
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(ChatProductCard);