import React from 'react';
import { Form, Input, Select, Row, Col, Button, Icon, Table } from 'antd';
import { get } from '../../../utils';
import './style.scss'

const FormItem = Form.Item;
const Option = Select.Option;

class BankTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bankList: [],

            columns: [
                {
                    title: 'Line ID',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center ',
                    width: '10%',
                    render: (text, item, index) => (index + 1).toString().padStart(5, 0)
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    width: '30%',
                    render: (text, item, index) => this.renderNameColumn(text, item, index)
                },
                {
                    title: 'Account',
                    dataIndex: 'account',
                    key: 'account',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => this.renderAccountColumn(text, item, index)
                },
                {
                    title: 'Bank',
                    dataIndex: 'bank_id',
                    key: 'bank_id',
                    align: 'center',
                    width: '15%',
                    render: (text, item, index) => this.renderBankColumn(text, item, index)
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    align: 'center',
                    width: '10%',
                    render: (text, item, index) => this.renderActionColumn(text, item, index)
                },
            ]
        }
    }
    renderNameColumn(text, item, index) {
        return (
            <Input
                value={item.name}
                onChange={(event) => this.updateBankTransfer(index, 'name', event.target.value)}
            />
        )
    }

    renderAccountColumn(text, item, index) {
        return (
            <Input
                value={item.account}
                onChange={(event) => this.updateBankTransfer(index, 'account', event.target.value)}
            />
        )
    }

    renderBankColumn(text, item, index) {
        return (
            <Select
                showSearch={true}
                optionFilterProp={'title'}
                value={item.bank_id}
                onChange={(value) => this.updateBankTransfer(index, 'bank_id', value)}
            >
                {this.state.bankList.map((item, index) => <Option key={index} value={item.id} title={item.name_thai} >{item.name_thai}</Option>)}
            </Select>
        )
    }

    renderActionColumn(text, item, index) {
        return (
            <div style={{
                margin: 5,
                marginLeft: "auto",
                marginRight: "auto",
                width: 30, height: 30, borderRadius: 15,
                backgroundColor: '#EAEAEA',
                cursor: 'pointer',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
                onClick={() => this.deleteBankTransfer(index)}
            >
                <Icon type="close" style={{ fontSize: 16, color: 'red' }} />
            </div>
        )
    }

    getBankList() {
        let url = '/api/bank/raw?is_delete=0';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                this.setState({ bankList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getBankList()
    }


    addBankTransfer() {
        let bankTransferList = this.props.bankTransferList.map(item => Object.assign({}, item))
        bankTransferList.push({
            payment_method_id: this.props.paymentMethodId,
            name: '',
            account: '',
            bank_id: null
        })
        this.props.updateBankTransferList(bankTransferList)
    }
    updateBankTransfer(index, name, value) {
        let bankTransferList = this.props.bankTransferList.map(item => Object.assign({}, item))
        bankTransferList[index][name] = value
        this.props.updateBankTransferList(bankTransferList)
    }
    deleteBankTransfer(index) {
        let bankTransferList = this.props.bankTransferList.filter((item, i) => i !== index)
        this.props.updateBankTransferList(bankTransferList)
    }

    render() {
        const { columns } = this.state
        const { bankTransferList } = this.props
        return (
            <div className='banktransfer-container-wrapper'>
                <Row className='action-button-wrapper' gutter={24} type="flex" justify='space-between'>
                    <p className='title-header'>{'Bank Account'}</p>
                    <Button className='create-button' onClick={() => this.addBankTransfer()}  ><Icon type="plus" />{'Add'}</Button>
                </Row>
                <Table
                    dataSource={bankTransferList}
                    columns={columns}
                    pagination={false}
                />
            </div>
        );
    }
}


export default BankTransfer;

