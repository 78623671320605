import React from 'react';
import { Row } from 'antd';

class ChatVideoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            categoryProductId: '',
            categoryTicketId: '',
            detail: '',
            total: 0,
            purchase: 0,
            inventory: 0,
            price: 0,
            priceForSale: 0,
            detailList: [],
            imageCoverList: [],
            imageDetailList: []
        }
    }

    render() {
        const { video } = this.props
        return (
            <div style={{ padding: '10px', backgroundColor: '#fff', borderRadius: '10px' }}>
                <Row>
                    <video src={video} controls width={250} style={{ borderRadius: '6px' }} />
                </Row>
            </div>
        )
    }
}

export default ChatVideoCard;