import React from 'react';
import { Row, Col, Modal, Select, Form, Input } from 'antd';
import ImageUpload from '../../../../containers/ImageUpload';
import './style.scss'


const Option = Select.Option;
const FormItem = Form.Item;

class HomeVIPBannerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    updateSettingImageLayoutList(value, index) {
        let settingLayoutList = this.props.settingLayoutList.map(item => Object.assign({}, item))
        settingLayoutList[index].imageList = value
        this.props.updateSettingLayoutList(settingLayoutList)
    }


    handleOk() {
        this.props.onClose()
    }
    handleCancel() {
        this.props.onClose()
    }



    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 4, },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 20 },
            },
        };

        const { visible, settingLayoutList } = this.props
        return (
            <div className={'layout-mobile-wrapper'}>
                <Modal
                    title={'Layout mobile'}
                    visible={visible}
                    width={800}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                >
                    <div className="layout-setting-container">
                        {settingLayoutList.map((item, index) => {
                            return (
                                <div>
                                    <Row className="layout-setting-item" >
                                        <div className="layout-setting-item-label"><p style={{color:'#47C5EB', fontWeight:'700'}}>{`รูปที่ : ${index + 1}`}</p></div>
                                    </Row>
                                    <Row className="layout-setting-item" >
                                        <Col sm={24} md={24} lg={24} xl={24}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <FormItem
                                                style={{ margin: 0, width: '100%' }}>
                                                <ImageUpload
                                                    label={'Upload image'}
                                                    limit={1}
                                                    dataSource={item.imageList}
                                                    updateDataSource={(dataSource) => this.updateSettingImageLayoutList(dataSource, index)}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default HomeVIPBannerModal;

