import React, { Component } from 'react'
import { connect } from 'react-redux';
import { loginSuccess } from '../../../actions/login';
import { post } from '../../../utils';


class UserAdministratorInvitation extends Component {

    inviteUserAdministrator(userAdministratorId, mainCustomerId, inviteToken, inviteStatus) {
        let url = '/api/user/invite/administrator'
        let data = { user_administrator_id: userAdministratorId, main_customer_id: mainCustomerId, invite_token: inviteToken, invite_status: inviteStatus }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log("response", response)
                if (response.success) {
                    this.props.history.push('/')
                } else {
                    console.log("response error", response)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const userAdministratorId = params.get('user');
        const mainCustomerId = params.get('maincustomer');
        const inviteToken = params.get('token');
        const inviteStatus = params.get('status');
        if (userAdministratorId && mainCustomerId && inviteToken && inviteStatus) {
            this.inviteUserAdministrator(userAdministratorId, mainCustomerId, inviteToken, inviteStatus)
        }

    }
    render() {
        return (
            <div>{'User Administrator Invitation'}</div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
})

export default connect(null, mapDispatchToProps)(UserAdministratorInvitation);

