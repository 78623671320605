import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Icon } from 'antd';
import { lget } from '../../../utils';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;

class CategoryCourse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        }
    }


    getCategoryCourseDetail() {
        const { editId, codexlearnApiProtocol, codexlearnApiHostname, codexlearnApiPort } = this.props
        let url = '/learn/connect/administrator/category/' + editId;
        let serverUrl = `${codexlearnApiProtocol}://${codexlearnApiHostname}:${codexlearnApiPort}` + url
        lget(serverUrl)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                if (responses.length) {
                    let response = responses[0]
                    this.setState({
                        name: response.name,
                    })
                }

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getCategoryCourseDetail()
        }
    }




    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <div className='categoryproduct-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >View Category Product</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Name'}>
                                    <Input
                                        value={this.state.name}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,

    codexlearnApiProtocol: state.login.codexlearn_api_protocol,
    codexlearnApiHostname: state.login.codexlearn_api_hostname,
    codexlearnApiPort: state.login.codexlearn_api_port,
})

export default connect(mapStateToProps, null)(CategoryCourse);

