import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Modal,notification } from 'antd';
import { get, post } from '../../../utils';
import './style.scss'
notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;

class CategoryArticleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        }
    }


    getCategoryArticleDetail() {
        const { editId } = this.props
        let url = '/api/administrator/category/article/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    name: response.name,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getCategoryArticleDetail()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible != this.props.visible) {
            if (this.props.editId) {
                this.getCategoryArticleDetail()
            }
        }
    }

    updateCategoryArticle() {
        const { name } = this.state
        if (!name || name == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Name Category".')
            return
        }

        let url = '/api/crm/administrator/category/article';
        if (this.props.editId) {
            url = '/api/administrator/category/article/update/' + this.props.editId
        }
        let data = {
            name: name,
            user: this.props.administratorId
        }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ name: '' })
                this.props.onClose()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateCategoryArticle()
    }
    onClickCancel() {
        this.setState({ name: '' })
        this.props.onClose()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 6 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 18 },
            },
        };
        const { visible } = this.props
        return (
            <div>
                <Modal
                    title={this.props.editId ? 'Edit Category Article' : 'Create Category Article'}
                    visible={visible}
                    onOk={() => this.onClickOk()}
                    onCancel={() => this.onClickCancel()}
                    style={{ textAlign: 'center' }}
                    footer={[
                        <Button key="back" onClick={() => this.onClickCancel()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                            {'Cancel'}
                        </Button>,
                        <Button key="submit" onClick={() => this.onClickOk()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                            {this.props.editId ? 'Edit' : 'Create'}
                        </Button>,
                    ]}
                >
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Name Category'} style={{ fontSize: 20 }} required={true}>
                                <Input
                                    value={this.state.name}
                                    onChange={(event) => this.setState({ name: event.target.value })}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

CategoryArticleModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

CategoryArticleModal.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
})

export default connect(mapStateToProps)(CategoryArticleModal);

