import fetch from 'isomorphic-fetch';

const PROTOCOL = process.env.REACT_APP_API_PROTOCOL
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME
const PORT = process.env.REACT_APP_API_PORT

console.log('process.env.REACT_APP_API_ENDPOINT', process.env.REACT_APP_API_ENDPOINT)
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : `${PROTOCOL}://${HOSTNAME}:${PORT}`

export function call(serverUrl, method, data) {
  console.log("serverUrl", serverUrl)
  return fetch(serverUrl, {
    method: method,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    body: JSON.stringify(data)
  })
    .then(parseJSON)
    .then(checkHttpStatus)
    .catch(error => {
      // No response from the server
      console.log("error at utils.index.js line 21")
      console.log(error)
      if (typeof error.response === 'undefined') {
        error.response = {
          status: 408,
          message: 'Cannot connect to the server'
        }
      }
      throw error
    })
}

export function get(url) {
  const serverUrl = `${API_ENDPOINT}${url}`
  return call(serverUrl, 'GET')
}

export function post(url, data) {
  const serverUrl = `${API_ENDPOINT}${url}`
  return call(serverUrl, 'POST', data)
}

export function put(url, data) {
  const serverUrl = `${API_ENDPOINT}${url}`
  return call(serverUrl, 'PUT', data)
}

export function patch(url, data) {
  const serverUrl = `${API_ENDPOINT}${url}`
  return call(serverUrl, 'PATCH', data)
}

export function del(url, data) {
  const serverUrl = `${API_ENDPOINT}${url}`
  return call(serverUrl, 'DELETE', data)
}

export function lget(serverUrl) {
  return call(serverUrl, 'GET')
}

export function lpost(serverUrl, data) {
  return call(serverUrl, 'POST', data)
}


export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.body
  } else {
    var error = new Error(response.statusText)
    error.response = response.body
    throw error
  }
}

export function parseJSON(response) {
  return response.json()
    .then(function (body) {
      return {
        status: response.status,
        statusText: response.statusText,
        body: body
      }
    })
    .catch(function (e) {
      return response;
    })
}

export function ppost(url, data) {
  return ccall(url, 'post', data)
}

export function ccall(url, method, data) {
  console.log("ccall: ", data)
  const serverUrl = `${API_ENDPOINT}${url}`
  console.log("serverUrl", serverUrl)
  return fetch(serverUrl, {
    method: method,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    body: data
  })
    .then(parseJSON)
    .then(checkHttpStatus)
    .catch(error => {
      // No response from the server
      console.log("error at utils.index.js line 107")
      console.log(error)
      if (typeof error.response === 'undefined') {
        error.response = {
          status: 408,
          message: 'Cannot connect to the server'
        }
      }
      throw error
    })
}

export function exportnb(url) {
  const openSaveDialog = function (data) {
    let a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"

    let blob = new Blob([data], {
      type: "*"
    })
    let url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = 'DSC03373.JPG'
    a.click()
    window.URL.revokeObjectURL(url)
  }


  const serverUrl = `${API_ENDPOINT}${url}`
  console.log("serverUrl", serverUrl)
  fetch(serverUrl, {
    method: 'get',
    headers: {
      'Accept': '*/*',
      'Content-Type': '*',
      'Access-Control-Allow-Credentials': true,
    },
  })
    .then(function (result) {
      openSaveDialog(result.body)
    })
    .catch(err => {
      console.log('err', err);
    })
}


export function exportXlsx(url, data, fileName = 'file.xlsx') {
  const openSaveDialog = function (value) {
    let a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"

    let blob = new Blob([value], { type: "*" })
    let url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const serverUrl = `${API_ENDPOINT}${url}`
  fetch(serverUrl, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    body: JSON.stringify(data)
  })
    .then(function (result) {
      const reader = result.body.getReader()
      reader.read().then(dataReader => openSaveDialog(dataReader.value))
    })
    .catch(err => {
      console.log('err', err);
    })
}
