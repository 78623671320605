import React from 'react';
import { Col, Row, Icon, List, Modal, Button, Input } from 'antd';
import { get, ppost } from '../../utils';
import config from '../../config.json'
import { connect } from 'react-redux';
import fuzzysearch from 'fuzzysearch';
import StorageOverLimitModal from '../../containers/CustomModal/StorageOverLimitModal';
import defaultSquareSrc from '../../resource/default/300x300-square.png'
import './style.scss'

const { Search } = Input;

class UploadImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleAddMember: false,
            visibleProduct: false,
            visibleProductCategory: false,

            typeFileVideo: ['video/mp4', 'video/webm'],
            typeFile: ['all'],

            galleryList: [],
            selectGalleryName: null,
            selectGalleryItem: [],



            productCategoryList: [],
            selectProductCategoryName: null,
            selectProductCategoryItem: [],
            selectCategoryId: '',
            selectCategoryImage: [],

            productList: [],
            selectProductName: null,
            selectProductItem: [],
            selectProductId: '',

            memberList: [],
            visibleModalOversStorage: false,
            productDetailList: [],

            filterProductCategory: null,
            filterProduct: null,
            filterGallery: null,
        }
    }

    getGalleryAll() {
        let url = '/api/administrator/gallery/all';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.length > 0) {
                    this.setState({
                        galleryList: response,
                        selectGalleryName: response[0].name,
                    })
                }

            })
            .catch(err => {
                console.log(err.message)
            })
    }


    getProductAll() {
        let url = '/api/administrator/product/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.length > 0) {
                    this.setState({
                        productList: response,
                        selectProductName: response[0].name,
                    })
                    this.setSelectProductItem(response[0].image, response[0].id, response[0].name)
                }

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCatalogAll() {
        let url = '/api/category/product/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response.length > 0) {
                    let catalogdata = []
                    response.map(item => {
                        catalogdata.push({
                            id: item.id,
                            name: item.name,
                            image: item.image
                        })
                    })

                    this.setState({
                        productCategoryList: catalogdata,
                        selectProductCategoryName: response[0].name,
                        selectCategoryId: response[0].id
                    })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getProductDetail(product_id) {
        let url = '/api/administrator/product/' + product_id;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                else {
                    this.setState({
                        productDetailList: response.imageDetailList
                    })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getGalleryAll()
        this.getProductAll()
        this.getCatalogAll()
        // this.getProductDetail()
    }

    getStorageDetail(callback) {
        console.log('get storage')
        let url = '/api/crm/administrator/setting/storage';
        get(url)
            .then(responses => {
                callback({ success: true, storage: responses })
            })
            .catch(error => {
                console.log(error.message)
                callback({ success: false, error: error })
            })
    }

    async updateImageMessage(fileList) {
        if (fileList.length > 0) {
            console.log('upload >', fileList.length)
            let formData = new FormData();
            fileList.map((file, index) => formData.append("message" + index, file))
            console.log('formData >', formData)
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                console.log('fileNameList', fileNameList)
                this.props.onSendFile(fileNameList, '#FILE')
            }
        }
    }


    async uploadImageToServer(formData) {
        console.log('formData', formData)
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log('res', response)
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }


    onChangeCheckbox(index) {
        let memberList = Object.assign([], this.state.memberList)
        memberList[index].checked = !this.state.memberList[index].checked
        this.setState({ memberList: memberList })
    }

    handleOkCatalog() {
        const { selectCategoryId, selectCategoryImage } = this.state
        this.props.onSendFile(selectCategoryImage, '#CATEGORY_PRODUCT-' + selectCategoryId)
        this.setState({
            visibleProductCategory: false,
            selectProductCategoryName: null,
            selectCategoryId: '',
            selectCategoryImage: '',
        })
    }

    handleOkGallery() {
        const { selectGalleryItem } = this.state
        this.props.onSendFile(selectGalleryItem, '#IMAGE')
        this.setState({
            selectGalleryItem: [],
            visible: false,
        })
    }

    handleOkProduct() {
        const { selectProductItem, selectProductId, } = this.state
        this.props.onSendFile(selectProductItem, '#PRODUCT-' + selectProductId)
        this.setState({
            selectProductItem: [],
            selectProductId: '',
            visibleProduct: false,
        })
    }

    handleCancelCatalog() {
        this.setState({ visibleProductCategory: false })
    }
    handleCancelGallery() {
        this.setState({ visible: false })
    }
    handleCancelProduct() {
        this.setState({ visibleProduct: false })
    }

    galleryModal() {
        const { filterGallery, selectGalleryItem } = this.state
        const { folderName } = this.props
        let galleryNameList = this.state.galleryList.map(gallery => gallery.name)
        let galleryItemList = []
        this.state.galleryList.filter(gallery => gallery.name == this.state.selectGalleryName).map(gallery => {
            let imageList = JSON.parse(gallery.images)
            if (imageList.length > 0) {
                imageList.map(image => {
                    galleryItemList.push({
                        name: image,
                        url: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${image}`
                    })
                })
            }
        })
        let filterGalleryList = galleryNameList
        if (filterGallery) {
            filterGalleryList = filterGalleryList.filter(name => fuzzysearch(filterGallery, name))
        }

        return (
            <Modal
                title="Gallery"
                visible={this.state.visible}
                width={'90%'}
                onOk={() => this.handleOkGallery()}
                onCancel={() => this.handleCancelGallery()}
                className="modal-product-input"
                footer={[
                    <Button key="back" onClick={() => this.handleCancelGallery()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={() => this.handleOkGallery()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                        OK
                    </Button>,
                ]}
            >
                <Row type="flex" justify="space-around" align="top">
                    <Col sm={24} md={8} lg={8} className="left-category">
                        <div className="inside-category">
                            <div className="category-header-text">
                                <h3>หมวดหมู่แกลอรี่</h3>
                            </div>
                            <div className="category-header-text">
                                <Search
                                    placeholder={"พิมพ์หมวดหมู่แกลอรี่ที่ต้องการ ..."}
                                    onSearch={value => this.setState({ filterGallery: value })}
                                    style={{ width: '100%' }}
                                    className="searchbox"
                                />
                            </div>
                            <div className="modal-list-file">
                                <List
                                    dataSource={filterGalleryList}
                                    renderItem={item => (
                                        <List.Item style={{ padding: 0, border: this.state.selectGalleryName == item ? '2px solid #48c5eb' : '', borderRadius: 5 }}>
                                            <p
                                                style={{ width: '100%', height: '100%', cursor: 'pointer', margin: 0, padding: '15px 10px' }}
                                                onClick={() => this.setState({ selectGalleryName: item })}
                                            >
                                                {item}
                                            </p>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={16} lg={16} className="right-category">
                        <div className="inside-category">
                            <div className="category-header-text">
                                <h3>รูปภาพแกลอรี่ : {this.state.selectGalleryName}</h3>
                            </div>
                            <div className="modal-list-product">
                                <List
                                    style={{width:'98%'}}
                                    dataSource={galleryItemList}
                                    grid={{
                                        gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
                                    }}
                                    renderItem={item => (
                                        <List.Item >
                                            <div style={{ border: selectGalleryItem.includes(item.name) ? '3px solid #48c5eb' : 'none', padding: '3px', borderRadius: '5px' }}>
                                                <img
                                                    id="target"
                                                    style={{ width: '100%', height: 150, cursor: 'pointer', borderRadius: '5px' }}
                                                    src={item.url} 
                                                    onClick={() => this.setSelectGalleryItem(item.name)}
                                                    onError={(image) => image.target.src = defaultSquareSrc}
                                                />
                                            </div>
                                        </List.Item>
                                    )}

                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        )
    }


    catalogModal() {
        const { filterProductCategory, productCategoryList, selectProductCategoryName, productList, selectCategoryId } = this.state
        const { folderName } = this.props
        let filterProductCategoryList = productCategoryList.map(item => Object.assign({}, item))
        if (filterProductCategory) {
            filterProductCategoryList = filterProductCategoryList.filter(item => fuzzysearch(filterProductCategory, item.name))
        }
        let productItemList = []
        this.state.productList.filter(product => product.category_product_id == selectCategoryId).map(product2 => {
            productItemList.push({
                id: product2.id,
                name: product2.image,
                url: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${product2.image}`,
                productname: product2.name
            })
        })
        return (
            <Modal
                title="Catalog Product"
                visible={this.state.visibleProductCategory}
                width={'90%'}
                onOk={() => this.handleOkCatalog()}
                onCancel={() => this.handleCancelCatalog()}
                className="modal-product-input"
                footer={[
                    <Button key="back" onClick={() => this.handleCancelCatalog()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={() => this.handleOkCatalog()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                        OK
                    </Button>,
                ]}
            >
                <Row type="flex" justify="space-around" align="top">
                    <Col sm={24} md={8} lg={8} className="left-category">
                        <div className="inside-category">
                            <div className="category-header-text">
                                <h3>หมวดหมู่สินค้า</h3>
                            </div>
                            <div className="category-header-text">
                                <Search
                                    placeholder={"พิมพ์หมวดหมู่สินค้าที่ต้องการ ..."}
                                    onSearch={value => this.setState({ filterProductCategory: value })}
                                    style={{ width: '100%', }}
                                    className="searchbox"
                                />
                            </div>
                            <div className="modal-list-file">
                                <List
                                    dataSource={filterProductCategoryList}
                                    renderItem={item => (
                                        <List.Item style={{ padding: 0, border: selectProductCategoryName == item.name ? '2px solid #48c5eb' : '', borderRadius: 5 }}>
                                            <p
                                                style={{ width: '100%', height: '100%', cursor: 'pointer', margin: 0, }}
                                                onClick={() => this.setSelectCatalogProduct(item.name, item.id, item.image)}
                                            >
                                                <img
                                                    width="60px" height="60px"
                                                    style={{ marginRight: 10 }}
                                                    src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.image}`}
                                                    onError={(image) => image.target.src = defaultSquareSrc}
                                                />
                                                {item.name}
                                            </p>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={16} lg={16} className="right-category">
                        <div className="inside-category">
                            <div className="category-header-text">
                                <h3>รายการสินค้าในหมวดหมู่ : {selectProductCategoryName}</h3>
                            </div>
                            <div className="modal-list-product">
                                <List
                                    style={{width:'98%'}}
                                    dataSource={productItemList}
                                    grid={{
                                        gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
                                    }}
                                    renderItem={item => (
                                        <List.Item >
                                            <div className="product-list-item">
                                                <img
                                                    style={{ width: '100%', height: 150 }}
                                                    src={item.url}
                                                    onError={(image) => image.target.src = defaultSquareSrc}
                                                />
                                                <p style={{ padding: 10, margin: 0,height:55,overflow:'hidden' }}>{item.productname}</p>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        )
    }

    productModal() {
        const { filterProduct, selectProductItem, productList, productDetailList } = this.state
        const { folderName } = this.props
        let productNameList = productList.map(product => product)
        let productItemList = []
        this.state.productList.filter(product => product.name == this.state.selectProductName).map(product2 => {
            productItemList.push({
                id: product2.id,
                name: product2.image,
                url: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${product2.image}`,
            })
        })
        let filterProductList = productNameList
        if (filterProduct) {
            filterProductList = productNameList.filter(item => fuzzysearch(filterProduct, item.name))
        }
        return (
            <Modal
                title="Product"
                visible={this.state.visibleProduct}
                width={'90%'}
                onOk={() => this.handleOkProduct()}
                onCancel={() => this.handleCancelProduct()}
                className="modal-product-input"
                footer={[
                    <Button key="back" onClick={() => this.handleCancelProduct()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={() => this.handleOkProduct()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                        OK
                    </Button>,
                ]}
            >
                <Row type="flex" justify="space-around" align="top">
                    <Col sm={24} md={8} lg={8} className="left-category">
                        <div className="inside-category">
                            <div className="category-header-text">
                                <h3>รายชื่อสินค้า</h3>
                            </div>
                            <div className="category-header-text">
                                <Search
                                    placeholder={"พิมพ์สินค้าที่ต้องการ ..."}
                                    onSearch={value => this.setState({ filterProduct: value })}
                                    style={{ width: '100%' }}
                                    className="searchbox"
                                />
                            </div>
                            <div className="modal-list-file">
                                <List
                                    dataSource={filterProductList}
                                    renderItem={item => (
                                        <List.Item style={{ padding: 0, border: this.state.selectProductName == item.name ? '2px solid #48c5eb' : '', borderRadius: 5 }}>

                                            <p
                                                style={{ width: '100%', height: '100%', cursor: 'pointer', margin: 0, padding: '10px' }}
                                                // onClick={() => this.getProductDetail(item.id)}
                                                onClick={() => this.setSelectProductItem(item.image, item.id, item.name)}
                                            >
                                                <img
                                                    width="60px" height="60px"
                                                    style={{ marginRight: 10 }} src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.image}`}
                                                    onError={(image) => image.target.src = defaultSquareSrc}
                                                />
                                                {item.name}
                                            </p>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={16} lg={16} className="right-category">
                        <div className="inside-category">
                            <div className="category-header-text">
                                <h3>รูปภาพสินค้า : {this.state.selectProductName}</h3>
                            </div>
                            <div className="modal-list-product">
                                <List
                                    style={{width:'98%'}}
                                    dataSource={productDetailList}
                                    grid={{
                                        gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
                                    }}
                                    renderItem={item => (
                                        <List.Item >
                                            {/* <div style={{ border: selectProductItem.includes(item.name) ? '2px solid green' : 'none' }}> */}
                                            <div>
                                                <img
                                                    id="target"
                                                    style={{ width: '100%', height: 150, cursor: 'pointer' }}
                                                    src={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`}
                                                    onError={(image) => image.target.src = defaultSquareSrc}
                                                />
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        )
    }

    setSelectGalleryItem(item) {
        const { selectGalleryItem } = this.state
        let newSelectGalleryItem = Object.assign([], selectGalleryItem)
        if (selectGalleryItem.includes(item)) {
            newSelectGalleryItem = selectGalleryItem.filter(image => image != item)
        } else {
            newSelectGalleryItem.push(item)
        }
        this.setState({ selectGalleryItem: newSelectGalleryItem })
    }

    setSelectCatalogItem(item) {
        const { selectProductCategoryItem } = this.state
        let newSelectCatalogItem = Object.assign([], selectProductCategoryItem)
        if (selectProductCategoryItem.includes(item)) {
            newSelectCatalogItem = selectProductCategoryItem.filter(image => image != item)
        } else {
            newSelectCatalogItem.push(item)
        }
        this.setState({ selectProductCategoryItem: newSelectCatalogItem })
    }

    setSelectProductItem(item, id, name) {
        const { selectProductItem } = this.state
        let newSelectProductItem = Object.assign([], newSelectProductItem)
        if (selectProductItem.includes(item)) {
            newSelectProductItem = selectProductItem.filter(image => image != item)
        } else {
            newSelectProductItem.push(item)
        }
        this.getProductDetail(id)
        this.setState({ selectProductItem: newSelectProductItem, selectProductId: id, selectProductName: name })
    }


    setSelectCatalogProduct(item, id, image) {
        let newselectCategoryImage = Object.assign([], newselectCategoryImage)
        newselectCategoryImage.push(image)
        this.setState({ selectProductCategoryName: item, selectCategoryId: id, selectCategoryImage: newselectCategoryImage })
    }

    // onChangeButton(event) {
    //     console.log('upload vidoe',event.target.files)
    //     let fileList = []
    //     if (event.target.files && event.target.files.length > 0) {
    //         for (let index = 0; index < event.target.files.length; index++) {
    //             fileList.push(event.target.files[index])
    //         }
    //         event.target.value = null;
    //     }
    //     console.log('upload vidoe2',fileList)
    //     this.updateImageMessage(fileList)
    // }


    onChangeButton(event) {
        let fileSelect = Array.from(this.refs.File.files)
        this.getStorageDetail((storageDetail) => {
            if (storageDetail.success) {
                // let dataSource = event.target.files.map(item => Object.assign({}, item))
                let fileLists = []
                if (fileSelect && fileSelect.length > 0) {
                    let fileSize = fileSelect[0].size / (1024 * 1024 * 1024)
                    if (storageDetail.storage.free < fileSize) {
                        this.showStorageOverLimitModal()
                    } else {
                        console.log('else')
                        let temp = { file: fileSelect[0] }
                        let reader = new FileReader();
                        reader.readAsDataURL(fileSelect[0]);
                        reader.onload = (e) => {
                            temp['view'] = e.target.result
                            fileLists.push(temp.file)
                            this.updateImageMessage(fileLists)
                        };
                    }
                    event.target.value = null;
                }
            }
        })
    }
    async updateVideoMessage(fileList) {
        if (fileList.length > 0) {
            let formData = new FormData();
            fileList.map((file, index) => formData.append("message" + index, file))
            let fileNameList = await this.uploadVideoToServer(formData)
            if (fileNameList.length) {
                // console.log('fileNameList',fileNameList)
                this.props.onSendFile(fileNameList,'#VIDEO')
            }
        }
    }

    async uploadVideoToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    onChangeButtonVideo(event) {
        let fileSelect = Array.from(this.refs.videoFile.files)
        this.getStorageDetail((storageDetail) => {
            if (storageDetail.success) {
                // let dataSource = event.target.files.map(item => Object.assign({}, item))
                let fileLists=[]
                if (fileSelect && fileSelect.length > 0) {
                    let fileSize = fileSelect[0].size / (1024 * 1024 * 1024)
                    if (storageDetail.storage.free < fileSize) {
                        this.showStorageOverLimitModal()
                    } else {
                        let temp = { file: fileSelect[0] }
                        let reader = new FileReader();
                        reader.readAsDataURL(fileSelect[0]);
                        reader.onload = (e) => {
                            temp['view'] = e.target.result
                            fileLists.push(temp.file)
                            this.updateVideoMessage(fileLists)
                        };
                    }
                    event.target.value = null;
                }
            }
        })
    }

    showStorageOverLimitModal() {
        this.setState({ visibleModalOversStorage: true })
    }

    onClickGallery() {
        this.setState({ visible: true })
        this.props.closeHover()
    }
    onClickProduct() {
        this.setState({ visibleProduct: true })
        this.props.closeHover()
    }
    onClickCatalog() {
        this.setState({ visibleProductCategory: true })
        this.props.closeHover()
    }

    // onClickNewUpload() {
    //     this.refs.imageFile.click()
    //     this.props.closeHover()
    // }

    onClickAddMember() {
        this.setState({ visibleAddMember: true })
        this.props.closeHover()
    }

    onClickUpload() {
        this.refs.videoFile.click()
        this.props.closeHover()
    }
    onClickUploadFile() {
        this.refs.File.click()
        this.props.closeHover()
    }

    render() {
        return (
            <div>
                <div className="upload-file-container">
                    <div style={{ textAlign: 'center' }} onClick={() => this.onClickUploadFile()} >
                        <input
                            multiple
                            id="File"
                            type="file"
                            ref="File"
                            accept={this.state.typeFile}
                            onChange={(event) => this.onChangeButton(event)}
                            style={{ display: 'none' }}
                        />
                        <Icon type="file" className="upload-file-btn"/>
                        <p className="upload-file-text">File</p>
                    </div>
                    <div style={{ textAlign: 'center' }} onClick={() => this.onClickUpload()} >
                        <input
                            multiple
                            id="videoFile"
                            type="file"
                            ref="videoFile"
                            accept={this.state.typeFileVideo}
                            onChange={(event) => this.onChangeButtonVideo(event)}
                            style={{ display: 'none' }}
                        />
                        <Icon type="video-camera" className="upload-file-btn"  />
                        <p className="upload-file-text">Video</p>
                    </div>
                    <div style={{ textAlign: 'center' }} onClick={() => this.onClickCatalog()}>
                        <Icon type="book" className="upload-file-btn" />
                        <p className="upload-file-text">Catalog</p>
                    </div>
                    <div style={{ textAlign: 'center' }} onClick={() => this.onClickProduct()}>
                        <Icon type="inbox" className="upload-file-btn" />
                        <p className="upload-file-text">Product</p>
                    </div>
                    <div style={{ textAlign: 'center' }} onClick={() => this.onClickGallery()}>
                        <Icon type="picture" className="upload-file-btn" />
                        <p className="upload-file-text">Gallery</p>
                    </div>
                </div>

                {this.galleryModal()}
                {this.productModal()}
                {this.catalogModal()}
                <StorageOverLimitModal
                    visible={this.state.visibleModalOversStorage}
                    onClose={() => this.setState({ visibleModalOversStorage: false })}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(UploadImage);