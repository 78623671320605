import React from 'react';
import { Col, Row, Icon, Button, Popconfirm } from 'antd';
import StorageOverLimitModal from '../../containers/CustomModal/StorageOverLimitModal';
import { get } from '../../utils';
import defaultSquareSrc from '../../resource/default/300x300-square.png'
import './style.scss'

class VideoUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    showStorageOverLimitModal() {
        this.setState({ visible: true })
    }

    getStorageDetail(callback) {
        let url = '/api/crm/administrator/setting/storage';
        get(url)
            .then(responses => {
                callback({ success: true, storage: responses })
            })
            .catch(error => {
                console.log(error.message)
                callback({ success: false, error: error })
            })
    }

    onClickButtonUpload(event) {
        let fileSelect = Array.from(this.refs.imageFile.files)
        this.getStorageDetail((storageDetail) => {
            if (storageDetail.success) {
                let dataSource = this.props.dataSource.map(item => Object.assign({}, item))
                if (fileSelect && fileSelect.length > 0) {
                    let fileSize = fileSelect[0].size / (1024 * 1024 * 1024)
                    if (storageDetail.storage.free < fileSize) {
                        this.showStorageOverLimitModal()
                    } else {
                        let temp = { 
                            file: fileSelect[0],
                            view: URL.createObjectURL(this.refs.imageFile.files[0])
                        }

                        dataSource.push(temp);
                        this.props.updateDataSource(dataSource)
                    }
                    event.target.value = null;
                }
            }
        })
    }

    onClickButtonDelete(index) {
        let dataDelete = this.props.dataSource[index]
        let dataSource = this.props.dataSource.filter((data, i) => i != index)
        if(this.props.isSaveDelete && !dataDelete.file){
            // let tempDeleteFileList = Object.assign([],this.props.deleteFileList)
            // tempDeleteFileList.push(dataDelete.name)
            this.props.updateDeleteFileList([dataDelete.name])
        }
        
        this.props.updateDataSource(dataSource)
    }

    render() {
        const { label, limit, dataSource, previewImage, className, disabled } = this.props
        return (
            <div className={className}>
                <Row
                    type="flex"
                    className='video-upload-container'
                >
                    {previewImage ?
                        <div className='preview-video-wrapper' >
                            <img
                                id="target"
                                style={{ width: '100%', height: '100%' }}
                                src={previewImage}
                                onError={(image) => image.target.src = defaultSquareSrc}
                            />
                        </div>
                        : null
                    }
                    {dataSource.length < limit ?
                        <div
                            className='upload-button-wrapper'
                            onClick={() => disabled ? null : this.refs.imageFile.click()}
                            style={{
                                opacity: disabled ? 0.5 : 1,
                                cursor: disabled ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <input
                                multiple={false} // false only ! if not function check storage is incorrect.
                                accept="video/mp4,video/x-m4v,video/*"
                                id="imageFile"
                                type="file"
                                ref="imageFile"
                                onChange={(event) => this.onClickButtonUpload(event)}
                                style={{ display: 'none' }}
                            />
                            <Icon style={{ fontSize: '50px' }} type="upload" />
                            <p>{label}</p>
                        </div>
                        : null
                    }
                    {dataSource.map((item, index) => {
                        return (
                            <div className='view-image-wrapper' >
                                <video
                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    controls
                                    controlsList="nodownload"
                                    id="videoPlayer"
                                    src={item.view} >
                                    Your browser does not support the video tag.
                                </video>
                                <div 
                                className='delete-button-wrapper'
                                style = {{display : disabled ? 'none' : 'block'}}
                                >
                                    <Popconfirm title="Sure to delete?" onConfirm={() => this.onClickButtonDelete(index)}>
                                        <Button style={{ width: '100%', height: '25px' }}><Icon type="delete" /></Button>
                                    </Popconfirm>
                                </div>
                            </div>
                        )
                    })}
                    <StorageOverLimitModal
                        visible={this.state.visible}
                        onClose={() => this.setState({ visible: false })}
                    />
                </Row>

            </div>
        )
    }
}

export default VideoUpload;