import React,{useEffect,useState} from 'react'
import {connect} from 'react-redux'
import {Row,Col,Icon,Button,Card,Form,Select, notification,Input} from 'antd'
import ImageUpload from '../../../containers/ImageUpload';
import { get, post, ppost,put } from '../../../utils';

const FormItem = Form.Item; 
const Option = Select.Option ; 

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

function Advert({editId,backpage,administratorId,folderName}) {
    const [name, setName] = useState('')
    const [isPublic, setIsPublic] = useState(1)
    const [imageList, setImageList] = useState([])

    const formItemLayout = {
        // labelCol: {
        //     xs: { span: 8 },
        //     sm: { span: 4 },
        // },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    useEffect(()=>{
        if(editId){
            getData()
        }
        
    },[])

    const getData = () => {
        let url = '/api/crm/administrator/advert/' + editId
        get(url)
            .then((response)=>{
                console.log('response',response)
                if(response.success){
                    let imageList = []
                    if (response.data[0].image) {
                        imageList = [{
                            view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.data[0].image}`,
                            file: null,
                            name: response.data[0].image
                        }]
                    }

                    setName(response.data[0].name)
                    setIsPublic(response.data[0].is_public)
                    setImageList(imageList)
                }
            })
            .catch((err)=>{
                console.log('err',err)
            })
    }
    const onClickCancel = () => {
        setImageList([])
        setIsPublic(1)
        backpage()
    }

    const onClickOk = async () =>{
        if(!imageList.length){
            openNotificationWithIcon('warning', 'System notification', 'Please upload image.')
            return
        }

        if(!name){
            openNotificationWithIcon('warning', 'System notification', 'Please enter Name.')
            return
        }

        let data = {
            publicity: isPublic,
            name: name,
            user: administratorId,
            image: null,
        }

        let uploadFileList = imageList.filter(item => item.file)
        if (uploadFileList.length) {
            let formData = new FormData();
            uploadFileList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await uploadImageToServer(formData)
            if (fileNameList.length) {
                data.image = fileNameList[0]
            }
        } else if (imageList.length) {
            data.image = imageList[0].name
        }


        if (editId) {
            let url = '/api/crm/administrator/advert/' + editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    onClickCancel()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/crm/administrator/advert';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    onClickCancel()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    const uploadImageToServer= async (formData) => {
        let url = '/api/crm/administrator/upload/image'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    return (
        <div className='advert-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
            <Row className="header-card" >
                <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => backpage()} >
                    <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                    <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{editId ? 'Edit Advert' : 'Create Advert'}</span>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => onClickCancel()}>{'Cancel'}</Button>
                    <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => onClickOk()} >{'Save'}</Button>
                </Col>
            </Row>
            <Card className='cardstyle' >
                <Form>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Row>
                                <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                    <FormItem {...formItemLayout} label={'Image'}>
                                        <ImageUpload
                                            label={'Upload image advert'}
                                            limit={1}
                                            dataSource={imageList}
                                            updateDataSource={(dataSource) =>setImageList(dataSource)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <FormItem {...formItemLayout} label={'Name'} required>
                                <Input
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <FormItem {...formItemLayout} label={'Publicity'}>
                                <Select
                                    value={isPublic}
                                    onChange={(value) => setIsPublic(value)}
                                >
                                    <Option value={0}>{'No'}</Option>
                                    <Option value={1}>{'Yes'}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(Advert);