import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, notification, Checkbox } from 'antd';
import { get, patch,post } from '../../../utils'; 

import ProductListLayoutButtonModal from './component/ProductListLayoutButtonModal'

import productLayoutOne from '../../../resource/screen-mobile/home-product/home-product-one.png'
import productLayoutTwo from '../../../resource/screen-mobile/home-product/home-product-two.png'
import productLayoutThree from '../../../resource/screen-mobile/home-product/home-product-three.png'


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class HomeLayoutProductLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            settingScreenMobileId: null,
            productLayout: 1,
            homeSwipeList: [
                { value: 1, name: 'Product Layout One', image: productLayoutOne, size: 0 },
                { value: 2, name: 'Product Layout Two', image: productLayoutTwo, size: 0 },
                { value: 3, name: 'Product Layout Three', image: productLayoutThree, size: 1 },
            ],
            settingLayoutList: [],
            settingLayoutTypeList: [
                { value: 'chat', label: 'Chat' },
                { value: 'buy_now', label: 'Buy now' },
                { value: 'add_to_cart', label: 'Add to cart' },
            ],
        }
    }

    getSettingScreenMobile() {
        let url = '/api/crm/administrator/setting/screen/mobile';
        get(url)
            .then(response => {
                this.setState({
                    settingScreenMobileId: response.id,
                    productLayout: response.home_product
                })
                this.getSettingLayoutMenu()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getSettingLayoutMenu() {
        let url = '/api/setting/product/list/layout/button';
        get(url)
            .then(responses => {
                let data = responses.filter(item=>item.page_name == 'home')
                this.setState({ settingLayoutList: data })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    componentDidMount() {
        this.getSettingScreenMobile()
    }


    updateSettingScreenMobile() {
        const { settingScreenMobileId, productLayout } = this.state
        let url = '/api/crm/administrator/setting/screen/mobile/' + settingScreenMobileId + '/home/product';
        let data = { home_product: productLayout }
        patch(url, data)
            .then(response => {
                this.updateSettingProductListLayout()
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    async updateSettingProductListLayout() {
        let settingLayoutList = await Promise.all(this.state.settingLayoutList.map(async (item) => {
            let temp = {
                layout_number: item.layout_number,
                layout_type: item.layout_type,
                name: item.name,
            } 
            return temp
        }))
        let data = {
            settingLayoutList: JSON.stringify(settingLayoutList),
            page_name:'home'
        }
        let url = '/api/setting/product/list/layout/button'
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save setting screen mobile product list layout success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateSettingScreenMobile()
    }
    onClickCancel() {
        this.getSettingScreenMobile()
    }


    updateHomeSwiper(item) {
        let settingLayoutList = this.state.settingLayoutList.map(item => Object.assign({}, item)).slice(0, item.size)

        for (let index = settingLayoutList.length; index < item.size; index++) {
        settingLayoutList.push({
            layout_number: index,
            layout_type: null,
            name: null,
            imageList: []
        })
        }

        this.setState({
            productLayout: item.value,
            settingLayoutList: settingLayoutList,
            visible: item.size === 0 ? false : true,
        })
    }

    getRoleEnabled() {
        const { roleDetail } = this.props
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'mobile')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    render() {
        const { homeSwipeList } = this.state
        return (
            <div >
                <Row >
                    <Col span={12} style={{ textAlign: 'left' }}>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <div className="mobile-screen-content-wrapper">
                    {homeSwipeList.map(item => {
                        return (
                            <div className="item-mobile-screen-wrapper">
                                <Checkbox
                                    className='item-mobile-screen-checkbox-wrapper'
                                    checked={item.value == this.state.productLayout}
                                    onChange={() => this.updateHomeSwiper(item)}
                                />
                                <div
                                    className='item-mobile-screen-image-wrapper'
                                    onClick={() => this.updateHomeSwiper(item)}
                                >
                                    <img
                                        src={item.image}
                                        className='item-mobile-screen-image'
                                        style={{ border: item.value == this.state.productLayout ? '4px solid #48c5eb' : 'none' }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <ProductListLayoutButtonModal
                    visible={this.state.visible}
                    settingLayoutList={this.state.settingLayoutList}
                    settingLayoutTypeList={this.state.settingLayoutTypeList}
                    updateSettingLayoutList={(value) => this.setState({ settingLayoutList: value })}
                    onClose={() => this.setState({ visible: false })}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roleDetail: state.role.roleDetail,
})

export default connect(mapStateToProps, null)(HomeLayoutProductLayout);
