import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Select } from 'antd';
import { Helmet } from "react-helmet";
import { clearActiveLeftMenu } from "../../actions/leftmenu";
import { initializePackage } from "../../actions/package";
import { initializeRole } from "../../actions/role";
import { showExpiredModal } from "../../actions/systemmodal";
import LeftMenu from '../LeftMenu'
import { get } from '../../utils'
import DashboardAdministrator from './DashboardAdministrator'
import DashboardManager from './DashboardManager'
import ExpiredModal from '../CustomModal/ExpiredModal'
import Header from '../../components/Header'
import './style.scss' 
const { Option } = Select;

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tempFilter: 'day',
        }
    }
 
    getPackageDetail() {
        let url = '/api/crm/administrator/setting/package';
        get(url)
            .then(responses => {
                this.props.initializePackage(responses)
            })
            .catch(err => {
                if (err.message == 'Unauthorized') {
                    this.props.showExpiredModal()
                }
                console.log(err.message)
            })
    }

    getRoleDetail() {
        const { roleId } = this.props
        let url = '/api/account/role/detail/' + roleId;
        get(url)
            .then(responses => {
                this.props.initializeRole(responses)
            })
            .catch(err => {
                if (err.message == 'Unauthorized') {
                    this.props.showExpiredModal()
                }
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.props.clearActiveLeftMenu()
        this.getPackageDetail()
        this.getRoleDetail()
    }

    render() {
        const { roleName } = this.props
        const { tempFilter } = this.state
        let OverviewContent = () => <div>{'overview content'}</div>
        switch (roleName) {
            case 'Administrator':
                OverviewContent = () => <DashboardAdministrator tempFilter={tempFilter} />
                break;
            case 'Manager':
                OverviewContent = () => <DashboardManager tempFilter={tempFilter} />
                break;
            default:
                OverviewContent = () => <DashboardManager tempFilter={tempFilter} />
                break;
        }
        return (
            <div className="home-page-scroll">
                <div className="home-page-wrapper">
                    <Helmet>
                        <title>{"Home Dashboard"}</title>
                    </Helmet>
                    <div className="home-header-wrapper">
                        <Header title={'Dashboard'} />
                    </div>
                    <div className="left-colunm-wrapper">
                        <LeftMenu />
                    </div>
                    <div className="right-colunm-wrapper">
                        <div className="container"> 
                            <div className="header">
                                <p className="title">Statistic</p>
                                <div className="filter">
                                    <Select value={tempFilter} style={{ width: 120 }} onChange={(value) => this.setState({ tempFilter: value })}>
                                        <Option value={'day'}>{'Today'}</Option>
                                        <Option value={'week'}>{'7 Day ago'}</Option>
                                        <Option value={'month'}>{'1 Month ago'}</Option>
                                        <Option value={'year'}>{'1 Year ago'}</Option>
                                    </Select>
                                </div>
                            </div>
                            <OverviewContent />
                        </div>
                    </div>
                    <ExpiredModal visible={this.props.showModalExpired} onClose={() => null} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    roleId: state.login.roleId,
    roleName: state.login.roleName,

    showModalExpired: state.systemmodal.showModalExpired,
})

const mapDispatchToProps = (dispatch) => ({
    clearActiveLeftMenu: () => dispatch(clearActiveLeftMenu()),
    initializePackage: (payload) => dispatch(initializePackage(payload)),
    initializeRole: (payload) => dispatch(initializeRole(payload)),
    showExpiredModal: () => dispatch(showExpiredModal()),

})

export default connect(mapStateToProps, mapDispatchToProps)(Home);

