import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Card, Row, Col, Button, Icon, Table } from 'antd';
import { get, put, post } from '../../../utils';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;

class DeliveryMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            isDefault: false,
            priceList: [],

            columns: [
                {
                    title: 'Line ID',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center ',
                    width: '20%',
                    render: (text, item, index) => (index + 1).toString().padStart(5, 0)
                },
                {
                    title: 'Weight(KG)',
                    dataIndex: 'weight',
                    key: 'weight',
                    align: 'center',
                    width: '30%',
                    render: (text, item, index) => this.renderWeightColumn(text, item, index)
                },
                {
                    title: 'Price (THB)',
                    dataIndex: 'price',
                    key: 'price',
                    align: 'center',
                    width: '30%',
                    render: (text, item, index) => this.renderPriceColumn(text, item, index)
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    align: 'center',
                    width: '20%',
                    render: (text, item, index) => this.renderActionColumn(text, item, index)
                },
            ]
        }
    }

    renderWeightColumn(text, item, index) {
        return (
            <InputNumber
                style={{ width: '100%' }}
                value={item.weight}
                onChange={(value) => this.updateDeliveryMethodPrice(index, 'weight', value)}
            />
        )
    }

    renderPriceColumn(text, item, index) {
        return (
            <InputNumber
                style={{ width: '100%' }}
                value={item.price}
                onChange={(value) => this.updateDeliveryMethodPrice(index, 'price', value)}
            />
        )
    }

    renderActionColumn(text, item, index) {
        return (
            <div style={{
                margin: 5,
                marginLeft: "auto",
                marginRight: "auto",
                width: 30, height: 30, borderRadius: 15,
                backgroundColor: '#EAEAEA',
                cursor: 'pointer'
            }}
                onClick={() => this.deleteDeliveryMethodPrice(index)}
            >
                <Icon type="close" style={{ fontSize: 16, padding: 5, color: 'red' }} />
            </div>
        )
    }


    getDeliveryMethodDetail() {
        const { editId } = this.props
        let url = '/api/crm/administrator/delivery/method/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({
                    name: response.name,
                    is_default: response.is_default,
                    priceList: response.priceList
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getDeliveryMethodDetail()
        }
    }


    updateDeliveryMethod() {
        const { name } = this.state
        let url = '/api/crm/administrator/delivery/method';
        let update = post
        if (this.props.editId) {
            url = '/api/crm/administrator/delivery/method/' + this.props.editId
            update = put
        }
        let data = {
            name: name,
            is_default: this.state.isDefault,
            priceList: JSON.stringify(this.state.priceList),
            user: this.props.administratorId
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateDeliveryMethod()
    }
    onClickCancel() {
        this.setState({
            name: '',
        })
        this.props.backpage()
    }

    addDeliveryMethodPrice() {
        let priceList = this.state.priceList.map(item => Object.assign({}, item))
        let weight = 0
        let price = 0
        if (this.state.priceList.length) {
            weight = this.state.priceList[this.state.priceList.length - 1].weight + 1
            price = this.state.priceList[this.state.priceList.length - 1].price
        }
        priceList.push({ weight: weight, price: price })
        this.setState({ priceList: priceList })
    }

    updateDeliveryMethodPrice(index, name, value) {
        let priceList = this.state.priceList.map(item => Object.assign({}, item))
        priceList[index][name] = value ? parseInt(value) : 0
        this.setState({ priceList: priceList })
    }

    deleteDeliveryMethodPrice(index) {
        let priceList = this.state.priceList.filter((item, i) => i !== index)
        this.setState({ priceList: priceList })
    }
    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <div className='deliverymethod-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left' }} onClick={() => this.props.backpage()} style={{ cursor: 'pointer' }}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Delivery Method' : 'Create Delivery Method'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Name'}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row className='action-button-wrapper' gutter={24} type="flex" justify='space-between'>
                            <p className='title-header'>{'Price'}</p>
                            <Button className='create-button' onClick={() => this.addDeliveryMethodPrice()}  ><Icon type="plus" />{'Add'}</Button>
                        </Row>
                        <Table
                            dataSource={this.state.priceList}
                            columns={this.state.columns}
                            pagination={false}
                        />
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
})

export default connect(mapStateToProps, null)(DeliveryMethod);

