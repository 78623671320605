import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Popconfirm, Icon, List,notification } from 'antd';
import { get, post, ppost, put } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import config from '../../../config.json'
import './style.scss'
notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const { Meta } = Card;
const { TextArea } = Input;

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            imageList: [],
        }
    }


    getGalleryDetail() {
        const { editId, folderName } = this.props
        let url = '/api/administrator/gallery/detail/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const jsonImageList = JSON.parse(response.images)
                let imageList = jsonImageList.map(fileName => {
                    return {
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${fileName}`,
                        file: null,
                        name: fileName
                    }
                })
                this.setState({
                    name: response.name,
                    description: response.description,
                    imageList: imageList
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        if (this.props.editId) {
            this.getGalleryDetail()
        }
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateGallery() {
        const { name, description, imageList } = this.state
        if (!name || name == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Category Gallery".')
            return
        }
        let uploadFileList = imageList.filter(item => item.file)
        let uploadDataList = imageList.filter(item => !item.file).map(item => item.name)


        if (uploadFileList.length) {
            let formData = new FormData();
            uploadFileList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            fileNameList.map(fileName => {
                uploadDataList.push(fileName)
            })
        }

        let data = {
            name: name,
            description: description,
            images: JSON.stringify(uploadDataList),
            user: this.props.administratorId
        }

        if (this.props.editId) {
            let url = '/api/administrator/gallery/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/administrator/gallery/';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.props.backpage()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }


    onClickOk() {
        this.updateGallery()
    }

    onClickCancel() {
        this.setState({
            name: '',
            status: 'ยังไม่ส่ง',
            description: ''
        })
        this.props.backpage()
    }

    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        const { imageList } = this.state
        return (
            <div className='gallery-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card">
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => this.props.backpage()} >
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit  Gallery' : 'Create Gallery'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className="cardstyle">
                    <Form>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={12}  >
                                <FormItem {...formItemLayout} label={'Category'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}  >
                                <FormItem {...formItemLayout} label={'Description'}>
                                    <Input
                                        value={this.state.description}
                                        onChange={(event) => this.setState({ description: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={24}>
                                <ImageUpload
                                    label={'Upload image'}
                                    limit={99}
                                    dataSource={imageList}
                                    updateDataSource={(dataSource) => this.setState({ imageList: dataSource })}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps, null)(Gallery);

