import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import SignIn from '../../containers/SignIn'
import MainCustomerHome from '../../containers/MainCustomer/Home'
import Home from '../../containers/Home'
import './style.scss'
class Authentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { isAuthenticated, mainCustomerId, } = this.props
        if (isAuthenticated == false || isAuthenticated == 'false') {
            return <SignIn />
        } else if (mainCustomerId == null || mainCustomerId == 'null') {
            return <MainCustomerHome />
        } else {
            return <Home />
        }


    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    mainCustomerId: state.login.mainCustomerId,
})

export default connect(mapStateToProps, null)(Authentication);

