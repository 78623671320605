import React, { Component } from 'react'
import { connect } from 'react-redux';
import { loginSuccess } from '../../../actions/login';
import { post } from '../../../utils';


class EmailValidation extends Component {

    validateEmail(userAdministratorId, emailConfirmToken, emailConfirmStatus) {
        let url = '/api/user/validate/email/administrator'
        let data = { user_administrator_id: userAdministratorId, email_confirm_token: emailConfirmToken, email_confirm_status: emailConfirmStatus }
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log("response", response)
                if (response.success) {
                    this.props.loginSuccess(response.user)
                    this.props.history.push('/')
                } else {
                    console.log("response error", response)
                }

            })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const userAdministratorId = params.get('user');
        const emailConfirmToken = params.get('token');
        const emailConfirmStatus = params.get('status');
        if (userAdministratorId && emailConfirmToken) {
            this.validateEmail(userAdministratorId, emailConfirmToken, emailConfirmStatus)
        }

    }
    render() {
        return (
            <div>{'Email Validation'}</div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
})

export default connect(null, mapDispatchToProps)(EmailValidation);

