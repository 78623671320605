import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, notification } from 'antd';
import { get, put, post } from '../../../utils';

import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;
const { TextArea } = Input;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};


function PricingPlanPackageAccount(props) {
    const [name,setName] = useState('')
    const [description,setDescription] = useState('')
    const [fullPrice,setFullPrice] = useState(0)
    const [price,setPrice] = useState(0)
    const [period,setPeriod] = useState('')
    const [periodUnit,setPeriodUnit] = useState('month')

    useEffect(()=>{
        if(props.editId){
            getPackageData()
        }
    },[])

    const getPackageData = () =>{
        let url = '/api/crm/administrator/package/' + props.editId
        get(url)
            .then((response)=>{
                if(response.success){
                    setName(response.data.name)
                    setDescription(response.data.description)
                    setFullPrice(response.data.price)
                    setPrice(response.data.price_for_sale)
                    setPeriod(response.data.term)
                    setPeriodUnit(response.data.term_unit)
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    }

    const onClickOk = () => {
        if(!name){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Name".')
            return
        }
        if(!description){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Description".')
            return
        }
        if(!period){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Period".')
            return
        }

        let url = '/api/crm/administrator/package'
        let data = {
            adminId: props.administratorId,
            name,
            description,
            fullPrice,
            price,
            term: period,
            termUnit: periodUnit
        }
        
        let update = post
        if(props.editId){
            url = '/api/crm/administrator/package/' + props.editId
            update= put
        }
        
        update(url,data)
            .then((response)=>{
                if(response.success){
                    props.backpage()
                }
            })
            .catch(err => {
                console.log(err.message)
            })
        
    }

    const onClickCancel = () => {
        setName('')
        props.backpage()
    }

    const formItemLayout = {
        // labelCol: {
        //     xs: { span: 8 },
        //     sm: { span: 4 },
        // },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    return (
        <div className='categoryarticle-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
            <Row className="header-card" >
                <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => props.backpage()} >
                    <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                    <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{props.editId ? 'Edit Pricing Plan' : 'Create Pricing Plan'}</span>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => onClickCancel()}>{'Cancel'}</Button>
                    <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => onClickOk()} >{'Save'}</Button>
                </Col>
            </Row>
            <Card className='cardstyle'>
                <Form>
                    <Row gutter={24}>
                        <Col sm={24} md={24} lg={8} xl={6} >
                            <FormItem {...formItemLayout} label={'Name'} required={true}>
                                <Input
                                    value={name}
                                    onChange={(e)=> setName(e.target.value) }
                                />
                            </FormItem>
                        </Col>
                        {/* <Col sm={24} md={24} lg={8} xl={6} >
                            <FormItem {...formItemLayout} label={'Description'} required={true}>
                                <Input
                                    value={description}
                                    onChange={(e)=> setDescription(e.target.value) }
                                />
                            </FormItem>
                        </Col> */}
                        <Col sm={24} md={24} lg={8} xl={6} >
                            <FormItem {...formItemLayout} label={'Full Price'}>
                                <Input
                                    value={fullPrice}
                                    onChange={(e)=> setFullPrice(e.target.value) }
                                    addonAfter="Bath"
                                />
                            </FormItem>
                        </Col>
                        <Col sm={24} md={24} lg={8} xl={6} >
                            <FormItem {...formItemLayout} label={'Sale Price'}>
                                <Input
                                    value={price}
                                    onChange={(e)=> setPrice(e.target.value) }
                                    addonAfter="Bath"
                                />
                            </FormItem>
                        </Col>
                        <Col sm={24} md={24} lg={8} xl={6} >
                            <FormItem {...formItemLayout} label={'Period'}  required={true}>
                                <Input.Group compact>
                                    <Input 
                                        value={period} 
                                        onChange={(e)=> setPeriod(e.target.value) }
                                    />
                                    <Select
                                        value={periodUnit}
                                        onChange={(value) => setPeriodUnit(value)}
                                    >
                                        <Option value={'day'}>{'Day'}</Option>
                                        <Option value={'month'}>{'Month'}</Option>
                                        <Option value={'year'}>{'Year'}</Option>
                                    </Select>
                                </Input.Group>
                            </FormItem>
                        </Col>
                        <Col sm={24} md={24} lg={8} xl={8} >
                        <FormItem {...formItemLayout} label={'Description'} required={true}>
                                <TextArea 
                                    autosize={{ minRows: 3 }}
                                    value={description}
                                    onChange={(e)=> setDescription(e.target.value) }
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    )
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
})


export default connect(mapStateToProps)(PricingPlanPackageAccount)